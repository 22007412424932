import DateHelper from "@/helpers/DateHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
import { AuthKeyDto } from "@/models/user/AuthKeyDto";
import { AuthKeyType } from "@/models/user/AuthKeyType";
import { BitpoolOSPermissions } from "@/models/BitpoolOSPermissions";
import { CreateAuthKeyDto } from "@/models/user/CreateAuthKeyDto";
import { EditAuthKeyRequest } from "@/models/user/EditAuthKeyRequest";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import AuthState from "../states/AuthState";
import CustomWindow from '@/CustomWindow';
import { AllUserData } from "@/models/user/AllUserData";
import { AUTH_ChangePassword } from "@/models/user/AUTH_ChangePassword";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import { v4 as uuidv4 } from "uuid";
import { UserSettingsModel } from "@/models/user/UserSettingsModel";
import { UserData } from "@/models/user/UserData";
import { useOrganisationStore } from "@/stores/organisation";
import localeManager from "@/localeManager";

declare const window: CustomWindow;

const AuthKeyLSKey = "ls.bpoAuthorization";
const OrgRoleLSKey = "ls.bpoOrgRole";
const DevOptionsSaveDashboard = "dev.saveDashboard";

const emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

const getters = <GetterTree<AuthState, any>>{  
  getDashboardReadOnlyMode(state): boolean {
    const organisationStore = useOrganisationStore();
    return state.jailMode || !state.enableSaveDashboard || !organisationStore.currentOrganisation || organisationStore.currentOrganisation.Id === 7; // readonly for tcc
  },
  getJailMode(state): boolean {
    return state.jailMode;
  },
  getAllUserData(): AllUserData {
    return window.angularUserprofile.getAllUserData();
  },
  getFullUsername(state, getters): string {
    const userData = getters.getAllUserData as AllUserData;
    let result = userData && userData.userName ? userData.userName : "";
    if (userData && userData.userData && (userData.userData.Firstname || userData.userData.Lastname)) {
      result = userData.userData.Firstname ? userData.userData.Firstname : "";
      if (userData.userData.Lastname) {
        result = result.length ? `${result} ${userData.userData.Lastname}` : userData.userData.Lastname;
      }
    }
    return result;
  },
  getAvatarUrl(state, getters, rootState): string {
    const userData = getters.getAllUserData as AllUserData;
    const apiUrl = rootState.apiUrl;
    return userData && userData.userData && userData.userData.Avatar ? `${apiUrl}/rest/AWS_S3_V1/File/${userData.userData.Avatar}` : "";
  }
};

const mutations = <MutationTree<AuthState>>{
  setJailMode(state, value) {
    state.jailMode = value;
  },
  setSharedDashboardInfo(state, value) {
    state.sharedDashboardInfo = value;
  },
  setSharedWidgetInfo(state, value) {
    state.sharedWidgetInfo = value;
  },
  setPresentationInfo(state, value) {
    state.sharedPresentationInfo = value;
  },
  loadDevOptions(state) {
    // load from local storage
    const value = localStorage.getItem(DevOptionsSaveDashboard);
    if (value && value === 'false') {
      state.enableSaveDashboard = false;
    } else {
      state.enableSaveDashboard = true;
    }
  },
  setEnableSaveDashboard(state, value) {
    state.enableSaveDashboard = value;
    // save to local storage
    localStorage.setItem(DevOptionsSaveDashboard, value);
  },
  setAuthKey(state, { authKey, needSave }) {
    state.authKey = authKey;
    // save to local storage
    if (needSave) {
      localStorage.setItem(AuthKeyLSKey, authKey);
    }
    axios.defaults.headers.common["Authorization"] = authKey;
  },
  loadAuthKey(state) {
    // load from local storage
    const authKey = localStorage.getItem(AuthKeyLSKey);
    if (authKey) {
      state.authKey = authKey;
      axios.defaults.headers.common["Authorization"] = authKey;
    } else {
      state.authKey = "";
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
  setOrgRole(state, { orgRole, needSave }) {
    state.orgRole = orgRole;
    // save to local storage
    if (needSave) {
      localStorage.setItem(OrgRoleLSKey, orgRole);
    }
  },
  loadOrgRole(state) {
    // load from local storage
    const value = localStorage.getItem(OrgRoleLSKey);
    if (value) {
      state.orgRole = value;
    } else {
      state.orgRole = "";
    }
  },
  setOrganisationChangingInProgress(state, value) {
    state.organisationChangingInProgress = value;
  },
  resetNewAuthKey(state) {
    state.newAuthKey = null;
  },
};

const actions = <ActionTree<AuthState, any>>{
  async loadPermissions({ state }) {
    try {
      const url = `rest/bitpool_v2/os/Permissions`;
      const response = await axios.get<BitpoolOSPermissions>(url);
      state.permissions = response.data;
    } catch (error) {
      ToastService.showToast(
        "error",
        localeManager.t("init.cantLoadPermissions"),
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async loadAllUsers({ state }) {
    try {
      state.isLoadedAllUsers = false;
      const url = `rest/UserAdmin_V1/Users`;
      const response = await axios.get<UserData[]>(url);
      state.allUsers = response.data;
      state.isLoadedAllUsers = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load users",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.allUsers = [];
      state.isLoadedAllUsers = true;
    }
  },
  async unloadAllUsers({ state }) {
    state.isLoadedAllUsers = false;
    state.allUsers = [];
  },
  async loadAuthKeys({ state }) {
    try {
      state.isLoadedAuthKeys = false;
      const url = `public/v2/AuthKeys`;
      const response = await axios.get<AuthKeyDto[]>(url);
      if (response.data && response.data.length) {
        response.data.forEach((key) => {
          if (key.Date) {
            key.Date = DateHelper.parseFromMicrosoftString(key.Date as string);
          }
          if (typeof key.KeyType === "string") {
            key.KeyType = AuthKeyType[key.KeyType as keyof typeof AuthKeyType];
          }
        });
      }
      state.authKeys = response.data;
      state.isLoadedAuthKeys = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load api keys",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.authKeys = [];
      state.isLoadedAuthKeys = true;
    }
  },
  async createAuthKey({ state, dispatch }, body: CreateAuthKeyDto) {
    try {
      state.createAuthKeyInProgress = true;
      state.createAuthKeyError = false;
      state.newAuthKey = null;
      const url = `public/v2/AuthKeys`;
      const response = await axios.post<string>(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      state.newAuthKey = response.data;
      state.createAuthKeyInProgress = false;
      ToastService.showToast("success", "", "Changes saved", 5000);
      if (state.isLoadedAuthKeys) {
        await dispatch("loadAuthKeys");
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't creat api key",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.createAuthKeyInProgress = false;
      state.createAuthKeyError = true;
    }
  },
  async editAuthKey({ state, dispatch }, request: EditAuthKeyRequest) {
    try {
      state.createAuthKeyInProgress = true;
      state.createAuthKeyError = false;
      state.newAuthKey = null;
      const url = `public/v2/AuthKeys/${request.authKey}`;
      await axios.post(url, request.body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      state.newAuthKey = `Bitpool2 ${request.authKey}`;
      state.createAuthKeyInProgress = false;
      ToastService.showToast("success", "", "Changes saved", 5000);
      if (state.isLoadedAuthKeys) {
        await dispatch("loadAuthKeys");
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't edit api key",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.createAuthKeyInProgress = false;
      state.createAuthKeyError = true;
    }
  },
  async deleteAuthKey({ state }, key: string) {
    try {
      const url = `public/v2/AuthKeys/${key}`;
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      ToastService.showToast("success", "", "Changes saved", 5000);
      if (state.isLoadedAuthKeys) {
        const index = state.authKeys.findIndex((x) => x.Value === key);
        if (index >= 0) {
          state.authKeys.splice(index, 1);
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete api key",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async saveUserAdditionalData({ state }) {
    try {
      state.userAdditionalDataSaveError = null;
      const url = `/rest/useradmin_v1/user`;
      await axios.post(url, window.angularUserprofile.getAllUserData().userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      window.angularUserprofile.saveAllUserDataLocal();
      emitter.emit("user_updated", "");
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        localeManager.t("accountSettings.account.cantSaveUserData"),
        errorMessage,
        5000
      );
      state.userAdditionalDataSaveError = errorMessage;
    }
  },
  async changePassword({ state }, request: AUTH_ChangePassword) {
    try {
      state.changePasswordError = null;
      const url = `/rest/Auth_V3/ChangePassword`;
      await axios.post(url, request, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        localeManager.t("accountSettings.password.cantChangePassword"),
        errorMessage,
        5000
      );
      state.changePasswordError = errorMessage;
    }
  },
  async loadUserSettings({ state, dispatch }) {
    try {
      const guid = uuidv4();
      state.guidUserSettings = guid;
      state.isLoadedUserSettings = false;
      //state.userSettings = null;
      const url = `rest/bitpool_v2/os/user-settings`;
      const response = await axios.get<UserSettingsModel>(url);
      if (state.guidUserSettings === guid) {
        let needSave = false;
        if (response.data) {
          state.userSettings = response.data;
          state.isLoadedUserSettings = true;
          // set theme
          const root = document.getElementsByTagName("html")[0];
          const isDark = root.classList.contains("bitpool-dark");
          if (state.userSettings.darkTheme === undefined) {
            state.userSettings.darkTheme = isDark;
            needSave = true;
          } else if (state.userSettings.darkTheme !== isDark) {
            window.switchTheme(state.userSettings.darkTheme);
          }
        } else {
          const userData = window.angularUserprofile.getAllUserData();
          const lsDarkThemeKey = "darkTheme";
          const storedValue = localStorage.getItem(lsDarkThemeKey);
          const isDark = storedValue === undefined || storedValue === null ?
            window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches :
            JSON.parse(storedValue);
          state.userSettings = {
            Id: "",
            username: userData && userData.userName ? userData.userName : "",
            showHelp: true,
            showHolidays: true,
            darkTheme: isDark,
            selectedPersona: undefined,
            customPersona: undefined,
            isEnabledSpeech: undefined,
            locale: undefined,
            notificationsEmailInsights: false,
            notificationsEmailReports: false,
            notificationsOnSiteDashboardComments: true,
            notificationsOnSiteInsights: true,
            notificationsOnSiteReports: true
          };
          needSave = true;
          state.isLoadedUserSettings = true;
        }
        if (!state.userSettings.locale) {
          state.userSettings.locale = localeManager.currentLocale;
          needSave = true;
        }
        localeManager.changeLocale(state.userSettings.locale);
        if (needSave) {
          dispatch("saveUserSettings", true);
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        localeManager.t("init.cantLoadUserSettings"),
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.userSettings = null;
      state.isLoadedUserSettings = true;
    }
  },
  async saveUserSettings({ state }, silent = false) {
    try {
      if (state.userSettings) {
        state.userSettingsSaveError = null;
        const url = `rest/bitpool_v2/os/user-settings`;
        const response = await axios.post<string>(url, state.userSettings);
        if (!state.userSettings.Id) {
          state.userSettings.Id = response.data;
        }
      }
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      if (!silent) {
        ToastService.showToast(
          "error",
          localeManager.t("init.cantSaveUserSettings"),
          errorMessage,
          5000
        );
      }
      state.userSettingsSaveError = errorMessage;
    }
  }
};

const AuthModule = {
  namespaced: true,
  state: new AuthState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default AuthModule;
