<template>
  <BlockUI 
    :blocked="!isLoaded || updateInProgress" 
    :autoZIndex="false" 
    :baseZIndex="100" 
    class="blockui-with-spinner blockui-with-fixed-spinner blockui-with-spinner-100" 
    :class="(!isLoaded || updateInProgress) ? 'blockui-blocked' : ''"
  >
    <div v-if="isLoaded && editRecord" class="hd:flex hd:h-full">
      <div class="report-manager-content-wrapper">
        <div class="hd:h-full hd:mx-1 relative">
          <div class="report-manager-content">

            <div v-if="isCompactMode" class="report-open-settings-button-wrapper">
              <Button
                v-tippy="'Element settings'"
                @click="openSettingsDialog"
                class="report-open-settings-button"
              >
                <CogwheelSvg/>
              </Button>

              <Dialog header="Settings" v-model:visible="reports3Store.displaySettingsDialog" :modal="true" :style="{width: '62rem'}" class="report-manager-dialog report-manager-dialog-settings">
                <ReportsElementRightPanelView/>
              </Dialog>
            </div>

            <div class="formgrid grid">
              <div class="field col-12 mb-0">
                <div>
                  <div class="report-constructor-area">
                    <ReportsHtmlElementView 
                      :element="editRecord"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isCompactMode" class="report-manager-sidebar-wrapper">
        <div class="hd:h-full relative">
          <div class="report-manager-sidebar report-manager-sidebar-right">
            <ReportsElementRightPanelView/>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoaded" class="m-5">
      <Message severity="error" :closable="false">Element not found</Message>
    </div>

    <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import AuthState from "@/store/states/AuthState";
import { useReports3ElementsStore } from "@/stores/reports3Elements";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { nextTick } from "vue";
import ReportsHtmlElementView from "@/components/views/reports/ReportsHtmlElementView.vue";
import { useReports3Store } from "@/stores/reports3";
import CogwheelSvg from "@/components/svg/CogwheelSvg.vue";
import { useReports3DataStore } from "@/stores/reports3Data";
import ReportsElementRightPanelView from "@/components/views/reports/ReportsElementRightPanelView.vue";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import { Report3ElementFeatures } from "@/models/reports/v3/Report3ElementFeatures";
import { debounce } from "throttle-debounce";
import { usePageStore } from "@/stores/page";

@Component({
  components: {
    Button,
    Dialog,
    BlockUI,
    Message,
    ProgressSpinner,
    ReportsHtmlElementView,
    CogwheelSvg,
    ReportsElementRightPanelView
  },
  directives: {
  }
})
class ReportsElementView extends Vue { 
  @Prop({ required: true }) elementId!: string;

  reports3Store = useReports3Store();
  reports3ElementsStore = useReports3ElementsStore();
  reports3DataStore = useReports3DataStore();
  pageStore = usePageStore();

  get editRecord(): Reports3ElementEntity | null {
    return this.reports3ElementsStore.dataOne;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isChanged(): boolean {
    return this.pageStore.dirty;
  }

  set isChanged(value: boolean) {
    this.pageStore.dirty = value;
  }

  @Watch('elementId', { immediate: false, deep: false })
  async onIdChanged(val: string, oldVal: string): Promise<void> {
    this.reports3ElementsStore.$reset();
    this.reports3DataStore.$reset();
    await nextTick();
    if (val === "new") {
      this.isChanged = true;
    }
    this.init();
  }

  @Watch('editRecord', { immediate: false, deep: true })
  onEditRecordChanged(val: Reports3ElementEntity | null, oldVal: Reports3ElementEntity | null): void {
    if (this.reports3ElementsStore.isLoadedOne && oldVal !== null && val !== null && val.Id === oldVal.Id) {
      this.reports3ElementsStore.isOneDirty = true;
      this.isChanged = true;
    }
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created() {
    this.emitter.on("window_size_changed_debounce", this.onResize);
    this.onResize();
    if (this.elementId === "new") {
      this.isChanged = true;
    }
    this.init();
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.onResize);
  }

  async init(): Promise<void> {
    if (this.authState.permissions?.FullAccess) {
      await this.reports3Store.loadCss();
      await this.reports3ElementsStore.loadOne(this.elementId);
      //this.initDatasources(); - watch will init datasource, so we don;t need it here
    }
  }

  async initDatasources(): Promise<void> {
    if (this.reports3ElementsStore.dataOne) {
      this.reports3DataStore.load(this.reports3ElementsStore.dataOne, null, this.reports3DataStore.buildStubDataConfiguration(this.editRecord?.DataStreamsMaxCount ?? 0), null);
    }
  }

  @Watch('reports3ElementsStore.dataOne.Features', { immediate: false, deep: true })
  onFeaturesChange(val: Report3ElementFeatures[], oldVal: Report3ElementFeatures[]) {
    this.debounceFeaturesChange();
  }

  debounceFeaturesChange = debounce(1000, this.initDatasources);

  get isLoaded(): boolean {
    const result = this.reports3ElementsStore.isLoadedOne;
    return result;
  }

  get updateInProgress(): boolean {
    return this.reports3ElementsStore.updateInProgress;
  }

  modbileModeFrom = 1600;
  windowWidth = window.innerWidth;

  get isCompactMode(): boolean {
    return this.reports3Store.isCompactMode;
  }

  set isCompactMode(value: boolean) {
    this.reports3Store.isCompactMode = value;
  }

  onResize(): void {
    this.windowWidth = window.innerWidth;
    const oldValue = this.isCompactMode;
    this.isCompactMode = this.windowWidth < this.modbileModeFrom;
    if (oldValue !== this.isCompactMode) {
      this.reports3Store.displaySettingsDialog = false;
    }
  }

  openSettingsDialog(): void {
    this.reports3Store.displaySettingsDialog = true;
  }
}

export default ReportsElementView;
</script>