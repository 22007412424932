<template>
  <Dialog v-model:visible="displayCreateUpdateDialog" :modal="true" :style="{width: '62rem'}" class="theme-colors-config-dialog">
    <div class="dialog-content" v-if="editRecord">
      <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
        <div class="formgrid grid">
          <div class="field col-12 xl:col-6">
            <label for="editRecordName">Name</label>
            <div>
              <InputText 
                id="editRecordName" 
                class="inputfield w-full"
                type="text" 
                v-model="editRecord.Name"
              />
            </div>
          </div>
          <div class="field col-12 xl:col-6">
            <label for="editRecordDescription">Description</label>
            <div>
              <InputText 
                id="editRecordDescription" 
                class="inputfield w-full"
                type="text" 
                v-model="editRecord.Description"
              />
            </div>
          </div>
          <div class="col-12">
            <ul class="theme-colors-list">
              <li 
                v-for="(themeColor, index) in editRecord.Colors" 
                :key="`color-${index}-${themeColor.replaceAll('#', '')}`" 
              >
                <ColorSelectView
                  :color="themeColor" 
                  :updateColor="(color: string) => { 
                    if (editRecord) {
                      if (color === 'delete') {
                        editRecord.Colors.splice(index, 1);
                      } else {
                        editRecord.Colors[index] = color;
                      }
                    }
                  }"
                  :enableThemes="false"
                  :allowDelete="true"
                />
              </li>
              <li>
                <span @click="() => editRecord && editRecord.Colors.push(colorHelper.random())" class="theme-colors-list-add-color">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.333 11H1.666m9.333-9.333v18.667"/></svg>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #header>
      <div class="flex-auto flex justify-content-between align-items-center gap-4">
        <span class="p-dialog-title" v-if="editRecord?.Id">Update color theme</span>
        <span class="p-dialog-title" v-else>Create new color theme</span>
        <div class="color-themes-logs-switch-field pr-3" v-if="canEditGlobal">
          <div class="flex align-items-center">
            <InputSwitch 
                inputId="color-themes-edit-global"
                v-model="selectedEntityIsGlobal"
                class="vertical-align-top"
                :disabled="updateInProgress"
              />
            <label for="color-themes-edit-global" class="mb-0 ml-2">Public</label>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
        <span class="block">
          <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        </span>
        <span class="block ml-2">
          <Button 
            v-if="editRecord" 
            :label="editRecord.Id ? 'Update' : 'Create'" 
            :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
            @click="saveRecord" 
            :disabled='!editRecord.Name || updateInProgress || editRecord.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin' 
          />
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import moment from "moment";
import { ColorThemeEntity } from "@/models/organisation/ColorThemeEntity";
import { useColorThemeStore } from "@/stores/colorTheme";
import ColorSelectView from "@/components/widgets-next/settings/ColorSelectView.vue";
import ColorHelper from "@/helpers/ColorHelper";
import { useOrganisationStore } from "@/stores/organisation";

@Component({
  components: {
    Button,
    InputText,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    ColorSelectView
  },
})
class OrganisationColorsDialogView extends Vue {
  @Emit themeSaved(entity: ColorThemeEntity): ColorThemeEntity | null {
    return entity;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  colorThemeStore = useColorThemeStore();

  colorHelper = ColorHelper;

  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get selectedEntityIsGlobal(): boolean {
    return this.editRecord?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.editRecord) {
      if (value) {
        this.editRecord.OrganisationId = 0;
      } else {
        this.editRecord.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  displayCreateUpdateDialog = false;
  editRecord: ColorThemeEntity | null = null;

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  openCloneDialog(record: ColorThemeEntity): void {
    const newRecord: ColorThemeEntity = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    newRecord.Name = `${record.Name} - Copy`;
    if (!this.canEditGlobal) {
      newRecord.OrganisationId = this.currentOrganisation?.Id ?? -1;
    }
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: ColorThemeEntity | null): void {
    const nowUtc = moment.utc().toDate();
    this.editRecord = record ? JSON.parse(JSON.stringify(record)) : {
      Id: "",
      Name: "",
      Description: "",
      Colors: [],
      OrganisationId: this.currentOrganisation ? this.currentOrganisation.Id : -1,
      Created: nowUtc,
      Updated: nowUtc,
      CreatedBy: this.allUserData.userName,
      UpdatedBy: this.allUserData.userName
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.colorThemeStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.colorThemeStore.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      const result = await this.colorThemeStore.createUpdate(this.editRecord);
      if (result) {
        this.displayCreateUpdateDialog = false;
        this.themeSaved(result);
      }
    }
  }
}

export default OrganisationColorsDialogView;
</script>