import { createStore } from 'vuex';
import AuthModule from './modules/auth';
import PoolsModule from './modules/pools';
import PoolToolsModule from './modules/poolTools';
import PoolModule from './modules/pool';
import MQTTModule from './modules/mqtt';
import { TimeZoneDto } from '@/models/TimeZoneDto';
import axios from "axios";
import ToastService from '@/services/ToastService';
import ErrorHelper from '@/helpers/ErrorHelper';
import StreamModule from './modules/stream';
import HaystackModule from './modules/haystack';
import RootState from './states/RootState';
import ImportDataModule from './modules/importData';
import FlowModule from './modules/flow';
import DashboardModule from './modules/dashboard';
import WidgetDataModule from './modules/widgetData';
import ShareModule from './modules/share';
import ShareWidgetModule from './modules/shareWidget';
import PresentationModule from './modules/presentation';
import SpaceCommentsModule from './modules/spaceComments';
import MQTTServerModule from './modules/mqttServer';

export default createStore({
  state: new RootState(),
  mutations: {
    setApiUrl(state, value) {
      state.apiUrl = value;
    },
    setIsFullscreen(state, value) {
      state.isFullscreen = value;
    },
    setMinimizeNav(state, value) {
      state.minimizeNav = value;
      localStorage.setItem("minimizeNav", `${value}`);
    },
    setIsSidebarMode(state, value) {
      state.isSidebarMode = value;
    }
  },
  actions: {
    async loadTimezones({state}) {
      try {
        const url = `public/v2/timezones`;
        const response = await axios.get<TimeZoneDto[]>(url);
        state.timezones = response.data;
      } catch(error) {
        ToastService.showToast("error", "Can't load timezones", ErrorHelper.handleAxiosError(error).message, 5000);
      }
    }
  },
  modules: {
    // please don't forget to register new module in shims-vuex.d.ts too
    auth: AuthModule,
    pools: PoolsModule,
    poolTools: PoolToolsModule,
    pool: PoolModule,
    stream: StreamModule,
    mqtt: MQTTModule,
    mqttServer: MQTTServerModule,
    haystack: HaystackModule,
    importData: ImportDataModule,
    flow: FlowModule,
    dashboard: DashboardModule,
    share: ShareModule,
    shareWidget: ShareWidgetModule,
    presentation: PresentationModule,
    widgetData: WidgetDataModule,
    spaceComments: SpaceCommentsModule
  }
})
