<template>
  <div class="default-view-container">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="mb-0 lg:mr-5 page-breadcrumb"
    >
      <template #separator>
        <span class="pi pi-chevron-right" aria-hidden="true"></span>
      </template>
    </Breadcrumb>
    <div class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>{{`${flowId === 'new' ? 'Add' : 'Edit'} Flow`}}</h2>
      </header>
      <div class="default-view-body">
        <div v-if="flowState.isLoadedCurrentFlow && poolsState.isLoadedList && dashboardState.dashboards !== null && navTreeStore.isLoaded">
          <BlockUI :blocked="flowState.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index" :class="flowState.updateInProgress ? 'blockui-blocked' : ''">
            <FlowGraphView v-if="flowState.currentFlow" />
            <Message v-else severity="error" :closable="false" class="my-0">Flow not found</Message>

            <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
          </BlockUI>
        </div>
        <div
          v-else
          class="w-full flex justify-content-center align-items-center flex-auto"
          style="min-height: 50vh"
        >
          <ProgressSpinner
            class="spinner-primary"
            style="width: 100px; height: 100px"
            strokeWidth="4"
            animationDuration="1s"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Breadcrumb from "primevue/breadcrumb";
import ProgressSpinner from "primevue/progressspinner";
import Message from 'primevue/message';
import BlockUI from 'primevue/blockui';
import { Component, Prop, Vue } from "vue-facing-decorator";
import FlowGraphView from "./FlowGraphView.vue";
import FlowState from "@/store/states/FlowState";
import PoolsState from "@/store/states/PoolsState";
import { Watch } from "vue-facing-decorator";
import { nextTick } from "vue";
import DashboardState from "@/store/states/DashboardState";
import { useNavTreeStore } from "@/stores/navTree";
import { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";
import NavigationHelper from "@/helpers/NavigationHelper";
import { useNotificationGroupStore } from "@/stores/notificationGroup";
import { useAIInsightLibraryStore } from "@/stores/aiInsightLibrary";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";
import { useOrganisationStore } from "@/stores/organisation";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";

@Component({
  components: {
    Breadcrumb,
    ProgressSpinner,
    Message,
    BlockUI,
    FlowGraphView,
  },
})
class FlowView extends Vue {
  @Prop({ required: true }) flowId!: string;

  get flowState(): FlowState {
    return this.$store.state.flow;
  }

  get poolsState(): PoolsState {
    return this.$store.state.pools;
  }

  navTreeStore = useNavTreeStore();
  aiInsightLibraryStore = useAIInsightLibraryStore();
  organisationStore = useOrganisationStore();
  organisationUsersStore = useOrganisationUsersStore();
  notificationGroupStore = useNotificationGroupStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  breadcrumbHome = {
    label: "Flows",
    url: "/data/flow",
    command: (event: MenuItemCommandEvent) => {
      if (!NavigationHelper.goTo("/data/flow")) {
        event.originalEvent.preventDefault();
      }
    }
  };

  get breadcrumbItems(): MenuItem[] {
    const url = `/data/flow/${this.flowId}`;
    return this.flowState && this.flowState.currentFlow
      ? [
        {
          label: this.flowId === "new" ? "New" : this.flowState.currentFlow.Name,
          url: url,
          command: (event: MenuItemCommandEvent) => {
            if (!NavigationHelper.goTo(url)) {
              event.originalEvent.preventDefault();
            }
          }
        },
      ]
      : [];
  }

  @Watch('flowId', { immediate: false, deep: false })
  async onIdChanged(val: string, oldVal: string): Promise<void> {
    this.$store.commit("flow/unloadCurrentFlow");
    await nextTick();
    this.$store.dispatch("flow/loadOne", this.flowId);
  }

  created(): void {
    this.$store.dispatch("pools/loadPoolsList");
    this.$store.dispatch("flow/loadOne", this.flowId);
    this.organisationUsersStore.load(this.currentOrganisation?.Id || 0);
    this.notificationGroupStore.load();
    if (!this.dashboardState.dashboards) {
      this.$store.dispatch("dashboard/loadOrganisation", { id: null, silent: true });
    }
  }

  mounted() {
    this.aiInsightLibraryStore.load();
    if (!this.navTreeStore.isLoaded) {
      this.navTreeStore.load();
    }
  }

  unmounted(): void {
    const poolKeys = [];
    for (const poolKey in this.$store.state.pool.poolStreamsDictionary) {
      poolKeys.push(poolKey);
    }
    poolKeys.forEach(poolKey => {
      this.$store.commit("pool/unload", poolKey);       
    });
    this.aiInsightLibraryStore.$reset();
  }
}

export default FlowView;
</script>
