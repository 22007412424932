<template>
  <div class="default-view-container">
    <h1 class="mb-0">Organisation Stats</h1>
    <div v-if="auth.permissions?.BitpoolAdmin" class="default-view increase-padding-bottom mt-4 lg:mt-5">   
      <header class="default-view-header">
        <h2>Organisations List</h2>
      </header>
      <div class="default-view-body admin-organisation-stats">
        <DataTable
          ref="dt"
          :value="adminOrganisationStatsState.data" 
          dataKey="OrganisationId"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="750px" 
          sortField="OrganisationName" 
          :sortOrder="1"
          :paginator="true"
          :rows="20"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          v-model:filters="filters"
          filterDisplay="menu"
          :globalFilterFields="['OrganisationName']"
          class="p-datatable-sm responsive-breakpoint admin-organisation-stats-table">
          <template #header>
            <div class="table-header">
              <div class="sm:flex sm:align-items-center sm:justify-content-between sm:gap-3">
                <div class="sm:flex-shrink-0">
                  <Button 
                    label="Export" 
                    icon="pi pi-cloud-download" 
                    class="my-1"
                    @click="exportCSV"
                  />
              </div>
                <div class="mt-3 sm:mt-0">
                  <IconField iconPosition="left" class="w-full sm:w-auto">
                    <InputIcon class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Find Organisation" class="w-full sm:w-auto" />
                  </IconField>
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            <div v-if="adminOrganisationStatsState.isLoaded" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column :sortable="true" field="OrganisationName" header="Organisation" headerStyle="width: 36%; min-width: min-content;"></Column>
          <Column :sortable="true" field="PoolCount" header="Pool Count" headerStyle="width: 8%; min-width: min-content;"></Column>
          <Column :sortable="true" field="StreamCount" header="Stream Count" headerStyle="width: 8%; min-width: min-content;"></Column>
          <Column :sortable="true" field="AdminCount" header="Admin Count" headerStyle="width: 8%; min-width: min-content;"></Column>
          <Column :sortable="true" field="UserCount" header="User Count" headerStyle="width: 8%; min-width: min-content;"></Column>
          <Column :sortable="true" field="SiteCount" header="Site Count" headerStyle="width: 8%; min-width: min-content;"></Column>
          <Column :sortable="true" field="SitesArea" header="Area (m²)" headerStyle="width: 8%; min-width: min-content;"></Column>
          <Column :sortable="true" field="SpaceCount" header="Space Count" headerStyle="width: 8%; min-width: min-content;"></Column>
          <Column :sortable="true" field="EquipCount" header="Equip Count" headerStyle="width: 8%; min-width: min-content;"></Column>
        </DataTable>
      </div>
    </div>
    <div v-else class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import AuthState from "@/store/states/AuthState";
import AdminOrganisationStatsState from "@/stores/states/AdminOrganisationStatsState";
import { useAdminOrganisationStatsStore } from "@/stores/adminOrganisationStats";

@Component({
  components: {
    InputText,
    Button,
    ProgressSpinner,
    DataTable,
    Column,
    IconField,
    InputIcon
  },
})
class AdminOrganisationStatsView extends Vue {
  get auth(): AuthState {
    return this.$store.state.auth;
  }

  adminOrganisationStatsStore = useAdminOrganisationStatsStore();

  get adminOrganisationStatsState(): AdminOrganisationStatsState {
    return this.adminOrganisationStatsStore;
  }

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  created(): void {
    this.adminOrganisationStatsStore.loadData();
  }

  exportCSV() {
    (this.$refs.dt as DataTable)?.exportCSV();
  }
}

export default AdminOrganisationStatsView;
</script>
