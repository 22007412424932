<template>
  <div>
    <DataTable
      :value="reports3Store.data?.Items" 
      dataKey="Id"
      :totalRecords="reports3Store.data?.Total"
      :paginator="true"
      :rows="reports3Store.take"
      :lazy="true"
      @page="onPage($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint data-table">
      <template #header>
        <div class="table-header">
          <div class="md:flex md:align-items-center md:justify-content-between md:gap-3">
            <div class="md:flex-shrink-0">
              <Button 
                label="Add Report"
                icon="pi pi-plus-circle"
                class="my-1"
                @click="openItem(null)"
              />
            </div>
            <div class="mt-3 md:mt-0">
              <IconField iconPosition="left" class="w-full md:w-auto">
                <InputIcon class="pi pi-search" />
                <InputText 
                  v-model="search"
                  @input="debounceSearch()"
                  placeholder="Find Report" 
                  class="w-full md:w-auto" 
                />
              </IconField>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div v-if="reports3Store.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Name" header="Name" headerStyle="min-width: min-content;" headerClass="no-break-word">
        <template #body="slotProps">
          <span class="block with-inline-btn">
            {{ slotProps.data.Name }}
            <span v-if="slotProps.data.OrganisationId === 0" v-tippy="'Public'" class="table-cell-icon">
              <i class="pi pi-globe"></i>
            </span>
          </span>
        </template>
      </Column>
      <Column 
        :exportable="false" 
        headerStyle="width: 1%; min-width: 128px;" 
        bodyStyle="text-align: right; justify-content: flex-end;"
      >
        <template #body="slotProps">
          <div>
            <div class="inline-flex">
              <Button 
                icon="pi pi-clone" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="cloneItem(slotProps.data)"
                v-tippy="'Copy'"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openItem(slotProps.data)"
                v-tippy="'Edit'"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                :disabled="slotProps.data.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin"
                v-tippy="'Delete'"
              />
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import AuthState from "@/store/states/AuthState";
import ConfirmationService from "@/services/ConfirmationService";
import { useReports3Store } from "@/stores/reports3";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import NavigationHelper from "@/helpers/NavigationHelper";
import { AllUserData } from "@/models/user/AllUserData";
import moment from "moment";
import { debounce } from "throttle-debounce";

@Component({
  components: {
    Button,
    DataTable,
    Column,
    Message,
    ProgressSpinner,
    IconField,
    InputIcon,
    InputText
  },
  directives: {
  }
})
class ReportsReportsView extends Vue { 
  reports3Store = useReports3Store();

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isAvailable(): boolean | undefined {
    const result = (this.authState.permissions?.FullAccess || this.authState.permissions?.Reports);
    return result;
  }

  mounted() {
    this.search = this.reports3Store.search;
    this.searchFinal = this.reports3Store.search;
    this.loadData();
  }

  search = '';
  searchFinal = '';

  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  onInputChanged(): void {
    this.loadData();
  }

  loadData(): void {
    if (this.isAvailable) {
      this.reports3Store.load(this.reports3Store.skip, this.reports3Store.take, this.searchFinal);
    }
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.reports3Store.skip = event.page * event.rows;
    this.reports3Store.take = event.rows;
    this.loadData();
  }

  get allUserData() : AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  async cloneItem(record: Reports3Entity): Promise<void> {
    const clone: Reports3Entity = JSON.parse(JSON.stringify(record));
    clone.Id = "";
    clone.Name += " - Copy"
    const nowUtc = moment.utc().toDate();
    clone.Created = nowUtc;
    clone.Updated = nowUtc;
    clone.CreatedBy = this.allUserData.userName;
    clone.UpdatedBy = this.allUserData.userName;
    const result = await this.reports3Store.createUpdate(clone);
    if (result) {
      this.openItem(result);
    }
  }

  openItem(record: Reports3Entity | null): void {
    NavigationHelper.goTo(`/report-manager/report/${record ? record.Id : 'new'}`);
  }

  // #region delete
  selectedRecord: Reports3Entity | null = null;

  openConfirmation(record: Reports3Entity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete ${this.selectedRecord?.Name}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.selectedRecord) {
          await this.reports3Store.delete(this.selectedRecord);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion delete
}

export default ReportsReportsView;
</script>