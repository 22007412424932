<template>
  <BlockUI 
    :blocked="!reports3Store.isLoadedOne || !aiInsightLibraryStore.isLoaded || updateInProgress" 
    :autoZIndex="false" 
    :baseZIndex="100"  
    class="blockui-with-spinner blockui-with-fixed-spinner blockui-with-spinner-100" 
    :class="(!reports3Store.isLoadedOne || !aiInsightLibraryStore.isLoaded || updateInProgress) ? 'blockui-blocked' : ''"
  >
    <div v-if="reports3Store.isLoadedOne && aiInsightLibraryStore.isLoaded && editRecord" class="hd:flex hd:h-full">
      <div 
        v-if="reports3Store.editMode && !isCompactMode"
        class="report-manager-sidebar-wrapper"
      >
        <div class="hd:h-full relative">
          <div class="report-manager-sidebar">
            <ReportsReportLeftPanelView/>
          </div>
        </div>
      </div>

      <div class="report-manager-content-wrapper">
        <div class="hd:h-full hd:mx-1 relative">
          <div class="report-manager-content">

            <div v-if="isCompactMode" class="report-open-settings-button-wrapper">
              <Button
                v-tippy="'Report settings'"
                @click="openSettingsDialog"
                class="report-open-settings-button"
              >
                <CogwheelSvg/>
              </Button>
              
              <Dialog header="Configuration" v-model:visible="reports3Store.displayConfigurationDialog" :modal="true" :style="{width: '62rem'}" class="report-manager-dialog report-manager-dialog-configuration">
                <template #header>
                  <span class="p-dialog-title">{{ reports3Store.getSelectedElementTitle }}</span>
                </template>
                <ReportsReportLeftPanelView/>
              </Dialog>
              <Dialog header="Settings" v-model:visible="reports3Store.displaySettingsDialog" :modal="true" :style="{width: '62rem'}" class="report-manager-dialog report-manager-dialog-settings">
                <ReportsReportRightPanelView/>
              </Dialog>
            </div>

            <div class="formgrid grid">
              <div class="field col-12 mb-0">
                <div class="overflow-x-auto">
                  <div class="report-constructor-area" :class="reports3Store.editMode ? 'edit-mode' : 'preview-mode'">
                    <!-- Header -->
                    <div
                      class="report-section report-header"
                      :class="`${reports3Store.editMode && editRecord.Header && editRecord.Header.Uid === reports3Store.dataOneSelectedElementConfiguration?.Uid ? 'is-selected' : ''}`"
                      id="reportConstructorElementContainer-header"
                    >
                      <div 
                        v-if="reports3Store.editMode"
                        class="report-section-head"
                      >
                        <div class="report-section-head-inner" @click="editRecord.Header && selectElement(editRecord.Header)"></div>
                        <Button
                          v-tippy="'Add Header'"
                          class="p-button-half-white"
                          @click="openElementsDialog($event, Reports3ElementRole.Header)"
                          :disabled="reports3Store.inProgressTest"
                        >
                          <AddReportElementSvg/>
                        </Button>
                        <Button
                          v-if="editRecord.Header && reports3Store.isCompactMode"
                          v-tippy="'Edit'"
                          @click="openEditDialog($event, editRecord.Header)"
                          class="p-button-half-white"
                        >
                          <ReportEditSvg/>
                        </Button>
                        <Button
                          v-if="editRecord.Header"
                          v-tippy="'Delete'"
                          @click="deleteHeader"
                          class="p-button-half-white"
                        >
                          <ReportDeleteSvg/>
                        </Button>
                        <!-- <Button
                          v-tippy="'More Actions'"
                          class="p-button-half-white"
                        >
                          <ReportMoreActionsSvg/>
                        </Button> -->
                      </div>
                      <div class="report-section-inner">
                        <ReportsHtmlElementView
                          v-if="editRecord.Header && getElement(editRecord.Header.ElementId)"
                          :element="getElement(editRecord.Header.ElementId)" 
                          :configuration="editRecord.Header"
                          :report="editRecord"
                        />
                      </div>
                    </div>

                    <!-- Body -->
                    <div
                      class="report-section report-body"
                      :class="`${reports3Store.editMode && !reports3Store.dataOneSelectedElementConfiguration ? 'is-selected' : ''}`"
                      id="reportConstructorElementContainer-body"
                    >
                      <div 
                        v-if="reports3Store.editMode"
                        class="report-section-head"
                      >
                        <div class="report-section-head-inner" @click="reports3Store.dataOneSelectedElementConfiguration = null"></div>
                        <Button
                          v-tippy="'Add Grid'"
                          class="p-button-half-white"
                          @click="addGrid"
                          :disabled="reports3Store.inProgressTest"
                        >
                          <AddReportGridSvg/>
                        </Button>
                        <Button
                          v-tippy="'Add Element'"
                          @click="openElementsDialog($event, Reports3ElementRole.Body)"
                          class="p-button-half-white"
                        >
                          <AddReportElementSvg/>
                        </Button>
                        <Button
                          v-if="reports3Store.isCompactMode"
                          v-tippy="'Edit'"
                          @click="openEditDialog($event, null)"
                          class="p-button-half-white"
                        >
                          <ReportEditSvg/>
                        </Button>
                        <Button
                          v-tippy="'More Actions'"
                          @click="showAdditionalMenu($event)"
                          class="p-button-half-white"
                        >
                          <ReportMoreActionsSvg/>
                        </Button>
        
                        <Menu ref="additionalMenu" :model="additionalMenuItems" :popup="true" />
                      </div>
                      <div
                        class="report-section-inner"
                        :style="`padding-top: ${editRecord.MarginTop}cm; padding-bottom: ${editRecord.MarginBottom}cm; padding-left: ${editRecord.MarginLeft}cm; padding-right: ${editRecord.MarginRight}cm;`"
                      >
                        <ReportsHtmlGridView 
                          v-model="editRecord.Items" 
                          :report="editRecord"
                        />
                      </div>
                    </div>

                    <!-- Footer -->
                    <div
                      class="report-section report-footer"
                      :class="`${reports3Store.editMode && editRecord.Footer && editRecord.Footer.Uid === reports3Store.dataOneSelectedElementConfiguration?.Uid ? 'is-selected' : ''}`"
                      id="reportConstructorElementContainer-footer"
                    >
                      <div 
                        v-if="reports3Store.editMode"
                        class="report-section-head"
                      >
                        <div class="report-section-head-inner" @click="editRecord.Footer && selectElement(editRecord.Footer)"></div>
                        <Button
                          v-tippy="'Add Footer'"
                          class="p-button-half-white"
                          @click="openElementsDialog($event, Reports3ElementRole.Footer)"
                          :disabled="reports3Store.inProgressTest"
                        >
                          <AddReportElementSvg/>
                        </Button>
                        <Button
                          v-if="editRecord.Footer && reports3Store.isCompactMode"
                          v-tippy="'Edit'"
                          @click="openEditDialog($event, editRecord.Footer)"
                          class="p-button-half-white"
                        >
                          <ReportEditSvg/>
                        </Button>
                        <Button
                          v-if="editRecord.Footer"
                          v-tippy="'Delete'"
                          @click="deleteFooter"
                          class="p-button-half-white"
                        >
                          <ReportDeleteSvg/>
                        </Button>
                        <!-- <Button
                          v-tippy="'More Actions'"
                          class="p-button-half-white"
                        >
                          <ReportMoreActionsSvg/>
                        </Button> -->
                      </div>
                      <div class="report-section-inner">
                        <ReportsHtmlElementView
                          v-if="editRecord.Footer && getElement(editRecord.Footer.ElementId)"
                          :element="getElement(editRecord.Footer.ElementId)" 
                          :configuration="editRecord.Footer"
                          :report="editRecord"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div 
        v-if="!isCompactMode"
        class="report-manager-sidebar-wrapper"
      >
        <div class="hd:h-full relative">
          <div class="report-manager-sidebar report-manager-sidebar-right">
            <ReportsReportRightPanelView/>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="reports3Store.isLoadedOne" class="m-5">
      <Message severity="error" :closable="false">Report not found</Message>
    </div>

    <ReportsSelectElementDialogView
      v-model="displayElementsDialog"
      :elementRole="elementRole"
      @elementSelected="addElement"
    />

    <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-facing-decorator";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Menu from "primevue/menu";
import { MenuItem } from "primevue/menuitem";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import { useOrganisationStore } from "@/stores/organisation";
import { useReports3Store } from "@/stores/reports3";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import { nextTick, reactive } from "vue";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { v4 as uuidv4 } from "uuid";
import ReportsHtmlGridView from "@/components/views/reports/ReportsHtmlGridView.vue";
import ReportsSelectElementDialogView from "@/components/views/reports/ReportsSelectElementDialogView.vue";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import ReportsHtmlElementView from "@/components/views/reports/ReportsHtmlElementView.vue";
import AddReportElementSvg from "@/components/svg/AddReportElementSvg.vue";
import AddReportGridSvg from "@/components/svg/AddReportGridSvg.vue";
import ReportMoreActionsSvg from "@/components/svg/ReportMoreActionsSvg.vue";
import ReportDeleteSvg from "@/components/svg/ReportDeleteSvg.vue";
import ReportEditSvg from "@/components/svg/ReportEditSvg.vue";
import CogwheelSvg from "@/components/svg/CogwheelSvg.vue";
import ReportsReportLeftPanelView from "@/components/views/reports/ReportsReportLeftPanelView.vue";
import ReportsReportRightPanelView from "@/components/views/reports/ReportsReportRightPanelView.vue";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import { useNavTreeStore } from "@/stores/navTree";
import { useReports3DataStore } from "@/stores/reports3Data";
import { RDRSModel } from "@/models/reports/v3/RDRSModel";
import { debounce } from "throttle-debounce";
import { usePageStore } from "@/stores/page";
import { useReports3SchedulesStore } from "@/stores/reports3Schedules";
import ConfirmationService from "@/services/ConfirmationService";
import { useAIInsightLibraryStore } from "@/stores/aiInsightLibrary";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";

@Component({
  components: {
    Accordion,
    AccordionTab,
    Button,
    InputText,
    InputNumber,
    Dialog,
    BlockUI,
    InputSwitch,
    SelectButton,
    Message,
    ProgressSpinner,
    Dropdown,
    TabView,
    TabPanel,
    Menu,
    ReportsHtmlGridView,
    ReportsSelectElementDialogView,
    ReportsHtmlElementView,
    AddReportElementSvg,
    AddReportGridSvg,
    ReportMoreActionsSvg,
    ReportDeleteSvg,
    ReportEditSvg,
    CogwheelSvg,
    ReportsReportLeftPanelView,
    ReportsReportRightPanelView
  },
  directives: {
  }
})
class ReportsReportView extends Vue { 
  @Prop({ required: true }) reportId!: string;

  reports3Store = useReports3Store();
  reports3DataStore = useReports3DataStore();
  reports3SchedulesStore = useReports3SchedulesStore();
  navTreeStore = useNavTreeStore();
  organisationStore = useOrganisationStore();
  pageStore = usePageStore();
  aiInsightLibraryStore = useAIInsightLibraryStore();

  get editRecord(): Reports3Entity | null {
    return this.reports3Store.dataOne;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get isChanged(): boolean {
    return this.pageStore.dirty;
  }

  set isChanged(value: boolean) {
    this.pageStore.dirty = value;
  }

  @Watch('reportId', { immediate: false, deep: false })
  async onIdChanged(val: string, oldVal: string): Promise<void> {
    this.reports3Store.$reset();
    this.reports3DataStore.$reset();
    await nextTick();
    this.init();
  }

  @Watch('editRecord', { immediate: false, deep: true })
  onEditRecordChanged(val: Reports3Entity | null, oldVal: Reports3Entity | null): void {
    if (this.reports3Store.isLoadedOne && oldVal !== null && val !== null && val.Id === oldVal.Id) {
      this.reports3Store.isOneDirty = true;
      this.isChanged = true;
    }
  }

  @Watch('reports3Store.dataOneSchedules', { immediate: false, deep: true })
  onDataOneSchedulesChanged(val: string[] | null, oldVal: string[] | null): void {
    if (this.reports3Store.isLoadedOne && oldVal !== null && val !== null) {
      this.reports3Store.isOneSchedulesDirty = true;
      this.isChanged = true;
    }
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created() {
    this.emitter.on("window_size_changed_debounce", this.onResize);
    this.onResize();
    this.init();
    this.reports3SchedulesStore.loadLight();
  }

  mounted() {
    this.aiInsightLibraryStore.load();
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.onResize);
    this.aiInsightLibraryStore.$reset();
  }

  async init(): Promise<void> {
    if (this.authState.permissions?.FullAccess || this.authState.permissions?.Reports) {
      await this.reports3Store.loadOne(this.reportId);
      // Edit - default view mode for new, Preview - default view mode for old
      this.reports3Store.editMode = !this.reports3Store.dataOne?.Id;
      this.initDatasources();

      // load streams for data sources
      if (!this.navTreeStore.isLoaded) {
        this.navTreeStore.load();
      }
      if (!this.navTreeStore.unstructuredIsLoaded) {
        this.navTreeStore.loadUnstructured();
      }
    }
  }

  async initDatasources(): Promise<void> {
    if (this.reports3Store.dataOne) {
      // Load data sources
      const elementConfigurations = this.reports3Store.findAllElementConfigurations(this.reports3Store.dataOne.Items);
      const promises = [];
      for (const elementConfiguration of elementConfigurations) {
        const datasource = this.reports3Store.dataOne.Datasources.find((x) => x.Uid === elementConfiguration.DatasourceId);
        if (!datasource) {
          continue;
        }
        const element = this.getElement(elementConfiguration.ElementId);
        if (!element) {
          continue;
        }
        const promise = this.reports3DataStore.load(element, elementConfiguration, datasource, this.reports3Store.dataOne);
        promises.push(promise);
      }
      await Promise.all(promises);
    }
  }

  @Watch('reports3Store.dataOne.DateRange', { immediate: false, deep: true })
  onDateRangeChange(val: RDRSModel, oldVal: RDRSModel) {
    if (val && oldVal) {
      this.debounceDateRangeChange();
    }
  }

  debounceDateRangeChange = debounce(1000, this.initDatasources);
  
  modbileModeFrom = 1600;
  windowWidth = window.innerWidth;

  get isCompactMode(): boolean {
    return this.reports3Store.isCompactMode;
  }

  set isCompactMode(value: boolean) {
    this.reports3Store.isCompactMode = value;
  }

  onResize(): void {
    this.windowWidth = window.innerWidth;
    const oldValue = this.isCompactMode;
    this.isCompactMode = this.windowWidth < this.modbileModeFrom;
    if (oldValue !== this.isCompactMode) {
      this.reports3Store.displayConfigurationDialog = false;
      this.reports3Store.displaySettingsDialog = false;
    }
  }

  get updateInProgress(): boolean {
    return this.reports3Store.updateInProgress;
  }

  async addGrid(): Promise<void> {
    if (this.editRecord) {
      const item = this.reports3Store.createGridConfiguration();
      this.editRecord.Items.push(item);
      this.reports3Store.dataOneSelectedElementConfiguration = item;
      // Scroll to the new element
      await nextTick();
      const id = `reportConstructorGridContainer-${item.Uid}`;
      const targetScrollElement = document.getElementById(id);
      if (targetScrollElement) {
        targetScrollElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  displayElementsDialog = false;
  elementRole = Reports3ElementRole.Body;
  Reports3ElementRole = Reports3ElementRole;

  openElementsDialog(event: Event, role: Reports3ElementRole): void {
    this.elementRole = role;
    this.displayElementsDialog = true;
  }

  async addElement(element: Reports3ElementEntity): Promise<void> {
    if (this.editRecord) {
      const item = this.reports3Store.createElementConfiguration(element);
      switch (this.elementRole) {
        //case Reports3ElementRole.Body:
        default:
          this.editRecord.Items.push(item);
          break;
        case Reports3ElementRole.Header:
          this.editRecord.Header = item;
          break;
        case Reports3ElementRole.Footer:
          this.editRecord.Footer = item;
          break;
      }
      this.reports3Store.dataOneSelectedElementConfiguration = item;
      this.displayElementsDialog = false;
      // Scroll to the new element
      await nextTick();
      const id = `reportConstructorElementContainer-${item.Uid}`;
      const targetScrollElement = document.getElementById(id);
      if (targetScrollElement) {
        targetScrollElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3Store.dataOneElements?.length) {
      const element = this.reports3Store.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }

  selectElement(elementConfiguration: Reports3ElementConfiguration): void {
    this.reports3Store.selectElement(elementConfiguration);
  }

  deleteHeader(event: Event): void {
    const message = `Are you sure you want to delete header?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (this.editRecord) {
          this.editRecord.Header = null;
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteFooter(event: Event): void {
    const message = `Are you sure you want to delete footer?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (this.editRecord) {
          this.editRecord.Footer = null;
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  openSettingsDialog(): void {
    this.reports3Store.displaySettingsDialog = true;
  }

  openEditDialog(event: Event, elementConfiguration: Reports3ElementConfiguration | null): void {
    if (elementConfiguration) {
      this.selectElement(elementConfiguration);
    } else {
      this.reports3Store.dataOneSelectedElementConfiguration = null;
    }
    if (this.reports3Store.isCompactMode) {
      this.reports3Store.displayConfigurationDialog = true;
    }
  }

  // #region additional actions
  @Ref() readonly additionalMenu!: Menu;

  showAdditionalMenu(event: Event): void {
    this.reports3Store.dataOneSelectedElementConfiguration = null;
    this.additionalMenu.toggle(event);
  }
  
  get additionalMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    result.push({
      label: 'Paste',
      icon: undefined,
      command: () => {
        if (this.reports3Store.dataOne?.Items && this.reports3Store.copiedConfiguration) {
          this.pasteConfiguration(this.reports3Store.dataOne.Items, this.reports3Store.copiedConfiguration, this.reports3Store.cutOrCopy);
        }
      },
      disabled: !this.reports3Store.copiedConfiguration
    });
    return result;
  }

  regenerateUids(source: Reports3ElementConfiguration): void {
    source.Uid = uuidv4();
    if (source.Items) {
      source.Items.forEach((x) => this.regenerateUids(x));
    }
  }

  findParent(source: Reports3ElementConfiguration, items: Reports3ElementConfiguration[]): Reports3ElementConfiguration[] | null {
    for (const item of items) {
      if (item.Uid === source.Uid) {
        return items;
      }
      if (item.Items) {
        const result = this.findParent(source, item.Items);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  pasteConfiguration(destination: Reports3ElementConfiguration[], source: Reports3ElementConfiguration, cutOrCopy: boolean): void {
    // cutOrCopy: false - cut, true - copy
    if (cutOrCopy) {
      // copy
      const newItem = JSON.parse(JSON.stringify(source)) as Reports3ElementConfiguration;
      this.regenerateUids(newItem);
      destination.push(newItem);
      this.reloadDataWithChild(newItem);
    } else {
      // cut
      const newItem = source;
      if (this.reports3Store.dataOne?.Items) {
        const parent = this.findParent(source, this.reports3Store.dataOne.Items);
        if (parent) {
          const index = parent.findIndex((x) => x.Uid === source.Uid);
          if (index !== -1) {
            parent.splice(index, 1);
          }
        }
        destination.push(newItem);
      }
    }
  }

  reloadDataWithChild(elementConfiguration: Reports3ElementConfiguration): void {
    if (elementConfiguration.Role === Reports3ItemRole.Grid) {
      if (elementConfiguration.Items) {
        elementConfiguration.Items.forEach((x) => this.reloadDataWithChild(x));
      }
    } else {
      this.reloadData(elementConfiguration);
    }
  }

  reloadData(elementConfiguration: Reports3ElementConfiguration): void {
    const element = this.getElement(elementConfiguration.ElementId);
    const datasource = this.reports3Store.dataOne?.Datasources.find(x => x.Uid === elementConfiguration.DatasourceId);
    if (element && datasource) {
      this.reports3DataStore.load(element, elementConfiguration, datasource, this.reports3Store.dataOne);
    }
  }
  // #endregion additional actions
}

export default ReportsReportView;
</script>