<template>
  <div class="bp-textarea-with-speech">
    <Textarea
      v-model="newMessage"
      :placeholder="placeholder"
      :disabled="disabled"
      style="white-space: pre-wrap;" 
      :autoResize="autoResize" 
      :rows="rows"
      class="w-full"
    />
    <div>
      <Button 
        @click="toggleMic" 
        :disabled="!isSRSupported || disabled" 
        label="Speech" 
        :icon="recognitionInProgress && (speechRecognitionStore.startedManually || speechRecognitionStore.commandRecognized) ? 'pi pi-stop-circle' : 'pi pi-microphone'" 
        class="flex-shrink-0 p-button-icon-only p-button-text" />
    </div>
  </div>
</template>

<script lang="ts">
import { useSpeechRecognitionStore } from "@/stores/speechRecognition";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import { Component, Prop, Vue, Model } from "vue-facing-decorator";

@Component({
  components: {
    Textarea,
    Button
  },
})
class TextAreaSpeech extends Vue {
  @Model({ required: true }) newMessage!: string;
  @Prop({ required: false, default: "Hey Bitpool" }) placeholder!: string;
  @Prop({ required: false, default: false }) disabled!: boolean;
  @Prop({ required: false, default: false }) autoResize!: boolean;
  @Prop({ required: false, default: 3 }) rows!: number;
  
  speechRecognitionStore = useSpeechRecognitionStore();
  
  mounted() {
    this.speechRecognitionStore.currentReciever = this;
    this.speechRecognitionStore.startWaitingForSpeech();
  }

  unmounted() {
    this.speechRecognitionStore.currentReciever = null;
    this.speechRecognitionStore.stopWaitingForSpeech();
    this.speechRecognitionStore.stop();
  }

  get isSRSupported(): boolean {
    return  this.speechRecognitionStore.isSupported;
  }

  get recognitionInProgress(): boolean {
    return this.speechRecognitionStore.recognitionInProgress;
  }

  toggleMic(): void {
    if (this.isSRSupported) {
      if (this.recognitionInProgress) {
        this.speechRecognitionStore.stop();
      } else {
        this.speechRecognitionStore.start(this.newMessage);
      }
    }
  }

  get inProgress(): boolean {
    return this.disabled;
  }

  async sendMessage(): Promise<string> {
    return "";
  }
}

export default TextAreaSpeech;
</script>