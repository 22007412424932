import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import DateHelper from "@/helpers/DateHelper";
import OrganisationUsersState from "./states/OrganisationUsersState";
import { UserInOrganisationDto } from "@/models/user/UserInOrganisationDto";
import { AddUsersToOrganisation } from "@/models/user/AddUsersToOrganisation";
import { UserInOrganisationRegResultDto } from "@/models/user/UserInOrganisationRegResultDto";
import { UserFullInOrganisation2Dto } from "@/models/user/UserFullInOrganisation2Dto";

export const useOrganisationUsersStore = defineStore('organisationUsers', {
  state: (): OrganisationUsersState => ({ 
    data: {},
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number) {
      const organisationIdStr = organisationId.toString();
      try {
        const guid = uuidv4();
        this.data[organisationIdStr] = {
          guid: guid,
          isLoaded: false,
          entities: null
        }
        const url = `rest/BitPool_V2/organisations/${organisationId}/users2`;
        const response = await axios.get<UserFullInOrganisation2Dto[]>(url);
        if (this.data[organisationIdStr] && this.data[organisationIdStr].guid === guid) {
          response.data.forEach(x => {
            x.DateAdded = DateHelper.parseFromMicrosoftString(x.DateAdded as string);
            x.LastLogin = DateHelper.parseFromMicrosoftString(x.LastLogin as string);
          });
          this.data[organisationIdStr].entities = response.data;
          this.data[organisationIdStr].isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load organisation members",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data[organisationIdStr].entities = null;
        this.data[organisationIdStr].isLoaded = true;
      }
    },
    async save(organisationId: number, body: AddUsersToOrganisation) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/organisations/users`;
        const response = await axios.put<UserInOrganisationRegResultDto[]>(url, body);
        await this.load(organisationId);
        const pendingUserrs = response.data.filter(x => !x.RegisteredUser);
        const successUserrs = response.data.filter(x => x.RegisteredUser);
        if (pendingUserrs.length) {
          ToastService.showToast("success", "Organisation", `Invite was sent to: ${pendingUserrs.map(x => x.UserName).join(", ")}`, 5000);
        }
        if (successUserrs.length) {
          ToastService.showToast("success", "Organisation", `Successfully saved: ${successUserrs.map(x => x.UserName).join(", ")}`, 5000);
        }
        this.updateInProgress = false;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save organisation members",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
    },
    async delete(organisationId: number, body: UserInOrganisationDto[]) {
      const organisationIdStr = organisationId.toString();
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/BitPool_V2/organisations/users`;
        await axios.delete(url, {
          headers: {
            "Content-Type": "application/json",
          },
          data: body,
        });
        ToastService.showToast("success", "Organisation", `Changes saved`, 5000);
        this.deleteInProgress = false;
        const data = this.data[organisationIdStr];
        if (data?.isLoaded && data?.entities) {
          for (const user of body) {
            const index = data.entities.findIndex((x) => x.Id === user.Id);
            if (index > -1) {
              data.entities.splice(index, 1);
            }
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete members`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
      }
    }
  },
})
