<template>
  <div class="default-view-container semantics-container">
    <div class="default-view-head-section flex align-items-center justify-content-between gap-2">
      <h1 class="mb-0 md:mr-5 w-auto flex-shrink-0">Semantics</h1>
      <Button 
        v-if="navTreeStore.isLoaded && !tagManagerStore.isMiniMode && selectedStatTreeNodes.length > 0" 
        label="Map" 
        icon="pi pi-map" 
        class="p-button-outlined flex-shrink-0 md:ml-auto hidden md:flex"
        @click="selectNone()" 
        :disabled="uiIsBlocked" 
      />
      <Button 
        v-if="navTreeStore.isLoaded" 
        label="Multi-Tagging" 
        icon="pi pi-tags" 
        class="p-button-outlined flex-shrink-0 hidden md:flex" 
        :class="{ 'md:ml-auto': !(!tagManagerStore.isMiniMode && selectedStatTreeNodes.length > 0) }"
        @click="openMultiTagging()" 
        :disabled="uiIsBlocked" 
      />
      <Button 
        v-if="navTreeStore.isLoaded" 
        label="Backup & Restore" 
        icon="pi pi-history" 
        class="p-button-outlined flex-shrink-0 hidden md:flex"
        @click="openBackupRestore()" 
        :disabled="uiIsBlocked" 
        :visible="authState.permissions?.FullAccess"
      />
      <Button 
        label="Settings" 
        icon="pi pi-cog" 
        class="p-button-outlined flex-shrink-0 hidden md:flex" 
        @click="gotoConfig()" 
        :disabled="uiIsBlocked" 
      />
      <Button
        icon="pi pi-ellipsis-h text-2xl" 
        class="p-button-text p-button-text-neutral p-button-icon-only p-button-rounded flex-shrink-0 ml-auto md:hidden"
        @click="toggleActionsMenu"
        :disabled="uiIsBlocked" 
      />
      <Menu 
        id="semantics_actions" 
        ref="semantics_actions" 
        :model="actionsMenuItems" 
        :popup="true" 
      />
    </div>
    <div class="default-view mt-4 lg:mt-5 semantics">
      <header class="default-view-header">
        <div class="flex flex-wrap sm:flex-nowrap justify-content-between align-items-center column-gap-3 row-gap-2">
          <h2 class="mb-0 flex-shrink-0">Model Manager</h2>
          <div class="semantics-config-search mb-0 w-full sm:w-22rem">
            <IconField iconPosition="left" class="search-input-box">
              <InputIcon class="pi pi-search"></InputIcon>
              <InputText
                class="inputfield"
                placeholder="Search"
                type="text"
                v-model="search"
                @input="debounceSearch()"
                :disabled="uiIsBlocked"
              />
            </IconField>
          </div>
        </div>
        <div class="semantics-summary" v-if="summaryIsReady">
          <TagManagerSummaryView
            class="hidden md:flex"
            :summarySites="summarySites"
            :summarySpaces="summarySpaces"
            :summaryEquips="summaryEquips"
            :summaryMeters="summaryMeters"
            :summaryPoints="summaryPoints"
            :summaryArea="summaryArea"
          />

          <div class="semantics-summary-show-text block md:hidden mt-3">
            <Button 
              link
              :label="showDetails ? 'Hide details' : 'View details'" 
              :icon="showDetails ? 'pi pi-chevron-up ml-auto' : 'pi pi-chevron-down ml-auto'"
              iconPos="right"
              @click="showDetails = !showDetails" 
              class="semantics-summary-show-text-link"
            />
          
            <Transition name="p-toggleable-content">
              <div v-if="showDetails">
                <TagManagerSummaryView
                  :summarySites="summarySites"
                  :summarySpaces="summarySpaces"
                  :summaryEquips="summaryEquips"
                  :summaryMeters="summaryMeters"
                  :summaryPoints="summaryPoints"
                  :summaryArea="summaryArea"
                />
              </div>
            </Transition>
          </div>
        </div>
      </header>
      <div class="default-view-body">
        <BlockUI :blocked="uiIsBlocked" :autoZIndex="false" :baseZIndex="100"  class="h-full blockui-with-spinner blockui-with-overlay-z-index with-opacity" :class="uiIsBlocked ? 'blockui-blocked' : ''">
          <div class="flex flex-column h-full">
            <TagManagerSelectorView 
              v-if="tagManagerStore.isMiniMode"
              v-model="selectedStatTreeNodes"
            />

            <TabMenu v-if="tagManagerStore.isMiniMode" v-model:activeIndex="activeTabIndex" :model="tabs" class="flex-shrink-0" />

            <div v-if="summaryIsReady && haystackDefsStore.isLoaded && navTreeStore.isLoaded && navTreeStore.structuredDataForUI" class="he-tree-with-checkbox grid mt-0 flex-auto lg:ml-0">
              <div 
                v-show="!tagManagerStore.isMiniMode || activeTabIndex === 0"
                class="he-tree-wrapper col-12 lg:col-6"
              >
                <div class="flex align-items-center pt-1 flex-shrink-0 semantics-drag-drop">
                  <InputSwitch v-model="enableDragDrop" inputId="enable-dnd" class="vertical-align-top" />
                  <label for="enable-dnd" class="mb-0 ml-2">Enable Drag & Drop</label>
                </div>
                <div class="flex-auto relative">
                  <!-- If tree will be without checkboxes, change indent to :indent="22". 33 = $checkboxWidth + 1px + $inlineSpacing -->
                  <Draggable
                    id="tag-manager-tree"
                    v-model="modelForTree" 
                    :defaultOpen="false"
                    :dragOpenDelay="500"
                    :disableDrag="!enableDragDrop"
                    :disableDrop="!enableDragDrop"
                    :statHandler="statHandler"
                    textKey="label" 
                    :indent="33" 
                    :virtualizationPrerenderCount="50" 
                    :watermark="false" 
                    ref="tree"
                    virtualization
                    :each-draggable="eachDraggable"
                    :each-droppable="eachDroppable"
                    :rootDroppable="false"
                    @change="treeChange"
                    updateBehavior="new"
                  >
                    <template #default="{ node, stat }">
                      <Button 
                        :icon="stat.open ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" 
                        text 
                        rounded
                        @click="toggleNode(stat)" 
                        v-if="stat.children?.length"
                        class="p-link"
                      />
                      <div 
                        :class="{ 'tree-node-selected': stat.checkedSingle, 'tree-node-selectable': true, 'tree-node-without-children': !stat.children?.length }" 
                        @contextmenu="openMenu($event, stat)"
                        @touchstart="touchStartContextMenu($event, stat)"
                        @touchmove="touchMoveContextMenu($event, stat)"
                        @touchend="touchEndContextMenu($event, stat)"
                      >
                        <Checkbox v-model="stat.checkedSingle" :binary="true" @change="checkedIsChangedClick(stat)"/>
                        <span  
                          @click="itemSelected(stat)"
                          class="flex align-items-center"
                        >
                          <span v-if="node.icon" :class="node.icon" class="tree-node-icon"></span>
                          <span class="tree-node-label">{{ node.label }}</span>
                        </span>
                      </div>
                    </template>
                  </Draggable>
                </div>
                <div class="flex-shrink-0 flex gap-2 align-items-center semantics-add-delete-site">
                  <Button v-if="selectedStatTreeNodes.length > 0" label="Delete Selected" icon="pi pi-trash" class="p-button-outlined p-button-danger w-full" @click="deleteSelected" />
                  <Button label="Add Site" icon="pi pi-plus" class="p-button-outlined w-full" @click="openCreateEntityDialog(null)" :disabled="uiIsBlocked || !haystackDefsStore.isLoaded || !navTreeStore.isLoaded"/>
                </div>
              </div>
              <TagManagerTagsView 
                v-show="selectedStatTreeNodes.length > 0 && (!tagManagerStore.isMiniMode || activeTabIndex === 1 || activeTabIndex === 2)"
                v-model="selectedStatTreeNodes"
                class="semantics-settings-tags-panel col-12 lg:col-6 flex-column h-full"
              />
              <TagManagerMapView 
                v-if="!tagManagerStore.isMiniMode && selectedStatTreeNodes.length === 0"
                class="semantics-settings-tags-panel semantics-settings-tags-map-panel col-12 lg:col-6 flex flex-column h-full py-0 pl-0"
                @siteChanged="siteChangedByMap"
              />
            </div>
            <div v-else class="progress-spinner-container min-h-full">
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </BlockUI>

        <!-- todo: Remove me! -->
        <!-- <div v-if="haystackDefsStore.isLoaded">
          <label>Test Definitions</label>
          <TestDefsView/>
        </div> -->

      </div>
    </div>

    <ContextMenu ref="menu" :model="menuItems" class="tree-context-menu" />

    <Dialog header="Duplicate" v-model:visible="displayDuplicateDialog" :modal="true" :style="{width: '36rem'}" class="duplicate-dialog">
      <div>
        <div class="field mb-0">
          <label for="duplicateTreeNode">Create</label>
          <div>
            <InputNumber
              class="inputfield w-full"
              v-model="duplicateAmount"
              :minFractionDigits="0"
              :maxFractionDigits="0"
              :min="1"
              id="duplicateTreeNode"
            />
          </div>
          <span class="block mt-2" id="duplicateTreeNodeHelp">{{ duplicateAmount > 1 ? "Duplicates" : "Duplicate" }} of <b class="font-semibold">{{ duplicateNodes.map(x => x.data.label).join(', ') }}</b> {{ duplicateNodes.length > 1 ? "nodes" : "node" }}</span>
        </div>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeDuplicateDialog" class="p-button-text p-button-secondary"/>
        <Button label="Save" :icon="tagManagerStore.duplicateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="duplicateNode" :disabled='tagManagerStore.duplicateInProgress' />
      </template>
    </Dialog>

    <Dialog header="Add Entity" v-model:visible="displayCreateEntityDialog" :modal="true" :style="{width: '36rem'}" >
      <div class="dialog-content">
        <BlockUI :blocked="tagManagerStore.createNodeInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="tagManagerStore.createNodeInProgress ? 'blockui-blocked' : ''">
          <div class="field">
            <label for="entityType">Type</label>
            <div>
              <Dropdown
                v-model="newEntityType"
                :options="availableEntityTypes"
                id="entityType"
                class="w-full"
              />
            </div>
          </div>
          <div class="field" :class="{ 'mb-0': newEntityType !== 'site' }">
            <label for="entityName">Name</label>
            <div>
              <InputText
                id="newEntityName"
                class="inputfield w-full"
                type="text"
                v-model="newEntityName"
                @keyup.enter="createEntity"
              />
            </div>
          </div>
          <div class="field mb-0" v-if="newEntityType === 'site'">
            <label for="newLocation">Location</label>
            <div class="flex align-items-center gap-3">
              <span>{{ newLocation ? newLocation.formatted_address : "Not Selected" }}</span>
              <span class="block flex-shrink-0">
                <Button v-tippy="'Remove Location'" icon="pi pi-trash" class="p-button-icon-only p-button-outlined p-button-danger p-button-rounded mr-2" v-if="newLocation" @click="newLocation = null"/>
                <Button v-tippy="'Select Location'" icon="pi pi-map-marker" class="p-button-icon-only p-button-outlined p-button-rounded" @click="locationIsOpen = true"/>
              </span>
              <SelectLocationView v-model="locationIsOpen" @locationChanged="locationChanged"/>
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeCreateEntityDialog" class="p-button-text p-button-secondary"/>
        <Button label="Save" :icon="tagManagerStore.createNodeInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="createEntity" :disabled='tagManagerStore.createNodeInProgress || !newEntityName' />
      </template>
    </Dialog>

    <Dialog header="Attach Points" v-model:visible="displaySelectStreams" class="attach-point-dialog" :modal="true" :breakpoints="{'1400px': '70vw', '1024px': '85vw', '640px': '90vw'}" :style="{width: '60vw'}">
      <div class="dialog-content">
        <BlockUI :blocked="tagManagerStore.changeTagsInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="tagManagerStore.changeTagsInProgress ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <!-- Not-structured -->
            <div class="field col-12">
              <label for="unstructuredDataStreams">
                Unstructured Data
              </label>
              <div>
                <TreeWithCheckboxesView
                  v-if="navTreeStore.isLoaded && navTreeStore.unstructuredDataForUI"
                  :nodes="navTreeStore.unstructuredDataForUI"
                  :changeSelected="unstructuredChangeSelected"
                  placeholder="Find Streams"
                />
                <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6" animationDuration="1s" />
              </div>
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeSelectStreamsDialog" class="p-button-text p-button-secondary"/>
        <Button label="Add" icon="pi pi-check" @click="addSelectedStreams" :disabled="!unstructuredSelectedNodes.length" />
      </template>
    </Dialog>

    <TagManagerMultiTaggingDialogView v-model="displayMultiTagging" :nodesFlat="nodesFlat"/>

    <Dialog 
      header="Semantics Backup" 
      v-model:visible="displayBackupRestore" 
      :modal="true" 
      class="semantics-config-dialog" 
      :breakpoints="{'1599.98px': '56rem','991.98px': '90%'}" 
      :style="{width: '62rem'}"
    >
      <BackupRestoreSemanticsView/>
      <template #footer></template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import InputText from 'primevue/inputtext';
import Checkbox from "primevue/checkbox";
import InputSwitch from "primevue/inputswitch";
import BlockUI from "primevue/blockui";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import TabMenu from 'primevue/tabmenu';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { Component, Ref, Vue } from "vue-facing-decorator";
import { debounce } from 'throttle-debounce';
import { useNavTreeStore } from "@/stores/navTree";
import { Draggable } from '@he-tree/vue';
import { Stat } from "@he-tree/tree-utils";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import TreeHelper from "@/helpers/TreeHelper";
import { dragContext } from '@he-tree/vue';
import { useHaystackDefsStore } from "@/stores/haystackDefs";
import TestDefsView from "@/components/views/tags/TestDefsView.vue";
import TagManagerTagsView from "@/components/views/tags/TagManagerTagsView.vue";
import TagManagerMapView from "@/components/views/tags/TagManagerMapView.vue";
import NavigationHelper from "@/helpers/NavigationHelper";
import { useUnitsStore } from "@/stores/units";
import ContextMenu from "primevue/contextmenu";
import { MenuItem } from "primevue/menuitem";
import RootState from "@/store/states/RootState";
import EventBusHelper from "@/helpers/EventBusHelper";
import { useTagManagerStore } from "@/stores/tagManager";
import { Emitter } from "mitt";
import { BP_TagChangeStreams } from "@/models/BP_TagChangeStreams";
import ToastService from "@/services/ToastService";
import ConfirmationService from "@/services/ConfirmationService";
import AuthState from "@/store/states/AuthState";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import TreeWithCheckboxesView from "@/components/views/TreeWithCheckboxesView.vue";
import SelectLocationView from "@/components/views/SelectLocationView.vue";
import { GoogleMapLocation } from "@/models/ChangeLocationEvent";
import HaystackDefsService from "@/services/HaystackDefsService";
import TagManagerSelectorView from "@/components/views/tags/TagManagerSelectorView.vue";
import TagManagerSummaryView from "@/components/views/tags/TagManagerSummaryView.vue";
import TagManagerMultiTaggingDialogView from "@/components/views/tags/TagManagerMultiTaggingDialogView.vue";
import { nextTick, reactive } from "vue";
import saveAs from "file-saver";
import { useOrganisationStore } from "@/stores/organisation";
import { useSystemStore } from "@/stores/system";
import BackupRestoreSemanticsView from "@/components/views/tags/BackupRestoreSemanticsView.vue";
import Menu from "primevue/menu";

@Component({
  components: {
    Button,
    DataTable,
    Column,
    ProgressSpinner,
    InputText,
    Checkbox,
    InputSwitch,
    BlockUI,
    Dialog,
    InputNumber,
    Dropdown,
    TabMenu,
    Menu,
    IconField,
    InputIcon,
    Draggable,
    TestDefsView,
    TagManagerTagsView,
    TagManagerMapView,
    ContextMenu,
    TreeWithCheckboxesView,
    SelectLocationView,
    TagManagerSelectorView,
    TagManagerSummaryView,
    TagManagerMultiTaggingDialogView,
    BackupRestoreSemanticsView
  },
})
class TagManagerView extends Vue {
  navTreeStore = useNavTreeStore();
  haystackDefsStore = useHaystackDefsStore();
  unitsStore = useUnitsStore();
  tagManagerStore = useTagManagerStore();
  get rootState(): RootState {
    return this.$store.state;
  }
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();
  systemStore = useSystemStore();

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }

  get uiIsBlocked(): boolean {
    return this.tagManagerStore.changeTagsInProgress ||
      this.tagManagerStore.deleteNodesInProgress ||
      this.tagManagerStore.duplicateInProgress ||
      this.tagManagerStore.copyToOrganisationinProgress ||
      this.tagManagerStore.createNodeInProgress;
  }

  get modelForTree(): TreeNodeForUI[] | null {
    return this.navTreeStore.structuredDataForUI;
  }

  set modelForTree(value: TreeNodeForUI[] | null) {
    this.navTreeStore.structuredDataForUI = value;
  }

  checkedNodes: Record<string, Stat<TreeNodeForUI>> = {};

  created(): void {
    // sidebar
    this.emitter.on("window_size_changed_debounce", this.onResize);
    this.onResize();

    // url parameters to variables
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has("search")) {
      this.search = urlSearchParams.get("search") as string;
    }

    // are changes event
    this.emitter.on("semantics_area_changed", this.onAreaChanged);
  }

  mounted():void {
    this.init();
  }

  unmounted() {
    this.navTreeStore.$reset();
    // sidebar
    this.emitter.off("window_size_changed_debounce", this.onResize);
    // are changes event
    this.emitter.off("semantics_area_changed", this.onAreaChanged);
  }

  async init(): Promise<void> {
    // load data
    if (!this.navTreeStore.unstructuredIsLoaded) {
      // don't await here, we need unstructured data only for point attachments
      this.navTreeStore.loadUnstructured();
    }
    if (!this.navTreeStore.isLoaded) {
      await this.navTreeStore.load();
    }

    // search
    if (this.search) {
      await nextTick();
      this.updateFinalSearch();
    }
    
    this.refreshSummary();
  }

  summaryIsReady = false;
  summarySites = 0;
  summarySpaces = 0;
  summaryEquips = 0;
  summaryMeters = 0;
  summaryPoints = 0;
  summaryArea = 0;
  showDetails = false;

  refreshSummary(silent = false): void {
    if (!silent) {
      this.summaryIsReady = false;
    }
    this.summarySites = 0;
    this.summarySpaces = 0;
    this.summaryEquips = 0;
    this.summaryMeters = 0;
    this.summaryPoints = 0;
    this.summaryArea = 0;
    if (this.navTreeStore.structuredDataForUI?.length) {
      const result = this.refreshSummaryLoop(this.navTreeStore.structuredDataForUI);
      this.summarySites = result[0];
      this.summarySpaces = result[1];
      this.summaryEquips = result[2];
      this.summaryMeters = result[3];
      this.summaryPoints = result[4];
      this.summaryArea = result[5];
    }
    this.summaryIsReady = true;
  }

  onAreaChanged(): void {
    if (this.navTreeStore.structuredDataForUI?.length) {
      let totalArea = 0;
      this.navTreeStore.structuredDataForUI.forEach(node => {
        const areaTag = node.tags?.find(x => x.startsWith("area="));
        if (areaTag) {
          const area = this.calculateArea(areaTag);
          totalArea += area;
        }
      })
      this.summaryArea = totalArea;
    }
  }

  calculateArea(areaTag: string): number {
    let areaValue = areaTag.split("=")[1];
    let area = 0;
    const isFt = areaValue.includes("ft2") || areaValue.includes("ft²");
    if (isFt) {
      const value = parseFloat(areaValue.replace("ft2", "").replace("ft²", "").trim());
      if (!isNaN(value)) {
        area += 0.092903 * value;
      }
    } else {
      const value = parseFloat(areaValue.replace("m2", "").replace("m²", "").trim());
      if (!isNaN(value)) {
        area += value;
      }
    }
    return area;
  }

  refreshSummaryLoop(nodes: TreeNodeForUI[]): [number, number, number, number, number, number] {
    const result: [number, number, number, number, number, number] = [0, 0, 0, 0, 0, 0];
    nodes.forEach(node => {
      if (node.children?.length) {
        const res = this.refreshSummaryLoop(node.children);
        result[0] += res[0];
        result[1] += res[1];
        result[2] += res[2];
        result[3] += res[3];
        result[4] += res[4];
        result[5] += res[5];
      }
      if (node.tags?.length) {
        for (const tag of node.tags) {
          if (tag === "space") {
            result[1]++;
            break;
          } else if (tag === "equip") {
            result[2]++;
            if (node.tags?.find(x => x === "meter")) {
              result[3]++;
            }
            break;
          } else if (tag === "point") {
            result[4]++;
            break;
          } else if (tag === "site") {
            result[0]++;
            const areaTag = node.tags?.find(x => x.startsWith("area="));
            if (areaTag) {
              const area = this.calculateArea(areaTag);
              result[5] += area;
            }
            break;
          }
        }
      }
    });
    return result;
  }

  gotoConfig(): void {
    NavigationHelper.goTo(`/semantics/settings`);
  }

  toggleActionsMenu(event: Event): void {
    if (this.$refs.semantics_actions) {
      (this.$refs.semantics_actions as Menu).toggle(event);
    }
  }

  get actionsMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    result.push({ 
      label: "Map",
      command: () => this.selectNone(),
      visible: this.navTreeStore.isLoaded && !this.tagManagerStore.isMiniMode && this.selectedStatTreeNodes.length > 0,
      disabled: this.uiIsBlocked
    });
    result.push({ 
      label: "Multi-Tagging",
      command: () => this.openMultiTagging(),
      visible: this.navTreeStore.isLoaded,
      disabled: this.uiIsBlocked
    });
    result.push({ 
      label: "Backup & Restore",
      command: () => this.openBackupRestore(),
      visible: this.navTreeStore.isLoaded && this.authState.permissions?.FullAccess,
      disabled: this.uiIsBlocked
    });
    result.push({ 
      label: "Settings",
      command: () => this.gotoConfig(),
      disabled: this.uiIsBlocked
    });
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  // #region Backup & Restore
  displayBackupRestore = false;

  openBackupRestore(): void {
    this.displayBackupRestore = true;
  }
  // #endregion Backup & Restore

  // #region mini mode
  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();
  miniModeDisabledFrom = 992;
  windowWidth = window.innerWidth;

  onResize(): void {
    this.windowWidth = window.innerWidth;
    const oldValue = this.tagManagerStore.isMiniMode;
    this.tagManagerStore.isMiniMode = this.windowWidth < this.miniModeDisabledFrom;
    if (this.tagManagerStore.isMiniMode && !oldValue) {
      this.tagManagerStore.activeTabMiniMode = 0;
    }
    if (!this.tagManagerStore.isMiniMode && oldValue) {
      this.tagManagerStore.activeTab = 0;
    }
  }

  get activeTabIndex(): number {
    return this.tagManagerStore.activeTabMiniMode;
  }
  set activeTabIndex(value: number) {
    this.tagManagerStore.activeTabMiniMode = value;
  }
  get tabs(): MenuItem[] {
    const result: MenuItem[] = [
      {
        label: 'Entity Tree',
      },
      {
        label: 'Model',
        disabled: !this.tagManagerStore.activeNode
      },
      {
        label: 'Tag Editor',
        disabled: !this.tagManagerStore.activeNode
      }
    ];
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }
  // #endregion mini mode
  
  // #region context menu
  @Ref() readonly menu!: ContextMenu;
  // Timer for long touch detection
  timerLongTouch: number | undefined = undefined;
  // Long touch flag for preventing "normal touch event" trigger when long touch ends
  longTouch = false;

  cutNodes: Stat<TreeNodeForUI>[] = [];
  // cut - true, copy - false
  isCut = true;

  hasTag(node: Stat<TreeNodeForUI>, tag: string): boolean {
    return !!node.data.tags?.includes(tag);
  }

  menuItemSelectFix(): void {
    if (this.contextMenuNode) {
      if (!this.selectedTreeNodes.find(x => x.key === this.contextMenuNode?.data?.key)) {
        if (!this.contextMenuNode.checkedSingle) {
          this.itemSelected(this.contextMenuNode);
        }
      }
    }
  }

  get menuItems(): MenuItem[] {
    return [{
      label: 'Add Entity',
      icon: undefined,
      command: () => {
        if (this.contextMenuNode) {
          this.openCreateEntityDialog(this.contextMenuNode)
        }
      },
      visible: this.contextMenuNode && !this.hasTag(this.contextMenuNode, "point")
    }, {
      label: 'Attach Points',
      icon: undefined,
      command: () => {
        if (this.contextMenuNode) {
          this.openSelectStreamsDialog(this.contextMenuNode)
        }
      },
      visible: this.contextMenuNode && this.hasTag(this.contextMenuNode, "equip")
    }, {
      label: 'Edit',
      icon: undefined,
      command: () => {
        if (this.contextMenuNode) {
          this.tagManagerStore.activeTabMiniMode = 1;
        }
      },
      visible: this.tagManagerStore.isMiniMode
    }, {
      label: 'Cut',
      icon: undefined,
      command: () => {
        if (this.contextMenuNode) {
          const selectedNodes = this.getSelectedNodes();
          const cantCutNodes: Stat<TreeNodeForUI>[] = [];
          for (let i = selectedNodes.length - 1; i >= 0; i--) {
            if (this.hasTag(selectedNodes[i], "site")) {
              cantCutNodes.push(selectedNodes[i]);
              selectedNodes.splice(i, 1);
            }
          }
          if (cantCutNodes.length > 0) {
            ToastService.showToast(
              "warn", 
              "Cannot cut nodes", 
              `Cannot cut ${cantCutNodes.length} nodes: ${cantCutNodes.map(x => x.data.label).join(", ")}`, 
              5000
            );
          }
          if (selectedNodes.length > 0) {
            this.cutNodes = selectedNodes;
            ToastService.showToast(
              "info", 
              "Cut nodes", 
              `Cut ${selectedNodes.length} nodes: ${selectedNodes.map(x => x.data.label).join(", ")}`, 
              5000
            );
          } else {
            this.cutNodes = [];
          }
          this.isCut = true;
        }
      },
      visible: (this.contextMenuNode?.level ?? 0) > 1
    }, {
      label: 'Copy',
      icon: undefined,
      command: () => {
        if (this.contextMenuNode) {
          const selectedNodes = this.getSelectedNodes();
          const cantCutNodes: Stat<TreeNodeForUI>[] = [];
          for (let i = selectedNodes.length - 1; i >= 0; i--) {
            if (this.hasTag(selectedNodes[i], "site")) {
              cantCutNodes.push(selectedNodes[i]);
              selectedNodes.splice(i, 1);
            }
          }
          if (cantCutNodes.length > 0) {
            ToastService.showToast(
              "warn", 
              "Cannot copy nodes", 
              `Cannot copy ${cantCutNodes.length} nodes: ${cantCutNodes.map(x => x.data.label).join(", ")}`, 
              5000
            );
          }
          if (selectedNodes.length > 0) {
            this.cutNodes = selectedNodes;
            ToastService.showToast(
              "info", 
              "Copy nodes", 
              `Copy ${selectedNodes.length} nodes: ${selectedNodes.map(x => x.data.label).join(", ")}`, 
              5000
            );
          } else {
            this.cutNodes = [];
          }
          this.isCut = false;
        }
      },
      visible: (this.contextMenuNode?.level ?? 0) > 1
    }, {
      label: 'Paste',
      icon: undefined,
      command: async () => {
        const newParent = this.contextMenuNode;
        if (newParent) {
          const canPasteNodes: Stat<TreeNodeForUI>[] = [];
          const cantPasteNodes: Stat<TreeNodeForUI>[] = [];
          this.cutNodes.forEach(node => {
            if (this.canDropHere(node, newParent)) {
              canPasteNodes.push(node);
            } else {
              cantPasteNodes.push(node);
            }
          });
          if (cantPasteNodes.length > 0) {
            ToastService.showToast(
              "warn",
              "Cannot paste nodes",
              `Cannot paste ${cantPasteNodes.length} nodes: ${cantPasteNodes.map(x => x.data.label).join(", ")}`,
              5000
            );
          }
          if (canPasteNodes.length > 0) {
            if (this.isCut) {
              // cut - paste
              const tagsAdd: Set<string> = new Set<string>();
              const tagsRemove: Set<string> = new Set<string>();
              const streamKeys: string[] = [];
              for (const node of canPasteNodes) {
                const request = this.buildChangeRefsRequest(node, newParent);
                if (request) {
                  const streamKey = node.data.key ?? "";
                  streamKeys.push(streamKey);
                  request.TagsAdd.forEach(tag => {
                    tagsAdd.add(tag);
                  });
                  request.TagsRemove.forEach(tag => {
                    tagsRemove.add(tag);
                  })
                } else {
                  ToastService.showToast(
                    "error",
                    "Cannot copy entity",
                    "Selected node don't have any entity tag, please fix it",
                    5000
                  );
                  return;
                }
              }
              const request: BP_TagChangeStreams = {
                StreamKeys: streamKeys,
                TagsRemove: Array.from(tagsRemove),
                TagsAdd: Array.from(tagsAdd)
              };
              const updateResult = await this.tagManagerStore.changeTags(request);
              if (updateResult) {
                for (const streamKey of streamKeys) {
                  const apiTags = updateResult[streamKey];
                  const node = canPasteNodes.find(x => x.data.key === streamKey);
                  if (node && apiTags) {
                    this.tree?.move(node, newParent);
                    this.tagManagerStore.replaceTreeNodeTags(node.data, apiTags);
                  }
                }
                newParent.open = true;
                ToastService.showToast(
                  "success", 
                  "Success", 
                  `Moved ${canPasteNodes.length} nodes: ${canPasteNodes.map(x => x.data.label).join(", ")}`, 
                  5000
                );
              }
            } else {
              // copy - paste
              const streamKeys = canPasteNodes.map(x => x.data.key ?? "");
              const destinationKey = newParent.data.key ?? "";
              const result = await this.tagManagerStore.copyNodesTo(streamKeys, destinationKey);
              if (result) {
                const createdNodes: string[] = [];
                for (const originalNode of canPasteNodes) {
                  const streamKey = originalNode.data.key ?? "";
                  const copyNodeResult = result[streamKey];
                  if (copyNodeResult) {
                    if (copyNodeResult.Error) {
                      ToastService.showToast(
                        "error",
                        "Cannot copy node",
                        copyNodeResult.Error,
                        5000
                      )
                    } else {
                      const isRoot = copyNodeResult.Result.Nodes[0].Tags.includes("site");
                      const newNodes = this.navTreeStore.navTreeToUIStructure(copyNodeResult.Result.Nodes, isRoot);
                      if (newNodes.length > 0) {
                        this.tree?.addMulti(
                          newNodes,
                          newParent
                        );
                      }
                      // tree is not updating data, so we need to update
                      for (const node of newNodes) {
                        newParent.data?.children?.push(node);
                      }
                      newNodes.forEach(element => {
                        createdNodes.push(element.label ?? "");
                      })
                    }
                  }
                }
                newParent.open = true;
                // show success message
                ToastService.showToast(
                  "success", 
                  "Success", 
                  `Created ${createdNodes.length} nodes: ${createdNodes.join(", ")}`, 
                  5000
                );
              }
            }
          }
        }
      },
      visible: this.cutNodes.length > 0 && this.contextMenuNode && !this.hasTag(this.contextMenuNode, "point")
    }, {
      label: 'Delete',
      icon: undefined,
      command: () => {
        this.deleteSelected();
      }
    }, {
      label: 'Duplicate',
      icon: undefined,
      command: () => {
        this.openDuplicateDialog();
      },
      visible: this.contextMenuNode && !this.hasTag(this.contextMenuNode, "point")
    }, {
      label: 'Select All',
      icon: undefined,
      command: async () => {
        const isItOkToChangeSelection = await this.tagManagerStore.isItOkToChangeSelection();
        if (isItOkToChangeSelection) {
          const nodes = this.getSelectedNodes();
          nodes.forEach(element => {
            if (element.data?.key) {
              element.checkedSingle = false;
              delete this.checkedNodes[element.data.key];
            }
          });
          if (this.contextMenuNode) {
            let newSelectedNodes: Stat<TreeNodeForUI>[] = [];
            if (this.contextMenuNode.parent) {
              // select all children
              newSelectedNodes = [...this.contextMenuNode.parent.children];
            } else {
              // select all root
              newSelectedNodes = [...this.tree.stats]
            }
            newSelectedNodes.forEach(element => {
              if (element.data?.key) {
                element.checkedSingle = true;
                this.checkedNodes[element.data.key] = element;
              }
            });
            this.checkedIsChangedForMany(newSelectedNodes);
          }
        }
      }
    }, {
      label: 'Deselect',
      icon: undefined,
      command: async () => {
        await this.selectNone();
      }
    }, {
      label: 'Export as JSON',
      icon: undefined,
      command: () => {
        this.exportTree();
      }
    }, {
      label: 'See Data',
      icon: undefined,
      command: () => {
        if (this.contextMenuNode?.data) {
          const newUrl = `/data/streams/${this.contextMenuNode.data.key}`;
          window.open(newUrl, '_blank');
        }
      }
    }];
  }

  contextMenuNode: Stat<TreeNodeForUI> | undefined;

  openMenu(event: Event, node: Stat<TreeNodeForUI>) {
    this.contextMenuNode = node;
    this.menuItemSelectFix();
    this.menu.show(event);
  }

  touchStartContextMenu(event: Event, node: Stat<TreeNodeForUI>) {
    // contextmenu event is not compatible with iOS - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event 
    if (this.systemStore.isIOs) {
      // Timer for long touch detection
      this.timerLongTouch = window.setTimeout(() => {
        // Flag for preventing "normal touch event" trigger when touch ends.
        this.longTouch = true;
      }, 700);
    }
  }

  touchMoveContextMenu(event: Event, node: Stat<TreeNodeForUI>) {
    // contextmenu event is not compatible with iOS - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event 
    if (this.systemStore.isIOs) {
      // If timerLongTouch is still running, then this is not a long touch
      // (there is a move) so stop the timer
      clearTimeout(this.timerLongTouch);

      if (this.longTouch) {
        this.longTouch = false;
      }
    }
  }

  touchEndContextMenu(event: Event, node: Stat<TreeNodeForUI>) {
    // contextmenu event is not compatible with iOS - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event 
    if (this.systemStore.isIOs) {
      // If timerLongTouch is still running, then this is not a long touch
      // so stop the timer
      clearTimeout(this.timerLongTouch);

      if (this.longTouch) {
        this.longTouch = false;
        this.openMenu(event, node);
      }
    }
  }

  deleteSelected(): void {
    const selectedNodes = this.getSelectedNodes();
    if (selectedNodes.length > 0) {
      const message = `Are you sure you want to delete ${selectedNodes.length} nodes: ${selectedNodes.map(x => x.data.label).join(", ")}?`;
      ConfirmationService.showConfirmation({
        message: message,
        header: 'Delete Nodes',
        icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectClass: 'p-button-secondary p-button-text',
        accept: async () => {
          // callback to execute when user confirms the action
          const deleteResult = await this.tagManagerStore.deleteNodes(selectedNodes.map(x => x.data.key ?? ""));
          if (deleteResult) {
            this.tree?.removeMulti(selectedNodes);
            ToastService.showToast(
              "success", 
              "Success", 
              `Deleted ${selectedNodes.length} nodes: ${selectedNodes.map(x => x.data.label).join(", ")}`, 
              5000
            );
            // refresh selected nodes
            selectedNodes.forEach(element => {
              element.checkedSingle = false;
              // update summary
              if (element.data.tags?.includes("site")) {
                this.summarySites--;
                const areaTag = element.data.tags?.find(x => x.startsWith("area="));
                if (areaTag) {
                  const area = this.calculateArea(areaTag);
                  this.summaryArea -= area;
                }
              } else if (element.data.tags?.includes("space")) {
                this.summarySpaces--;
              }  else if (element.data.tags?.includes("equip")) {
                this.summaryEquips--;
                if (element.data.tags?.includes("meter")) {
                  this.summaryMeters--;
                }
              } else if (element.data.tags?.includes("point")) {
                this.summaryPoints--;
              }
            })
            this.checkedIsChangedForMany(selectedNodes);
          }
        },
        reject: () => {
          // callback to execute when user rejects the action
        }
      });
    }
  }

  async selectNone(): Promise<void> {
    const isItOkToChangeSelection = await this.tagManagerStore.isItOkToChangeSelection();
    if (isItOkToChangeSelection) {
      const nodes = this.getSelectedNodes();
      nodes.forEach(element => {
        if (element.data?.key) {
          element.checkedSingle = false;
          delete this.checkedNodes[element.data.key];
        }
      });
      this.checkedIsChangedForMany(nodes);
    }
  }
  // #endregion context menu

  // #region export
  exportTree(): void {
    if (this.contextMenuNode) {
      const tree = this.iterateExport([this.contextMenuNode]);
      const str = JSON.stringify(tree[0]);
      const blob = new Blob([str], {type: "text/plain;charset=utf-8"});
      saveAs(blob, "model.json");
    }
  }

  iterateExport(items: Stat<TreeNodeForUI>[]): any[] {
    const result: any[] = [];
    items.forEach(element => {
      result.push({
        name: element.data.label,
        tags: element.data.tags,
        children: this.iterateExport(element.children)
      })
    });
    return result;
  }
  // #endregion export

  // #region add multiple streams
  displaySelectStreams = false;
  unstructuredSelectedNodes: TreeNodeForUI[] = [];
  pointsParent: Stat<TreeNodeForUI> | null = null;

  openSelectStreamsDialog(parent: Stat<TreeNodeForUI>): void {
    this.pointsParent = parent;
    this.unstructuredSelectedNodes = [];
    this.displaySelectStreams = true;
  }

  closeSelectStreamsDialog(): void {
    this.displaySelectStreams = false;
    this.pointsParent = null;
  }

  unstructuredChangeSelected(nodes: TreeNodeForUI[]): void {
    this.unstructuredSelectedNodes = nodes;
  }

  async addSelectedStreams(): Promise<void> {
    if (this.pointsParent) {
      const alreadyAttached: TreeNodeForUI[] = [];
      const readyToAttach: TreeNodeForUI[] = [];
      for (const node of this.unstructuredSelectedNodes) {
        // check for *Ref tags
        const hasRef = node.tags?.find(x => this.entitiesRefs.includes(x.split("=")[0] + "="));
        if (hasRef) {
          alreadyAttached.push(node);
        } else {
          readyToAttach.push(node);
        }
      }
      if (alreadyAttached.length > 0) {
        ToastService.showToast(
          "warn", 
          "Cannot attach points", 
          `Cannot attach ${alreadyAttached.length} points, because they are already attached: ${alreadyAttached.map(x => x.label).join(", ")}`, 
          5000
        );
      }
      if (readyToAttach.length > 0) {
        // add *Ref tag, save, add node to the tree
        const newRef = this.buildParentRef(this.pointsParent);
        if (newRef) {
          const streamKeys: string[] = [];
          for (const node of readyToAttach) {
            const streamKey = node.key ?? "";
            streamKeys.push(streamKey);
          }
          const request: BP_TagChangeStreams = {
            StreamKeys: streamKeys,
            TagsRemove: [],
            TagsAdd: [newRef, "point"]
          };
          const updateResult = await this.tagManagerStore.changeTags(request);
          if (updateResult) {
            for (const streamKey of streamKeys) {
              const apiTags = updateResult[streamKey];
              const node = readyToAttach.find(x => x.key === streamKey);
              if (node && apiTags) {
                node.tags = apiTags;
              }
            }
            this.tree?.addMulti(JSON.parse(JSON.stringify(readyToAttach)), this.pointsParent);
            this.pointsParent.open = true;
            ToastService.showToast(
              "success", 
              "Success", 
              `Attached ${readyToAttach.length} points: ${readyToAttach.map(x => x.label).join(", ")}`, 
              5000
            );
            this.summaryPoints+=readyToAttach.length;
            this.closeSelectStreamsDialog();
          }
        } else {
          ToastService.showToast(
            "error",
            "Cannot attach point",
            "Selected node don't have any entity tag, please fix it",
            5000
          );
          return;
        }
      }
    }
  }
  // #endregion add multiple streams

  // #region duplicate
  displayDuplicateDialog = false;
  duplicateNodes: Stat<TreeNodeForUI>[] = [];
  duplicateAmount = 1;

  openDuplicateDialog(): void {
    const selectedNodes = this.getSelectedNodes();
    this.duplicateNodes = selectedNodes.filter(x => !this.hasTag(x, "point"));
    if (this.duplicateNodes.length < 1) {
      ToastService.showToast(
        "warn",
        "Cannot duplicate points",
        "Please select at least one site, space, or equip",
        5000
      );
    } else {
      this.displayDuplicateDialog = true;
    }
  }

  closeDuplicateDialog(): void {
    this.displayDuplicateDialog = false;
  }

  async duplicateNode(): Promise<void> {
    const streamKeys = this.duplicateNodes.map(x => x.data.key ?? "");
    const result = await this.tagManagerStore.duplicateNodes(streamKeys, this.duplicateAmount);
    if (result) {
      const createdNodes: string[] = [];
      for (const originalNode of this.duplicateNodes) {
        const streamKey = originalNode.data.key ?? "";
        const copyNodeResult = result[streamKey];
        if (copyNodeResult) {
          if (copyNodeResult.Error) {
            ToastService.showToast(
              "error",
              "Cannot duplicate node",
              copyNodeResult.Error,
              5000
            )
          } else {
            const isRoot = copyNodeResult.Result.Nodes[0].Tags.includes("site");
            const newNodes = this.navTreeStore.navTreeToUIStructure(copyNodeResult.Result.Nodes, isRoot);
            if (newNodes.length > 0) {
              this.tree?.addMulti(
                newNodes,
                originalNode.parent
              );
            }
            // tree is not updating data, so we need to update
            for (const node of newNodes) {
              originalNode.parent?.data?.children?.push(node);
            }
            newNodes.forEach(element => {
              createdNodes.push(element.label ?? "");
            })
          }
        }
      }
      this.refreshSummary(true);
      // show success message
      ToastService.showToast(
        "success", 
        "Success", 
        `Created ${createdNodes.length} nodes: ${createdNodes.join(", ")}`, 
        5000
      );
      this.displayDuplicateDialog = false;
    }
  }
  // #endregion duplicate

  // #region create entity
  displayCreateEntityDialog = false;
  newEntityName = "";
  newEntityParent: Stat<TreeNodeForUI> | null = null;
  newEntityType = "";

  get availableEntityTypes(): string[] {
    if (this.newEntityParent) {
      return this.getSmallestEntityTag(this.newEntityParent) === "equip" ? 
        ["equip"] : 
        ["space", "equip"];
    } else {
      return ["site"];
    }
  }

  openCreateEntityDialog(parent: Stat<TreeNodeForUI> | null): void {
    this.newLocation = null;
    this.locationIsOpen = false;
    this.newEntityName = "";
    this.newEntityParent = parent;
    if (parent) {
      const smallestEntityTag = this.getSmallestEntityTag(parent);
      if (smallestEntityTag) {
        this.newEntityType = smallestEntityTag === "equip" ? "equip" : "space";
        this.displayCreateEntityDialog = true;
      } else {
        ToastService.showToast(
          "error",
          "Cannot create entity",
          "Selected node don't have any entity tag, please fix it",
          5000
        );
      }
      
    } else {
      this.newEntityType = "site";
      this.displayCreateEntityDialog = true;
    }
  }

  closeCreateEntityDialog(): void {
    this.displayCreateEntityDialog = false;
  }

  locationIsOpen = false;
  newLocation: GoogleMapLocation | null = null;

  locationChanged(newLocation: GoogleMapLocation): void {
    this.newLocation = newLocation;
  }

  async createEntity(): Promise<void> {
    if (this.newEntityName) {
      const tags: string[] = [];
      tags.push(`dis=${this.newEntityName}`);
      tags.push(this.newEntityType);
      if (this.newEntityParent) {
        const newRef = this.buildParentRef(this.newEntityParent);
        if (newRef) {
          tags.push(newRef);
        } else {
          ToastService.showToast(
            "error",
            "Cannot create entity",
            "Selected node don't have any entity tag, please fix it",
            5000
          );
          return;
        }
      } else if (this.newLocation) {
        const locationTags = HaystackDefsService.googleLocationToTags(this.newLocation);
        if (locationTags.length > 0) {
          for (const tag of locationTags) {
            tags.push(tag);
          }
        }
      }
      const newNode = await this.tagManagerStore.createNode(this.newEntityName, tags);
      if (newNode) {
        const newNodesPrime = this.navTreeStore.navTreeToUIStructure([newNode], this.newEntityType === "site");
        this.tree?.add(newNodesPrime[0], this.newEntityParent);
        // tree is not updating data, so we need to update
        this.newEntityParent?.data?.children?.push(newNodesPrime[0]);
        this.closeCreateEntityDialog();
        if (this.newEntityParent) {
          this.newEntityParent.open = true;
        }
        const newStat = (this.newEntityParent ?
          this.newEntityParent.children :
          (this.tree.stats as Stat<TreeNodeForUI>[])).find(x => x.data.key === newNode.Key);
        if (newStat) {
          this.itemSelected(newStat);
        }

        // Update summary
        switch (this.newEntityType) {
          case "site":
            this.summarySites++;
            break;
          case "space":
            this.summarySpaces++;
            break;
          case "equip":
            this.summaryEquips++;
            if (newNode.Tags.includes("meter")) {
              this.summaryMeters++;
            }
            break;
        }
      }
    }
  }
  // #endregion create entity

  // #region search
  search = '';
  searchFinal = '';

  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
    NavigationHelper.goToParams(window.location.pathname, this.search ? { search: this.search } : {});
    const nodes = this.tree.statsFlat as Stat<TreeNodeForUI>[];
    TreeHelper.search(nodes, this.searchFinal);
  }
  // #endregion search

  // #region tree
  statHandler(node: Stat<TreeNodeForUI>): Stat<TreeNodeForUI> {
    if (node.data.children?.length) {
      node.class = "has-children";
    } else {
      node.class = "";
    }
    // isRoot - additional field
    if ((node.data as any).isRoot) {
      node.class = (node.class ? node.class : "") + " root-level";
    }
    node.checkedSingle = false;
    return node;
  }

  toggleNode(node: Stat<TreeNodeForUI>): void {
    node.open = !node.open;
  }

  get tree(): any {
    return (this.$refs.tree as any);
  }

  getSelectedNodes(): Stat<TreeNodeForUI>[] {
    return Object.entries(this.checkedNodes).map(([key, value]) => value);
  }

  selectedStatTreeNodes: Stat<TreeNodeForUI>[] = [];
  get selectedTreeNodes(): TreeNodeForUI[] {
    return this.selectedStatTreeNodes.map((value) => value.data) as TreeNodeForUI[];
  }

  async checkedIsChangedClick(node: Stat<TreeNodeForUI>): Promise<void> {
    const isItOkToChangeSelection = 
      this.tagManagerStore.activeNode?.data.key !== node.data.key ||
      await this.tagManagerStore.isItOkToChangeSelection();
    if (isItOkToChangeSelection) {
      this.checkedIsChanged(node);
    } else {
      node.checkedSingle = !node.checkedSingle;
    }
  }

  checkedIsChanged(node: Stat<TreeNodeForUI>): void {
    if (node.data.key) {
      if (node.checkedSingle) {
        this.checkedNodes[node.data.key] = node;
      } else {
        delete this.checkedNodes[node.data.key];
      }
      const treeNodes = this.getSelectedNodes();
      this.selectedStatTreeNodes = treeNodes;
    }
  }

  checkedIsChangedForMany(nodes: Stat<TreeNodeForUI>[]): void {
    nodes.forEach(node => {
      if (node.data.key) {
        if (node.checkedSingle) {
          this.checkedNodes[node.data.key] = node;
        } else {
          delete this.checkedNodes[node.data.key];
        }
      }
    })
    const treeNodes = this.getSelectedNodes();
    this.selectedStatTreeNodes = treeNodes;
  }

  siteChangedByMap(site: TreeNodeForUI): void {
    const stat = (this.tree.stats as Stat<TreeNodeForUI>[]).find(x => x.data.key === site.key);
    if (stat) {
      this.itemSelected(stat);
    }
  }

  async itemSelected(node: Stat<TreeNodeForUI>): Promise<void> {
    if (node.data.key) {
      const isItOkToChangeSelection = await this.tagManagerStore.isItOkToChangeSelection();
      if (isItOkToChangeSelection) {
        node.checkedSingle = !node.checkedSingle;
        if (node.checkedSingle) {
          const nodes = this.getSelectedNodes();
          nodes.forEach(element => {
            if (element.data?.key && element.data.key !== node.data.key) {
              element.checkedSingle = false;
              delete this.checkedNodes[element.data.key];
            }
          });
        }
        this.checkedIsChanged(node);
      }
    }
  }
  // #endregion tree

  // #region drag and drop
  enableDragDrop = false;

  eachDraggable(node: Stat<TreeNodeForUI>): boolean {
    // prevent root elements drag
    return node.level > 1;
  }

  oldParentNodeStat: Stat<TreeNodeForUI> | null = null;
  newParentNodeStat: Stat<TreeNodeForUI> | null = null;
  draggedNodeStat: Stat<TreeNodeForUI> | null = null;

  eachDroppable(node: Stat<TreeNodeForUI>): boolean {
    // new parent key - node.data?.key
    // old parent key - dragContext?.dragNode?.parent?.data?.key
    this.oldParentNodeStat = dragContext?.dragNode?.parent ?? null;
    this.newParentNodeStat = node ?? null;
    this.draggedNodeStat = dragContext?.dragNode ?? null;

    if (!dragContext?.dragNode?.data) {
      return false;
    }

    // prevent move inside the same node
    if (node.data?.key === dragContext?.dragNode?.parent?.data?.key) {
      return false;
    }

    return this.canDropHere(dragContext.dragNode, node);
  }

  canDropHere(dragged: Stat<TreeNodeForUI>, parent: Stat<TreeNodeForUI>): boolean {
    if (dragged.data.key === parent.data.key) {
      return false;
    }
    // compare tags of the new parent and dragged node
    const newParentTags = parent.data.tags ?? [];
    const draggedNodeTags = dragged.data.tags ?? [];
    if (!newParentTags.find(tag => this.entitiesAllowedToDragTo.includes(tag)) ||
      !draggedNodeTags.find(tag => this.entitiesTags.includes(tag))) {
      return false;
    }
    const smallestEntityTagIndexOfNewParent = Math.max(...newParentTags
      .map(tag => this.entitiesAllowedToDragTo.findIndex(x => x === tag))
      .filter(x => x !== -1));
    const smallestEntityTagIndexOfDraggedNode = Math.max(...draggedNodeTags
      .map(tag => this.entitiesTags.findIndex(x => x === tag))
      .filter(x => x !== -1));
    if (smallestEntityTagIndexOfNewParent > smallestEntityTagIndexOfDraggedNode ||
      !smallestEntityTagIndexOfNewParent && !smallestEntityTagIndexOfDraggedNode) {
      return false;
    }
    if (this.entitiesTags[smallestEntityTagIndexOfNewParent] !== "equip" &&
      this.entitiesTags[smallestEntityTagIndexOfDraggedNode] === "point") {
      return false;
    }

    // everethign is ok
    return true;
  }

  entitiesTags: string[] = ["site", "space", "equip", "point"];
  entitiesAllowedToDragTo: string[] = ["site", "space", "equip"];
  entitiesRefs: string[] = ["siteRef=", "spaceRef=", "equipRef="];

  async treeChange(): Promise<void> {
    if (this.oldParentNodeStat) {
      this.statHandler(this.oldParentNodeStat);
    }
    if (this.newParentNodeStat) {
      this.statHandler(this.newParentNodeStat);
    }
    // change refs for this.draggedNodeStat
    // holders for nodes: this.oldParentNodeStat, this.newParentNodeStat, this.draggedNodeStat
    if (this.newParentNodeStat && this.draggedNodeStat && this.oldParentNodeStat) {
      const request = this.buildChangeRefsRequest(this.draggedNodeStat, this.newParentNodeStat);
      if (request) {
        const streamKey = this.draggedNodeStat.data.key ?? "";
        const updateResult = await this.tagManagerStore.changeTags(request);
        if (updateResult && updateResult[streamKey]) {
          const apiTags = updateResult[streamKey];
          this.tagManagerStore.replaceTreeNodeTags(this.draggedNodeStat.data, apiTags);
          ToastService.showToast(
            "success", 
            "Success", 
            `Moved ${this.draggedNodeStat.data.label} node`, 
            5000
          );
        } else {
          // move back
          this.tree?.move(this.draggedNodeStat, this.oldParentNodeStat);
        }
      } else {
        ToastService.showToast(
          "error",
          "Cannot move entity",
          "Selected node don't have any entity tag, please fix it",
          5000
        );
        // move back
        this.tree?.move(this.draggedNodeStat, this.oldParentNodeStat);
      }
    }
    // cleanup
    this.oldParentNodeStat = null;
    this.newParentNodeStat = null;
    this.draggedNodeStat = null;
  }

  getSmallestEntityTag(stat: Stat<TreeNodeForUI>): string | undefined {
    const tags = stat.data.tags ?? [];
    const smallestEntityTagIndexOfNewParent = Math.max(...tags
      .map(tag => this.entitiesAllowedToDragTo.findIndex(x => x === tag))
      .filter(x => x !== -1));
    const result = this.entitiesAllowedToDragTo[smallestEntityTagIndexOfNewParent];
    return result;
  }

  buildParentRef(parent: Stat<TreeNodeForUI>): string {
    const newParentTags = parent.data.tags ?? [];
    const parentStreamKey = parent.data.key ?? "";
    let parentId = parentStreamKey;
    const parentIdTag = newParentTags.find(tag => tag.startsWith("id="));
    if (parentIdTag) {
      parentId = parentIdTag.split("=")[1];
    }
    const smallestParentEntityTag = this.getSmallestEntityTag(parent);
    const newRef = typeof smallestParentEntityTag === "undefined" ? "" : `${smallestParentEntityTag}Ref=${parentId}`;
    return newRef;
  }

  buildChangeRefsRequest(dragged: Stat<TreeNodeForUI>, parent: Stat<TreeNodeForUI>): BP_TagChangeStreams | undefined {
    const draggedNodeTags = dragged.data.tags ?? [];
    const newRef = this.buildParentRef(parent);
    if (!newRef) {
      return undefined;
    }
    const tagsAdd = [newRef];
    const tagsRemove: string[] = [];
    const bpRefTag = draggedNodeTags.find(tag => tag.startsWith("ref="));
    if (bpRefTag) {
      const parentStreamKey = parent.data.key ?? "";
      tagsAdd.push(`ref=${parentStreamKey}`);
      tagsRemove.push(bpRefTag);
    }
    
    for (const tag of draggedNodeTags) {
      for (const refStart of this.entitiesRefs) {
        if (tag.startsWith(refStart)) {
          tagsRemove.push(tag);
        }
      }
    }
    const streamKey = dragged.data.key ?? "";
    const request: BP_TagChangeStreams = {
      StreamKeys: [streamKey],
      TagsRemove: tagsRemove,
      TagsAdd: tagsAdd
    };
    return request;
  }
  // #endregion drag and drop

  // #region multi-tagging
  displayMultiTagging = false;
  nodesFlat: Stat<TreeNodeForUI>[] = [];

  openMultiTagging(): void {
    this.nodesFlat = this.tree ? this.tree.statsFlat as Stat<TreeNodeForUI>[] : [];
    this.displayMultiTagging = true;
  }
  // #endregion multi-tagging
}

export default TagManagerView;
</script>