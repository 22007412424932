<template>
  <div>
    <DataTable
      :value="reports3SchedulesStore.data?.Items" 
      dataKey="Id"
      :totalRecords="reports3SchedulesStore.data?.Total"
      :paginator="true"
      :rows="reports3SchedulesStore.take"
      :lazy="true"
      @page="onPage($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint data-table">
      <template #header>
        <div class="table-header">
          <div class="md:flex md:align-items-center md:justify-content-between md:gap-3">
            <div class="md:flex-shrink-0">
              <Button 
                label="Add Schedule" 
                icon="pi pi-plus-circle" 
                class="my-1" 
                @click="openCreateUpdateDialog(null)" 
              />
            </div>
            <div class="mt-3 md:mt-0">
              <IconField iconPosition="left" class="w-full md:w-auto">
                <InputIcon class="pi pi-search" />
                <InputText 
                  v-model="search"
                  @input="debounceSearch()"
                  placeholder="Find Schedule" 
                  class="w-full md:w-auto" 
                />
              </IconField>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div v-if="reports3SchedulesStore.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Enabled" header="Enabled" headerStyle="width: 1%; min-width: 65px;">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.Enabled ? 'Yes' : 'No' }}
          </div>
        </template>
      </Column>
      <Column field="Name" header="Name" headerStyle="min-width: min-content;" headerClass="no-break-word"></Column>
      <Column 
        :exportable="false" 
        headerStyle="width: 1%; min-width: 169px;" 
        bodyStyle="text-align: right; justify-content: flex-end;"
      >
        <template #body="slotProps">
          <div>
            <div class="inline-flex">
              <Button 
                icon="pi pi-book" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openLogsDialog(slotProps.data)"
                v-tippy="'Logs'"
              />
              <Button 
                icon="pi pi-clone" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCloneDialog(slotProps.data)"
                v-tippy="'Copy'"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCreateUpdateDialog(slotProps.data)"
                v-tippy="'Edit'"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                v-tippy="'Delete'"
              />
            </div>
          </div>
        </template>
      </Column>
    </DataTable>

    <Dialog header="Schedule Configuration" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="data-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <div class="field col-12">
              <div class="flex align-items-center">
                <InputSwitch 
                  inputId="editRecordClean"
                  v-model="editRecord.Enabled"
                  class="vertical-align-top"
                />
                <label for="editRecordClean" class="mb-0 ml-2">Enabled</label>
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="editRecordName">Name</label>
              <div>
                <InputText 
                  id="editRecordName" 
                  class="inputfield w-full"
                  type="text" 
                  v-model="editRecord.Name"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="editRecordTimezone">Timezone</label>
              <div>
                <Dropdown 
                  v-model="editRecord.Timezone" 
                  :options="timezones" 
                  optionValue="Id" 
                  optionLabel="DisplayName" 
                  placeholder="Select a Timezone" 
                  :filter="true" 
                  filterPlaceholder="Find Timezone" 
                  class="w-full"
                />
          
              </div>
            </div>
            <div class="field col-12 mb-0">
              <CronEditorView
                v-model="editRecord.CronExpression"
              />
            </div>
            <div class="field col-12">
              <label for="editRecordRecipients">Recipients</label>
              <div>
                <MultiSelect
                  inputId="editRecordRecipients"
                  v-model="editRecord.Recipients" 
                  :loading="!organisationUsersStore.data[organisationIdStr].isLoaded || !notificationGroupStore.isLoaded" 
                  :options="emailsAndGroups"
                  placeholder="Select Emails"
                  optionLabel="label" 
                  optionValue="value"
                  optionGroupLabel="label" 
                  optionGroupChildren="items"
                  display="chip"
                  :filter="true"
                  filterPlaceholder="Find Emails"
                  class="p-multiselect-multiline inputfield w-full"
                >
                  <template #optiongroup="slotProps">
                    <div class="flex align-items-center font-bold">
                      <i class="pi pi-folder font-bold mr-2 flex-shrink-0"></i>
                      <div class="flex-auto">{{ slotProps.option.label }}</div>
                    </div>
                  </template>
                  <template #option="slotProps">
                    <Chip 
                      :label="slotProps.option.label"
                      :style="getChipStyles(slotProps.option)"
                      :class="slotProps.option.color ? 'chip-colored' : ''"
                    />
                  </template>
                  <template #value="slotProps">
                    <tamplate v-if="!slotProps.value.length">
                      {{ slotProps.placeholder }}
                    </tamplate>
                    <template v-for="(item, index) in slotProps.value" :key="`${item}-${index}`">
                      <Chip 
                        :label="getLabelByValue(item)"
                        :style="getChipStylesByValue(item)"
                        :class="hasColorByValue(item) ? 'chip-colored' : ''"
                        removable
                        @remove="slotProps.value.splice(index, 1)"
                      />
                    </template>
                  </template>
                </MultiSelect>

              </div>
            </div>
            <div class="field col-12 mb-0">
              <label for="editRecordReports">Reports</label>
              <div>
                <MultiSelect
                  inputId="editRecordReports"
                  v-model="editRecord.Reports"
                  :options="reports3Store.dataLight"
                  :loading="!reports3Store.isLoadedLight"
                  placeholder="Select Reports"
                  display="chip"
                  :filter="true"
                  optionValue="Id"
                  optionLabel="Name"
                  class="p-multiselect-multiline inputfield w-full"
                />
              </div>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button v-if="editRecord" :label="editRecord.Id ? 'Update' : 'Create'" :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveRecord" :disabled='!editRecord.Name || !editRecord.CronExpression || !editRecord.Timezone || updateInProgress' />
          </span>
        </div>
      </template>
    </Dialog>

    <Dialog header="Logs" v-model:visible="displayLogsDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="connection-history-config-dialog">
      <div class="dialog-content" v-if="selectedRecord">
        <ConnectionHistoryView v-model="selectedRecord.Id" :type="logsType"/>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import AuthState from "@/store/states/AuthState";
import ConfirmationService from "@/services/ConfirmationService";
import { AllUserData } from "@/models/user/AllUserData";
import moment from "moment";
import { useReports3SchedulesStore } from "@/stores/reports3Schedules";
import { Reports3ScheduleEntity } from "@/models/reports/v3/Reports3ScheduleEntity";
import { useOrganisationStore } from "@/stores/organisation";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { TimeZoneDto } from "@/models/TimeZoneDto";
import ColorHelper from "@/helpers/ColorHelper";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";
import { useNotificationGroupStore } from "@/stores/notificationGroup";
import CustomWindow from '@/CustomWindow';
import chroma from "chroma-js";
import { useReports3Store } from "@/stores/reports3";
import { ConnectionHistoryType } from "@/models/connection/ConnectionHistoryType";
import ConnectionHistoryView from "@/components/views/connections/ConnectionHistoryView.vue";
import CronEditorView from "@/components/views/CronEditorView.vue";
import { debounce } from "throttle-debounce";

declare const window: CustomWindow;

@Component({
  components: {
    Button,
    DataTable,
    Column,
    Message,
    ProgressSpinner,
    BlockUI,
    Dialog,
    InputSwitch,
    Dropdown,
    MultiSelect,
    Chip,
    ConnectionHistoryView,
    IconField,
    InputIcon,
    InputText,
    CronEditorView
  },
  directives: {
  }
})
class ReportsSchedulesView extends Vue { 
  reports3Store = useReports3Store();
  reports3SchedulesStore = useReports3SchedulesStore();
  organisationStore = useOrganisationStore();
  organisationUsersStore = useOrganisationUsersStore();
  notificationGroupStore = useNotificationGroupStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isAvailable(): boolean | undefined {
    const result = (this.authState.permissions?.FullAccess || this.authState.permissions?.Reports);
    return result;
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }

  mounted() {
    this.search = this.reports3SchedulesStore.search;
    this.searchFinal = this.reports3SchedulesStore.search;
    this.loadData();
    this.organisationUsersStore.load(this.currentOrganisation?.Id || 0);
    if (!this.notificationGroupStore.isLoaded) {
      this.notificationGroupStore.load();
    }
    this.reports3Store.loadLight();
  }

  unmounted() {
    this.organisationUsersStore.$reset();
  }

  search = '';
  searchFinal = '';

  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  onInputChanged(): void {
    this.loadData();
  }

  loadData(): void {
    if (this.isAvailable) {
      this.reports3SchedulesStore.load(this.reports3SchedulesStore.skip, this.reports3SchedulesStore.take, this.searchFinal);
    }
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.reports3SchedulesStore.skip = event.page * event.rows;
    this.reports3SchedulesStore.take = event.rows;
    this.loadData();
  }

  get allUserData() : AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  // #region new/edit
  displayCreateUpdateDialog = false;
  editRecord: Reports3ScheduleEntity | null = null;

  openCloneDialog(record: Reports3ScheduleEntity): void {
    const newRecord: Reports3ScheduleEntity = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    newRecord.Name += " - Copy";
    const nowUtc = moment.utc().toDate();
    newRecord.Created = nowUtc;
    newRecord.Updated = nowUtc;
    newRecord.CreatedBy = this.allUserData.userName;
    newRecord.UpdatedBy = this.allUserData.userName;
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: Reports3ScheduleEntity | null): void {
    const nowUtc = moment.utc().toDate();
    this.editRecord = record ? Object.assign({}, record) : {
      Id: "",
      Name: "",
      OrganisationId: this.currentOrganisation?.Id ?? -1,
      Reports: [],
      Recipients: [],
      CronExpression: "0 0 1 ? * 2",
      Timezone: "Brisbane",
      Enabled: true,
      Created: nowUtc,
      Updated: nowUtc,
      CreatedBy: "", // api will fill it
      UpdatedBy: "" // api will fill it
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.reports3SchedulesStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.reports3SchedulesStore.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.reports3SchedulesStore.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }

  get emailsAndGroups(): any[] {
    const result = [];
    if (this.notificationGroupStore.entities?.length) {
      result.push({
        label: "Groups",
        items: this.notificationGroupStore.entities.map(x => {
          return { label: x.Name, value: x.Id, backgroundColor: x.Color, color: ColorHelper.getContrastColor(x.Color) };
        })
      });
    }
    const users = this.organisationUsersStore.data[this.organisationIdStr];
    if (users?.entities?.length) {
      let emails = users.entities.map(x => x.UserName);
      const userData = window.angularUserprofile.getAllUserData()
      if (!emails.find(x => x === userData.userName)){
        emails.push(userData.userName);
      }
      emails = emails.sort((a, b) => a.localeCompare(b));
      result.push({
        label: "Emails",
        items: emails.map(x => {
          return { label: x, value: x };
        })
      });
    }
    return result;
  }

  getLabelByValue(value: string): string {
    const group = this.emailsAndGroups.find(x => x.label === value.includes("@") ? "Emails" : "Groups");
    if (group) {
      const result = group.items.find((x: any) => x.value === value);
      return result ? result.label : value;
    }
    return value;
  }

  hasColorByValue(value: string): boolean {
    const group = this.emailsAndGroups.find(x => x.label === value.includes("@") ? "Emails" : "Groups");
    if (group) {
      const result = group.items.find((x: any) => x.value === value);
      return result ? !!result.color : false;
    }
    return false;
  }

  getChipStylesByValue(value: string): string {
    const group = this.emailsAndGroups.find(x => x.label === value.includes("@") ? "Emails" : "Groups");
    if (group) {
      const result = group.items.find((x: any) => x.value === value);
      return result ? this.getChipStyles(result) : "";
    }
    return "";
  }

  getChipStyles(item: any): string {
    let result: string = "";
    if (item.backgroundColor) {
      const colorRGB = chroma.hex(item.backgroundColor).rgb();
      result = `--colored-chip-selected-bg-color: ${colorRGB[0]}, ${colorRGB[1]}, ${colorRGB[2]}; --colored-chip-selected-bg-color-with-opacity: rgba(var(--colored-chip-selected-bg-color), var(--colored-chip-selected-bg-color-opacity));`;
    }
    if (item.color) {
      result = `${result}color: ${item.color};`;
    }
    return result;
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: Reports3ScheduleEntity | null = null;

  openConfirmation(record: Reports3ScheduleEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete ${this.selectedRecord?.Name}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.selectedRecord) {
          await this.reports3SchedulesStore.delete(this.selectedRecord);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion delete
  
  // #region logs
  displayLogsDialog = false;
  logsType = ConnectionHistoryType.ReportSchedule;

  openLogsDialog(record: Reports3ScheduleEntity | null): void {
    this.selectedRecord = record;
    this.displayLogsDialog = true;
  }
  // #endregion logs
}

export default ReportsSchedulesView;
</script>