import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import OrganisationSitesState from "./states/OrganisationSitesState";
import { SiteDto } from "@/models/organisation/SiteDto";
import { SitesToOrganisationDto } from "@/models/organisation/SitesToOrganisationDto";
import DateHelper from "@/helpers/DateHelper";

export const useOrganisationSitesStore = defineStore('organisationSites', {
  state: (): OrganisationSitesState => ({ 
    data: {},
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number) {
      const organisationIdStr = organisationId.toString();
      try {
        const guid = uuidv4();
        this.data[organisationIdStr] = {
          guid: guid,
          isLoaded: false,
          entities: null
        }
        const url = `rest/BitPool_V2/organisations/${organisationId}/sites`;
        const response = await axios.get<SiteDto[]>(url);
        if (this.data[organisationIdStr] && this.data[organisationIdStr].guid === guid) {
          response.data.forEach(x => x.RegistrationDate = DateHelper.parseFromMicrosoftString(x.RegistrationDate as string));
          this.data[organisationIdStr].entities = response.data;
          this.data[organisationIdStr].isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load organisation sites",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data[organisationIdStr].entities = null;
        this.data[organisationIdStr].isLoaded = true;
      }
    },
    async add(body: SitesToOrganisationDto) {
      const organisationIdStr = body.OrganisationId.toString();
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/organisations/sites`;
        const response = await axios.put<SiteDto[]>(url, body);
        ToastService.showToast("success", "Organisation Site", "Changes saved", 5000);
        this.updateInProgress = false;
        const data = this.data[organisationIdStr];
        if (data?.isLoaded && data?.entities && response.data.length) {
          response.data.forEach(x => x.RegistrationDate = DateHelper.parseFromMicrosoftString(x.RegistrationDate as string));
          data.entities.push(...response.data);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save organisation site",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
    },
    async delete(organisationId: number, sitesKeys: string[]) {
      const organisationIdStr = organisationId.toString();
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/BitPool_V2/organisations/${organisationId}/sites`;
        await axios.delete(url, {
          headers: {
            "Content-Type": "application/json",
          },
          data: sitesKeys,
        });
        ToastService.showToast("success", "Organisation", `Changes saved`, 5000);
        this.deleteInProgress = false;
        const data = this.data[organisationIdStr];
        if (data?.isLoaded && data?.entities) {
          for (const siteKey of sitesKeys) {
            const index = data.entities.findIndex((x) => x.SiteKey === siteKey);
            if (index > -1) {
              data.entities.splice(index, 1);
            }
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete site`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
      }
    }
  },
})
