import { FlowEntity } from "@/models/flow/FlowEntity";
import FlowQuery from "@/models/flow/FlowQuery";
import { MongoDymanicPage } from "@/models/MongoDymanicPage";

export default class FlowState {
  isLoaded = false;
  flows: MongoDymanicPage<FlowEntity> | null = null;
  guidPage = "";
  pageQuery: FlowQuery | null = null;

  currentFlow: FlowEntity | null = null;
  isLoadedCurrentFlow = false;
  updateInProgress = false;
  updateError = false;

  copiedNode: string | null = null;

  virtualStreamType: string[] = [
    "virtual_stream",
    "power_energy",
    "accumulating_interval",
  ];
  virtualStreamOperations: string[] = ["add", "subtract", "multiply", "divide"];
  virtualStreamData: string[] = ["stream", "value", "generator", "tag_node", "pool"];
  alarmTypes: string[] = ["out_of_limits", "data_anomaly", "data_quality", "ai_report", "csv_export", "tag_value"];
}
