<template>
  <section class="signup animate__animated animate__bounceInLeft">
    <div class="logo-container mb-4">
      <img
        :src="'/assets/bitpool-logo-dark.svg'"
        width="470"
        alt="Bitpool"
        class="logo-light-theme"
      />
      <img
        :src="'/assets/bitpool-logo-white.svg'"
        width="470"
        alt="Bitpool"
        class="logo-dark-theme"
      />
    </div>

    <div class="auth-form">
      <div class="auth-form-inner">
        <h1 class="text-center">{{ t('login.welcome') }}</h1>
        <div class="field">
          <InputText
            class="inputfield p-inputtext-lg w-full"
            :placeholder="t('common.email')"
            type="text"
            v-model="username"
          />
        </div>
        <div class="field">        
          <Password
            class="w-full"
            inputClass="inputfield p-inputtext-lg w-full"
            :placeholder="t('common.password')"
            v-model="password"
            toggleMask
          />
        </div>
        <div class="field">        
          <Password
            class="w-full"
            :class="{ 'p-invalid': password2 && password !== password2 }"
            inputClass="inputfield p-inputtext-lg w-full"
            :placeholder="t('login.confirmPassword')"
            v-model="password2"
            :feedback="false"
            toggleMask
            @keyup.enter="signup"
          />
        </div>
        <VueRecaptcha 
          sitekey="6LdeCvglAAAAAPyqVtmMqPAX7W3Yvnl8-sgbKWy2"
          size="normal" 
          :theme="recaptchaTheme"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          ref="vueRecaptcha"
          class="field"
        ></VueRecaptcha>
        <div class="field mt-4 pt-1">
          <Button
            class="w-full p-button-lg justify-content-center font-medium"
            @click="signup"
            :disabled="inProgress || !isFormFilled() || !isVerified"
            ><span :class="{ 'opacity-0': inProgress }">{{ t('login.register') }}</span
            ><ProgressSpinner class="spinner-white" style="width: 40px; height: 40px" strokeWidth="6" animationDuration="1s" v-if="inProgress"
          /></Button>
        </div>
        <div class="field mb-0">
          <span class="login-signup-with-social"><span>{{ t('login.orSignupWith') }}</span></span>
          <ExternalLoginView :disabled="inProgress || !isVerified" :registrationEmail="registrationEmail"/>
        </div>

        <div class="text-center mt-5 pt-2">
          <div @click="goLogin" v-html="t('login.alreadyHaveAccountHtml')"></div>
        </div>
      </div>
    </div>

    <div class="text-center mt-5 pt-2 md:mt-3 md:pt-0 auth-bottom" v-html="t('login.clickHereForHelpHtml', { href: 'mailto:support@bitpool.com' })"></div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AngularOptions from "@/models/AngularOptions";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Dropdown from 'primevue/dropdown';
import Password from 'primevue/password';
import VueRecaptcha from 'vue3-recaptcha2';
import ExternalLoginView from "@/components/views/auth/ExternalLoginView.vue";
import { useAuthStore } from "@/stores/auth";
import { ComposerTranslation, useI18n } from "vue-i18n";
import { MessageSchema } from "@/localeManager";
import { useLoginStore } from "@/stores/login.store";

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    InputText,
    Button,
    ProgressSpinner,
    Dropdown,
    Password,
    VueRecaptcha,
    ExternalLoginView
  },
})
class SignupView extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;
  
  loginStore = useLoginStore();

  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  inProgress = false;
  password2 = "";

  authStore = useAuthStore();

  registrationEmail: string | null = null;

  get username(): string {
    return this.loginStore.username;
  }

  set username(value: string) {
    this.loginStore.setUsername(value);
  }

  get password(): string {
    return this.loginStore.password;
  }

  set password(value: string) {
    this.loginStore.setPassword(value);
  }

  get displaySection(): string {
    return this.loginStore.displaySection;
  }

  set displaySection(value: string) {
    this.loginStore.setDisplaySection(value);
  }

  created(): void {
    if (this.angularOptions.angularScope.subscribeToSignupCallback) {
      this.angularOptions.angularScope.subscribeToSignupCallback(
        this.signupComplete
      );
    }
    const usp = new URLSearchParams(window.location.search);
    this.registrationEmail = usp.get("signup");
  }

  goLogin(): void {
    this.displaySection = "login";
  }

  isFormFilled(): boolean {
    if (this.username &&
      this.password && 
      this.password2 &&
      this.password === this.password2) {
      return true;
    } else {
      return false;
    }
  }

  signup(): void {
    if (this.isFormFilled()) {
      this.inProgress = true;
      this.angularOptions.angularScope.newUser.username = this.username;
      this.angularOptions.angularScope.newUser.password = this.password;
      this.angularOptions.angularScope.createUser();
    }
  }

  signupComplete(success: boolean): void {
    this.inProgress = false;
    if (success) {
      this.loginStore.setSignupSuccess(true);
      this.goLogin();
    }
  }

  // #region captcha
  get recaptchaTheme(): string {
    // find html element with class "bitpool-dark"
    const elements = document.getElementsByClassName("bitpool-dark");
    return elements.length > 0 ? "dark" : "light";
  }

  isVerified = false;

  recaptchaVerified(response: string) {
    // response - the g-recaptcha-response token
    // https://developers.google.com/recaptcha/docs/verify
    this.isVerified = true;
  }

  recaptchaExpired() {
    if (this.$refs.vueRecaptcha) {
      (this.$refs.vueRecaptcha as any).reset();
      this.isVerified = false;
    }
  }
  // #endregion captcha
}

export default SignupView;
</script>@/localeManager