<template>
  <div 
    class="widget-settings-streams-container report-general-settings-data-source"
  >
    <div class="formgrid grid">
      <div class="col-12">
        <div class="inline-flex align-items-center gap-2 flex-wrap sm:flex-nowrap">
          <h4 class="w-full mr-0 mb-1 sm:w-auto sm:mb-0 sm:mr-2">Streams</h4>
          <Button 
            @click="addStream"
            class="p-button-outlined p-button-rounded widget-settings-control-btn flex-none" icon="pi pi-plus"
            label="Single"
          />
          <Button 
            @click="openSelectStreamsDialog"
            class="p-button-outlined p-button-rounded widget-settings-control-btn flex-none"
            icon="pi pi-plus" label="Multiple"
          />
          <Button
            class="p-button-outlined p-button-danger p-button-rounded widget-settings-control-btn flex-none"
            icon="pi pi-trash" 
            label="Delete All"
            @click="deleteAllStreams()">
          </Button>
        </div>
      </div>
      <div class="col-12">
        <!-- Streams List -->
        <div 
          class="widget-settings-colored-box widget-settings-stream-item" 
          v-for="(stream, i) in wds.streamOptions"
          :key="i + stream.StreamKey" 
          :id="`streamContainer${i}`"
        >
          <div class="widget-settings-stream-item-header">
            <div>
              <h5>
                {{ stream.Label ? `${i + 1}.&nbsp;${stream.Label}` : `${i + 1}.&nbsp;Stream` }}
              </h5>
            </div>
            <div>
              <div class="flex-none flex gap-2">
                <Button class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn"
                  icon="pi pi-arrow-up" @click="() => moveStream(i, i - 1)" :disabled="i === 0" v-tippy="'Move Up'">
                </Button>
                <Button class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn"
                  icon="pi pi-arrow-down" @click="() => moveStream(i, i + 1)"
                  :disabled="i === wds.streamOptions.length - 1" v-tippy="'Move Down'">
                </Button>
                <Button
                  class="p-button-outlined p-button-danger p-button-icon-only p-button-rounded widget-settings-control-btn"
                  icon="pi pi-trash" @click="deleteStream(i)" v-tippy="'Delete'">
                </Button>
              </div>
              <span @click="toggleCollapseStreams(i)" class="link" :class="{ 'is-open': !collapseStreams[i] }">
                {{ collapseStreams[i] ? "Show" : "Hide" }} <i class="pi"
                  :class="{ 'pi-chevron-up': !collapseStreams[i], 'pi-chevron-down': collapseStreams[i] }"></i>
              </span>
            </div>
          </div>

          <transition name="p-toggleable-content">
            <WidgetSettingsStreamView 
              v-show="!collapseStreams[i]" 
              :isCollapsed="collapseStreams[i]"
              :widgetConfig="widgetConfig" 
              :streamOption="stream" 
              :nodes="nodes"
              :isDashboardsLoaded="isDashboardsLoaded"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>

  <Dialog header="Select Streams" v-model:visible="displaySelectStreams" :modal="true"
    :breakpoints="{ '1400px': '70vw', '1024px': '85vw', '640px': '90vw' }" :style="{ width: '60vw' }"
    class="widget-select-streams-config-dialog">
    <div class="dialog-content">
      <div class="formgrid grid">
        <!-- Structured/not-structured switch -->
        <div class="field col-12 pt-1">
          <div class="flex align-items-center">
            <InputSwitch v-model="structuredView" inputId="structuredViewStreams" class="vertical-align-top" />
            <label for="structuredViewStreams" class="mb-0 ml-2">Structured Data</label>
          </div>
        </div>

        <!-- Structured -->
        <div v-show="structuredView" class="field col-12">
          <label for="structuredDataStreams">
            Structured Data
          </label>
          <div>
            <TreeWithCheckboxesView v-if="navTreeStore.isLoaded && navTreeStore.structuredDataForUI"
              :nodes="navTreeStore.structuredDataForUI" :changeSelected="structuredChangeSelected"
              placeholder="Find Streams" :includeTags="widgetDescription?.includeTags ?? []" />
            <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6"
              animationDuration="1s" />
          </div>
        </div>

        <!-- Not-structured -->
        <div v-show="!structuredView" class="field col-12">
          <label for="unstructuredDataStreams">
            Unstructured Data
          </label>
          <div>
            <TreeWithCheckboxesView v-if="navTreeStore.isLoaded && navTreeStore.unstructuredDataForUI"
              :nodes="navTreeStore.unstructuredDataForUI" :changeSelected="unstructuredChangeSelected"
              placeholder="Find Streams" :includeTags="widgetDescription?.includeTags ?? []" />
            <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6"
              animationDuration="1s" />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" @click="closeSelectStreamsDialog"
        class="p-button-text p-button-secondary" />
      <Button label="Add" icon="pi pi-check" @click="addSelectedStreams"
        :disabled="!structuredSelectedNodes.length && !unstructuredSelectedNodes.length" />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { WidgetDataSettingsBasic } from "@/models/dashboard/WidgetDataSettingsBasic";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import WidgetSettingsStreamView from "@/components/widgets-next/settings/WidgetSettingsStreamView.vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import InputSwitch from "primevue/inputswitch";
import ConfirmationService from "@/services/ConfirmationService";
import { arrayMoveMutable } from "array-move";
import { nextTick } from "vue";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { AggregationType } from "@/models/enums/AggregationType";
import { useNavTreeStore } from "@/stores/navTree";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import StreamOption from "@/models/dashboard/StreamOption";
import { TimeRange } from "@/models/enums/TimeRange";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import TreeWithCheckboxesView from "@/components/views/TreeWithCheckboxesView.vue";
import WidgetHelper from "@/helpers/WidgetHelper";

@Component({
  components: {
    WidgetSettingsStreamView,
    Button,
    Dialog,
    ProgressSpinner,
    InputSwitch,
    TreeWithCheckboxesView
  },
  directives: {
  }
})
class ReportsDatasourceStreamsView extends Vue {
  @Prop({ required: true }) datasource!: Reports3Datasource;
  
  navTreeStore = useNavTreeStore();

  get wds(): WidgetDataSettingsBasic {
    return this.datasource.Configuration;
  }

  nodes: TreeNodeForUI[] = [];
  isDashboardsLoaded = false;

  widgetDescription: WidgetDescription | undefined = WidgetHelper.getWidget("reports3", false);

  widgetConfig: WidgetConfig = {
    _id: undefined,
    widgetMins: [],
    widgetMaxs: [],
    widgetOptions: {
      basicWidgetSettings: {
        widgetName: "",
        widgetSubtitle: "",
        widgetDesc: ""
      },
      advancedWidgetSettings: {
        yaxis: [{name: ""}],
        useGDRS: 1,
        triggerLevels: []
      },
      widgetDataSettings: {
        rangePreset: TimeRange.Today,
        rangePresetHolder: TimeRange.Today,
        aggPeriod: AggregationPeriod.Hourly,
        autoAggPeriod: true,
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        streamOptions: [],
        aggType: AggregationType.Avg,
        showNullValues: 1
      }
    },
    widgetRefreshSecs: 0,
    guid: "",
    DateUpdated: 0,
    DateCreated: 0,
    widgetType: "reports3",
    version: ""
  };

  created(): void {
    // init stream collapsed array
    this.initCollapseStreams();
  }

  async addStream(): Promise<void> {
    if (this.wds && this.widgetDescription && this.widgetDescription.defaultStreamOptions) {
      this.wds.streamOptions.push(JSON.parse(JSON.stringify(this.widgetDescription.defaultStreamOptions)));
      this.collapseStreams.push(false);
      await nextTick();
      const targetScrollElement = document.getElementById(`streamContainer${this.wds.streamOptions.length - 1}`);
      if (targetScrollElement) {
        targetScrollElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  // #region add multiple streams
  displaySelectStreams = false;
  structuredView = false;
  structuredSelectedNodes: TreeNodeForUI[] = [];
  unstructuredSelectedNodes: TreeNodeForUI[] = [];

  openSelectStreamsDialog(): void {
    this.structuredSelectedNodes = [];
    this.unstructuredSelectedNodes = [];
    this.displaySelectStreams = true;
  }

  closeSelectStreamsDialog(): void {
    this.displaySelectStreams = false;
  }

  structuredChangeSelected(nodes: TreeNodeForUI[]): void {
    this.structuredSelectedNodes = nodes;
  }

  unstructuredChangeSelected(nodes: TreeNodeForUI[]): void {
    this.unstructuredSelectedNodes = nodes;
  }

  async addSelectedStreams(): Promise<void> {
    const scrollTo = this.wds?.streamOptions.length ?? 0;
    this.unstructuredSelectedNodes.forEach(node => {
      if (this.wds && this.widgetDescription && this.widgetDescription.defaultStreamOptions && node.key) {
        const streamOption: StreamOption = JSON.parse(JSON.stringify(this.widgetDescription.defaultStreamOptions));
        streamOption.StreamKey = node.key;
        streamOption.Name = node.label ?? "";
        streamOption.Label = node.label ?? "";
        streamOption.structured = false;
        this.wds.streamOptions.push(streamOption);
        this.collapseStreams.push(false);
      }
    });
    this.structuredSelectedNodes.forEach(node => {
      if (this.wds && this.widgetDescription && this.widgetDescription.defaultStreamOptions && node.key) {
        const streamOption: StreamOption = JSON.parse(JSON.stringify(this.widgetDescription.defaultStreamOptions));
        streamOption.StreamKey = node.key;
        streamOption.Name = node.label ?? "";
        streamOption.Label = node.label ?? "";
        streamOption.structured = true;
        this.wds.streamOptions.push(streamOption);
        this.collapseStreams.push(false);
      }
    });
    this.closeSelectStreamsDialog();
    await nextTick();
    const targetScrollElement = document.getElementById(`streamContainer${scrollTo}`);
    if (targetScrollElement) {
      targetScrollElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  // #endregion add multiple streams

  moveStream(fromIndex: number, toIndex: number): void {
    if (this.wds) {
      arrayMoveMutable(this.wds.streamOptions, fromIndex, toIndex);
      arrayMoveMutable(this.collapseStreams, fromIndex, toIndex);
    }
  }

  deleteStream(index: number): void {
    ConfirmationService.showConfirmation({
      message: "Are you sure you want to delete stream?",
      header: 'Delete Stream',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.wds) {
          this.wds.streamOptions.splice(index, 1);
          this.collapseStreams.splice(index, 1);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteAllStreams(): void {
    ConfirmationService.showConfirmation({
      message: "Are you sure you want to delete all streams?",
      header: 'Delete Streams',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.wds) {
          if (this.wds.streamOptions?.length) {
            this.wds.streamOptions.splice(0, this.wds.streamOptions.length);
          }
          if (this.collapseStreams?.length) {
            this.collapseStreams.splice(0, this.collapseStreams.length);
          }
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  collapseStreams: boolean[] = [];

  initCollapseStreams(): void {
    this.collapseStreams = [];
    if (this.wds?.streamOptions?.length) {
      this.wds.streamOptions.forEach(element => {
        this.collapseStreams.push(true);
      });
    }
  }

  toggleCollapseStreams(index: number): void {
    this.collapseStreams[index] = !this.collapseStreams[index];
  }
}

export default ReportsDatasourceStreamsView;
</script>