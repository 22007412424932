<template>
  <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index" :class="updateInProgress ? 'blockui-blocked' : ''">
    <h2 class="account-settings-title hidden xl:block">{{ t('accountSettings.notifications.title') }}</h2>
    <p class="account-settings-description hidden xl:block">{{ t('accountSettings.notifications.subTitle') }}</p>

    <section>
      <h3>{{ t('accountSettings.notifications.emailNotifications') }}</h3>
      <div class="flex align-items-start notifications-settings-item pt-1 xl:pt-0">
        <InputSwitch v-model="notificationsEmailInsights" inputId="notificationsEmailInsights" class="vertical-align-top"  @change="settingsChange"/>
        <div class="ml-3 xl:ml-4 notifications-settings-description">
          <label for="notificationsEmailInsights" class="mb-0">{{ t('accountSettings.notifications.emailInsights') }}</label>
          <p>{{ t('accountSettings.notifications.emailInsightsDescription') }}</p>
        </div>
      </div>
      <div class="flex align-items-start notifications-settings-item pt-1 xl:pt-0">
        <InputSwitch v-model="notificationsEmailReports" inputId="notificationsEmailReports" class="vertical-align-top"  @change="settingsChange"/>
        <div class="ml-3 xl:ml-4 notifications-settings-description">
          <label for="notificationsEmailReports" class="mb-0">{{ t('accountSettings.notifications.emailReports') }}</label>
          <p>{{ t('accountSettings.notifications.emailReportsDescription') }}</p>
        </div>
      </div>
    </section>

    <section class="xl:mb-0">
      <h3>{{ t('accountSettings.notifications.onSiteNotifications') }}</h3>
      <div class="flex align-items-start notifications-settings-item pt-1 xl:pt-0">
        <InputSwitch v-model="notificationsOnSiteDashboardComments" inputId="notificationsOnSiteDashboardComments" class="vertical-align-top"  @change="settingsChange"/>
        <div class="ml-3 xl:ml-4 notifications-settings-description">
          <label for="notificationsOnSiteDashboardComments" class="mb-0">{{ t('accountSettings.notifications.onSiteComments') }}</label>
          <p>{{ t('accountSettings.notifications.onSiteCommentsDescription') }}</p>
        </div>
      </div>
      <div class="flex align-items-start notifications-settings-item pt-1 xl:pt-0">
        <InputSwitch v-model="notificationsOnSiteInsights" inputId="notificationsOnSiteInsights" class="vertical-align-top"  @change="settingsChange"/>
        <div class="ml-3 xl:ml-4 notifications-settings-description">
          <label for="notificationsOnSiteInsights" class="mb-0">{{ t('accountSettings.notifications.onSiteInsights') }}</label>
          <p>{{ t('accountSettings.notifications.onSiteInsightsDescription') }}</p>
        </div>
      </div>
      <div class="flex align-items-start notifications-settings-item pt-1 xl:pt-0">
        <InputSwitch v-model="notificationsOnSiteReports" inputId="notificationsOnSiteReports" class="vertical-align-top"  @change="settingsChange"/>
        <div class="ml-3 xl:ml-4 notifications-settings-description">
          <label for="notificationsOnSiteReports" class="mb-0">{{ t('accountSettings.notifications.onSiteReports') }}</label>
          <p>{{ t('accountSettings.notifications.onSiteReportsDescription') }}</p>
        </div>
      </div>
    </section>

    <div class="block md:flex align-items-center justify-content-end account-settings-button-container">
      <Button 
        @click="closeDialog" 
        :label="t('common.close')" 
        icon="pi pi-times" 
        class="hidden md:inline-flex p-button-text p-button-secondary mr-2"
      />
      <Button 
        :label="t('common.saveChanges')" 
        :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-save'" 
        @click="saveAll"
        class="account-settings-save-button"
        :disabled='updateInProgress || !isSettingsChaged' 
      />
    </div>

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import AuthState from "@/store/states/AuthState";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import localeManager from "@/localeManager";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    Button,
    BlockUI,
    ProgressSpinner,
    InputSwitch,
    Dropdown
  },
})
class UserSettingsNotificationsView extends Vue {
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;
    
  get auth(): AuthState {
    return this.$store.state.auth;
  }

  isSettingsChaged = false;
  notificationsEmailInsights = false;
  notificationsEmailReports = false;
  notificationsOnSiteDashboardComments = false;
  notificationsOnSiteInsights = false;
  notificationsOnSiteReports = false;
  updateInProgress = false;

  created(): void {
    this.notificationsEmailInsights = !!this.auth.userSettings?.notificationsEmailInsights;
    this.notificationsEmailReports = !!this.auth.userSettings?.notificationsEmailReports;
    this.notificationsOnSiteDashboardComments = !!this.auth.userSettings?.notificationsOnSiteDashboardComments;
    this.notificationsOnSiteInsights = !!this.auth.userSettings?.notificationsOnSiteInsights;
    this.notificationsOnSiteReports = !!this.auth.userSettings?.notificationsOnSiteReports;
  }

  settingsChange(): void {
    this.isSettingsChaged = true;
  }

  async saveAll(): Promise<boolean> {
    this.updateInProgress = true;
    let isOk = true;
    try {
      // check what need to save and call api
      let needThemeReloading = false;
      if (this.isSettingsChaged && this.auth.userSettings) {
        // save ui settings
        this.auth.userSettings.notificationsEmailInsights = this.notificationsEmailInsights;
        this.auth.userSettings.notificationsEmailReports = this.notificationsEmailReports;
        this.auth.userSettings.notificationsOnSiteDashboardComments = this.notificationsOnSiteDashboardComments;
        this.auth.userSettings.notificationsOnSiteInsights = this.notificationsOnSiteInsights;
        this.auth.userSettings.notificationsOnSiteReports = this.notificationsOnSiteReports;
        await this.$store.dispatch("auth/saveUserSettings");
        if (this.auth.userSettingsSaveError) {
          isOk = false;
        } else {
          this.isSettingsChaged = false;
        }
      }
      if (isOk) {
        ToastService.showToast("success", "", this.t("common.changesSaved"), 5000);
      }
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        this.t("common.cantSaveChanges"),
        errorMessage,
        5000
      );
    } finally {
      this.updateInProgress = false;
    }
    return isOk
  }

  get locales(): [string, string][] {
    return localeManager.supportedLocales;
  }

  @Emit
  closeDialog(): void {
    // nothing to do, just emit an event
  }
}

export default UserSettingsNotificationsView;
</script>