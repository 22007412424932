import StreamOption from "@/models/dashboard/StreamOption";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import { WidgetGroupDescription } from "@/models/dashboard/WidgetGroupDescription";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import { AggregationType } from "@/models/enums/AggregationType";
import { AggregationTypeString } from "@/models/enums/AggregationTypeString";
import { AlarmOperators } from "@/models/enums/AlarmOperators";
import { TimeRange } from "@/models/enums/TimeRange";
import { WidgetFeature } from "@/models/enums/WidgetFeature";
import moment from "moment";
import ColorHelper from "./ColorHelper";
import DashboardMigrationHelper from "./DashboardMigrationHelper";
import { DashboardType } from "@/models/dashboard/DashboardType";
import { v4 as uuidv4 } from 'uuid';

class WidgetHelper {
  private groups: WidgetGroupDescription[] = [
    {
      name: "Data Visualisation",
      icon: "assets/widget-group-icons/data-visualisation-icon.svg",
      active: true,
    },
    {
      name: "Charting",
      icon: "assets/widget-group-icons/charting-icon.svg",
      active: true,
    },
    {
      name: "Time and Date",
      icon: "assets/widget-group-icons/time-date-icon.svg",
      active: true,
    },
    {
      name: "Display",
      icon: "assets/widget-group-icons/display-icon.svg",
      active: true,
    },
    {
      name: "Weather",
      icon: "assets/widget-group-icons/weather-icon.svg",
      active: true,
    },
    {
      name: "Utility",
      icon: "assets/widget-group-icons/utility-icon.svg",
      active: true,
    },
    {
      name: "Infographic",
      icon: "assets/widget-group-icons/infographic-icon.svg",
      active: true,
    },
    {
      name: "Command and Control",
      icon: "assets/widget-group-icons/mqtt-control-icon.svg",
      active: true,
    }
  ];
  private widgets: WidgetDescription[] = [
    {
      group: "Data Visualisation",
      name: "heatmap",
      displayName: "Heat Map",
      description:
        "Visualize data across a span of time with a value-scaled colour gradient.",
      icon: "assets/widget-type-icons/heat-map.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.units,
        WidgetFeature.decimalPlaces,
        WidgetFeature.dataAggregation,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.minMaxRange,
        WidgetFeature.minMaxRangeAuto,
        WidgetFeature.inactiveColor,
        WidgetFeature.gaugeRange,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType
      ],
      streamSlots: [
        { label: "Value", unit: "" },
        { label: "Active", unit: "On/Off" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Heat Map Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          decimals: 2,
          widgetUnit: "bits",
          usePercentage: 1,
          minMaxRange: [0, 100],
          autoMinMax: false,
          inactiveColor: "rgb(150,150,150)",
          gaugeRange: [{
            from: 0,
            fromP: 0,
            to: 50,
            toP: 50,
            color: "#4CAF50"
          }, {
            from: 50,
            fromP: 50,
            to: 100,
            toP: 100,
            color: "#f44336"
          }],
        },
        widgetDataSettings: {
          rangePreset: TimeRange.ThisMonth,
          rangePresetHolder: TimeRange.ThisMonth,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_heatmap_003",
      active: true,
      aiCompatible: true
    },
    {
      group: "Data Visualisation",
      name: "radialgaugehc",
      displayName: "Radial Gauge",
      description: "Visualize value of any data stream.",
      icon: "assets/widget-type-icons/radial-gauge.svg",
      iconClass: "reduce-icon-box-width",
      features: [
        WidgetFeature.title,
        WidgetFeature.units,
        WidgetFeature.decimalPlaces,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.minMaxRange,
        WidgetFeature.gaugeRange,
        WidgetFeature.gaugeInnerSize,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType
      ],
      streamSlots: [
        { label: "Value", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Radial Gauge Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          decimals: 2,
          widgetUnit: "bits",
          usePercentage: 1,
          minMaxRange: [0, 100],
          gaugeInnerSize: 60,
          gaugeRange: [{
            from: 0,
            fromP: 0,
            to: 50,
            toP: 50,
            color: "#4CAF50"
          }, {
            from: 50,
            fromP: 50,
            to: 100,
            toP: 100,
            color: "#f44336"
          }],
        },
        widgetDataSettings: {
          rangePreset: TimeRange.ThisMonth,
          rangePresetHolder: TimeRange.ThisMonth,
          aggPeriod: AggregationPeriod.Daily,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_radialgaugehc_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Data Visualisation",
      name: "piechartwidget",
      displayName: "Pie & Donut Chart",
      description:
        "Use this chart to visualise the total quantities for any time-series data values. Typically used for Energy Analysis, Water Analysis, and alarms raised.",
      icon: "assets/widget-type-icons/pie-donut-chart.svg",
      iconClass: "reduce-icon-box-width",
      features: [
        WidgetFeature.title,
        WidgetFeature.units,
        WidgetFeature.donut,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsTimeOffset
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Pie & Donut Chart",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          widgetUnit: "bits",
          widgetDonutInnerSize: 0
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_piechartwidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Data Visualisation",
      name: "funnelwidget",
      displayName: "Funnel Chart",
      description:
        "Use this funnel chart to visualise the total quantities for any time-series data values with sequential stages. Typically used for ranking site consumptions, Energy Analysis, Water Analysis, and alarms raised.",
      icon: "assets/widget-type-icons/funnel-chart.svg",
      iconClass: "reduce-icon-box-width",
      features: [
        WidgetFeature.title,
        WidgetFeature.units,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsTimeOffset
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Funnel Chart",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          widgetUnit: "bits"
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_funnelwidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Data Visualisation",
      name: "activitygaugewidget",
      displayName: "Activity Gauge Chart",
      description:
        "Use this activity gauge to visualise the multiple time-series data values with sequential stages. Typically used for ranking site consumptions, People Counting, Energy Analysis, Water Analysis, and alarms raised.",
      icon: "assets/widget-type-icons/activity-gauge-chart.svg",
      iconClass: "reduce-icon-box-width",
      features: [
        WidgetFeature.title,
        WidgetFeature.units,
        WidgetFeature.activityGauge,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsTimeOffset
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Activity Gauge Chart",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          widgetUnit: "bits",
          widgetActivityGaugeMaxThickness: 20,
          widgetActivityGaugeCenterValueType: "Max",
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_activitygaugewidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Data Visualisation",
      name: "imagefreeformwidget",
      displayName: "Image Freeform",
      description:
        "Freeform allows you to place data over any image or draw custom heatmaps.",
      icon: "assets/widget-type-icons/image-freeform.svg",
      iconClass: "reduce-icon-box-width",
      features: [
        WidgetFeature.title,
        WidgetFeature.image,
        WidgetFeature.dateRange,
        WidgetFeature.dataAggregation,
        WidgetFeature.gdrs,
        WidgetFeature.mapInitialZoom,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsAggregationTypeString,
        WidgetFeature.dataStreamsTimeOffset,
        WidgetFeature.dataStreamsMinMaxRange,
        WidgetFeature.dataStreamsMinMaxRangeAuto,
        WidgetFeature.dataStreamsInactiveColor,
        WidgetFeature.dataStreamsGaugeRange,
        WidgetFeature.dataStreamsUnits
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          },
          geoData: [],
          minMaxRange: [0, 100],
          autoMinMax: false,
          inactiveColor: "rgb(150,150,150)",
          gaugeRange: [{
            from: 0,
            fromP: 0,
            to: 50,
            toP: 50,
            color: "#4CAF50"
          }, {
            from: 50,
            fromP: 50,
            to: 100,
            toP: 100,
            color: "#f44336"
          }],
          units: ""
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Image Freeform",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          widgetImageSrc: ""
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None, // total
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1,
          mapInitialZoom: -1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_imagefreeformwidget_002",
      active: true,
      aiCompatible: false
    },
    {
      group: "Charting",
      name: "dashboardwidget",
      displayName: "Basic Chart",
      description:
        "Use this widget to create a simple column, line or area chart with multiple time-series data.",
      icon: "assets/widget-type-icons/basic-chart.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.dataAggregation,
        WidgetFeature.yAxis,
        WidgetFeature.chartBackgroundColorRange,
        WidgetFeature.gdrs,
        WidgetFeature.showNullValues,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsSeriesType,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsTimeOffset
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "column",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Basic Chart Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          yaxis: [{name: ""}],
          useGDRS: 1,
          triggerLevels: []
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_dashboardwidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Charting",
      name: "stringchart",
      displayName: "String Chart",
      description:
        "Use this for a 'Basic Chart' which uses strings rather than numerical values on the y-axis.",
      icon: "assets/widget-type-icons/string-chart.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.dataAggregation,
        WidgetFeature.yAxis,
        WidgetFeature.gdrs,
        WidgetFeature.showNullValues,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsSeriesType,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsAggregationTypeString,
        WidgetFeature.dataStreamsTimeOffset,
        WidgetFeature.dataStreamsStateNameConverter
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "line",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "String Chart Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          yaxis: [{name: ""}],
          useGDRS: 1,
          triggerLevels: []
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_stringchart_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Charting",
      name: "stackedchartwidget",
      displayName: "Stacked Chart",
      description:
        "Use this for a 'Basic Chart' which allows y-axis data to be stacked.",
      icon: "assets/widget-type-icons/stacked-chart.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.dataAggregation,
        WidgetFeature.yAxis,
        WidgetFeature.gdrs,
        WidgetFeature.showNullValues,
        WidgetFeature.stacking,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsSeriesTypeStacked,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsTimeOffset
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "column",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Stacked Chart Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          yaxis: [{name: ""}],
          useGDRS: 1,
          stacking: "normal"
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_stackedchartwidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Charting",
      name: "peakdemand",
      displayName: "Peak Demand",
      description:
        "Find the highest power value within the time-series data stream and compare this with the overall highest value.",
      icon: "assets/widget-type-icons/peakdemand.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.dataAggregation,
        WidgetFeature.gdrs,
        WidgetFeature.units,
        WidgetFeature.target,
        WidgetFeature.dataIcon,
        WidgetFeature.text,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsAggregationType
      ],
      streamSlots: [
        { label: "Demand", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "area",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Max,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Peak Demand Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          target: 0,
          text: "Peak Demand",
          dataIcon: "plug",
          widgetUnit: "kW"
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Max,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_peakdemand_002",
      active: true,
      aiCompatible: true
    },
    {
      group: "Charting",
      name: "baselinechart",
      displayName: "Baseline Compare",
      description: "Compare selected period of a time-series data stream against previous period of same data stream.",
      icon: "assets/widget-type-icons/baseline-compare.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.dataAggregation,
        WidgetFeature.gdrs,
        WidgetFeature.showNullValues,
        WidgetFeature.units,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsColor2,
        WidgetFeature.dataStreamsColor2PreviousPeriod,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsSeriesType,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsTimeOffset
      ],
      streamSlots: [
        { label: "Value", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "column",
          hexStreamColor: ColorHelper.random(),
          hexStreamColor2: "#00b1f0",
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Baseline Compare Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          widgetUnit: ""
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_baselinechart_002",
      active: true,
      aiCompatible: true
    },
    {
      group: "Charting",
      name: "conditiondurationwidget",
      displayName: "Condition Duration",
      description:
        "Use this condition widget to capture condition based monitoring of any data stream. Ie. fault analysis, people counting, alarms raised.",
      icon: "assets/widget-type-icons/condition-duration.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.dataAggregation,
        WidgetFeature.gdrs,
        WidgetFeature.showNullValues,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsTimeOffset,
        WidgetFeature.dataStreamsConditionDuration
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          durationRule : ">",
          durationRuleValue : 0,
          durationRuleValue2 : 10,
          durationRuleText : "true",
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Condition Duration Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_conditiondurationwidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Charting",
      name: "columnrangewidget",
      displayName: "Column Range",
      description:
        "Use this column range widget to show the min, avg, and max value for any selected data stream. Typically used for capturing consumption data sets, temperature changes, voltage range and equipment runtime or fault status.",
      icon: "assets/widget-type-icons/column-range.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.dataAggregation,
        WidgetFeature.units,
        WidgetFeature.decimalPlaces,
        WidgetFeature.gdrs,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsTimeOffset
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Column Range Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          widgetUnit: "bits",
          decimals: 2,
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_columnrangewidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Time and Date",
      name: "aclock",
      displayName: "Analog Clock",
      description: "A simple analog clock face.",
      icon: "assets/widget-type-icons/analog-clock.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.timezone
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Analog Clock Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          timeZoneId: "Brisbane",
          useGDRS: 0,
        },
        widgetDataSettings: undefined
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(2),
      hasTitle: false,
      latestVersion: undefined,
      active: true,
      aiCompatible: true
    },
    {
      group: "Time and Date",
      name: "dclock",
      displayName: "Digital Clock",
      description: "A simple digital clock.",
      icon: "assets/widget-type-icons/digital-clock.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.timezone
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Digital Clock Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          timeZoneId: "Brisbane",
          useGDRS: 0,
        },
        widgetDataSettings: undefined
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(2),
      hasTitle: false,
      latestVersion: undefined,
      active: true,
      aiCompatible: true
    },
    {
      group: "Time and Date",
      name: "date",
      displayName: "Date Display",
      description: "A simple date display widget.",
      icon: "assets/widget-type-icons/date-display.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.timezone
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Date Display Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          timeZoneId: "Brisbane",
          useGDRS: 0,
        },
        widgetDataSettings: undefined
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(2),
      hasTitle: false,
      latestVersion: undefined,
      active: true,
      aiCompatible: true
    },
    {
      group: "Display",
      name: "pointwidget",
      displayName: "Data Point",
      description:
        "This widget is used to display the value from a single data stream. The background colour can be changed to suit colour-uniforming data representation. The readout can be from any date range can be configuration.",
      icon: "assets/widget-type-icons/data-point.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.backgroundColor,
        WidgetFeature.decimalPlaces,
        WidgetFeature.units,
        WidgetFeature.unitsPosition,
        WidgetFeature.dateRange,
        WidgetFeature.dateRangeCompact,
        WidgetFeature.gdrs,
        WidgetFeature.dataConditions,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsAggregationTypeString,
        WidgetFeature.dataStreamsTimeOffset
      ],
      streamSlots: [
        { label: "Value", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Data Point Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          widgetColor: 0,
          bgColor: -1,
          widgetColorEnabled: false,
          widgetColorHex: "#FFFFFF",
          useGDRS: 1,
          widgetUnit: "",
          widgetUnitPos: 1,
          decimals: 2,
          dataConditions: []
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(3),
      hasTitle: true,
      latestVersion: "vue_pointwidget_002",
      active: true,
      aiCompatible: true
    },
    {
      group: "Display",
      name: "datagridwidget",
      displayName: "Data Grid",
      description:
        "The Data Grid is an extremely useful widget to break-down data into a clear 'spreadsheet-like' table. Its allows quick comparison of various values and statistics of data. Easily identify the detailed information of your data streams and sort them. It also includes helpful time-period and aggregation selection options.",
      icon: "assets/widget-type-icons/data-grid.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.decimalPlaces,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.tableFields,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsTimeOffset
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Data Grid Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          decimals: 2,
          fields: ["streamLogsCount", "firstValue", "lastValue", "avg"]
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_datagridwidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Display",
      name: "rawdatawidget",
      displayName: "Raw Data",
      description:
        "This widget displays a simple grid showing time and values. Essentially it displays the raw data version of a chart.",
      icon: "assets/widget-type-icons/raw-data.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.decimalPlaces,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.dataAggregation,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsAggregationTypeString,
        WidgetFeature.dataStreamsTimeOffset
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Raw Data Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          decimals: 2
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_rawdatawidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Display",
      name: "leaderboardwidget",
      displayName: "Leaderboard",
      description:
        "This widget allows you to assign multiple mains-meters or a series of sub-meters to rank the respective consumption. You can grade your data against each other to see the comparative values. You will have the option to see the lowest to highest, or the highest to lowest.",
      icon: "assets/widget-type-icons/leaderboard.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.decimalPlaces,
        WidgetFeature.units,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.dataAggregation,
        WidgetFeature.leaderboardDirection,
        WidgetFeature.leaderboardFilter,
        WidgetFeature.dataConditions,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsAggregationTypeString,
        WidgetFeature.dataStreamsTimeOffset,
        WidgetFeature.dataStreamsLink
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            timeOffset: {
              value: 0,
              period: "H"
            }
          },
          linkType: 0,
          link: "",
          linkDashboardType: DashboardType.Organisation
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Leaderboard Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          decimals: 2,
          widgetUnit: "bits",
          leaderboardDirection: "desc",
          dataConditions: []
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_leaderboardwidget_002",
      active: true,
      aiCompatible: true
    },
    {
      group: "Display",
      name: "titlewidget",
      displayName: "Title",
      description: "Title box that used for headings or any other large text.",
      icon: "assets/widget-type-icons/title.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.backgroundColor,
        WidgetFeature.link
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Title Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          widgetColor: 0,
          bgColor: -1,
          widgetColorEnabled: false,
          widgetColorHex: "#FFFFFF",
          linkType: 0,
          link: "",
          urlLink: "",
          useGDRS: 0
        },
        widgetDataSettings: undefined
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: false,
      latestVersion: "vue_titlewidget_001",
      active: true,
      aiCompatible: false
    },
    {
      group: "Display",
      name: "textboxwidget",
      displayName: "Text Box",
      description: "Text box used for custom styled text.",
      icon: "assets/widget-type-icons/text-box.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.titleOptional,
        WidgetFeature.backgroundColor,
        WidgetFeature.htmlContent
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Text Box Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          widgetColor: 0,
          bgColor: -1,
          widgetColorEnabled: false,
          widgetColorHex: "#FFFFFF",
          useGDRS: 0,
          widgetContent: "",
          displayTitle: false
        },
        widgetDataSettings: undefined
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: false,
      latestVersion: "vue_textboxwidget_001",
      active: true,
      aiCompatible: false
    },
    {
      group: "Display",
      name: "websiteview",
      displayName: "Web Embed",
      description: "Widget for viewing embed websites.",
      icon: "assets/widget-type-icons/web-embed.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.iframe
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Web Embed Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 0,
          web_url: ""
        },
        widgetDataSettings: undefined
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(12),
      hasTitle: true,
      latestVersion: undefined,
      active: true,
      aiCompatible: false
    },
    {
      group: "Display",
      name: "imagewidget",
      displayName: "Image",
      description: "Display a picture of your choice in your space.",
      icon: "assets/widget-type-icons/image.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.titleOptional,
        WidgetFeature.image,
        WidgetFeature.imageFillType,
        WidgetFeature.minHeight,
        WidgetFeature.nogutter,
        WidgetFeature.link,
        WidgetFeature.dataOptional,
        // optional
        WidgetFeature.dateRange,
        WidgetFeature.dateRangeCompact,
        WidgetFeature.gdrs,
        WidgetFeature.dataConditionsImage,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsAggregationTypeString,
        WidgetFeature.dataStreamsTimeOffset
      ],
      streamSlots: [
        { label: "Value", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Image Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          widgetImageSrc: "",
          fillType: "fill",
          minHeight: 200,
          linkType: 0,
          link: "",
          urlLink: "",
          displayTitle: false,
          enableOptionalData: false,
          dataConditionsImage: []
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(3),
      hasTitle: false,
      latestVersion: "vue_imagewidget_003",
      active: true,
      aiCompatible: false
    },
    {
      group: "Display",
      name: "aibuttonwidget",
      displayName: "AI Button",
      description: "Widget for displaying AI insights.",
      icon: "assets/widget-type-icons/ai-button.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.titleOptional,
        WidgetFeature.image,
        WidgetFeature.imageFillType,
        WidgetFeature.minHeight,
        WidgetFeature.nogutter,
        WidgetFeature.link,
        WidgetFeature.dataAggregation,
        WidgetFeature.dateRange,
        WidgetFeature.dateRangeCompact,
        WidgetFeature.gdrs,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsAggregationTypeString,
        WidgetFeature.dataStreamsTimeOffset,
        WidgetFeature.aiCustomQuestion
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "AI Button",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          widgetImageSrc: "",
          fillType: "fill",
          minHeight: 200,
          linkType: 2,
          link: "",
          urlLink: "",
          displayTitle: false,
          aiCustomQuestion: "",
          aiPersonaId: "custom",
          aiQuestionId: null
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(3),
      hasTitle: false,
      latestVersion: "vue_aibuttonwidget_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Display",
      name: "limittrackerwidget",
      displayName: "Limit Tracker",
      description:
        "Compare a dynamic value to a set maximum value.",
      icon: "assets/widget-type-icons/limit-tracker.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.limitColorText,
        WidgetFeature.dateRange,
        WidgetFeature.dateRangeCompact,
        WidgetFeature.decimalPlaces,
        WidgetFeature.gdrs,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsAggregationTypeString,
      ],
      streamSlots: [
        { label: "Input Value", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Limit Tracker",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          limitThreshold: 10,
          decimals: 2,
          useGDRS: 1,
          widgetColor: 0,
          widgetColorEnabled: false,
          bgColor: -1,
          limitRuleOperator: AlarmOperators.lte,
          underLimitText: "You may enter",
          underLimitColor: "#5AC15F",
          overLimitText: "Please wait",
          overLimitColor: "#FF3E3E",
          underLimitState: "Available",
          overLimitState: "At Capacity"
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_limitracker_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Display",
      name: "mapwidget",
      displayName: "Map",
      description: "Use the maps widget to display site locations.",
      icon: "assets/widget-type-icons/maps.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dataSites,
        WidgetFeature.dataSitesLink,
        WidgetFeature.nogutter,
        WidgetFeature.titleOptional,
        WidgetFeature.mapType,
        WidgetFeature.minHeight
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Map",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          widgetColor: 0,
          bgColor: -1,
          widgetColorEnabled: false,
          widgetColorHex: "#FFFFFF",
          useGDRS: 0,
          displayTitle: true,
          minHeight: 400
        },
        widgetDataSettings: {
          sites: [],
          mapType: "roadmap",
          // not used, but it's required
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: []
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: false,
      latestVersion: "vue_mapwidget_002",
      active: true,
      aiCompatible: false
    },
    {
      group: "Display",
      name: "notificationsstatuswidget",
      displayName: "Notifications Status",
      description: "Displays the current status of a notification group.",
      icon: "assets/widget-type-icons/notifications-status.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.notificationGroups,
        WidgetFeature.notificationStatus,
        WidgetFeature.notificationFilter
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Notifications Status",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 0,
          notificationGroupId: "",
          notificationStatus: false, // false - unread
          notificationFilter: "",
          notificationSubtitles: [],
          notificationSourceIds: []
        },
        widgetDataSettings: undefined
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: false,
      latestVersion: "vue_notificationsstatus_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Weather",
      name: "cweather",
      displayName: "Current Weather",
      description:
        "Displays the current weather conditions of a chosen location.",
      icon: "assets/widget-type-icons/current-weather.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.weather
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Current Weather Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 0,
          location: {
            full: "Brisbane City, Australia",
            country: "AU",
            lat: -27.4689682,
            lng: 153.0234991
          }
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(2),
      hasTitle: false,
      latestVersion: "vue_cweather_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Weather",
      name: "fweather",
      displayName: "Forecast",
      description: "Display the forecast.",
      icon: "assets/widget-type-icons/forecast.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.weather
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Forecast Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 0,
          location: {
            full: "Brisbane City, Australia",
            country: "AU",
            lat: -27.4689682,
            lng: 153.0234991
          }
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: false,
      latestVersion: "vue_fweather_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Utility",
      name: "tariffsimulator",
      displayName: "Tariff Simulator",
      description:
        "Compare your building's electricity usage on current tariff against multiple alternative tariffs.",
      icon: "assets/widget-type-icons/tariff-simulator.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.gdrs,
        WidgetFeature.dateRange,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsAggregationType
      ],
      streamSlots: [
        { label: "Total Usage", unit: "kWh" },
        { label: "Demand", unit: "kW" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Diff,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Tariff Simulator Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          tariffs: [],
          currentTariff: null
        },
        widgetDataSettings: {
          rangePreset: TimeRange.ThisMonth,
          rangePresetHolder: TimeRange.ThisMonth,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: []
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_tariffsimulator_001",
      active: true,
      aiCompatible: false
    },
    {
      group: "Utility",
      name: "tariffcalculator",
      displayName: "Tariff Calculator",
      description:
        "Compare your building's electricity usage on current tariff against multiple alternative tariffs.",
      icon: "assets/widget-type-icons/tariff-calculator.svg",
      iconClass: "",
      features: [
        WidgetFeature.title
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Tariff Calculator Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 0
        },
        widgetDataSettings: {
          rangePreset: TimeRange.ThisMonth,
          rangePresetHolder: TimeRange.ThisMonth,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: moment().startOf("month").format("YYYY-MM-DD"),
          startTime: "",
          endDate: moment().startOf("month").add(1, "month").format("YYYY-MM-DD"),
          endTime: "",
          streamOptions: [],
          includeGST: true,
          ReadingStart: 0,
          ReadingEnd: 100,
          DebtorId: undefined,
          kW: 0
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_tariffcalculator_001",
      active: true,
      aiCompatible: false
    },
    {
      group: "Utility",
      name: "alarm",
      displayName: "Alarm",
      description:
        "Monitor data against a set-point value and get alerted when the data reaches or exceeds the set-point.",
      icon: "assets/widget-type-icons/alarm.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.decimalPlaces,
        WidgetFeature.units,
        WidgetFeature.unitsPosition,
        WidgetFeature.alarm,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsAggregationType
      ],
      streamSlots: [
        { label: "Value", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Max,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Alarm Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 0,
          widgetUnit: "",
          widgetUnitPos: 1,
          decimals: 2,
          alarmValue: 50,
          alarmCheckMins: 5,
          aggregating: false,
          alarmOperator: AlarmOperators.gte,
          alarmShowTime: true,
          alarmShowDate: false
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Max,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_alarm_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Utility",
      name: "reporting",
      displayName: "Reporting",
      description:
        "Generate a monthly report to support NABERS or tenant billing.",
      icon: "assets/widget-type-icons/reporting.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.meteringReport,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSkipInTotal,
        WidgetFeature.dataStreamsIsAccumulating,
        WidgetFeature.dataStreamsGroups
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Max,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            groupIndex: 0,
            skipInTotal: false,
            IsAccumulating: false
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Reporting Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 0,
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Max,
          showNullValues: 1,
          Month: moment().format("MM/YYYY")
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "vue_reporting_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Utility",
      name: "fileattachment",
      displayName: "File Attachment",
      description: "Add, share and store files on a dashboard.",
      icon: "assets/widget-type-icons/file-attachment.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.file,
        WidgetFeature.backgroundColor
      ],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "File Attachment Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          widgetColor: 0,
          bgColor: -1,
          widgetColorEnabled: false,
          widgetColorHex: "#FFFFFF",
          useGDRS: 0,
          widgetFileUrl: ""
        },
        widgetDataSettings: undefined
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(3),
      hasTitle: true,
      latestVersion: "vue_fileattachment_002",
      active: true,
      aiCompatible: false
    },
    {
      group: "Infographic",
      name: "temperature",
      displayName: "Temperature",
      description:
        "Showcase daily temperature ranges from a specific sensor relative to its set-point.",
      icon: "assets/widget-type-icons/temperature.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.setPoint,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType,
      ],
      streamSlots: [
        { label: "Temperature Inside", unit: "" },
        { label: "Temperature Outside", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Last,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Temperature Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          setPoint: 20,
          useGDRS: 1,
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Last,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_temperature_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Infographic",
      name: "humidity",
      displayName: "Humidity",
      description:
        "Showcase variance of humidity between inside and outside of a building.",
      icon: "assets/widget-type-icons/humidity.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType,
      ],
      streamSlots: [
        { label: "Humidity Inside", unit: "" },
        { label: "Humidity Outside", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Last,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Humidity Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Last,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_humidity_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Infographic",
      name: "powerusage",
      displayName: "Period Compare",
      description: "\"Period Compare\" widget enables the current value and time range to be compared against the previous.",
      icon: "assets/widget-type-icons/power-usage.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.units,
        WidgetFeature.dataIcon,
        WidgetFeature.widgetMode,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataOptionalTimestamp,
        WidgetFeature.dataOptionalLastPeriodValue,
        WidgetFeature.dataNegativeState,
        WidgetFeature.dataConditions,
        WidgetFeature.dataConditionsMode
      ],
      streamSlots: [
        { label: "Value", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: "#FF8F2E",
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Sum,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Period Compare Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
          widgetUnit: "",
          dataIcon: "bitpool",
          widgetMode: "arrowsOnly",
          enableOptionalTimestamp: false,
          enableOptionalLastPeriodValue: false,
          dataNegativeState: "increase",
          dataConditions: [],
          dataConditionsMode: "percent"
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Last,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_powerusage_005",
      active: true,
      aiCompatible: true
    },
    {
      group: "Infographic",
      name: "solargenerated",
      displayName: "Solar Generated",
      description:
        "Showcase solar energy generated today and relative power to operate a standard 250W computer.",
      icon: "assets/widget-type-icons/solar-generated.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType,
      ],
      streamSlots: [
        { label: "Solar Value", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Sum,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Solar Generated Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Last,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_solargenerated_001",
      active: true,
      aiCompatible: true
    },
    {
      group: "Infographic",
      name: "powerusedgenerated",
      displayName: "Power Used/Generated",
      description:
        "Compare energy consumed by a building to the amount generated by solar or other means.",
      icon: "assets/widget-type-icons/power-used-generated.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dateRange,
        WidgetFeature.gdrs,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsExportCSV,
        WidgetFeature.dataAnalyzeOpenAI,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsText
      ],
      streamSlots: [
        { label: "Grid Power Value", unit: "" },
        { label: "Solar Power Value", unit: "" }
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          text: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Power Used/Generated Widget",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 1,
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.None,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_powerusedgenerated_002",
      active: true,
      aiCompatible: true
    },
    {
      group: "Command and Control",
      name: "mqttbooleancontrolwidget",
      displayName: "Boolean Control",
      description: "Use the boolean control to attach a toggle switch to any data values with binary states.",
      icon: "assets/widget-type-icons/boolean-control.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.booleanValuesName,
        WidgetFeature.dataStreams
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Last,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      includeTags: ["mqtt|nubeIO&writeable=true"],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Boolean Control",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          booleanTrueName: "On",
          booleanFalseName: "Off",
          useGDRS: 0
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Daily,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Last,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_mqttbooleancontrolwidget_001",
      active: true,
      aiCompatible: false
    },
    {
      group: "Command and Control",
      name: "mqttnumericcontrolwidget",
      displayName: "Numeric Control",
      description: "Use the numeric control to modify the number value of data sets. Typically used for temperature set points.",
      icon: "assets/widget-type-icons/numeric-control.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.units,
        WidgetFeature.unitsPosition,
        WidgetFeature.dataStreams
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Last,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      includeTags: ["mqtt|nubeIO&writeable=true"],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "Numeric Control",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          widgetUnit: "",
          widgetUnitPos: 1,
          useGDRS: 0
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Daily,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Last,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_mqttnumericcontrolwidget_001",
      active: true,
      aiCompatible: false
    },
    {
      group: "Command and Control",
      name: "mqttstringcontrolwidget",
      displayName: "String Control",
      description: "Use the string control to modify the string values for data sets.",
      icon: "assets/widget-type-icons/string-control.svg",
      iconClass: "",
      features: [
        WidgetFeature.title,
        WidgetFeature.dataStreams
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Last,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      includeTags: ["mqtt"],
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "String Control",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          useGDRS: 0
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Daily,
          autoAggPeriod: false,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Last,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(4),
      hasTitle: true,
      latestVersion: "vue_mqttstringcontrolwidget_001",
      active: true,
      aiCompatible: false
    },

    // reporting
    {
      group: "_reports",
      name: "reports3",
      displayName: "reports3",
      description: "",
      icon: "",
      iconClass: "",
      features: [
        WidgetFeature.dateRange,
        WidgetFeature.dataAggregation,
        WidgetFeature.gdrs,
        WidgetFeature.showNullValues,
        WidgetFeature.dataStreams,
        WidgetFeature.dataStreamsMultiplier,
        WidgetFeature.dataStreamsSmooth,
        WidgetFeature.dataStreamsColor,
        WidgetFeature.dataStreamsTimeIntervals,
        WidgetFeature.dataStreamsAggregationType,
        WidgetFeature.dataStreamsTimeOffset
      ],
      get defaultStreamOptions(): StreamOption {
        return {
          type: "column",
          hexStreamColor: ColorHelper.random(),
          StreamKey: "",
          Uid: uuidv4(),
          Name: "",
          Label: "",
          period: "",
          stackedType: "",
          streamColor: "",
          Params: {
            aggType: AggregationType.Avg,
            aggTypeString: AggregationTypeString.Last,
            multiplier: 1,
            postProcessIfAvailable: 0,
            yaxis: 0,
            useTimeIntervals: 0,
            timeIntervals: [],
            timeOffset: {
              value: 0,
              period: "H"
            }
          }
        }
      },
      defaultOptions: {
        basicWidgetSettings: {
          widgetName: "",
          widgetSubtitle: "",
          widgetDesc: ""
        },
        advancedWidgetSettings: {
          yaxis: [{name: ""}],
          useGDRS: 1,
          triggerLevels: []
        },
        widgetDataSettings: {
          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          streamOptions: [],
          aggType: AggregationType.Avg,
          showNullValues: 1
        }
      },
      defaultSize: DashboardMigrationHelper.buildSizeFromMap(6),
      hasTitle: true,
      latestVersion: "reports3",
      active: false,
      aiCompatible: false
    }
  ];

  getGroups(): WidgetGroupDescription[] {
    return this.groups.filter((x) => x.active);
  }

  getWidgets(group: string): WidgetDescription[] {
    return this.widgets.filter((x) => x.group === group && x.active);
  }

  getWidget(name: string, activeOnly: boolean = true): WidgetDescription | undefined {
    return this.widgets.find((x) => x.name === name && (x.active || !activeOnly));
  }

  getWidgetsListForAI(): WidgetDescription[] {
    return this.widgets.filter((x) => x.aiCompatible);
  }

  getWidgetsStringForAI(names: string[]): string {
    let result: string = "";
    names.forEach(name => {
      const widget = this.getWidget(name);
      if (!widget || !widget.aiCompatible) {
        return;
      }
      let acceptStreams = widget.streamSlots?.length ? widget.streamSlots.length : widget.defaultStreamOptions ? 'many' : '0';
      if (widget.name === "heatmap") {
        acceptStreams = 1;  // ai can't fill second stream correctly
      }
      let streamConfig = `Widget can display data for ${acceptStreams} streams!`;
      if (widget.streamSlots?.length) {
        widget.streamSlots.forEach((slot, index) => {
          if (index === 1 && widget.name === "heatmap") {
            return; // ai can't fill second stream correctly
          }
          streamConfig += `\nStream slot #${index + 1}: '${slot.label}' accept ${slot.unit ? slot.unit : 'any'} units`;
        });
      }
      const supportedDataType: string[] = [];
      if (widget.features.includes(WidgetFeature.dataStreamsAggregationType) && widget.name !== "stringchart") {
        supportedDataType.push('numeric');
      }
      if (widget.features.includes(WidgetFeature.dataStreamsAggregationTypeString)) {
        supportedDataType.push('string');
      }
      if (supportedDataType.length) {
        streamConfig += `\nSupported stream data type: ${supportedDataType.join(', ')}`;
      }
      if (widget.features.includes(WidgetFeature.dataStreamsSeriesType) || widget.features.includes(WidgetFeature.dataStreamsSeriesTypeStacked)) {
        streamConfig += `\nSupported series types: "column", "line", "area", "scatter", "spline"`;
      }
      result += `Widget type: ${widget.name}\nDisplay name: ${widget.displayName}\nDescription: ${widget.description}\nGroup: ${widget.group}\n${streamConfig}\nMin size: ${widget.defaultSize.size}\nMin sizeSM: ${widget.defaultSize.sizeSM}\nMin sizeMD: ${widget.defaultSize.sizeMD}\nMin sizeLG: ${widget.defaultSize.sizeLG}\nMin sizeXL: ${widget.defaultSize.sizeXL}\n\n`;
    });
    return result;
  }

  getWidgetSizes(): any[] {
    return [
      { name: '1/12 (~ 8%)', key: 1 },
      { name: '2/12 (~ 17%)', key: 2 },
      { name: '3/12 (25%)', key: 3 },
      { name: '4/12 (~ 33%)', key: 4 },
      { name: '5/12 (~ 42%)', key: 5 },
      { name: '6/12 (50%)', key: 6 },
      { name: '7/12 (~ 58%)', key: 7 },
      { name: '8/12 (~ 67%)', key: 8 },
      { name: '9/12 (75%)', key: 9 },
      { name: '10/12 (~ 83%)', key: 10 },
      { name: '11/12 (~ 92%)', key: 11 },
      { name: '12/12 (100%)', key: 12 }
    ];
  }
}

export default new WidgetHelper();
