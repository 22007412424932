<template>
  <div class="report-constructor-settings">
    <div class="report-constructor-settings-title">
      <div v-if="!reports3Store.dataOneSelectedElementConfiguration && reports3Store.dataOne && !reports3Store.isCompactMode">
        <h3>{{ reports3Store.getSelectedElementTitle }}</h3>
      </div>
      <div v-else-if="reports3Store.dataOneSelectedElementConfiguration?.Role === Reports3ItemRole.Grid && !reports3Store.isCompactMode">
        <h3>{{ reports3Store.getSelectedElementTitle }}</h3>
      </div>
      <div v-else-if="reports3Store.dataOneSelectedElementConfiguration?.Role === Reports3ItemRole.Element">
        <h3 v-if="!reports3Store.isCompactMode">{{ reports3Store.getSelectedElementTitle }}</h3>
        <p v-if="reports3Store.getSelectedElementDescription" class="help-text">{{ reports3Store.getSelectedElementDescription }}</p>
      </div>
    </div>

    <div v-if="reports3Store.dataOneSelectedElementConfiguration && reports3Store.dataOneSelectedElementConfiguration.Uid !== reports3Store.dataOne?.Header?.Uid && reports3Store.dataOneSelectedElementConfiguration.Uid !== reports3Store.dataOne?.Footer?.Uid" class="report-constructor-settings-item">
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" style="transform: translateY(-1px);"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m12 8 7-7m0 0h-6m6 0v6M8 12l-7 7m0 0h6m-6 0v-6"/></svg>
        <span>Size</span>
      </h4>
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          <label for="editRecordItemSize">Width</label>
          <div>
            <Dropdown 
              intupId="editRecordItemSize"
              v-model="reports3Store.dataOneSelectedElementConfiguration.Size"
              :options="columns" 
              optionValue="key" 
              optionLabel="name" 
              placeholder="Select size" 
              class="w-full"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!reports3Store.dataOneSelectedElementConfiguration && reports3Store.dataOne" class="report-constructor-settings-item">
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1 7h18M7 7v12M5.8 1h8.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C19 3.28 19 4.12 19 5.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C16.72 19 15.88 19 14.2 19H5.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C1 16.72 1 15.88 1 14.2V5.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C3.28 1 4.12 1 5.8 1Z"/></svg>
        <span>Layout</span>
      </h4>
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          <label>Margin</label>
        </div>
        <div class="field col-6 flex align-items-center gap-2">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.5 1.5v.008M9 1.5v.008M5.25 1.5v.008m7.5-.008v.008M16.5 5.25v.008M9 5.25v.008m7.5 7.492v.008M9 12.75v.008M16.5 9v.008M9 9v.008M5.25 9v.008M12.75 9v.008M16.5 16.5v.008M9 16.5v.008M5.25 16.5v.008m7.5-.008v.008M1.5 16.5v-15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginLeft"
              class="inputfield w-full"
              v-model="reports3Store.dataOne.MarginLeft"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.5 16.5h-.008M16.5 9h-.008m.008-3.75h-.008m.008 7.5h-.008M12.75 16.5h-.008M12.75 9h-.008M5.25 16.5h-.008M5.25 9h-.008M9 16.5h-.008M9 9h-.008M9 5.25h-.008M9 12.75h-.008M1.5 16.5h-.008M1.5 9h-.008M1.5 5.25h-.008m.008 7.5h-.008M1.5 1.5h15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginTop"
              class="inputfield w-full"
              v-model="reports3Store.dataOne.MarginTop"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2 mb-0">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.5 16.5v-.008M9 16.5v-.008m3.75.008v-.008m-7.5.008v-.008M1.5 12.75v-.008m7.5.008v-.008M1.5 5.25v-.008M9 5.25v-.008M1.5 9v-.008M9 9v-.008M12.75 9v-.008M5.25 9v-.008M1.5 1.5v-.008M9 1.5v-.008m3.75.008v-.008m-7.5.008v-.008M16.5 1.5v15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginRight"
              class="inputfield w-full"
              v-model="reports3Store.dataOne.MarginRight"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2 mb-0">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.5 1.5h.008M1.5 9h.008M1.5 12.75h.008M1.5 5.25h.008M5.25 1.5h.008M5.25 9h.008m7.492-7.5h.008M12.75 9h.008M9 1.5h.008M9 9h.008M9 12.75h.008M9 5.25h.008M16.5 1.5h.008M16.5 9h.008m-.008 3.75h.008m-.008-7.5h.008M16.5 16.5h-15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginBottom"
              class="inputfield w-full"
              v-model="reports3Store.dataOne.MarginBottom"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
      </div>
    </div>

    <div 
      v-if="reports3Store.dataOneSelectedElementConfiguration?.Role === Reports3ItemRole.Element && reports3Store.getSelectedElementEntity?.Role === Reports3ElementRole.Body" 
      class="report-constructor-settings-item"
    >
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 16V4m0 12-3-2m3 2 3-2M10 4 7 6m3-2 3 2m6-5H1m18 18H1"/></svg>
        <span>Space</span>
      </h4>
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          <label>Margin</label>
        </div>
        <div class="field col-6 flex align-items-center gap-2">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.5 1.5v.008M9 1.5v.008M5.25 1.5v.008m7.5-.008v.008M16.5 5.25v.008M9 5.25v.008m7.5 7.492v.008M9 12.75v.008M16.5 9v.008M9 9v.008M5.25 9v.008M12.75 9v.008M16.5 16.5v.008M9 16.5v.008M5.25 16.5v.008m7.5-.008v.008M1.5 16.5v-15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginLeft"
              class="inputfield w-full"
              v-model="reports3Store.dataOneSelectedElementConfiguration.MarginLeft"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.5 16.5h-.008M16.5 9h-.008m.008-3.75h-.008m.008 7.5h-.008M12.75 16.5h-.008M12.75 9h-.008M5.25 16.5h-.008M5.25 9h-.008M9 16.5h-.008M9 9h-.008M9 5.25h-.008M9 12.75h-.008M1.5 16.5h-.008M1.5 9h-.008M1.5 5.25h-.008m.008 7.5h-.008M1.5 1.5h15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginTop"
              class="inputfield w-full"
              v-model="reports3Store.dataOneSelectedElementConfiguration.MarginTop"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2 mb-0">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.5 16.5v-.008M9 16.5v-.008m3.75.008v-.008m-7.5.008v-.008M1.5 12.75v-.008m7.5.008v-.008M1.5 5.25v-.008M9 5.25v-.008M1.5 9v-.008M9 9v-.008M12.75 9v-.008M5.25 9v-.008M1.5 1.5v-.008M9 1.5v-.008m3.75.008v-.008m-7.5.008v-.008M16.5 1.5v15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginRight"
              class="inputfield w-full"
              v-model="reports3Store.dataOneSelectedElementConfiguration.MarginRight"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2 mb-0">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.5 1.5h.008M1.5 9h.008M1.5 12.75h.008M1.5 5.25h.008M5.25 1.5h.008M5.25 9h.008m7.492-7.5h.008M12.75 9h.008M9 1.5h.008M9 9h.008M9 12.75h.008M9 5.25h.008M16.5 1.5h.008M16.5 9h.008m-.008 3.75h.008m-.008-7.5h.008M16.5 16.5h-15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginBottom"
              class="inputfield w-full"
              v-model="reports3Store.dataOneSelectedElementConfiguration.MarginBottom"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="reports3Store.dataOne && reports3Store.dataOneSelectedElementConfiguration && reports3Store.getSelectedElementEntity?.EnableData"  class="report-constructor-settings-item">
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" fill="none"><path fill="currentColor" d="M0 15.518a1.111 1.111 0 0 0 1.111 1.111H18.89A1.111 1.111 0 0 0 20 15.518V13.74a1.111 1.111 0 0 0-1.111-1.111H1.11A1.111 1.111 0 0 0 0 13.74v1.778Zm2-.889a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-.889-14A1.111 1.111 0 0 0 0 1.74v1.778a1.111 1.111 0 0 0 1.111 1.111H18.89A1.111 1.111 0 0 0 20 3.518V1.74A1.111 1.111 0 0 0 18.889.63H1.11Zm2.889 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM0 9.518a1.111 1.111 0 0 0 1.111 1.111H18.89A1.111 1.111 0 0 0 20 9.518V7.74a1.111 1.111 0 0 0-1.111-1.111H1.11A1.111 1.111 0 0 0 0 7.74v1.778Zm2-.889a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"/></svg>
        <span>Data</span>
      </h4>
      <div class="formgrid grid">
        <div class="field col-12">
          <label for="editRecordElementDatasource">Data source</label>
          <div>
            <Dropdown 
              intupId="editRecordElementDatasource"
              v-model="reports3Store.dataOneSelectedElementConfiguration.DatasourceId"
              :options="reports3Store.dataOne.Datasources" 
              optionValue="Uid" 
              optionLabel="Name" 
              placeholder="Select data source" 
              class="w-full"
              @change="onDatasourceChange"
            />
          </div>
        </div>
        <div class="field col-12" v-if="selectedDataStreams.length && !isHideStreamsFeature">
          <div class="flex justify-content-between align-items-center mb-2">
            <label class="mb-0">Streams</label>
            <span class="report-constructor-settings-item-label-links">
              <Button label="Select all" link class="p-0" @click="selectAllDataStreams(true)" />
              <i></i>
              <Button label="Deselect all" link class="p-0" @click="selectAllDataStreams(false)" />
            </span>
          </div>
          <div>
            <DataTable 
              :value="selectedDataStreams" 
              showGridlines 
              responsiveLayout="stack" 
              breakpoint="600px"
              class="widget-settings-table responsive-breakpoint p-datatable-sm"
            >
              <Column field="label" header="Stream" headerClass="text-sm" bodyClass="text-sm break-word" headerStyle="min-width: min-content;"></Column>
              <Column field="type" header="Enabled" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 1%; min-width: 42px;">
                <template #body="{ data }">
                  <InputSwitch
                    v-model="data.enabled"
                    @change="onDataStreamsEnabledChange"
                    :disabled="!canSelectMoreStreams && !data.enabled"
                    class="vertical-align-middle"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="field col-12" v-if="reports3Store.getSelectedElementEntity.Features.includes(Report3ElementFeatures.DataSeriesType) && selectedDataSeriesTypes.length">
          <label>Series type</label>
          <div>
            <DataTable 
              :value="selectedDataSeriesTypes" 
              showGridlines 
              responsiveLayout="stack" 
              breakpoint="600px"
              class="widget-settings-table responsive-breakpoint p-datatable-sm"
            >
              <Column field="label" header="Stream" headerClass="text-sm" bodyClass="text-sm break-word" headerStyle="min-width: min-content;"></Column>
              <Column field="type" header="Type" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 1%; min-width: 126px;">
                <template #body="{ data }">
                  <Dropdown
                    v-model="data.type"
                    :options="seriesTypes"
                    placeholder="Select type" 
                    class="w-full vertical-align-middle"
                    optionLabel="name"
                    optionValue="id"
                    @change="onSeriesTypeChange"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="field col-12" v-if="reports3Store.getSelectedElementEntity.Features.includes(Report3ElementFeatures.DataSeriesTypeStacked) && selectedDataSeriesTypesStacked.length">
          <label>Series stacked type</label>
          <div>
            <DataTable 
              :value="selectedDataSeriesTypesStacked" 
              showGridlines 
              responsiveLayout="stack" 
              breakpoint="600px"
              class="widget-settings-table responsive-breakpoint p-datatable-sm"
            >
              <Column field="label" header="Stream" headerClass="text-sm" bodyClass="text-sm break-word" headerStyle="min-width: min-content;"></Column>
              <Column field="type" header="Type" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 1%; min-width: 126px;">
                <template #body="{ data }">
                  <Dropdown
                    v-model="data.stackedType"
                    :options="seriesTypesStacked"
                    placeholder="Select type" 
                    class="w-full vertical-align-middle"
                    optionLabel="name"
                    optionValue="id"
                    @change="onSeriesTypeStackedChange"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="field col-12" v-if="reports3Store.getSelectedElementEntity.Features.includes(Report3ElementFeatures.YAxis) && selectedYAxis.length">
          <label>Y Axis</label>
          <div>
            <DataTable 
              :value="selectedYAxis" 
              showGridlines 
              responsiveLayout="stack" 
              breakpoint="600px"
              class="widget-settings-table responsive-breakpoint p-datatable-sm"
            >
              <Column field="label" header="Stream" headerClass="text-sm" bodyClass="text-sm break-word" headerStyle="min-width: min-content;"></Column>
              <Column field="yAxis" header="Y Axis" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 1%; min-width: 126px;">
                <template #body="{ data }">
                  <InputText
                    v-model="data.yAxis"
                    class="w-full vertical-align-middle"
                    @input="onYAxisChange"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="field col-12" v-if="reports3Store.getSelectedElementEntity.Features.includes(Report3ElementFeatures.BitpoolAI)">
          <label for="">Bitpool AI Skill</label>
          <div>
            <Dropdown
              v-model="bitpoolAIConfiguration.PersonaId"
              :options="availablePersonas"
              optionLabel="label"
              optionValue="value"
              @change="onBitpoolAIChange"
              panelClass="reduce-options-font-size"
              class="w-full"
            />
          </div>
        </div>
        <div class="field col-12" v-if="reports3Store.getSelectedElementEntity.Features.includes(Report3ElementFeatures.BitpoolAI)">
          <label for="">Question</label>
          <div>
            <Dropdown
              v-model="bitpoolAIConfiguration.QuestionId"
              :options="availableInsights"
              optionLabel="label"
              optionValue="value"
              @change="onBitpoolAIChange"
              panelClass="reduce-options-font-size"
              class="w-full"
              :loading="!aiInsightLibraryStore.isLoaded"
            />
          </div>
          <div v-if="bitpoolAIConfiguration.QuestionId === 'custom'" class="mt-2 report-bitpool-ai-custom-question">
            <Textarea
              v-model="bitpoolAICustomQuestion" 
              rows="3"
              :autoResize="true"
              class="w-full"
              style="white-space: pre-wrap;"
            />

            <div class="inline-flex report-bitpool-ai-custom-question-btn">
              <Button
                icon="pi pi-send text-xl" 
                class="flex-shrink-0 p-button-lg p-button-icon-only p-button-text p-button-secondary" 
                @click="onBitpoolAICustomQuestionChange"
                :disabled="!bitpoolAICustomQuestion || bitpoolAICustomQuestion === bitpoolAIConfiguration.CustomQuestion"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="reports3Store.dataOneSelectedElementConfiguration?.AdditionalParameters && reports3Store.getSelectedElementEntity?.AdditionalParameters?.length"  class="report-constructor-settings-item">
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.955 4.092h12.857m0 0c0 1.521 1.152 2.755 2.571 2.755 1.42 0 2.572-1.234 2.572-2.755 0-1.522-1.151-2.756-2.572-2.756-1.42 0-2.57 1.234-2.57 2.756Zm-7.714 6.705h12.857m-12.857 0c0 1.522-1.152 2.756-2.571 2.756-1.42 0-2.572-1.234-2.572-2.756 0-1.522 1.151-2.755 2.572-2.755 1.42 0 2.57 1.233 2.57 2.755ZM.956 17.595h12.856m0 0c0 1.521 1.152 2.755 2.571 2.755 1.42 0 2.572-1.234 2.572-2.755 0-1.523-1.151-2.756-2.572-2.756-1.42 0-2.57 1.233-2.57 2.756Z"/></svg>
        <span>Parameters</span>
      </h4>
      <div class="formgrid grid">
        <div 
          class="field" 
          v-for="(additionalParameter, index) in reports3Store.getSelectedElementEntity.AdditionalParameters" 
          :key="index + reports3Store.dataOneSelectedElementConfiguration.Uid"
          :class="(additionalParameter.CSSClass ? additionalParameter.CSSClass : 'col-12') + (additionalParameter.Type === ScriptAdditionalParameterType.GroupTitle ? ' report-constructor-settings-group-title' : (additionalParameter.Type === ScriptAdditionalParameterType.Divider ? ' report-constructor-settings-divider' : ''))"
        >
          <label :for="`editRecordParam-${additionalParameter.Name}`" v-if="additionalParameter.Type !== ScriptAdditionalParameterType.Divider">
            {{ additionalParameter.Label }}
          </label>
          <div v-if="additionalParameter.Type !== ScriptAdditionalParameterType.Divider">
            <InputText 
              v-if="additionalParameter.Type === ScriptAdditionalParameterType.String"
              :id="`editRecordParam-${additionalParameter.Name}`"
              class="inputfield w-full"
              type="text" 
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
            />
            <InputNumber
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Number"
              :id="`editRecordParam-${additionalParameter.Name}`"
              class="inputfield w-full"
              type="text" 
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
            <InputSwitch
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Boolean"
              :id="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              class="vertical-align-middle"
            />
            <div v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Color" class="flex align-items-center gap-3">
              <ColorSelectView 
                :color="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]" 
                :updateColor="(color: string) => { 
                  if (reports3Store.dataOneSelectedElementConfiguration?.AdditionalParameters) {
                    reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name] = color;
                  }
                }"
              />
              <span class="font-medium">{{ reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name] }}</span>
            </div>
            <div v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Image" class="image-inside-table">
              <div
                v-if="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              >
                <img 
                  class="image-inside-table-img" 
                  :src="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]" 
                />
                <span
                  class="image-inside-table-delete"
                  @click="() => { 
                    if (reports3Store.dataOneSelectedElementConfiguration?.AdditionalParameters) {
                      reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name] = ''; 
                    }
                  }"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><path stroke-linecap="round" stroke-linejoin="round" d="M9 1 1 9m0-8 8 8"/></svg>
                </span>
              </div>
              <ImageGalleryDialogButtonView class="image-inside-table-btn"
                @imageSelected="(entity: ImageGalleryEntity | null) => { 
                  if (reports3Store.dataOneSelectedElementConfiguration?.AdditionalParameters) {
                    reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name] = entity ? entity.OptimizedFilename ? `${uploadUrl}/${entity.OptimizedFilename}` : `${uploadUrl}/${entity.Filename}` : '';
                  }
                }"
              />
            </div>
            <Textarea 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.StringMultiLine"
              :id="`editRecordParam-${additionalParameter.Name}`"
              class="w-full" 
              style="white-space: pre-wrap;"
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :autoResize="true" 
              :rows="3"
            />
            <Dropdown 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.FontFamily"
              :inputId="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.fontFamilies"
              placeholder="Select font family" 
              class="w-full"
            />
            <Dropdown 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.FontSize"
              :inputId="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.fontSizesWithLabel"
              optionValue="value"
              optionLabel="label"
              placeholder="Select font size" 
              class="w-full"
            />
            <Dropdown 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.FontWeight"
              :inputId="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.fontWeights"
              placeholder="Select font weight" 
              class="w-full"
            />
            <Dropdown 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.FontStyle"
              :inputId="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.fontStyles"
              placeholder="Select font style" 
              class="w-full"
            />
            <SelectButton
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.TextAlign" 
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.textAlignsWithIcons" 
              optionLabel="value"
              optionValue="value"
              dataKey="value"
              class="report-constructor-settings-text-align selectbutton-lg"
            >
              <template #option="slotProps">
                <i :class="slotProps.option.icon"></i>
              </template>
            </SelectButton>
            <SelectButton
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.TextAlignVertical" 
              v-model="reports3Store.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.textAlignsVerticalWithIcons" 
              optionLabel="value"
              optionValue="value"
              dataKey="value"
              class="report-constructor-settings-text-align selectbutton-lg"
            >
              <template #option="slotProps">
                <i :class="slotProps.option.icon">{{ slotProps.option.svg }}</i>
              </template>
            </SelectButton>
            <p v-if="additionalParameter.HelpText" class="help-text">{{ additionalParameter.HelpText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Textarea from 'primevue/textarea';
import SelectButton from 'primevue/selectbutton';
import { useReports3Store } from "@/stores/reports3";
import WidgetHelper from "@/helpers/WidgetHelper";
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import { Report3ElementFeatures, Report3ElementFeaturesToString } from "@/models/reports/v3/Report3ElementFeatures";
import ColorSelectView from '@/components/widgets-next/settings/ColorSelectView.vue';
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";
import ImageGalleryDialogButtonView from "@/components/views/image-gallery/ImageGalleryDialogButtonView.vue"
import { useReports3DataStore } from "@/stores/reports3Data";
import FontHelper from "@/helpers/FontHelper";
import { useAIPersonaStore } from "@/stores/aiPersona";
import { useAIInsightLibraryStore } from "@/stores/aiInsightLibrary";
import { AIPersonaEntity } from "@/models/bitpool-ai/AIPersonaEntity";
import { debounce } from "throttle-debounce";

@Component({
  components: {
    Button,
    InputText,
    InputNumber,
    InputSwitch,
    Dropdown,
    DataTable,
    Column,
    Textarea,
    SelectButton,
    ColorSelectView,
    ImageGalleryDialogButtonView
  },
  directives: {
  }
})
class ReportsReportLeftPanelView extends Vue { 
  reports3Store = useReports3Store();
  reports3DataStore = useReports3DataStore();
  aiPersonaStore = useAIPersonaStore();
  aiInsightLibraryStore = useAIInsightLibraryStore();

  columns = WidgetHelper.getWidgetSizes();
  ScriptAdditionalParameterType = ScriptAdditionalParameterType;
  Reports3ItemRole = Reports3ItemRole;
  Reports3ElementRole = Reports3ElementRole;
  Report3ElementFeatures = Report3ElementFeatures;
  FontHelper = FontHelper;

  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3Store.dataOneElements?.length) {
      const element = this.reports3Store.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }
  
  get uploadUrl() {
    return `${this.$store.state.apiUrl}/rest/AWS_S3_V1/File`;
  }

  onDatasourceChange(): void {
    this.reloadData();
  }

  async reloadData(): Promise<void> {
    const elementConfiguration = this.reports3Store.dataOneSelectedElementConfiguration;
    const datasource = this.reports3Store.dataOne?.Datasources.find(x => x.Uid === this.reports3Store.dataOneSelectedElementConfiguration?.DatasourceId);
    if (elementConfiguration && datasource) {
      const element = this.getElement(elementConfiguration.ElementId);
      if (element) {
        await this.reports3DataStore.load(element, elementConfiguration, datasource, this.reports3Store.dataOne);
      }
    }
  }  

  // #region data streams
  get canSelectMoreStreams(): boolean {
    const maxCount = this.reports3Store.getSelectedElementEntity?.DataStreamsMaxCount ?? 0;
    return maxCount === 0 || maxCount > this.selectedDataStreams.filter(x => x.enabled).length;
  }

  get selectedDataStreams(): any[] {
    const result: any[] = [];
    if (this.reports3Store.dataOne?.Datasources.length && this.reports3Store.dataOneSelectedElementConfiguration) {
      const datasource = this.reports3Store.dataOne.Datasources.find(x => x.Uid === this.reports3Store.dataOneSelectedElementConfiguration?.DatasourceId);
      if (datasource) {
        for (const streamOption of datasource.Configuration.streamOptions) {
          result.push({
            uid: streamOption.Uid,
            label: streamOption.Label,
            enabled: false
          });
        }
      }
      const key = "DataStreams";
      if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration &&
        this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration[key]
      ) {
        const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration[key];
        const uids = Object.getOwnPropertyNames(featuresConfiguration);
        if (uids.length) {
          // restore state
          for (const item of result) {
            if (featuresConfiguration[item.uid]) {
              item.enabled = featuresConfiguration[item.uid];
            }
          }
        }
      }
    }
    return result;
  }

  set selectedDataStreams(value: any[]) {
    this.saveDataStreams(value);
  }

  selectAllDataStreams(value: boolean): void {
    const maxCount = this.reports3Store.getSelectedElementEntity?.DataStreamsMaxCount ?? 0;
    if (maxCount > 0) {
      for (let i = 0; i < this.selectedDataStreams.length; i++) {
        if (i < maxCount) {
          this.selectedDataStreams[i].enabled = value;
        } else {
          this.selectedDataStreams[i].enabled = false;
        }
      }
    } else {
      for (let i = 0; i < this.selectedDataStreams.length; i++) {
        this.selectedDataStreams[i].enabled = value;
      }
    }
    this.onDataStreamsEnabledChange();
  }

  onDataStreamsEnabledChange(): void {
    this.saveDataStreams(this.selectedDataStreams);
  }

  saveDataStreams(value: any[]): void {
    if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration) {
      const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration;
      const key = "DataStreams";
      featuresConfiguration[key] = {};
      for (const item of value) {
        featuresConfiguration[key][item.uid] = item.enabled;
      }
      this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration = featuresConfiguration;
      // for ai
      this.debounceReloadData();
    }
  }

  get isHideStreamsFeature(): boolean {
    return this.reports3Store.getSelectedElementEntity?.Features?.includes(Report3ElementFeatures.DataStreamsHide) ?? false;
  }

  debounceReloadData = debounce(1000, this.reloadData);
  // #endregion data streams

  // #region series type
  seriesTypes = [
    {
      id: "column",
      name: "Column"
    }, {
      id: "line",
      name: "Line"
    }, {
      id: "area",
      name: "Area"
    }, {
      id: "scatter",
      name: "Scatter"
    }, {
      id: "spline",
      name: "Spline"
    }
  ];

  getSeriesTypeNameById(id: string): string {
    const result = this.seriesTypes.find(x => x.id === id); 
    return result ? result.name : "";
  }

  get selectedDataSeriesTypes(): any[] {
    const result: any[] = [];
    if (this.reports3Store.dataOne?.Datasources.length && this.reports3Store.dataOneSelectedElementConfiguration) {
      const datasource = this.reports3Store.dataOne.Datasources.find(x => x.Uid === this.reports3Store.dataOneSelectedElementConfiguration?.DatasourceId);
      if (datasource) {
        for (const streamOption of datasource.Configuration.streamOptions) {
          result.push({
            uid: streamOption.Uid,
            label: streamOption.Label,
            type: "column"
          });
        }
      }
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesType];
      if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration &&
        this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration[key]
      ) {
        const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration[key];
        for (const item of result) {
          if (featuresConfiguration[item.uid]) {
            item.type = featuresConfiguration[item.uid];
          }
        }
      }
    }
    return result;
  }

  set selectedDataSeriesTypes(value: any[]) {
    this.saveSeriesTypes(value);
  }

  onSeriesTypeChange(): void {
    this.saveSeriesTypes(this.selectedDataSeriesTypes);
  }

  saveSeriesTypes(value: any[]): void {
    if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration) {
      const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration;
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesType];
      featuresConfiguration[key] = {};
      for (const item of value) {
        featuresConfiguration[key][item.uid] = item.type;
      }
      this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration = featuresConfiguration;
    }
  }
  // #endregion series type

  // #region series type stacked
  seriesTypesStacked = [
    {
      id: "column",
      name: "Column"
    }, {
      id: "column2",
      name: "Column 2"
    }, {
      id: "line",
      name: "Line"
    }, {
      id: "area",
      name: "Area"
    }, {
      id: "scatter",
      name: "Scatter"
    }, {
      id: "spline",
      name: "Spline"
    }
  ];

  getSeriesTypeStackedNameById(id: string): string {
    const result = this.seriesTypesStacked.find(x => x.id === id); 
    return result ? result.name : "";
  }

  get selectedDataSeriesTypesStacked(): any[] {
    const result: any[] = [];
    if (this.reports3Store.dataOne?.Datasources.length && this.reports3Store.dataOneSelectedElementConfiguration) {
      const datasource = this.reports3Store.dataOne.Datasources.find(x => x.Uid === this.reports3Store.dataOneSelectedElementConfiguration?.DatasourceId);
      if (datasource) {
        for (const streamOption of datasource.Configuration.streamOptions) {
          result.push({
            uid: streamOption.Uid,
            label: streamOption.Label,
            stackedType: "column"
          });
        }
      }
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesTypeStacked];
      if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration &&
        this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration[key]
      ) {
        const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration[key];
        for (const item of result) {
          if (featuresConfiguration[item.uid]) {
            item.stackedType = featuresConfiguration[item.uid];
          }
        }
      }
    }
    return result;
  }

  set selectedDataSeriesTypesStacked(value: any[]) {
    this.saveSeriesTypesStacked(value);
  }

  onSeriesTypeStackedChange(): void {
    this.saveSeriesTypesStacked(this.selectedDataSeriesTypesStacked);
  }

  saveSeriesTypesStacked(value: any[]): void {
    if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration) {
      const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration;
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesTypeStacked];
      featuresConfiguration[key] = {};
      for (const item of value) {
        featuresConfiguration[key][item.uid] = item.stackedType;
      }
      this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration = featuresConfiguration;
    }
  }
  // #endregion series type stacked

  // #region y axis
  get selectedYAxis(): any[] {
    const result: any[] = [];
    if (this.reports3Store.dataOne?.Datasources.length && this.reports3Store.dataOneSelectedElementConfiguration) {
      const datasource = this.reports3Store.dataOne.Datasources.find(x => x.Uid === this.reports3Store.dataOneSelectedElementConfiguration?.DatasourceId);
      if (datasource) {
        for (const streamOption of datasource.Configuration.streamOptions) {
          result.push({
            uid: streamOption.Uid,
            label: streamOption.Label,
            yAxis: ""
          });
        }
      }
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.YAxis];
      if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration &&
        this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration[key]
      ) {
        const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration[key];
        for (const item of result) {
          if (featuresConfiguration[item.uid]) {
            item.yAxis = featuresConfiguration[item.uid] ?? "";
          }
        }
      }
    }
    return result;
  }

  set selectedYAxis(value: any[]) {
    this.saveYAxis(value);
  }

  onYAxisChange(): void {
    this.saveYAxis(this.selectedYAxis);
  }

  saveYAxis(value: any[]): void {
    if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration) {
      const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration;
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.YAxis];
      featuresConfiguration[key] = {};
      for (const item of value) {
        featuresConfiguration[key][item.uid] = item.yAxis;
      }
      this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration = featuresConfiguration;
    }
  }
  // #endregion y axis

  // #region Bitpool AI
  get defaultPersona(): string {
    const defaultPersona: AIPersonaEntity | undefined = this.aiPersonaStore.entities ? this.aiPersonaStore.entities.find(x => x.Enabled && x.Default) : undefined;
    return defaultPersona ? defaultPersona.Id : ""; 
  }

  get availablePersonas(): any[] {
    return (this.aiPersonaStore.entities ? this.aiPersonaStore.entities.filter(x => x.Enabled) : [])
      .map(x => { return { label: x.Name, value: x.Id }; });
  }

  get availableInsights(): any[] {
    const result = (this.aiInsightLibraryStore.entities ? this.aiInsightLibraryStore.entities : [])
      .map(x => { return { label: x.Name, value: x.Id }; });
    result.push({ label: "Custom", value: "custom" });
    return result;
  }

  get bitpoolAIConfiguration(): any {
    let result: any = null;
    if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration) {
      const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration;
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.BitpoolAI];
      result = featuresConfiguration[key];
    }
    if (!result) {
      result = {
        PersonaId: "",
        QuestionId: "custom",
        CustomQuestion: "Please provide a brief overview of the data."
      };
    }
    if (!result.PersonaId) {
      result.PersonaId = this.defaultPersona;
    }
    return result;
  }

  set bitpoolAIConfiguration(value: any) {
    this.saveBitpoolAIConfiguration(value);
  }

  saveBitpoolAIConfiguration(value: any): void {
    if (this.reports3Store.dataOneSelectedElementConfiguration?.FeaturesConfiguration) {
      const featuresConfiguration = this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration;
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.BitpoolAI];
      featuresConfiguration[key] = value;
      this.reports3Store.dataOneSelectedElementConfiguration.FeaturesConfiguration = featuresConfiguration;
    }
  }

  onBitpoolAIChange(): void {
    this.saveBitpoolAIConfiguration(this.bitpoolAIConfiguration);
    this.reloadData();
  }

  bitpoolAICustomQuestion: string = "";

  @Watch('bitpoolAIConfiguration.CustomQuestion', { immediate: false, deep: true })
  async onConfigCustomQuestionChanged(val: string, oldVal: string): Promise<void> {
    if (this.bitpoolAICustomQuestion !== val) {
      this.bitpoolAICustomQuestion = val;
    }
  }

  onBitpoolAICustomQuestionChange(): void {
    this.bitpoolAIConfiguration.CustomQuestion = this.bitpoolAICustomQuestion;
    this.onBitpoolAIChange();
  }
  // #endregion Bitpool AI
}

export default ReportsReportLeftPanelView;
</script>