<template>
  <div class="default-view-container">
    <div class="default-view-head-section">
      <h1 class="mb-0 mr-5">Insights Management</h1>
    </div>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Insights List</h2>
      </header>
      <div class="default-view-body">
        <DataTable
          :value="aiInsightLibraryStore.entities" 
          dataKey="Id"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="850px" 
          class="p-datatable-sm default-visual-table responsive-breakpoint ai-insights-table">
          <template #header>
            <div class="table-header">
              <Button :disabled="!aiInsightLibraryStore.isLoaded" label="Add Insight" icon="pi pi-plus-circle" class="my-1" @click="openCreateUpdateDialog(null)" />
            </div>
          </template>
          <template #empty>
            <div v-if="aiInsightLibraryStore.isLoaded" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column field="Name" header="Name" headerStyle="min-width: min-content; width: 20%;" headerClass="no-break-word">
            <template #body="slotProps">
              <span class="block with-inline-btn">
                {{ slotProps.data.Name }}
                <span v-if="slotProps.data.OrganisationId === 0" v-tippy="'Public'" class="table-cell-icon">
                  <i class="pi pi-globe"></i>
                </span>
              </span>
            </template>
          </Column>
          <Column field="PersonaId" header="Skill" headerStyle="min-width: min-content; width: 20%;" headerClass="no-break-word">
            <template #body="slotProps">
              {{ slotProps.data.PersonaId ? (aiPersonaStore.entities?.find(p => p.Id === slotProps.data.PersonaId)?.Name ?? "") : "" }}
            </template>
          </Column>
          <Column field="Question" header="Instructions" headerStyle="min-width: min-content; width: 50%;" headerClass="no-break-word"></Column>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div>
                <div class="inline-flex">
                  <Button 
                    icon="pi pi-clone" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openCloneDialog(slotProps.data)"
                    v-tippy="'Copy'"
                  />
                  <Button 
                    icon="pi pi-pencil" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openCreateUpdateDialog(slotProps.data)"
                    v-tippy="'Edit'"
                  />
                  <Button 
                    icon="pi pi-trash" 
                    class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                    @click="openConfirmation(slotProps.data)" 
                    :disabled="slotProps.data.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin"
                    v-tippy="'Delete'"
                  />
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
    <Dialog header="Insight" v-model:visible="displayCreateUpdateDialog" :modal="true" :style="{width: '56rem'}" class="ai-insights-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <div class="field col-12">
              <label for="editRecordName">Name</label>
              <div>
                <InputText 
                  id="editRecordName" 
                  class="inputfield w-full"
                  type="text" 
                  v-model="editRecord.Name"
                />
              </div>
            </div>
            <div class="field col-12">
              <label for="editRecordPersona">Bitpool AI Skill</label>
              <div>
                <Dropdown
                  inputId="editRecordPersona"
                  v-model="editRecord.PersonaId"
                  :options="availablePersonas"
                  optionLabel="label"
                  optionValue="value"
                  panelClass="reduce-options-font-size"
                  class="w-full"
                  showClear
                />
              </div>
            </div>
            <div class="field col-12 mb-0">
              <label for="editRecordQuestion">Instructions</label>
              <div>
                <Textarea 
                  id="editRecordQuestion" 
                  class="inputfield w-full"
                  style="white-space: pre-wrap;" 
                  :autoResize="true" 
                  :rows="3"
                  v-model="editRecord.Question"
                />
              </div>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #header>
        <div class="flex-auto flex justify-content-between align-items-center gap-4">
          <span class="p-dialog-title">Insight</span>
          <div class="ai-insights-switch-field pr-3" v-if="canEditGlobal">
            <div class="flex align-items-center">
              <InputSwitch 
                  inputId="ai-insights-edit-global"
                  v-model="selectedEntityIsGlobal"
                  class="vertical-align-top"
                  :disabled="updateInProgress"
                />
              <label for="ai-insights-edit-global" class="mb-0 ml-2">Public</label>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button 
              v-if="editRecord" 
              :label="editRecord.Id ? 'Update' : 'Create'" 
              :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
              @click="saveRecord" 
              :disabled='!editRecord.Name || updateInProgress || editRecord.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin' 
            />
          </span>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Message from 'primevue/message';
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import moment from "moment";
import ConfirmationService from "@/services/ConfirmationService";
import { useOrganisationStore } from "@/stores/organisation";
import { useAIInsightLibraryStore } from "@/stores/aiInsightLibrary";
import { AIInsightLibraryEntity } from "@/models/bitpool-ai/AIInsightLibraryEntity";
import Textarea from "primevue/textarea";
import { AIInsightLibraryInsightType } from "@/models/bitpool-ai/AIInsightLibraryInsightType";
import { useAIPersonaStore } from "@/stores/aiPersona";

@Component({
  components: {
    Message,
    Button,
    InputText,
    Textarea,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown
  },
})
class OrganisationInsightsLibraryView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  aiInsightLibraryStore = useAIInsightLibraryStore();
  aiPersonaStore = useAIPersonaStore();

  // #region new/edit
  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get selectedEntityIsGlobal(): boolean {
    return this.editRecord?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.editRecord) {
      if (value) {
        this.editRecord.OrganisationId = 0;
      } else {
        this.editRecord.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  displayCreateUpdateDialog = false;
  editRecord: AIInsightLibraryEntity | null = null;

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  openCloneDialog(record: AIInsightLibraryEntity): void {
    const newRecord: AIInsightLibraryEntity = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    if (!this.canEditGlobal) {
      newRecord.OrganisationId = this.currentOrganisation?.Id ?? -1;
    }
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: AIInsightLibraryEntity | null): void {
    const nowUtc = moment.utc().toDate();
    this.editRecord = record ? JSON.parse(JSON.stringify(record)) : {
      Id: "",
      Name: "",
      Question: "",
      PersonaId: null,
      InsightType: AIInsightLibraryInsightType.Standard,
      OrganisationId: this.currentOrganisation ? this.currentOrganisation.Id : -1,
      Created: nowUtc,
      Updated: nowUtc,
      CreatedBy: this.allUserData.userName,
      UpdatedBy: this.allUserData.userName
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.aiInsightLibraryStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.aiInsightLibraryStore.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.aiInsightLibraryStore.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }

  get availablePersonas(): any[] {
    return (this.aiPersonaStore.entities ? this.aiPersonaStore.entities.filter(x => x.Enabled) : [])
      .map(x => { return { label: x.Name, value: x.Id }; });
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: AIInsightLibraryEntity | null = null;

  openConfirmation(record: AIInsightLibraryEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete insight?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Insight',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.aiInsightLibraryStore.delete(this.selectedRecord);
    }
  }
  // #endregion delete
}

export default OrganisationInsightsLibraryView;
</script>