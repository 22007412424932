<template>
  <section class="reset-password animate__animated animate__bounceInLeft">
    <div class="logo-container mb-4">
      <img
        :src="'/assets/bitpool-logo-dark.svg'"
        width="470"
        alt="Bitpool"
        class="logo-light-theme"
      />
      <img
        :src="'/assets/bitpool-logo-white.svg'"
        width="470"
        alt="Bitpool"
        class="logo-dark-theme"
      />
    </div>

    <div class="auth-form">
      <div class="auth-form-inner">
        <h1 class="text-center">{{ t('login.forgotPassword') }}</h1>
        <div class="field">
          <InputText
            class="inputfield p-inputtext-lg w-full"
            :placeholder="t('common.email')"
            type="text"
            v-model="username"
            @keyup.enter="resetPassword"
          />
        </div>
        <div class="field mt-4 pt-1">
          <Button
            class="w-full p-button-lg justify-content-center font-medium"
            @click="resetPassword"
            :disabled="inProgress || !username"
            ><span :class="{ 'opacity-0': inProgress }">{{ t('login.continue') }}</span
            ><ProgressSpinner class="spinner-white" style="width: 40px; height: 40px" strokeWidth="6" animationDuration="1s" v-if="inProgress"
          /></Button>
        </div>

        <div class="text-center mt-5 pt-2">
          <div @click="goLogin" v-html="t('login.backToLoginHtml')"></div>
        </div>
      </div>
    </div>

    <div class="text-center mt-5 pt-2 md:mt-3 md:pt-0 auth-bottom" v-html="t('login.clickHereForHelpHtml', { href: 'mailto:support@bitpool.com' })"></div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AngularOptions from "@/models/AngularOptions";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import { ComposerTranslation, useI18n } from "vue-i18n";
import { MessageSchema } from "@/localeManager";
import { useLoginStore } from "@/stores/login.store";

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    InputText,
    Button,
    ProgressSpinner,
  },
})
class ResetPasswordView extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;
  
  loginStore = useLoginStore();

  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  inProgress = false;

  get username(): string {
    return this.loginStore.username;
  }

  set username(value: string) {
    this.loginStore.setUsername(value);
  }

  get displaySection(): string {
    return this.loginStore.displaySection;
  }

  set displaySection(value: string) {
    this.loginStore.setDisplaySection(value);
  }

  created(): void {
    if (this.angularOptions.angularScope.subscribeToResetPasswordCallback) {
      this.angularOptions.angularScope.subscribeToResetPasswordCallback(
        this.resetPasswordComplete
      );
    }
  }

  goSignup(): void {
    this.displaySection = "sign";
  }

  goLogin(): void {
    this.displaySection = "login";
  }

  resetPassword(): void {
    if (this.username !== "") {
      this.inProgress = true;
      this.angularOptions.angularScope.credentials.username = this.username;
      this.angularOptions.angularScope.resetPassword();
    }
  }

  resetPasswordComplete(success: boolean): void {
    this.inProgress = false;
    if (success) {
      this.goLogin();
    }
  }
}

export default ResetPasswordView;
</script>@/localeManager