import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import { v4 as uuidv4 } from "uuid";
import TextHelper from "./TextHelper";
import { Report3ElementFeatures } from "@/models/reports/v3/Report3ElementFeatures";
import DataHelper from "./DataHelper";
import DateHelper from "./DateHelper";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";

class ReportElementPdfHelper {
  public generateHtmlForPdf(report: Reports3Entity, element: Reports3ElementEntity, configuration: Reports3ElementConfiguration | null = null,
    globalStyles: string = "", organisationName: string = "Organisation",
    data: AggregatedDataHighchartsResponse[] | null = null, dataConfiguration: Reports3Datasource | null = null,
    aiInsight: [string, string] | null = null,
    utcOffset: number | null = null
  ): string {
    const html = this.generateHtml(
      report,
      element,
      configuration,
      element.Role === Reports3ElementRole.Body ?
        globalStyles :
        `${globalStyles}
          body {
            /* Header/footer-ralated styles */
            margin: 0;
            padding: 0;
            .report-element {
              width: 100%;
              height: ${element.Height}cm;
            }
          }`,
      organisationName,
      data,
      dataConfiguration,
      aiInsight,
      utcOffset
    );
    return html;
  }

  private generateHtml(report: Reports3Entity, element: Reports3ElementEntity, configuration: Reports3ElementConfiguration | null = null,
    globalStyles: string = "", organisationName: string = "Organisation",
    data: AggregatedDataHighchartsResponse[] | null = null, dataConfiguration: Reports3Datasource | null = null,
    aiInsight: [string, string] | null = null,
    utcOffset: number | null = null
  ): string {
    const parentId = `report-element-${uuidv4()}`;
    const parameters: Record<string, any> = {};
    if (element.AdditionalParameters) {
      for (const param of element.AdditionalParameters) {
        parameters[param.Name] = param.DefaultValue;
      }
    }
    if (configuration?.AdditionalParameters) {
      for (const key in configuration.AdditionalParameters) {
        parameters[key] = configuration.AdditionalParameters[key];
      }
    }
    parameters.organisationName = organisationName;
    parameters.reportName = report.Name;
    if (aiInsight) {
      parameters.aiQuestion = aiInsight[0];
      parameters.aiAnswer = aiInsight[1];
    }
    const now = new Date();
    if (typeof utcOffset === "number") {
      now.setUTCMinutes(now.getUTCMinutes() + now.getTimezoneOffset() + utcOffset);
    }
    const parts = DateHelper.dateToArray(now);
    parameters.todayYear = parts[0];
    parameters.todayMonth = parts[1];
    parameters.todayDay = parts[2];
    parameters.today = `${parts[2]}/${parts[1]}/${parts[0]}`;

    // Extract date range from data configuration
    const isTotal = element.Features.includes(Report3ElementFeatures.DataOnlyTotalAggregation);
    const requestBody = dataConfiguration ?
      DataHelper.wdsToApiRequest(
        dataConfiguration.Configuration, 
        report && dataConfiguration.UseReportDRS ? report.DateRange : null,
        isTotal ? "reports3_total" : "reports3"
      ) : null;
    let dateRange: [string, string] | null = null;
    if (requestBody) {
      const dates = DateHelper.extractDateFromRequestBody(requestBody);
      dateRange = [DateHelper.dateToString(dates[0]), DateHelper.dateToString(dates[1])];
    }
    parameters.dateRange = dateRange;

    let htmlWithParameters = element.HTML;
    let cssWithParameters = element.CSS;
    for (const key in parameters) {
      htmlWithParameters = htmlWithParameters.replace(new RegExp(`\\{\\{${key}\\}\\}`, 'g'), parameters[key]);
      cssWithParameters = cssWithParameters.replace(new RegExp(`\\{\\{${key}\\}\\}`, 'g'), parameters[key]);
    }
    const size = element.Role === Reports3ElementRole.Body ? (100 * (configuration ? configuration.Size : element.DefaultSize) / 12) : 100;
    // function displayData(parent, parameters, data, dataConfiguration, libraries, onReadyEvent)
    const html = this.getGeneratedPage(
      `<div id="${parentId}" class="report-element" style="width: ${size}%">${htmlWithParameters}</div>`,
      `${globalStyles} #${parentId} { ${cssWithParameters} }`,
      element.Role === Reports3ElementRole.Body ?
        `document.body.style.width = "calc(21cm - 1cm - 1cm)";
        const elementListReady = [];
        const elementList = ["${parentId}"];
        const timeout = 60000; // 1 minute
        const timeStart = Date.now();
        async function reportReady() {
          await document.fonts.ready;
          while (true) {
            if (elementListReady.length >= elementList.length) {
              // check if all elements are ready
              let isOk = true;
              for (const element of elementList) {
                if (!elementListReady.includes(element)) {
                  isOk = false;
                  break;
                }
              }
              if (isOk) {
                break;
              }
            }
            if (Date.now() - timeStart > timeout) {
              break;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
        (function() { 
          const onReadyEvent = function() {
            elementListReady.push("${parentId}");
          };
          try {
            ${element.JavaScript}
            if (displayData) {
              const parameters = ${JSON.stringify(parameters)};
              const data = ${JSON.stringify(TextHelper.objectKeysToCamelCase(data))};
              const dataConfiguration = ${JSON.stringify(TextHelper.objectKeysToCamelCase(dataConfiguration))};
              const libraries = {
                Highcharts: Highcharts,
                marked: marked,
                DOMPurify: DOMPurify
              };
              displayData(document.querySelector("#${parentId}"), parameters, data, dataConfiguration, libraries, onReadyEvent);
            } else {
              onReadyEvent();
            }
          } catch (error) {
            onReadyEvent();
          }
        })();` :
        ``
    );
    return html;
  }

  private getGeneratedPage(html: string, css: string, js: string): string {
    const cssLine = css && `<style>${css}</style>`;
    const jsLine = js && `<script>${js}</${"script"}>`; // note: regular script close tag breaks vue parser
    const source = `
      <html>
        <head>
          <script src="https://code.highcharts.com/11.4.8/highcharts.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/marked@15.0.3/lib/marked.umd.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/dompurify@3.2.2/dist/purify.min.js"></script>
          ${cssLine}
        </head>
        <body>
          ${html || ''}
          ${jsLine}
        </body>
      </html>`;
    return source;
  }
}

export default new ReportElementPdfHelper();
