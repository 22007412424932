<template>
  <div class="formgrid grid" v-if="cronConfig">
    <div class="field col-12 md:col-6">
      <label>Repeat</label>
      <div>
        <Dropdown 
          :options="cronConfig.modes" 
          v-model="cronConfig.mode" 
          @change="save"
          class="w-full" 
        />
      </div>
    </div>
    <div class="field col-12 md:col-6" v-if="isMonthVisible">
      <label>Month</label>
      <div>
        <MultiSelect
          v-model="month"
          :options="cronConfig.months"
          placeholder="Select Month"
          display="chip"
          :filter="true"
          :optionValue="x => x[0]"
          :optionLabel="x => x[1]"
          class="p-multiselect-multiline nowrap-token inputfield w-full"
        />
      </div>
    </div>
    <div class="field col-12 md:col-6" v-if="isDayOfMonthVisible">
      <label>Day of Month</label>
      <div>
        <MultiSelect
          v-model="day"
          :options="cronConfig.days"
          placeholder="Select Day"
          display="chip"
          :filter="true"
          class="p-multiselect-multiline nowrap-token inputfield w-full"
        />
      </div>
    </div>
    <div class="field col-12 md:col-6" v-if="isDayOfWeekVisible">
      <label>Day of Week</label>
      <div>
        <MultiSelect
          v-model="dayOfWeek"
          :options="cronConfig.daysOfWeek"
          placeholder="Select Day of Week"
          display="chip"
          :filter="true"
          :optionValue="x => x[0]"
          :optionLabel="x => x[1]"
          class="p-multiselect-multiline nowrap-token inputfield w-full"
        />
      </div>
    </div>
    <div class="field col-12 md:col-6">
      <label>Time</label>
      <div>
        <input
          id="editEventStartTime"
          class="w-full p-inputtext p-component"
          v-model="time"
          v-maska data-maska="##:##:##"
          @blur="saveTime"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CronConfig from "@/helpers/CronConfig";
import { Component, Model, Vue } from "vue-facing-decorator";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import { vMaska } from "maska";

@Component({
  components: {
    Dropdown,
    InputText,
    MultiSelect
  },
  directives: {
    maska: vMaska
  }
})
class CronEditorView extends Vue {
  @Model cronExpression!: string;

  cronConfig: CronConfig | null = null;
  time = "00:00:00";

  get month(): string[] {
    if (this.cronConfig) {
      return this.cronConfig.month.split(",");
    } else {
      return [];
    }
  }

  set month(value: string[]) {
    if (this.cronConfig) {
      if (value.length === 0) {
        return;
      }
      let month = value.sort((a: string, b: string) => {
        return parseInt(a) - parseInt(b);
      }).join(",");
      this.cronConfig.month = month;
      this.save();
    }
  }

  get day(): string[] {
    if (this.cronConfig) {
      return this.cronConfig.day.split(",");
    } else {
      return [];
    }
  }

  set day(value: string[]) {
    if (this.cronConfig) {
      if (value.length === 0) {
        return;
      }
      let day = value.sort((a: string, b: string) => {
        return parseInt(a) - parseInt(b);
      }).join(",");
      this.cronConfig.day = day;
      this.save();
    }
  }

  get dayOfWeek(): string[] {
    if (this.cronConfig) {
      return this.cronConfig.dayOfWeek.split(",");
    } else {
      return [];
    }
  }

  set dayOfWeek(value: string[]) {
    if (this.cronConfig) {
      if (value.length === 0) {
        return;
      }
      let dayOfWeek = value.sort((a: string, b: string) => {
        const a1 = a === "1" ? 8 : parseInt(a);
        const b1 = b === "1" ? 8 : parseInt(b);
        return a1 - b1;
      }).join(",");
      this.cronConfig.dayOfWeek = dayOfWeek;
      this.save();
    }
  }

  mounted(): void {
    this.init();
  }

  init(): void {
    this.cronConfig = new CronConfig(this.cronExpression);
    this.initTime();
  }

  save(): void {
    if (this.cronConfig) {
      this.cronExpression = this.cronConfig.toCronExpression();
    }
  }

  initTime() {
    if (this.cronConfig) {
      this.time = `${this.cronConfig.hour.padStart(2, '0')}:${this.cronConfig.minute.padStart(2, '0')}:${this.cronConfig.second.padStart(2, '0')}`;
    } else {
      this.time = "00:00:00";
    }
  }

  saveTime() {
    if (this.cronConfig) {
      const [hour, minute, second] = this.time.split(":");
      // remove leading 0
      let h = hour ? (hour.replace(/^0+/, "") || "0") : "0";
      if (parseInt(h) > 23) {
        h = "23";
      }
      let m = minute ? (minute.replace(/^0+/, "") || "0") : "0";
      if (parseInt(m) > 59) {
        m = "59";
      }
      let s = second ? (second.replace(/^0+/, "") || "0") : "0";
      if (parseInt(s) > 59) {
        s = "59";
      }
      this.cronConfig.hour = h;
      this.cronConfig.minute = m;
      this.cronConfig.second = s;
      this.save();
      this.initTime();
    }
  }

  get isMonthVisible(): boolean {
    return this.cronConfig?.mode === "Yearly";
  }

  get isDayOfWeekVisible(): boolean {
    return this.cronConfig?.mode === "Weekly";
  }

  get isDayOfMonthVisible(): boolean {
    return this.cronConfig?.mode === "Monthly" || this.cronConfig?.mode === "Yearly";
  }
}

export default CronEditorView;
</script>