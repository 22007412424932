<template>
  <draggable
    tag="div"
    :list="widgets"
    handle=".handle"
    @end="dropEvent"
    :group="{ name: 'widgets' }"
    :animation="150"
    :fallbackOnBody="true"
    :swapThreshold="0.65"
    :invertSwap="true"
    :direction="getSortDirection"
    :disabled="!dashboardState.editMode"
    ghostClass="dragging"
    class="grid formgrid grid-body droppable-area"
    item-key="guid"
  >
    <template #item="{ element }">
      <div
        class=""
        :class="[
          'col-' + element.size.size,
          'sm:col-' + element.size.sizeSM,
          'md:col-' + element.size.sizeMD,
          'lg:col-' + element.size.sizeLG,
          'xxl:col-' + element.size.sizeXL
        ]"
      >
        <div
          v-if="element.type === 'widget'"
          class="widget-container"
          :class="{'widget-expanded': isMaximizedWidget(element.guid)}"
          :id="`widgetContainer-${element.guid}`"
        >
          <div
            :class="[
              'widget',
              hasGutter(element) ? '' : 'widget-nogutter',
              getColorWidgetClass(element),
              hasOptionalTitle(element) ? 'with-title' : '',
              isBitpoolAIEnabled(element)  ? 'with-chat-gpt-button' : ''
            ]"
            :style="{backgroundColor: getBackgroundColor(element)}"
          >
            <div class="widget-head">
              <div
                v-if="dashboardState.editMode"
                class="handle widget-handle"
              >
                <HandleSvg/>
              </div>
              
              <div class="dashboard-control-buttons widget-control-buttons">
                <Button v-if="!authState.jailMode && dashboardState.editMode" @click="toggleSizeOverlay($event, element)" v-tippy="'Size'" class="p-button-icon-only p-button-rounded p-button-light size-control-button">
                  <span class="text-lg lg:text-2xl p-button-icon p-button-icon-left svg-icon">
                    <SizeSvg/>
                  </span>
                  <span class="p-button-label">Size</span>
                </Button>
                <Button v-if="!authState.jailMode && isBitpoolAIEnabled(element)" @click="openBitpoolAIDialog(element)" v-tippy="'Bitpool AI'" class="p-button-icon-only p-button-rounded p-button-light openai-button" style="margin-right: 4px;">
                  <span class="text-lg lg:text-2xl p-button-icon p-button-icon-left svg-icon">
                    <OpenAISvg/>
                  </span>
                  <span class="p-button-label">Bitpool AI</span>
                </Button>
                <Button v-if="!authState.jailMode" @click="showSettingMenu($event, element)" v-tippy="'Actions'" class="p-button-icon-only p-button-rounded p-button-light">
                  <span class="text-lg lg:text-2xl p-button-icon p-button-icon-left svg-icon">
                    <GearSvg/>
                  </span>
                  <span class="p-button-label">Actions</span>
                </Button>
              </div>
            </div>

            <DashboardWidgetView :dashboardId="dashboardId" :widget="element" :openBitpoolAIDialog="openBitpoolAIDialog"/>
          </div>
        </div>

        <div
          v-if="element.type === 'grid'"
          class="grid-container"
          :id="`gridContainer-${element.guid}`"
        >
          <div
            v-if="dashboardState.editMode"
            class="grid-head"
          >
            <div class="handle grid-handle">
              <HandleSvg/>
            </div>
            
            <div class="dashboard-control-buttons grid-control-buttons">
              <DashboardAddWidgetView 
                :dashboardId="dashboardId"
                :widgets="element.widgets"
                buttonClasses="p-button-icon-only p-button-rounded p-button-light"
                :noButton="false"
                v-tippy="'Add'"
              />
              <Button @click="toggleSizeOverlay($event, element)" v-tippy="'Size'" class="p-button-icon-only p-button-rounded p-button-light size-control-button">
                <span class="text-lg lg:text-2xl p-button-icon p-button-icon-left svg-icon">
                  <SizeSvg/>
                </span>
                <span class="p-button-label">Size</span>
              </Button>
              <Button @click="showSettingMenu($event, element)" v-tippy="'Actions'" class="p-button-icon-only p-button-rounded p-button-light">
                <span class="text-lg lg:text-2xl p-button-icon p-button-icon-left svg-icon">
                  <GearSvg/>
                </span>
                <span class="p-button-label">Actions</span>
              </Button>
            </div>
          </div>
          <DashboardGridView v-if="element.type === 'grid'" :dashboardId="dashboardId" :widgets="element.widgets"></DashboardGridView>
        </div>
      </div>
    </template>
  </draggable>

  <Menu ref="settingsMenu" :model="settingsMenuItems" :popup="true" />

  <OverlayPanel ref="sizeOverlayPanel" appendTo="#overlayPanelPlaceholder" :showCloseIcon="true" :dismissable="true" :breakpoints="{'470px': '310px'}" :style="{width: '400px'}" class="column-size-overlaypanel">
    <h5>Column size for different types of devices</h5>
    <div class="formgrid grid align-items-end row-gap-4" v-if="sizeOverlayWidget">
      <div class="field col-12 mb-0">
        <label class="inline-flex align-items-center column-gap-2" v-tippy="'Mobile portrait mode.'">
          <i class="mobile-icon"></i>
        </label>
        <div>
          Always 12/12 (100%)
        </div>
      </div>
      <div class="field col-6 mb-0">
        <label class="inline-flex align-items-center column-gap-2" v-tippy="'Mobile landscape mode. Screen size starts from 576px.'">
          <i class="mobile-icon mobile-icon-landscape -rotate-90"></i>
        </label>
        <div>
          <Dropdown v-model="sizeOverlayWidget.size.sizeSM" @change="saveDashboard" :options="columns" optionValue="key" optionLabel="name" placeholder="Select size" class="w-full"/>
        </div>
      </div>
      <div class="field col-6 mb-0">
        <label class="inline-flex align-items-center column-gap-2" v-tippy="'Tablet portrait mode. Screen size starts from 768px.'">
          <i class="tablet-icon"></i>
        </label>
        <div>
          <Dropdown v-model="sizeOverlayWidget.size.sizeMD" @change="saveDashboard" :options="columns" optionValue="key" optionLabel="name" placeholder="Select size" class="w-full"/>
        </div>
      </div>
      <div class="field col-6 mb-0">
        <label class="inline-flex align-items-center column-gap-2" v-tippy="'Tablet landscape mode, laptops. Screen size starts from 992px.'">
          <i class="tablet-icon tablet-icon-landscape -rotate-90"></i> <i class="laptop-icon"></i>
        </label>
        <div>
          <Dropdown v-model="sizeOverlayWidget.size.sizeLG" @change="saveDashboard" :options="columns" optionValue="key" optionLabel="name" placeholder="Select size" class="w-full"/>
        </div>
      </div>
      <div class="field col-6 mb-0">
        <label  class="inline-flex align-items-center column-gap-2" v-tippy="'Desktops, laptops. Screen size starts from 1400px.'">
          <i class="desktop-icon text-xl"></i> <i class="laptop-icon"></i>
        </label>
        <div>
          <Dropdown v-model="sizeOverlayWidget.size.sizeXL" @change="saveDashboard" :options="columns" optionValue="key" optionLabel="name" placeholder="Select size" class="w-full"/>
        </div>
      </div>
    </div>
  </OverlayPanel>

  <Dialog header="Widget Configuration" v-model:visible="displayWidgetSettingsModal" :modal="true" :style="{width: '56rem'}" class="widget-config-dialog">
    <template #header>
      <div class="flex align-items-center">
        <span class="p-dialog-title">{{getWidgetSettingsTitle(selectedWidgetConfigCopy)}}</span>
      </div>
    </template>
    <div class="dialog-content">
      <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner blockui-with-overlay-z-index" :class="updateInProgress ? 'blockui-blocked' : ''">

        <WidgetSettingsView :widgetConfig="selectedWidgetConfigCopy"></WidgetSettingsView>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />

      </BlockUI>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" @click="closeWidgetSettingsDialog" class="p-button-text p-button-secondary"/>
      <Button label="Save" :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveWidgetSettings" :disabled='updateInProgress' />
    </template>
  </Dialog>

  <Dialog header="See Data" v-model:visible="displayDataStreams" :modal="true" :style="{width: '56rem'}" class="see-data-dialog">
    <div class="dialog-content" style="min-height: 30vh;">
      <div v-if="selectedWidgetConfigDataStreams?.widgetOptions?.widgetDataSettings?.streamOptions?.length">
        <DataTable
          :value="selectedWidgetConfigDataStreams.widgetOptions.widgetDataSettings.streamOptions" 
          showGridlines 
          responsiveLayout="scroll"
          class="widget-settings-table p-datatable-sm"
        >
          <Column field="Name" header="Stream Name" headerClass="text-sm" headerStyle="border-right-color: transparent;" bodyClass="text-sm">
          </Column>
          <Column field="" header="" headerStyle="width: 1%; min-width: 44px; border-left-color: transparent;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="{ data }">
              <Button
                v-if="data.StreamKey"
                @click="viewStream(data.StreamKey)"
                label="See data"
                icon="pi pi-external-link" 
                class="p-button-icon-only p-button-rounded p-button-outlined"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else>Streams have not been selected.</div>
    </div>
    <template #footer></template>
  </Dialog>

  <Dialog header="Share Widget" v-model:visible="displayShare" :modal="true" :breakpoints="{'1400px': '75vw', '1024px': '90vw'}" :style="{width: '60vw'}" class="share-dashboard-dialog">
    <ShareWidgetView :widget="selectedWidget"/>
    <template #footer></template>
  </Dialog>

  <BitpoolAIRealtimeDialogView v-if="isBitpoolAIReltimeEnabled" :dashboardId="dashboardId" :selectedWidget="selectedWidget" v-model="displayBitpoolAI"/>
  <BitpoolAIDialogView v-else :dashboardId="dashboardId" :selectedWidget="selectedWidget" v-model="displayBitpoolAI"/>

  <BlockUI :blocked="blockPage" :fullScreen="true" :autoZIndex="true" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="blockPage ? 'blockui-blocked' : ''">
    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { nextTick } from "vue";
import draggable from "vuedraggable";
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import Menu from 'primevue/menu';
import { MenuItem } from "primevue/menuitem";
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Textarea from 'primevue/textarea';
import DashboardWidgetView from "./DashboardWidgetView.vue";
import ShareWidgetView from "./ShareWidgetView.vue";
import WidgetSettingsView from "@/components/widgets-next/settings/WidgetSettingsView.vue";
import DashboardAddWidgetView from "./DashboardAddWidgetView.vue";
import SizeSvg from "@/components/svg/SizeSvg.vue";
import OpenAISvg from "@/components/svg/OpenAISvg.vue";
import GearSvg from "@/components/svg/GearSvg.vue";
import HandleSvg from "@/components/svg/HandleSvg.vue";
import DashboardState from "@/store/states/DashboardState";
import { Ref, Watch } from "vue-facing-decorator";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import WidgetHelper from "@/helpers/WidgetHelper";
import { WidgetFeature } from "@/models/enums/WidgetFeature";
import ColorHelper from "@/helpers/ColorHelper";
import { Space } from "@/models/dashboard/Space";
import AuthState from "@/store/states/AuthState";
import SpaceHelper from "@/helpers/SpaceHelper";
import PrintHelper from "@/helpers/PrintHelper";
import DataHelper from "@/helpers/DataHelper";
import { GDRSModel } from "@/models/dashboard/GDRSModel";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import WidgetDataState from "@/store/states/WidgetDataState";
import ConfirmationService from "@/services/ConfirmationService";
import ToastService from "@/services/ToastService";
import { DashboardType } from "@/models/dashboard/DashboardType";
import RootState from "@/store/states/RootState";
import BitpoolAIDialogView from "./BitpoolAIDialogView.vue";
import BitpoolAIRealtimeDialogView from "./BitpoolAIRealtimeDialogView.vue";
import { useOrganisationStore } from "@/stores/organisation";
import { ConnectedOverlayScrollHandler } from 'primevue/utils';
import { useSystemStore } from "@/stores/system";

@Component({
  components: {
    draggable,
    Dropdown,
    SelectButton,
    Button,
    OverlayPanel,
    Menu,
    Dialog,
    BlockUI,
    ProgressSpinner,
    DataTable,
    Column,
    Textarea,
    DashboardWidgetView,
    ShareWidgetView,
    BitpoolAIDialogView,
    BitpoolAIRealtimeDialogView,
    WidgetSettingsView,
    DashboardAddWidgetView,
    SizeSvg,
    OpenAISvg,
    GearSvg,
    HandleSvg
  },
  directives: {
  }
})
class DashboardGridView extends Vue {
  @Prop({ required: true }) dashboardId!: string;
  @Prop({ required: true }) widgets!: SpaceWidgetConfig[];

  get rootState(): RootState {
    return this.$store.state;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  organisationStore = useOrganisationStore();
  systemStore = useSystemStore();

  get isBitpoolAIReltimeEnabled(): boolean {
    return this.systemStore.devSettigns.includes("bitpoolAIReatime");
  }

  isMaximizedWidget(guid: string): boolean {
    return this.widgetDataState.maximizedWidget === guid;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  columns = WidgetHelper.getWidgetSizes();

  getWidgetConfig(widget: SpaceWidgetConfig): WidgetConfig | undefined {
    const widgetConfig = this.dashboardState.widgets?.find(x => x.guid === widget.guid);
    return widgetConfig;
  }

  getBackgroundColor(widget: SpaceWidgetConfig): string {
    const widgetConfig = this.getWidgetConfig(widget);
    const guid = widgetConfig?.guid;
    if (guid && this.colorOverwrites[guid]) {
      return this.colorOverwrites[guid];
    }
    const aws = widgetConfig?.widgetOptions.advancedWidgetSettings;
    const result = aws?.widgetColorEnabled && aws?.widgetColorHex ? aws?.widgetColorHex : '';
    return result;
  }

  getColorWidgetClass(widget: SpaceWidgetConfig): string {
    const backgroundColor = this.getBackgroundColor(widget);
    if (backgroundColor) {
      const color = ColorHelper.getContrastColor(backgroundColor);
      if (color === ColorHelper.getDefaultContrastColor()) {
        return "opposite-bg";
      }
    }
    return "";
  }

  getWidgetDescription(spaceWidgetConfig: SpaceWidgetConfig): WidgetDescription | undefined {
    const widgetConfig = this.getWidgetConfig(spaceWidgetConfig);
    const widgetDescription = this.getWidgetDescriptionFromWidgetConfig(widgetConfig);
    return widgetDescription;
  }

  getWidgetDescriptionFromWidgetConfig(widgetConfig: WidgetConfig | null | undefined): WidgetDescription | undefined {
    const widgetDescription = widgetConfig && widgetConfig.widgetType ? WidgetHelper.getWidget(widgetConfig.widgetType) : undefined;
    return widgetDescription;
  }

  hasGutter(widget: SpaceWidgetConfig): boolean {
    const widgetDescription = this.getWidgetDescription(widget);
    return !widgetDescription?.features.includes(WidgetFeature.nogutter);
  }

  hasOptionalTitle(widget: SpaceWidgetConfig): boolean {
    const widgetDescription = this.getWidgetDescription(widget);
    const widgetConfig = this.getWidgetConfig(widget);
    const aws = widgetConfig?.widgetOptions.advancedWidgetSettings;
    return !!(widgetDescription?.features.includes(WidgetFeature.titleOptional) && aws?.displayTitle);
  }

  getWidgetSettingsTitle(widgetConfig: WidgetConfig | null | undefined): string {
    const widgetDescription = this.getWidgetDescriptionFromWidgetConfig(widgetConfig);
    const name = widgetDescription ? widgetDescription.displayName : widgetConfig ? widgetConfig.widgetType : "Widget";
    return `${name} Configuration`;
  }

  get dashboards(): Space[] | null | undefined {
    return this.$store.getters["dashboard/getDashboards"];
  }

  dropEvent(event: any): void {
    this.saveDashboard();
  }

  async saveDashboard(): Promise<void> {
    const dashboard = this.dashboards?.find(x => x._id === this.dashboardId);
    if (dashboard) {
      await this.$store.dispatch(
        "dashboard/saveDashboard", 
        { 
          dashboard: dashboard,
          organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
        }
      );
    }
  }

  getSortDirection(event: DragEvent, target: HTMLElement, dragEl: HTMLElement): string {
    // console.log(event);
    // console.log(target);
    // console.log(dragEl);
    if (target && target.classList.contains("grid")) {
      // drag to grid
      return "vertical";
    } else {
      // drag to widget
      return "horizontal";
    }
  }

  // #region size overlay
  sizeOverlayWidget: SpaceWidgetConfig | null = null;

  toggleSizeOverlay(event: Event, widget: SpaceWidgetConfig): void {
    this.sizeOverlayWidget = widget;
    if (this.$refs.sizeOverlayPanel) {
      // hack for primevue overlaypanel -> move on scroll instead of hide
      const overlayAny = this.$refs.sizeOverlayPanel as any;
      overlayAny.bindScrollListener = () => {
        if (!overlayAny.scrollHandler) {
          overlayAny.scrollHandler = new ConnectedOverlayScrollHandler(overlayAny.target, () => {
            overlayAny.alignOverlay();
          });
        }
        overlayAny.scrollHandler.bindScrollListener();
      };
      // show overlay
      const overlay = this.$refs.sizeOverlayPanel as OverlayPanel;
      overlay.toggle(event);
    }
  }

  @Watch('sizeOverlayWidget.size', { immediate: false, deep: true })
  onSizeChanged(): void {
    if (this.sizeOverlayWidget?.type === "grid") {
      this.emitter.emit("size_changed", this.sizeOverlayWidget.guid);
    }
  }
  // #endregion size overlay

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created() {
    if (this.dashboardState.isLoadedWidgets && this.dashboardState.widgets) {
      // delete missing widgets
      for (let index = this.widgets.length - 1; index >= 0; index--) {
        const widget = this.widgets[index];
        if (widget.type === "widget") {
          const widgetConfig = this.getWidgetConfig(widget);
          if (!widgetConfig) {
            this.widgets.splice(index, 1);
          }
        }
      }
    }
  }

  mounted(): void {
    this.emitter.on("grid_created", this.gridCreatedEvent);
    this.emitter.on("widget_created", this.widgetCreatedEvent);
    this.emitter.on("widgetColorOverwrite", this.widgetColorOverwrite);
  }

  unmounted(): void {
    this.emitter.off("grid_created", this.gridCreatedEvent);
    this.emitter.off("widget_created", this.widgetCreatedEvent);
    this.emitter.off("widgetColorOverwrite", this.widgetColorOverwrite);
  }

  async gridCreatedEvent(guid: string): Promise<void> {
    const widget = this.widgets.find(x => x.guid === guid);
    if (widget) {
      await nextTick();
      document.querySelector(`#gridContainer-${guid}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  async widgetCreatedEvent(guid: string): Promise<void> {
    const widget = this.widgets.find(x => x.guid === guid);
    if (widget) {
      await nextTick();
      document.querySelector(`#widgetContainer-${guid}`)?.scrollIntoView({ behavior: 'smooth' });
      this.selectedWidget = widget;
      this.openWidgetSettingsDialog();
    }
  }

  colorOverwrites: Record<string, string> = {};

  async widgetColorOverwrite(arg: string): Promise<void> {
    const args = arg.split(";");
    if (args[0].startsWith("guid:") && args[1].startsWith("color:")) {
      const guid = args[0].split(":")[1];
      const color = args[1].split(":")[1];
      const widget = this.widgets.find(x => x.guid === guid);
      if (widget) {
        if (color === "NONE") {
          if (this.colorOverwrites[guid]) {
            delete this.colorOverwrites[guid];
          }
        } else {
          this.colorOverwrites[guid] = color;
        }
      }
    }
  }

  // #region settings menu
  selectedWidget: SpaceWidgetConfig | null = null;

  @Ref() readonly settingsMenu!: Menu;
  get settingsMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    if (this.selectedWidget) {
      if (this.selectedWidget.type === "widget") {
        const widgetConfig = this.getWidgetConfig(this.selectedWidget);
        const widgetDescription = this.getWidgetDescriptionFromWidgetConfig(widgetConfig);

        result.push({
          label: 'Configure Widget',
          icon: undefined,
          command: () => {
            this.openWidgetSettingsDialog();
          },
          disabled: !this.canEditDashboard
        });
        result.push({
          label: 'Share',
          icon: undefined,
          command: () => {
            this.openShare();
          },
          disabled: !this.authState.permissions?.Sharing
        });
        if (widgetDescription?.features.includes(WidgetFeature.dataStreams)) {
          result.push({
            label: 'See Data',
            icon: undefined,
            command: () => {
              this.openSeeDataStreamsDialog();
            }
          });
        }
        if (this.widgetDataState.maximizedWidget) {
          result.push({
            label: 'Unmaximize',
            icon: undefined,
            command: () => {
              if (this.selectedWidget) { 
                this.$store.commit("widgetData/unmaximizeWidget");
                this.emitter.emit("size_changed", this.selectedWidget.guid);
              }
            }
          });
        } else {
          result.push({
            label: 'Maximize',
            icon: undefined,
            command: () => {
              if (this.selectedWidget) { 
                this.$store.commit("widgetData/maximizeWidget", this.selectedWidget.guid);
                document.querySelector('.dashboard-area')?.scrollIntoView();
                // delay for animation
                window.setTimeout(() => {
                  if (this.selectedWidget) {
                    this.emitter.emit("size_changed", this.selectedWidget.guid);
                  }
                }, 50);
              }
            },
            disabled: this.dashboardState.editMode
          });
        }
        if (widgetDescription?.features.includes(WidgetFeature.dataStreamsExportCSV)) {
          result.push({
            label: 'Export Data to CSV',
            icon: undefined,
            command: async () => {
              if (this.selectedWidget) {              
                if (widgetConfig?.widgetOptions?.widgetDataSettings?.streamOptions?.length) {
                  const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
                  const wds = widgetConfig.widgetOptions.widgetDataSettings;
                  const useGDRS = widgetConfig.widgetOptions.advancedWidgetSettings?.useGDRS ? true : false;
                  const requestBody = DataHelper.wdsToApiRequest(wds, useGDRS ? this.gdrs : null, widgetConfig.widgetType, aws?.fields);
                  await this.$store.dispatch("widgetData/exportWidgetData", [widgetConfig.guid, requestBody]);
                }
              }
            },
            disabled: !(this.getWidgetConfig(this.selectedWidget)?.widgetOptions?.widgetDataSettings?.streamOptions?.length)
          });
        }
        result.push({
          label: 'Print',
          icon: undefined,
          command: async () => {
            if (this.selectedWidget) {
              const element = document.querySelector(`#widgetContainer-${this.selectedWidget.guid}`);
              this.blockPage = true;
              document.body.classList.add("without-widget-shadow");
              await nextTick();
              await PrintHelper.printHtml(element, !!this.authState.userSettings?.darkTheme);
              document.body.classList.remove("without-widget-shadow");
              this.blockPage = false;
            }
          }
        })
      }
      if (this.dashboardState.editMode) {
        if (result.length) {
          result.push({
            separator:true
          });
        }
        result.push({
          label: 'Cut',
          icon: undefined,
          command: () => {
            if (this.selectedWidget) {
              const widgetConfigs = this.getWidgetConfigs(this.selectedWidget);
              const data: [boolean, string, string, SpaceWidgetConfig, WidgetConfig[]] = [
                true,
                this.dashboardState.dashboardType,
                this.dashboardId,
                this.selectedWidget,
                widgetConfigs
              ];
              this.$store.commit("dashboard/copyWidget", data);
            }
          },
          disabled: !this.canEditDashboard
        });
        result.push({
          label: 'Copy',
          icon: undefined,
          command: () => {
            if (this.selectedWidget) {
              const widgetConfigs = this.getWidgetConfigs(this.selectedWidget);
              const data: [boolean, string, string, SpaceWidgetConfig, WidgetConfig[]] = [
                false,
                this.dashboardState.dashboardType,
                this.dashboardId,
                this.selectedWidget,
                widgetConfigs
              ];
              this.$store.commit("dashboard/copyWidget", data);
            }
          },
          disabled: !this.canEditDashboard
        });
        result.push({
          label: 'Paste',
          icon: undefined,
          command: () => {
            this.openConfirmationPaste();
          },
          disabled: !this.canEditDashboard || !this.dashboardState.copiedWidget
        });
        result.push({
          label: 'Duplicate',
          icon: undefined,
          command: () => {
            this.openConfirmationDuplicate();
          },
          disabled: !this.canEditDashboard
        });
        result.push({
          label: 'Delete',
          icon: undefined,
          command: () => {
            this.openConfirmationDelete();
          },
          disabled: !this.canEditDashboard
        });
      }
    }
    return result;
  }

  get canEditDashboard(): boolean {
    const canEditDashboard = this.$store.getters["dashboard/canEditDashboard"] as (id: string) => boolean;
    const result = canEditDashboard(this.dashboardId);
    return result;
  }

  showSettingMenu(event: Event, widget: SpaceWidgetConfig): void {
    this.selectedWidget = widget;
    this.settingsMenu.toggle(event);
  }
  // #endregion settings menu

  // #region settings modal
  displayWidgetSettingsModal = false;
  selectedWidgetConfig: WidgetConfig | null | undefined = null;
  selectedWidgetConfigCopy: WidgetConfig | null | undefined = null;

  openWidgetSettingsDialog(): void {
    this.selectedWidgetConfig = this.dashboardState.widgets?.find(x => x.guid === this.selectedWidget?.guid);
    this.selectedWidgetConfigCopy = this.selectedWidgetConfig ? JSON.parse(JSON.stringify(this.selectedWidgetConfig)) : null;
    this.displayWidgetSettingsModal = true;
  }

  closeWidgetSettingsDialog(): void {
    this.displayWidgetSettingsModal = false;
  }

  updateInProgress = false;

  async saveWidgetSettings(): Promise<void> {
    if (this.dashboardState.widgets && this.selectedWidgetConfigCopy) {
      const index = this.dashboardState.widgets.findIndex(x => x.guid === this.selectedWidget?.guid);
      if (index >= 0) {
        this.updateInProgress = true;
        this.dashboardState.widgets[index] = this.selectedWidgetConfigCopy;
        await this.$store.dispatch("dashboard/saveWidget", this.selectedWidgetConfigCopy);
        const state = this.dashboardState.widgetState[this.selectedWidgetConfigCopy.guid];
        if (state && state[0] && !state[2]) {
          this.closeWidgetSettingsDialog();
        }
        this.updateInProgress = false;
        this.emitter.emit("size_changed", this.selectedWidgetConfigCopy.guid);
      }
    }
  }
  // #endregion settings modal

  // #region see data streams
  displayDataStreams = false;
  selectedWidgetConfigDataStreams: WidgetConfig | null | undefined = null;

  openSeeDataStreamsDialog(): void {
    this.selectedWidgetConfigDataStreams = this.dashboardState.widgets?.find(x => x.guid === this.selectedWidget?.guid);
    this.displayDataStreams = true;
  }
  
  viewStream(streamKey: string | null | undefined): void {
    if (streamKey) {
      const newUrl = `/data/streams/${streamKey}`;
      window.open(newUrl, '_blank');
    }
  }
  // #endregion see data streams

  // #region widget delete
  openConfirmationDelete(): void {
    const message = `Are you sure you want to delete ${this.selectedWidget?.type}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteWidget();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteWidget(): void {
    if (this.selectedWidget) {
      const index = this.widgets.findIndex(x => x.guid === this.selectedWidget?.guid)
      if (index >= 0) {
        const widget = this.widgets[index];
        const widgetsGuid = [];
        if (widget.type === "grid") {
          const widgets = SpaceHelper.getWidgets(widget.widgets);
          widgets.forEach(element => {
            widgetsGuid.push(element.guid);
          });
        } else {
          widgetsGuid.push(widget.guid);
        }
        this.widgets.splice(index, 1);
        if (widgetsGuid.length) {
          this.$store.dispatch(
            "dashboard/deleteWidgets", 
            widgetsGuid
          );
        }
        this.saveDashboard();
      }
    }
  }
  // #endregion widget delete

  // #region widget duplicate
  openConfirmationDuplicate(): void {
    const message = `Are you sure you want to duplicate ${this.selectedWidget?.type}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Duplicate Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.duplicateWidget();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  getWidgetConfigs(spaceWidgetConfig: SpaceWidgetConfig): WidgetConfig[] {
    let spaceWidgetConfigs: SpaceWidgetConfig[] = [];
    if (spaceWidgetConfig.type === "grid") {
      spaceWidgetConfigs = SpaceHelper.getWidgets(spaceWidgetConfig.widgets);
    } else {
      spaceWidgetConfigs.push(spaceWidgetConfig);
    }
    const widgetConfigs: WidgetConfig[] = [];
    spaceWidgetConfigs.forEach(widget => {
      const widgetConfig = this.dashboardState.widgets?.find(x => x.guid === widget.guid);
      if (widgetConfig) {
        widgetConfigs.push(widgetConfig);
      }
    });
    return widgetConfigs;
  }

  blockPage = false;

  async duplicateWidget(): Promise<void> {
    try {
      this.blockPage = true;
      if (this.selectedWidget) {
        const index = this.widgets.findIndex(x => x.guid === this.selectedWidget?.guid)
        if (index >= 0) {
          const spaceWidgetConfig = this.widgets[index];
          const widgetConfigs = this.getWidgetConfigs(spaceWidgetConfig);
          // clone widgets
          const cloned = SpaceHelper.cloneWidget(spaceWidgetConfig, widgetConfigs);
          if (cloned[1].length) {
            await this.$store.dispatch(
              "dashboard/saveWidgets", 
              cloned[1]
            );
            cloned[1].forEach(widgetConfig => {
              this.dashboardState.widgets?.push(widgetConfig);
              this.emitter.emit("widget_created", widgetConfig.guid);
            });
          }
          this.widgets.splice(index + 1, 0, cloned[0]);
          await this.saveDashboard();
        }
      }
    } finally {
      this.blockPage = false;
    }
  }
  // #endregion widget duplicate

  // #region widget paste
  openConfirmationPaste(): void {
    const message = `Are you sure you want to ${this.dashboardState.copiedWidget?.[0] ? "move" : "paste"} ${this.dashboardState.copiedWidget?.[3]?.type}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Paste Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.pasteWidget();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async pasteWidget(): Promise<void> {
    try {
      this.blockPage = true;
      if (this.selectedWidget && this.dashboardState.copiedWidget) {
        const index = this.widgets.findIndex(x => x.guid === this.selectedWidget?.guid)
        if (index >= 0) {
          const spaceWidgetConfigDestination = this.widgets[index];
          const isCut = this.dashboardState.copiedWidget[0];
          const spaceWidgetConfig = this.dashboardState.copiedWidget[3];
          const widgetConfigs = this.dashboardState.copiedWidget[4];
          if (isCut) {
            // move widgets
            const dashboardTypeSource = this.dashboardState.copiedWidget[1];
            const dashboardIdSource = this.dashboardState.copiedWidget[2];
            const dashboardSource = (dashboardTypeSource === DashboardType.Organisation ?
              this.dashboardState.dashboards :
              this.dashboardState.dashboardsPersonal)?.find(x => x._id === dashboardIdSource);
            if (dashboardSource) {
              const isSameDashboard = dashboardSource._id === this.dashboardId;
              // if same dash, check is guid equal or child
              if (isSameDashboard && !SpaceHelper.canMoveTo(spaceWidgetConfig, spaceWidgetConfigDestination)) {
                // error
                ToastService.showToast(
                  "error",
                  "Move Error",
                  "You can't move widget here",
                  5000
                );
              } else {
                SpaceHelper.deleteWidget(dashboardSource.widgets, spaceWidgetConfig.guid);
                // save source dashboard
                if (!isSameDashboard) {
                  await this.$store.dispatch(
                    "dashboard/saveDashboard", 
                    { 
                      dashboard: dashboardSource,
                      organisationId: dashboardTypeSource === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
                    }
                  );
                }
                widgetConfigs.forEach(widgetConfig => {
                  if (!isSameDashboard) {
                    this.dashboardState.widgets?.push(widgetConfig);
                  }
                  this.emitter.emit("widget_created", widgetConfig.guid);
                });
                if (spaceWidgetConfigDestination.type === "grid") {
                  spaceWidgetConfigDestination.widgets?.push(spaceWidgetConfig);
                } else {
                  this.widgets.splice(index + 1, 0, spaceWidgetConfig);
                }
                await this.saveDashboard();
                this.$store.commit("dashboard/copyWidget", null);
              }
            }
          } else {
            // clone widgets
            const cloned = SpaceHelper.cloneWidget(spaceWidgetConfig, widgetConfigs);
            if (cloned[1].length) {
              await this.$store.dispatch(
                "dashboard/saveWidgets", 
                cloned[1]
              );
              cloned[1].forEach(widgetConfig => {
                this.dashboardState.widgets?.push(widgetConfig);
                this.emitter.emit("widget_created", widgetConfig.guid);
              });
            }
            if (spaceWidgetConfigDestination.type === "grid") {
              spaceWidgetConfigDestination.widgets?.push(cloned[0]);
            } else {
              this.widgets.splice(index + 1, 0, cloned[0]);
            }
            await this.saveDashboard();
          }
        }
      }
    } finally {
      this.blockPage = false;
    }
  }
  // #endregion widget paste

  // #region share widget
  displayShare = false;

  openShare(): void {
    this.displayShare = true;
  }
  // #endregion share widget

  // #region openai
  isBitpoolAIEnabled(widget: SpaceWidgetConfig): boolean {
    if (this.authState.permissions?.BitpoolAI) {
      const widgetConfig = this.getWidgetConfig(widget);
      const widgetDescription = this.getWidgetDescription(widget);
      return !!widgetConfig &&
        !!this.widgetDataState.data[widgetConfig.guid] &&
        !!widgetDescription?.features.includes(WidgetFeature.dataAnalyzeOpenAI) &&
        !!(!widgetDescription?.features.includes(WidgetFeature.dataOptional) || widgetConfig.widgetOptions.advancedWidgetSettings?.enableOptionalData);
    } else {
      return false;
    }
  }

  displayBitpoolAI = false;

  openBitpoolAIDialog(widget: SpaceWidgetConfig): void {
    if (!this.authState.jailMode) {
      this.selectedWidget = widget;
      this.displayBitpoolAI = true;
    }
  }
  // #endregion openai
}

export default DashboardGridView;
</script>

