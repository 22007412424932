import { AggregatedDataRequest } from "@/models/AggregatedDataRequest";
import ChartRequstBody from "@/models/ChartRequstBody";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import { TimeRange } from "@/models/enums/TimeRange";
import moment, { Moment } from "moment";

class DateHelper {
  extractDateStepFromRequestBody(requestBody: ChartRequstBody | AggregatedDataRequest): number | undefined {
    switch (requestBody.AggregationPeriod) {
      case AggregationPeriod.Daily:
        return 24 * 3600 * 1000;
      case AggregationPeriod.Yearly:
      case AggregationPeriod.FinancialYearly:
        return 365 * 24 * 3600 * 1000;
      case AggregationPeriod.Hourly:
        return 3600 * 1000;
      case AggregationPeriod.Minute:
        return 60 * 1000;
        case AggregationPeriod.Minutes5:
          return 5 * 60 * 1000;
      case AggregationPeriod.Minutes10:
        return 10 * 60 * 1000;
      case AggregationPeriod.Minutes15:
        return 15 * 60 * 1000;
      case AggregationPeriod.Minutes30:
        return 30 * 60 * 1000;
      case AggregationPeriod.Monthly:
        return 28 * 24 * 3600 * 1000;
      case AggregationPeriod.Weekly:
        return 7 * 24 * 3600 * 1000;    
      default:
        return undefined;
    }
  }
  
  extractDateFromRequestBody(requestBody: ChartRequstBody | AggregatedDataRequest): [Date, Date] {
    let from: Moment;
    let to: Moment;
    const now = moment();
    switch (requestBody.TimeRange) {
      default: {
        throw new Error("Unsupported TimeRange");
      }
      case TimeRange.Custom: {
        const fromStr = requestBody.TimeFrom && typeof requestBody.TimeFrom === "string" ? requestBody.TimeFrom.replaceAll("/", "-") : requestBody.TimeFrom;
        const toStr = requestBody.TimeTo && typeof requestBody.TimeTo === "string" ? requestBody.TimeTo.replaceAll("/", "-") : requestBody.TimeTo;
        from = moment(fromStr);
        to = moment(toStr);
        break;
      }
      case TimeRange.ThisHour: {
        from = now.clone().startOf("hour");
        to = from.clone().add(1, "hour");
        break;
      }
      case TimeRange.Today: {
        from = now.clone().startOf("day");
        to = from.clone().add(1, "day");
        break;
      }
      case TimeRange.ThisWeek: {
        from = now.clone().startOf("isoWeek");
        to = from.clone().add(7, "day");
        break;
      }
      case TimeRange.ThisMonth: {
        from = now.clone().startOf("month");
        to = from.clone().add(1, "month");
        break;
      }
      case TimeRange.ThisYear: {
        from = now.clone().startOf("year");
        to = from.clone().add(1, "year");
        break;
      }
      case TimeRange.LastHour: {
        from = now.clone().startOf("hour").subtract(1, "hour");
        to = now.clone().startOf("hour");
        break;
      }
      case TimeRange.Yesterday: {
        from = now.clone().startOf("day").subtract(1, "day");
        to = now.clone().startOf("day");
        break;
      }
      case TimeRange.LastWeek: {
        from = now.clone().startOf("isoWeek").subtract(7, "day");
        to = now.clone().startOf("isoWeek");
        break;
      }
      case TimeRange.LastMonth: {
        from = now.clone().startOf("month").subtract(1, "day").startOf("month");
        to = from.clone().add(1, "month");
        break;
      }
      case TimeRange.LastYear: {
        from = now.clone().startOf("year").subtract(1, "day").startOf("year");
        to = from.clone().add(1, "year");
        break;
      }
      case TimeRange.RollingDay: {
        from = now.clone().subtract(1, "day");
        to = now.clone();
        break;
      }
      case TimeRange.RollingWeek: {
        from = now.clone().subtract(7, "day");
        to = now.clone();
        break;
      }
      case TimeRange.RollingMonth: {
        from = now.clone().subtract(30, "day");
        to = now.clone();
        break;
      }
      case TimeRange.RollingYear: {
        from = now.clone().subtract(365, "day");
        to = now.clone();
        break;
      }
      case TimeRange.RollingHour: {
        from = now.clone().subtract(1, "hour");
        to = now.clone();
        break;
      }
    }
    return [from.toDate(), to.toDate()];
  }

  shiftTime(time: Moment, period: string, value: number): Moment {
    return time.add(value, this.getMomentPeriod(period));
  }

  getMomentPeriod(shortPeriod: string): moment.unitOfTime.DurationConstructor {
    switch (shortPeriod) {
      case "m":
        return "minutes";
      case "H":
        return "hours";
      case "D":
        return "days";
      case "W":
        return "weeks";
      case "M":
        return "months";
      case "Y":
        return "years";
    }
    return "minutes";
  }

  parseFromMicrosoftString(str: string): Date | string {
    if (str) {
      if (str.startsWith("/Date(")) {
        try {
          const timestamp = str.substring(str.indexOf("(") + 1, str.indexOf(")"));
          const m = moment(parseInt(timestamp));
          const result = m.toDate();
          return result;
        } catch {
          return str;
        }
      } else if (str.includes("T") && str.endsWith("Z")) {
        const m = moment(str);
        const result = m.toDate();
        return result;
      } else {
        return str;
      }
    } else {
      return str;
    }
  }

  parseFromMongoDate(date: any): Date | null {
    let m: Moment | null = null;
    if (date) {
      if (date.$date) {
        m = moment(date.$date);
      } else if (typeof date === "string" ||  typeof date === "number") {
        m = moment(date);
      }
    }
    if (m) {
      return m.toDate();
    } else {
      return null;
    }
  }

  timeRangeToMoments(timeRange: TimeRange): [Moment | null, Moment | null] {
    const now = moment();
    let end: Moment | null = null;
    let start: Moment | null = null;

    switch (timeRange) {
      case TimeRange.ThisHour: {
        start = now.clone().startOf("hour");
        end = start.clone().add(1, "hour");
        break;
      }
      case TimeRange.LastHour: {
        start = now.clone().startOf("hour").subtract(1, "hour");
        end = start.clone().add(1, "hour");
        break;
      }
      case TimeRange.Today: {
        start = now.clone().startOf("day");
        end = start.clone().add(1, "day");
        break;
      }
      case TimeRange.Yesterday: {
        start = now.clone().startOf("day").subtract(1, "day");
        end = start.clone().add(1, "day");
        break;
      }
      case TimeRange.ThisWeek: {
        start = now.clone().startOf("isoWeek");
        end = start.clone().add(1, "week");
        break;
      }
      case TimeRange.LastWeek: {
        start = now.clone().startOf("isoWeek").subtract(1, "week");
        end = start.clone().add(1, "week");
        break;
      }
      case TimeRange.ThisMonth: {
        start = now.clone().startOf("month");
        end = start.clone().add(1, "month");
        break;
      }
      case TimeRange.LastMonth: {
        start = now.clone().startOf("month").subtract(1, "month");
        end = start.clone().add(1, "month");
        break;
      }
      case TimeRange.ThisYear: {
        start = now.clone().startOf("year");
        end = start.clone().add(1, "year");
        break;
      }
      case TimeRange.LastYear: {
        start = now.clone().startOf("year").subtract(1, "year");
        end = start.clone().add(1, "year");
        break;
      }
      case TimeRange.RollingDay: {
        start = now.clone().startOf("day");
        end = start.clone().add(1, "day");
        break;
      }
      case TimeRange.RollingWeek: {
        start = now.clone().startOf("day").subtract(6, "day");
        end = start.clone().add(7, "day");
        break;
      }
      case TimeRange.RollingMonth: {
        start = now.clone().startOf("day").subtract(29, "day");
        end = start.clone().add(30, "day");
        break;
      }
      case TimeRange.RollingYear: {
        start = now.clone().startOf("day").subtract(364, "day");
        end = start.clone().add(365, "day");
        break;
      }
      case TimeRange.RollingHour: {
        start = now.clone().startOf("hour").subtract(1, "hour");
        end = start.clone().add(1, "hour");
        break;
      }
    }
    return [start, end];
  }

  goBackForward(counter: number, dateFrom: Date, dateTo: Date, exactPeriod = false): [Date, Date] {
    let end: Date | null = null;
    let start: Date | null = null;
    const startDateM = moment(dateFrom);
    const endDateM = moment(dateTo);
    const isMonth = startDateM.clone().add(1, "month").isSame(endDateM);
    const isYear = startDateM.clone().add(1, "year").isSame(endDateM);
    if (isMonth && !exactPeriod) {
      start = startDateM.add(counter, "month").toDate();
      end = endDateM.add(counter, "month").toDate();
    } else if (isYear && !exactPeriod) {
      start = startDateM.add(counter, "year").toDate();
      end = endDateM.add(counter, "year").toDate();
    } else {
      const diffMinutes = counter * moment.duration(endDateM.diff(startDateM)).asMinutes();
      start = startDateM.add(diffMinutes, "minute").toDate();
      end = endDateM.add(diffMinutes, "minute").toDate();
    }
    return [start, end]
  }
  
  howManyWeeks(dateFrom: Date, dateTo: Date): number {
    const startDateM = moment(dateFrom);
    const endDateM = moment(dateTo);
    const startWeek = startDateM.startOf("isoWeek");
    const endWeek = endDateM.startOf("isoWeek").add(1, "week");
    const weeks = endWeek.diff(startWeek, "week");
    return weeks;
  }

  getDateRanges(): any[] {
    return [
      {name: 'This Hour', key: TimeRange.ThisHour},
      {name: 'Last Hour', key: TimeRange.LastHour},
      {name: 'Today', key: TimeRange.Today},
      {name: 'Yesterday', key: TimeRange.Yesterday},
      {name: 'This Week', key: TimeRange.ThisWeek},
      {name: 'Last Week', key: TimeRange.LastWeek},
      {name: 'This Month', key: TimeRange.ThisMonth},
      {name: 'Last Month', key: TimeRange.LastMonth},
      {name: 'This Year', key: TimeRange.ThisYear},
      {name: 'Last Year', key: TimeRange.LastYear},
      {name: 'Rolling Hour', key: TimeRange.RollingHour},
      {name: 'Rolling Day', key: TimeRange.RollingDay},
      {name: 'Rolling Week', key: TimeRange.RollingWeek},
      {name: 'Rolling Month', key: TimeRange.RollingMonth},
      {name: 'Rolling Year', key: TimeRange.RollingYear},
      {name: 'Custom', key: TimeRange.Custom}
    ];
  }

  getDataAggregations(): any[] {
    return [
      {name: 'Auto', key: -1},
      {name: 'Minute', key: AggregationPeriod.Minute},
      {name: '5 Minutes', key: AggregationPeriod.Minutes5},
      {name: '10 Minutes', key: AggregationPeriod.Minutes10},
      {name: '15 Minutes', key: AggregationPeriod.Minutes15},
      {name: '30 Minutes', key: AggregationPeriod.Minutes30},
      {name: 'Hourly', key: AggregationPeriod.Hourly},
      {name: 'Daily', key: AggregationPeriod.Daily},
      {name: 'Weekly', key: AggregationPeriod.Weekly},
      {name: 'Monthly', key: AggregationPeriod.Monthly},
      {name: 'Yearly', key: AggregationPeriod.Yearly},
      // {name: 'Financial Yearly', key: AggregationPeriod.FinancialYearly}, // not implemented, exist for bills only
      {name: 'Total', key: AggregationPeriod.None},
      {name: 'Raw', key: AggregationPeriod.Raw}
    ];
  }

  // from https://github.com/moment/moment/issues/2934
  calcWeeksInMonth(date: Moment): number {
    let weekInYearIndex = date.week();
    if (date.year() !== date.weekYear()) {
      weekInYearIndex = date.clone().subtract(1,'week').week() + 1;
    }
    const weekIndex = weekInYearIndex - moment(date).startOf('month').week() + 1;
    return weekIndex;
  }

  dateToArray(date: Date): string[] {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return [year, month, day, hours, minutes, seconds];
  }

  dateToString(date: Date) {
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear();
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}

export default new DateHelper();
