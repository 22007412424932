export enum ConnectionHistoryType {
  HaystackExternal,
  MQTTInternal,
  MQTTExternal,
  NubeIOExternal,
  BintrackerExternal,
  WebhookInternal,
  ReportSchedule,
  EPTEdge,
  Weather
}