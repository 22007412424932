<template>
  <div>
    <Dialog 
      header="Bitpool AI Realtime" 
      v-model:visible="displayBitpoolAIDialog" 
      :modal="true" 
      :style="{width: '72rem'}" 
      class="openai-widget-dialog" 
      @hide="onBitpoolAIHide"
    >
      <BitpoolAIRealtimeView :widget="selectedWidget"/>
      <template #header>
        <div class="w-full flex-shrink-0">
          <span class="p-dialog-title">
            <img src="/assets/openai.svg" alt="Bitpool AI logo" />
            <span>Bitpool AI Realtime</span>
          </span>

          <div>
            <!-- <div class="flex column-gap-3 justify-content-between sm:justify-content-start">
              <div class="ai-skills-dropdown">
                <label for="AiSkillsDropdown">Skill</label>
                <div>
                  <Dropdown
                    v-if="availablePersonas.length > 1"
                    v-model="bitpoolAIRealtimeState.selectedAI"
                    :options="availablePersonas"
                    optionValue="Id" 
                    optionLabel="Name"
                    placeholder="Select AI"
                    id="AiSkillsDropdown"
                    @change="onSelectedAIChange"
                  />
                </div>
              </div>
            </div> -->
            <Accordion class="openai-more-data-accordion flex-shrink-0">
              <AccordionTab header="See data">
                <div class="openai-more-data">{{ bitpoolAIRealtimeState.prependData }}</div>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="openai-comments-add mb-0 block w-full">
          <div>
            <Textarea 
              v-model="newMessage" 
              :disabled="bitpoolAIRealtimeState.inProgress || !bitpoolAIRealtimeState.isSessionActive" 
              placeholder="Hey Bitpool" 
              style="white-space: pre-wrap;" 
              :autoResize="true" 
              class="w-full"
              @keydown.enter="($event) => {
                if ($event.shiftKey) {
                  return;
                }
                $event.preventDefault();
                newMessage.trim();
                sendMessage();
              }"
            />
            <div class="inline-flex md:flex-column">
              <Button 
                @click="sendMessage" 
                :disabled="bitpoolAIRealtimeState.inProgress || !newMessage || !bitpoolAIRealtimeState.isSessionActive" 
                label="Add" 
                icon="pi pi-send text-xl" 
                class="flex-shrink-0 p-button-lg p-button-icon-only p-button-text p-button-primary" />
            </div>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import { useBitpoolAIRealtimeStore } from "@/stores/bitpoolAIRealtime";
import { useAISpeechStore } from "@/stores/aiSpeech";
import BitpoolAIRealtimeState from "@/stores/states/BitpoolAIRealtimeState";
import { Component, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import BitpoolAIRealtimeView from "./BitpoolAIRealtimeView.vue";
import { useAIPersonaStore } from "@/stores/aiPersona";
import { AIPersonaEntity } from "@/models/bitpool-ai/AIPersonaEntity";
import AuthState from "@/store/states/AuthState";
import { useSystemStore } from "@/stores/system";
import { useSpeechRecognitionStore } from "@/stores/speechRecognition";

@Component({
  components: {
    Button,
    Dialog,
    Textarea,
    Dropdown,
    Accordion,
    AccordionTab,
    BitpoolAIRealtimeView
  },
})
class BitpoolAIRealtimeDialogView extends Vue {
  @Model({ required: true }) displayBitpoolAIDialog!: boolean;
  @Prop({ required: true }) dashboardId!: string;
  @Prop({ required: true }) selectedWidget!: SpaceWidgetConfig | null;

  bitpoolAIRealtimeStore = useBitpoolAIRealtimeStore();
  aiSpeechStore = useAISpeechStore();
  aiPersonaStore = useAIPersonaStore();
  systemStore = useSystemStore();
  speechRecognitionStore = useSpeechRecognitionStore();

  get auth(): AuthState {
    return this.$store.state.auth;
  }
  
  get bitpoolAIRealtimeState(): BitpoolAIRealtimeState {
    return this.bitpoolAIRealtimeStore;
  }

  unmounted() {
    this.speechRecognitionStore.stopWaitingForSpeech();
    this.speechRecognitionStore.stop();

    this.bitpoolAIRealtimeStore.stopSession();
  }

  @Watch('displayBitpoolAIDialog', { immediate: false, deep: false })
  onDisplayBitpoolAIDialogChanged(): void {
    if (this.displayBitpoolAIDialog) {
      const selectedPersona = this.auth.userSettings?.selectedPersona ?? "";
      if (selectedPersona) {
        this.bitpoolAIRealtimeStore.selectedAI = selectedPersona;
      } else {
        const defaultPersona: AIPersonaEntity | undefined = this.aiPersonaStore.entities ? this.aiPersonaStore.entities.find(x => x.Enabled && x.Default) : undefined;
        this.bitpoolAIRealtimeStore.selectedAI = defaultPersona ? defaultPersona.Id : ""; 
      }
    } else {
      this.bitpoolAIRealtimeStore.stopSession();
    }
  }

  onSelectedAIChange(): void {
    this.bitpoolAIRealtimeStore.cleanHistory();
    this.aiSpeechStore.stopSpeak();
  }

  get availablePersonas(): AIPersonaEntity[] {
    const result: AIPersonaEntity[] = this.aiPersonaStore.entities ? this.aiPersonaStore.entities.filter(x => x.Enabled) : [];
    result.push({ 
      Id: "personal", 
      Name: "Personal",
      Model: "",
      Personality: "",
      Enabled: true,
      Default: false,
      Created: new Date(),
      Updated: new Date(),
      CreatedBy: "",
      UpdatedBy: "",
      Questions: [],
      Voice: "",
      Endpoint: "openai-chat"
    });
    return result;
  }

  get newMessage(): string {
    return this.bitpoolAIRealtimeState.newMessage;
  }

  set newMessage(value: string) {
    this.bitpoolAIRealtimeState.newMessage = value;
  }

  async sendMessage(): Promise<void> {
    this.speechRecognitionStore.stop();
    if (this.newMessage) {
      await this.bitpoolAIRealtimeStore.sendTextMessage(this.newMessage);
      this.newMessage = "";
    }
  }

  get isSRSupported(): boolean {
    return  this.speechRecognitionStore.isSupported;
  }

  get recognitionInProgress(): boolean {
    return this.speechRecognitionStore.recognitionInProgress;
  }

  toggleMic(): void {
    if (this.isSRSupported) {
      if (this.recognitionInProgress) {
        this.sendMessage();
      } else {
        this.aiSpeechStore.stopSpeak();
        this.speechRecognitionStore.start(this.newMessage);
      }
    }
  }

  get speechSynthesisIsEnabled(): boolean {
    return this.aiSpeechStore.speechSynthesisIsEnabled;
  }

  toggleSpeechSynthesis(): void {
    this.aiSpeechStore.toggleSpeechSynthesis();
  }

  onBitpoolAIHide(): void {
    this.speechRecognitionStore.stop();
    const devStub = this.bitpoolAIRealtimeStore.devStub;
    this.bitpoolAIRealtimeStore.$reset();
    this.bitpoolAIRealtimeStore.devStub = devStub;
    this.aiSpeechStore.stopSpeak();
  }
}

export default BitpoolAIRealtimeDialogView;
</script>