<template>
  <div class="he-tree-with-checkbox">
    <div class="he-tree-wrapper">
      <BaseTree 
        v-model="nodes" 
        :defaultOpen="true"
        :statHandler="statHandler"
        textKey="label"
        :indent="22"
        :virtualizationPrerenderCount="50" 
        :watermark="false" 
        ref="tree"
        virtualization 
      >
        <template #default="{ node, stat }">
          <Button 
              :icon="stat.open ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" 
              text 
              rounded
              @click="toggleNode(stat)" v-if="node.children?.length"
              class="p-link"
          />
          <div 
            :class="{ 'tree-node-selected': stat.checkedSingle, 'tree-node-selectable': node.selectable, 'tree-node-without-children': !node.children?.length && containsEquip }"
          >
            <span
              class="flex align-items-center"
            >
              <span v-if="node.icon" :class="node.icon" class="tree-node-icon"></span>
              <span class="tree-node-label break-word">{{ node.label }}</span>
            </span>
            <div class="ml-auto flex align-items-center flex-shrink-0 gap-1">
              <Button
                v-if="node.leaf"
                @click="viewStream(node.key)"
                icon="pi pi-external-link text-base m-0" 
                link
                class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button justify-content-center ml-2"
              />
              <Button
                v-if="!stat.parent && nodeDeleted"
                icon="pi pi-times text-base m-0"
                @click="nodeDeleted(node)"
                link
                class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button tree-node-delete-button justify-content-center"
              />
            </div>
          </div>
        </template>
      </BaseTree>
    </div>
  </div>
</template>

<script lang="ts">
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import { Component, Prop, Vue } from "vue-facing-decorator";
import { BaseTree } from '@he-tree/vue';
import { Stat } from '@he-tree/tree-utils';
import { TreeNodeForUI } from '@/models/nav-tree/NavTreeForUI';

@Component({
  components: {
    Button,
    Checkbox,
    BaseTree
  },
})
class DashboardNewNodesTreeView extends Vue {
  @Prop({ required: true }) nodes!: TreeNodeForUI[];
  @Prop({ required: false, default: null }) nodeDeleted!: null | ((node: TreeNodeForUI) => void);

  viewStream(streamKey: string | null | undefined): void {
    if (streamKey) {
      const newUrl = `/data/streams/${streamKey}`;
      window.open(newUrl, '_blank');
    }
  }

  statHandler(stat: Stat<TreeNodeForUI>): Stat<TreeNodeForUI> {
    if (stat.data.children?.length) {
      stat.class = "has-children";
    }
    return stat;
  }

  toggleNode(node: Stat<TreeNodeForUI>): void {
    node.open = !node.open;
  }

  get containsEquip(): boolean {
    return this.nodes.some(node => node.children?.length);
  }
}

export default DashboardNewNodesTreeView;
</script>
