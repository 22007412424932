<template>
  <div class="widget-settings-stream-item-body">
    <div class="formgrid grid">
      <!-- Structured/not-structured switch -->
      <div class="field col-12 pt-1" v-if="!disableStreamSelection">
        <div class="flex align-items-center">
          <InputSwitch v-model="structuredView" :inputId="`structuredView-${uniqueId}`" class="vertical-align-top" />
          <label :for="`structuredView-${uniqueId}`" class="mb-0 ml-2">Structured Data</label>
        </div>
      </div>

      <!-- Structured -->
      <div v-if="structuredView && !disableStreamSelection" class="field col-12">
        <label :for="`structuredData${uniqueId}`" class="block" :class="selectedStreamKey ? ' with-inline-btn' : ''">
          <span>Structured Data</span>
          <Button
            v-if="selectedStreamKey"
            @click="viewStream(selectedStreamKey)"
            label="View Stream"
            icon="pi pi-external-link" 
            class="p-button-icon-only p-button-rounded p-button-text action-button-size"
          />
        </label>
        <div>
          <TreeSelectView 
            v-if="(navTreeState.isLoaded && !isCollapsed && !isLoadingStreamDataType)"
            :selectedId="selectedStreamKey" 
            :nodes="navTreeState.structuredDataForUI"
            :changeSelectedId="streamFromTreeSelected"
            placeholder="Select Stream"
            :id="`structuredData-${uniqueId}`"
            :includeTags="widgetDescription?.includeTags ?? []"
          />
          <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6" animationDuration="1s" />
        </div>
      </div>

      <!-- Not-structured -->
      <div v-if="!structuredView && !disableStreamSelection" class="field col-12">
        <label :for="`unstructuredData-${uniqueId}`" class="block" :class="selectedStreamKey ? ' with-inline-btn' : ''">
          <span>Unstructured Data</span>
          <Button
            v-if="selectedStreamKey"
            @click="viewStream(selectedStreamKey)"
            label="View Stream"
            icon="pi pi-external-link" 
            class="p-button-icon-only p-button-rounded p-button-text action-button-size"
          />
        </label>
        <div>
          <TreeSelectView 
            v-if="(navTreeState.unstructuredIsLoaded && !isCollapsed && !isLoadingStreamDataType)"
            :selectedId="selectedStreamKey" 
            :nodes="navTreeState.unstructuredDataForUI"
            :changeSelectedId="streamFromTreeSelected"
            placeholder="Select Stream"
            :id="`unstructuredData-${uniqueId}`"
            :includeTags="widgetDescription?.includeTags ?? []"
          />
          <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6" animationDuration="1s" />
        </div>
      </div>

      <!-- Label -->
      <div class="field col-12">
        <label :for="`widgetLabel-${uniqueId}`">Label</label>
        <div>
          <InputText
            :id="`widgetLabel-${uniqueId}`"
            class="inputfield w-full"
            type="text"
            v-model="streamOption.Label"
          />
        </div>
      </div>
      <!-- Aggregation Type -->
      <div class="field col-12 sm:col-6" 
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsAggregationType) && selectedStreamDataType === 0">
        <label :for="`widgetAggregationType-${uniqueId}`">Aggregation Type</label>
        <div>
          <Dropdown
            v-model="streamOption.Params.aggType"
            :options="aggregationTypes"
            optionValue="id" 
            optionLabel="name"
            placeholder="Select aggregation type" 
            class="w-full"
            :id="`widgetAggregationType-${uniqueId}`"
          /> 
        </div>
      </div>
      <!-- Aggregation Type String -->
      <div class="field col-12 sm:col-6" 
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsAggregationTypeString) && selectedStreamDataType === 1">
        <label :for="`widgetAggregationTypeString-${uniqueId}`">Aggregation Type String</label>
        <div>
          <Dropdown
            v-model="streamOption.Params.aggTypeString"
            :options="aggregationTypesString"
            optionValue="id" 
            optionLabel="name"
            placeholder="Select aggregation type" 
            class="w-full"
            :id="`widgetAggregationTypeString-${uniqueId}`"
          /> 
        </div>
      </div>
      <!-- Series Type -->
      <div class="field col-12 sm:col-6" 
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsSeriesType)">
        <label :for="`widgetSeriesType-${uniqueId}`">Series Type</label>
        <div>
          <Dropdown
            v-model="streamOption.type"
            :options="seriesTypes"
            optionValue="id" 
            optionLabel="name"
            placeholder="Select series type" 
            class="w-full"
            :id="`widgetSeriesType-${uniqueId}`"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" class="dropdown-item-with-icon">
                <SeriesTypeIconView :seriesType="slotProps.value"/>
                <span>{{ getSeriesTypeNameById(slotProps.value) }}</span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="dropdown-item-with-icon">
                <SeriesTypeIconView :seriesType="slotProps.option.id"/>
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <!-- Series Type (stacked) -->
      <div class="field col-12 sm:col-6" 
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsSeriesTypeStacked)">
        <label :for="`widgetSeriesTypeStacked-${uniqueId}`">Series Type</label>
        <div>
          <Dropdown
            v-model="streamOption.stackedType"
            :options="seriesStackedTypes"
            optionValue="id" 
            optionLabel="name"
            placeholder="Select series type" 
            class="w-full"
            :id="`widgetSeriesTypeStacked-${uniqueId}`"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" class="dropdown-item-with-icon">
                <SeriesTypeIconView :seriesType="slotProps.value"/>
                <span>{{ getSeriesStackedTypeNameById(slotProps.value) }}</span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="dropdown-item-with-icon">
                <SeriesTypeIconView :seriesType="slotProps.option.id"/>
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <!-- Series Multiplier -->
      <div class="field col-12 sm:col-6"
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsMultiplier)">
        <label :for="`widgetSeriesMultiplier-${uniqueId}`">Series Multiplier</label>
        <div>
          <InputNumber
            class="w-full"
            :id="`widgetSeriesMultiplier-${uniqueId}`"
            v-model="streamOption.Params.multiplier" 
            mode="decimal" 
            :minFractionDigits="2"
          />
        </div>
      </div>
      <!-- Duration Rule -->
      <div class="field col-12 sm:col-6" 
        v-if="hasConditionDuration()">
        <label :for="`widgetDurationRule-${uniqueId}`">Duration Rule</label>
        <div>
          <Dropdown
            v-if="selectedStreamDataType === 0"
            v-model="streamOption.durationRule"
            :options="conditionDurationRules"
            optionValue="id" 
            optionLabel="name"
            placeholder="Select duration rule" 
            class="w-full"
            :id="`widgetDurationRule-${uniqueId}`"
          /> 
          <Dropdown
            v-else
            v-model="streamOption.durationRule"
            :options="conditionDurationRulesStr"
            optionValue="id" 
            optionLabel="name"
            placeholder="Select duration rule" 
            class="w-full"
            :id="`widgetDurationRule-${uniqueId}`"
          /> 
        </div>
      </div>
      <!-- Duration Rule (Min / Value) -->
      <div class="field col-12 sm:col-6" 
        v-if="hasConditionDuration()">
        <label :for="`widgetDurationRuleValue-${uniqueId}`">Duration Rule {{hasSecondConditionDurationValue() ? "Min" : "Value"}}</label>
        <div>
          <InputNumber
            v-if="selectedStreamDataType === 0"
            class="w-full"
            v-model="streamOption.durationRuleValue" 
            mode="decimal" 
            :minFractionDigits="2"
            :id="`widgetDurationRuleValue-${uniqueId}`"
          />
          <InputText
            v-else
            class="w-full"
            v-model="streamOption.durationRuleValueStr"
            :id="`widgetDurationRuleValue-${uniqueId}`"
          />
        </div>
      </div>
      <!-- Duration Rule Max -->
      <div class="field col-12 sm:col-6" 
        v-if="hasSecondConditionDurationValue()">
        <label :for="`widgetDurationMax-${uniqueId}`">Duration Rule Max</label>
        <div>
          <InputNumber
            class="w-full"
            :id="`widgetDurationMax-${uniqueId}`"
            v-model="streamOption.durationRuleValue2" 
            mode="decimal" 
            :minFractionDigits="2"
          />
        </div>
      </div>
      <!-- Duration Text -->
      <div class="field col-12 sm:col-6" 
        v-if="hasConditionDuration()">
        <label :for="`widgetDurationText-${uniqueId}`">Duration Text</label>
        <div>
          <InputText
            class="inputfield w-full"
            :id="`widgetDurationText-${uniqueId}`"
            type="text"
            v-model="streamOption.durationRuleText"
          />
        </div>
      </div>
      <!-- Y Axis -->
      <div class="field col-12 sm:col-6" 
        v-if="widgetDescription?.features.includes(widgetFeatures.yAxis)">
        <label :for="`widgetStreamYAxis-${uniqueId}`">Y-Axis</label>
        <div>
          <Dropdown
            v-model="streamOption.Params.yaxis"
            :options="yAxisOptions"
            optionValue="id" 
            optionLabel="name"
            placeholder="Select axis" 
            class="w-full"
            :id="`widgetStreamYAxis-${uniqueId}`"
          /> 
        </div>
      </div>
      <!-- Groups -->
      <div class="field col-12 sm:col-6" 
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsGroups)">
        <label :for="`widgetGroups-${uniqueId}`">Group</label>
        <div>
          <Dropdown
            v-model="streamOption.Params.groupIndex"
            :options="groupsOptions"
            optionValue="id" 
            optionLabel="name"
            placeholder="Select group" 
            class="w-full"
            :id="`widgetGroups-${uniqueId}`"
          /> 
        </div>
      </div>
      <!-- Offset Time -->
      <div class="field col-12 sm:col-6"
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsTimeOffset) && streamOption.Params.timeOffset">
        <label :for="`widgetOffsetTime-${uniqueId}`">Offset Time</label>
        <div>
          <div class="p-inputgroup w-full">
            <InputNumber
              v-model="streamOption.Params.timeOffset.value" 
              mode="decimal" 
              :minFractionDigits="0"
              :id="`widgetOffsetTime-${uniqueId}`"
            />
            <Dropdown
              v-model="streamOption.Params.timeOffset.period"
              :options="timeOffsetPeriods"
              optionValue="id" 
              optionLabel="name"
              placeholder="Select aggregation type" 
              class="p-inputgroup-addon w-9rem flex-none p-0"
            />
          </div>
        </div>
      </div>
      <!-- Units -->
      <div class="field col-12 sm:col-6" v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsUnits)">
        <label :for="`widgetStreamUnits-${uniqueId}`">Units</label>
        <div>
          <InputText
            class="inputfield w-full"
            :id="`widgetStreamUnits-${uniqueId}`"
            type="text"
            v-model="streamOption.units"
          />
        </div>
      </div>
      <!-- Text -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataStreamsText)">
        <label :form="`widgetText-${uniqueId}`">Text</label>
        <div>
          <InputText class="inputfield w-full" :id="`widgetText-${uniqueId}`" type="text" v-model="streamOption.text" />
        </div>
      </div>
      <!-- Stream Color -->
      <div class="field col-12" 
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsColor)">
        <div class="flex align-items-center">
          <label class="mb-0 mr-2">Stream Color</label>
          <ColorSelectView :color="streamOption.hexStreamColor" :updateColor="updateStreamColor"/>
        </div>
      </div>
      <!-- Stream Color 2 -->
      <div class="field col-12" 
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsColor2)">
        <div class="flex align-items-center">
          <label class="mb-0 mr-2">{{ widgetDescription?.features.includes(widgetFeatures.dataStreamsColor2PreviousPeriod) ? "Previous Period Color" : "Stream Color 2" }}</label>
          <ColorSelectView :color="streamOption.hexStreamColor2" :updateColor="updateStreamColor2"/>
        </div>
      </div>
      <!-- Min, Max Value -->
      <div class="col-12"
        v-if="streamOption && streamOption.minMaxRange && widgetDescription?.features.includes(widgetFeatures.dataStreamsMinMaxRange)">
        <div class="formgrid grid">
          <div class="field col-12" v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsMinMaxRangeAuto)">
            <div class="flex align-items-center">
              <InputSwitch v-model="streamOption.autoMinMax" inputId="widgetAutoMinMaxValue" class="vertical-align-top" />
              <label for="widgetAutoMinMaxValue" class="mb-0 ml-2">Auto Min & Max</label>
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label :for="`widgetMinValue-${uniqueId}`">Min Value</label>
            <div>
              <InputNumber
                class="inputfield w-full"
                :id="`widgetMinValue-${uniqueId}`"
                v-model="streamOption.minMaxRange[0]" 
                mode="decimal" 
                :minFractionDigits="0"
                :max="streamOption.minMaxRange[1]"
                :allowEmpty="false"
                :disabled="streamOption.autoMinMax && widgetDescription?.features.includes(widgetFeatures.dataStreamsMinMaxRangeAuto)"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label :for="`widgetMaxValue-${uniqueId}`">Max Value</label>
            <div>
              <InputNumber
                class="inputfield w-full"
                :id="`widgetMaxValue-${uniqueId}`"
                v-model="streamOption.minMaxRange[1]" 
                mode="decimal" 
                :minFractionDigits="0"
                :min="streamOption.minMaxRange[0]"
                :allowEmpty="false"
                :disabled="streamOption.autoMinMax && widgetDescription?.features.includes(widgetFeatures.dataStreamsMinMaxRangeAuto)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Inactive Color -->
      <div class="field col-12" 
        v-if="streamOption && widgetDescription?.features.includes(widgetFeatures.dataStreamsInactiveColor)">
        <div class="flex align-items-center">
          <label class="mb-0 mr-2">Inactive Color</label>
          <ColorSelectView :color="streamOption.inactiveColor" :updateColor="updateWidgetInactiveColor"/>
        </div>
      </div>
      <!-- Range Selector -->
      <div class="col-12" 
        v-if="streamOption && widgetDescription?.features.includes(widgetFeatures.dataStreamsGaugeRange)">
        <div class="formgrid grid">
          <div class="field col-12">
            <label>Range Selector</label>
            <div>
              <vue-gpickr v-model="gradient" class="gauge-range"/>
            </div>
          </div>
        </div>
      </div> 
      <!-- Smoothed Data -->
      <div class="field col-12 pt-1"
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsSmooth)">
        <div class="flex align-items-center">
          <InputSwitch v-model="postProcessIfAvailable" :inputId="`widgetSmoothedData-${uniqueId}`" class="vertical-align-top" />
          <label :for="`widgetSmoothedData-${uniqueId}`" class="mb-0 ml-2">ML Processed</label>
        </div>
      </div>
      <!-- Skip In Total -->
      <div class="field col-12 pt-1"
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsSkipInTotal)">
        <div class="flex align-items-center">
          <InputSwitch v-model="streamOption.Params.skipInTotal" :inputId="`widgetSkipInTotal-${uniqueId}`" class="vertical-align-top" />
          <label :for="`widgetSkipInTotal-${uniqueId}`" class="mb-0 ml-2">Skip In Total</label>
        </div>
      </div>
      <!-- Is Accumulating -->
      <div class="field col-12 pt-1"
        v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsIsAccumulating)">
        <div class="flex align-items-center">
          <InputSwitch v-model="streamOption.Params.IsAccumulating" :inputId="`widgetdataStreamsIsAccumulating-${uniqueId}`" class="vertical-align-top" />
          <label :for="`widgetdataStreamsIsAccumulating-${uniqueId}`" class="mb-0 ml-2">Is Accumulating</label>
        </div>
      </div>
      <!-- Time Interval Grid -->
      <div class="col-12">
        <div class="formgrid grid">
          <!-- Use Time Intervals -->
          <div class="field col-12 pt-1" v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsTimeIntervals)">
            <div class="flex align-items-center">
              <InputSwitch v-model="useTimeIntervals" class="vertical-align-top" :inputId="`widgetUseTimeIntervals-${uniqueId}`" />
              <label :for="`widgetUseTimeIntervals-${uniqueId}`" class="mb-0 ml-2">Use Time Intervals</label>
            </div>
          </div>
          <!-- Time Intervals -->
          <div class="col-12 mt-3"
            v-if="useTimeIntervals && widgetDescription?.features.includes(widgetFeatures.dataStreamsTimeIntervals)">
            <div class="inline-flex align-items-center">
              <h6 class="widget-settings-add-time-interval-title">Time Intervals</h6>
              <Button @click="addTimeInterval" class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn ml-3 flex-none" icon="pi pi-plus"/>
            </div>
          </div>
          <div class="col-12 mt-2"
            v-if="useTimeIntervals && timeIntervals?.length">
            <DataTable 
              :value="timeIntervals" 
              showGridlines 
              responsiveLayout="stack" 
              breakpoint="685px" 
              class="widget-settings-time-interval-table responsive-breakpoint p-datatable-sm"
            >
              <Column field="Day" header="Day" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 25%; min-width: 152px;">
                <template #body="{ data, field }">
                  <Dropdown
                    v-model="data[field]"
                    :options="daysOfWeek"
                    optionValue="id" 
                    optionLabel="name"
                    placeholder="Select day" 
                    class="w-full"
                  /> 
                </template>
              </Column>
              <Column field="From" header="From" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 20%;">
                <template #body="{ data, field, index }">
                  <input
                    class="w-full p-inputtext p-component"
                    v-model="data[field]"
                    v-maska data-maska="##:##"
                    @blur="validateTime(index, 0)"
                  />
                </template>
              </Column>
              <Column field="To" header="To" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 20%;">
                <template #body="{ data, field, index }">
                  <input
                    class="w-full p-inputtext p-component"
                    v-model="data[field]"
                    v-maska data-maska="##:##"
                    @blur="validateTime(index, 1)"
                  />
                </template>
              </Column>
              <Column field="InvertTime" header="Business Hours" headerStyle="width: 25%;"  headerClass="text-sm" bodyClass="text-sm">
                <template #body="{ data, field }">              
                  <SelectButton
                    v-model="data[field]"
                    :options="businessHours" 
                    optionValue="id" 
                    optionLabel="name"
                    class="w-full widget-settings-time-interval-business-hours"
                    :allowEmpty="false"
                  />
                </template>
              </Column>
              <Column field="" header="" headerStyle="width: 1%; min-width: 44px;" bodyStyle="text-align: right; justify-content: flex-end;">
                <template #body="{ index }">
                  <Button 
                      icon="pi pi-trash" 
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                      @click="removeTimeInterval(index)"
                    />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- State Name Converter -->
      <div class="col-12">
        <div class="formgrid grid">
          <div class="col-12 mt-3"
            v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsStateNameConverter)">
            <div class="inline-flex align-items-center">
              <h6 class="widget-settings-add-time-interval-title">State Name Converter</h6>
              <Button @click="addStateName" class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn ml-3 flex-none" icon="pi pi-plus"/>
            </div>
          </div>
          <div class="col-12 mt-2"
            v-if="stateNameConverters?.length">
            <DataTable 
              :value="stateNameConverters" 
              showGridlines 
              responsiveLayout="stack" 
              breakpoint="685px" 
              class="widget-settings-time-interval-table responsive-breakpoint p-datatable-sm"
            >
              <Column field="value" header="From">
                <template #body="{ data, field }">
                  <InputText
                    class="w-full"
                    v-model="data[field]"
                  />
                </template>
              </Column>
              <Column field="convertTo" header="To">
                <template #body="{ data, field }">
                  <InputText
                    class="w-full"
                    v-model="data[field]"
                  />
                </template>
              </Column>
              <Column field="" header="" headerStyle="width: 1%; min-width: 44px;" bodyStyle="text-align: right; justify-content: flex-end;">
                <template #body="{ index }">
                  <Button 
                      icon="pi pi-trash" 
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                      @click="removeStateName(index)"
                    />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- Link Grid -->
      <div class="col-12" v-if="widgetDescription?.features.includes(widgetFeatures.dataStreamsLink)">
        <div class="formgrid grid">
          <!-- Link Type -->
          <div class="field col-12 md:col-6">
            <label>Link Type</label>
            <div>
              <SelectButton 
                v-model="streamOption.linkType" 
                :options="linkTypes" 
                optionValue="key" 
                optionLabel="name"
                class="buttonset-equal-btns" 
                :allowEmpty="false"
              />
            </div>
          </div>
          <!-- Link -->
          <div class="field col-12 md:col-6" v-if="streamOption.linkType === 1">
            <label>Link</label>
            <div>
              <TreeSelectView v-if="isDashboardsLoaded" :selectedId="streamOption.link" :nodes="nodes"
                :changeSelectedId="dashboardSelected" placeholder="Select Dashboard" />
              <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6"
                animationDuration="1s" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { ColorPicker } from 'vue-accessible-color-picker'
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import SelectButton from 'primevue/selectbutton';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from "primevue/progressspinner";
import WidgetHelper from "@/helpers/WidgetHelper";
import { AdvancedWidgetSettings } from "@/models/dashboard/AdvancedWidgetSettings";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { WidgetDataSettings } from "@/models/dashboard/WidgetDataSettings";
import { WidgetFeature } from "@/models/enums/WidgetFeature";
import DashboardState from "@/store/states/DashboardState";
import ColorHelper from '@/helpers/ColorHelper';
import StreamOption from '@/models/dashboard/StreamOption';
import { DayTimeInterval } from '@/models/DayTimeInterval';
import { DayOfWeek } from '@/models/enums/DayOfWeek';
import { vMaska } from "maska";
import ColorSelectView from './ColorSelectView.vue';
import PlusCircleSvg from "@/components/svg/PlusCircleSvg.vue";
import SeriesTypeIconView from "./SeriesTypeIconView.vue";
import { StreamDataType } from '@/models/enums/StreamDataType';
import TreeSelectView from "@/components/views/TreeSelectView.vue";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { StreamDataTypeStr } from '@/models/enums/StreamDataTypeStr';
import { Watch } from 'vue-facing-decorator';
import HeatmapGaugeRange from '@/models/HeatmapGaugeRange';
import { VueGpickr, LinearGradient } from 'vue-gpickr';
import { useNavTreeStore } from '@/stores/navTree';
import NavTreeState from '@/stores/states/NavTreeState';
import { v4 as uuidv4 } from "uuid";
import StreamOptionStateNameConverter from '@/models/dashboard/StreamOptionStateNameConverter';
import { DashboardType } from '@/models/dashboard/DashboardType';
import { AggregationType } from '@/models/enums/AggregationType';
import { AggregationTypeString } from '@/models/enums/AggregationTypeString';

@Component({
  components: {
    ColorPicker,
    Button,
    InputText,
    InputSwitch,
    Dropdown,
    SelectButton,
    InputNumber,
    DataTable,
    Column,
    ProgressSpinner,
    ColorSelectView,
    PlusCircleSvg,
    SeriesTypeIconView,
    TreeSelectView,
    VueGpickr
  },
  directives: {
    maska: vMaska
  }
})
class WidgetSettingsStreamView extends Vue {
  @Prop({ required: true }) widgetConfig!: WidgetConfig;
  @Prop({ required: true }) streamOption!: StreamOption;
  @Prop({ required: true }) isCollapsed!: boolean;
  @Prop({ required: true }) nodes!: TreeNodeForUI[];
  @Prop({ required: true }) isDashboardsLoaded!: boolean;
  @Prop({ required: false, default: false }) disableStreamSelection!: boolean;

  widgetFeatures = WidgetFeature;
  colorHelper = ColorHelper;
  uniqueId = uuidv4();

  get widgetDescription(): WidgetDescription | undefined {
    return WidgetHelper.getWidget(this.widgetConfig.widgetType, false);
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  navTreeStore = useNavTreeStore();

  get navTreeState(): NavTreeState {
    return this.navTreeStore;
  }

  created(): void {
    this.loadInitialData();
  }

  // #region structured/unstructured data  
  isLoadingStreamDataType = false;
  selectedStreamKey: string | null | undefined = null;
  selectedStreamDataType = StreamDataType.Double;

  get structuredView(): boolean {
    return !!this.streamOption.structured;
  }

  set structuredView(value: boolean) {
    this.streamOption.structured = value;
  }

  async loadInitialData(): Promise<void> {
    if (!this.disableStreamSelection) {
      const streamKey = this.streamOption.StreamKey;
      if (streamKey) {
        this.isLoadingStreamDataType = true;
        await this.$store.dispatch("dashboard/loadStream", streamKey);
        if (this.dashboardState.streams[streamKey]) {
          const stream = this.dashboardState.streams[streamKey][1];
          this.selectedStreamKey = streamKey;
          this.selectedStreamDataType = stream ? stream.DataType as StreamDataType : StreamDataType.Double;
        }
        this.isLoadingStreamDataType = false;
      }
    }
  }

  streamFromTreeSelected(node: TreeNodeForUI): void {
    if (!this.streamOption.Label || this.streamOption.Name === this.streamOption.Label) {
      this.streamOption.Label = node.label ?? "";
    }
    this.streamOption.Name = node.label ?? "";
    this.streamOption.StreamKey = node.key ?? "";
    this.selectedStreamKey = this.streamOption.StreamKey;
    this.selectedStreamDataType = node.dataType === StreamDataTypeStr.Double ? StreamDataType.Double : StreamDataType.String;  
  }

  viewStream(streamKey: string | null | undefined): void {
    if (streamKey) {
      const newUrl = `/data/streams/${streamKey}`;
      window.open(newUrl, '_blank');
    }
  }
  // #endregion structured/unstructured data

  // #region color  
  updateStreamColor(color: string): void {
    this.streamOption.hexStreamColor = color;
  }

  updateStreamColor2(color: string): void {
    this.streamOption.hexStreamColor2 = color;
  }
  // #endregion color

  // #region y axis
  get yAxisOptions(): any[] {
    const result = this.aws?.yaxis?.map((axis, index) => { return {id: index, name: axis.name }; });
    return result ? result : [];
  }
  // #endregion y axis

  // #region groups
  get groupsOptions(): any[] {
    const result = this.wds?.groupNames?.map((x, index) => { return {id: index, name: x }; });
    return result ? result : [];
  }
  // #endregion groups

  // #region time intervals
  daysOfWeek = [
    {
      id: DayOfWeek.Monday,
      name: "Monday"
    }, {
      id: DayOfWeek.Tuesday,
      name: "Tuesday"
    }, {
      id: DayOfWeek.Wednesday,
      name: "Wednesday"
    }, {
      id: DayOfWeek.Thursday,
      name: "Thursday"
    }, {
      id: DayOfWeek.Friday,
      name: "Friday"
    }, {
      id: DayOfWeek.Saturday,
      name: "Saturday"
    }, {
      id: DayOfWeek.Sunday,
      name: "Sunday"
    }
  ];

  businessHours = [
    {
      id: false,
      name: "Inside"
    }, {
      id: true,
      name: "Outside"
    }
  ];

  addTimeInterval(): void {
    const newValue: DayTimeInterval = { Day: DayOfWeek.Monday, From: "06:00", To: "17:00", InvertTime: false };
    if (this.timeIntervals.length) {
      const oldValue = this.timeIntervals[this.timeIntervals.length - 1];
      if (oldValue.Day === DayOfWeek.Saturday) {
        newValue.Day = DayOfWeek.Sunday;
      } else {
        newValue.Day = oldValue.Day + 1;
      }
      newValue.From = oldValue.From;
      newValue.To = oldValue.To;
      newValue.InvertTime = oldValue.InvertTime;
    }
    this.timeIntervals.push(newValue);
  }

  removeTimeInterval(index: number): void {
    this.timeIntervals.splice(index, 1);
  }

  get timeIntervals(): DayTimeInterval[] {
    const result = this.streamOption.Params.timeIntervals ? this.streamOption.Params.timeIntervals : [];
    return result;
  }

  set timeIntervals(value: DayTimeInterval[]) {
    this.streamOption.Params.timeIntervals = value;
  }

  get useTimeIntervals(): boolean {
    return !!this.streamOption.Params.useTimeIntervals;
  }

  set useTimeIntervals(value: boolean) {
    this.streamOption.Params.useTimeIntervals = value ? 1 : 0;
  }

  validateTime(index: number, input: number) {
    const data = this.timeIntervals[index];
    const value = input === 0 ? data.From : data.To;
    let newValue = "";
    if (value) {
      const parts = value.split(":");
      let hours = parseInt(parts[0]);
      let minutes = parseInt(parts[1] ? parts[1] : "0");
      if (minutes > 59) {
        minutes = 59;
      }
      if (hours > 24) {
        hours = 24;
      }
      if (hours === 24) {
        minutes = 0;
      }
      newValue = `${hours.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}:${minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}`
    } else {
      newValue = "00:00";
    }
    if (input === 0) {
      data.From = newValue > data.To ? data.To : newValue;
    } else {
      data.To = newValue < data.From ? data.From : newValue;
    }
  }
  // #endregion time intervals

  // #region series type
  seriesTypes = [
    {
      id: "column",
      name: "Column"
    }, {
      id: "line",
      name: "Line"
    }, {
      id: "area",
      name: "Area"
    }, {
      id: "scatter",
      name: "Scatter"
    }, {
      id: "spline",
      name: "Spline"
    }
  ];

  getSeriesTypeNameById(id: string): string {
    const result = this.seriesTypes.find(x => x.id === id); 
    return result ? result.name : "";
  }

  seriesStackedTypes = [
    {
      id: "column",
      name: "Column"
    }, {
      id: "column2",
      name: "Column 2"
    }, {
      id: "line",
      name: "Line"
    }, {
      id: "area",
      name: "Area"
    }, {
      id: "scatter",
      name: "Scatter"
    }, {
      id: "spline",
      name: "Spline"
    }
  ];

  getSeriesStackedTypeNameById(id: string): string {
    const result = this.seriesStackedTypes.find(x => x.id === id); 
    return result ? result.name : "";
  }
  // #endregion series type

  // #region aggregation type
  aggregationTypes = [
    {name: "Average", id: AggregationType.Avg},
    {name: "Sum", id: AggregationType.Sum},
    {name: "Difference", id: AggregationType.Diff},
    {name: "Minimum", id: AggregationType.Min},
    {name: "Maximum", id: AggregationType.Max},
    // {name: "High Duration", id: 5}, deprecated
    // {name: "Low Duration", id: 6}, deprecated
    {name: "None", id: AggregationType.None},
    {name: "Min Time Occurrence", id: AggregationType.MinTimeOccurrence},
    {name: "Max Time Occurrence", id: AggregationType.MaxTimeOccurrence},
    {name: "First", id: AggregationType.First},
    {name: "Last", id: AggregationType.Last},
    {name: "Accum", id: AggregationType.Accum}
  ];

  aggregationTypesString = [
    {name: "Most", id: AggregationTypeString.Most},
    {name: "Least", id: AggregationTypeString.Least},
    {name: "First", id: AggregationTypeString.First},
    {name: "Last", id: AggregationTypeString.Last},
    {name: "None", id: AggregationTypeString.None}
  ];
  // #endregion aggregation type

  // #region smooth data
  get postProcessIfAvailable(): boolean {
    const result = !!this.streamOption.Params.postProcessIfAvailable;
    return result;
  }

  set postProcessIfAvailable(value: boolean) {
    this.streamOption.Params.postProcessIfAvailable = value ? 1 : 0;
  }
  // #endregion smooth data

  // #region time offset
  timeOffsetPeriods = [
    {name: "Minutes", id: "m"},
    {name: "Hours", id: "H"},
    {name: "Days", id: "D"},
    {name: "Weeks", id: "W"},
    {name: "Months", id: "M"},
    {name: "Years", id: "Y"}
  ];
  // #endregion time offset

  // #region condition duration
  hasConditionDuration(): boolean {
    return !!this.widgetDescription?.features.includes(WidgetFeature.dataStreamsConditionDuration);
  }

  hasSecondConditionDurationValue(): boolean {
    return this.hasConditionDuration() && this.streamOption.durationRule === "Between";
  }

  conditionDurationRules = [
    {name: ">", id: ">"},
    {name: ">=", id: ">="},
    {name: "=", id: "="},
    {name: "!=", id: "!="},
    {name: "<", id: "<"},
    {name: "<=", id: "<="},
    {name: "Between", id: "Between"}
  ];

  conditionDurationRulesStr = [
    {name: "=", id: "="},
    {name: "!=", id: "!="}
  ];
  // #endregion condition duration 

  // #region freeform heatmap
  updateWidgetInactiveColor(color: string): void {
    if (this.streamOption) {
      this.streamOption.inactiveColor = color;
    }
  }

  get gradient(): typeof LinearGradient {
    const result = new LinearGradient({
      angle: 90,
      stops: this.streamOption?.gaugeRange ? 
        this.streamOption?.gaugeRange.map(x => [x.color, x.fromP / 100]) :
        [
          ['#4CAF50', 0],
          ['#f44336', 0.5]
        ]
    });
    return result;
  }

  set gradient(value: typeof LinearGradient) {
    this.updateGaugeRange(value);
  }

  
  @Watch('streamOption.minMaxRange', { immediate: false, deep: true })
  updateGaugeRangeFromMinMaxRange(): void {
    if (this.streamOption?.gaugeRange) {
      this.updateGaugeRange(this.gradient);
    }
  }

  updateGaugeRange(value: typeof LinearGradient): void {
    if (this.streamOption) {
      this.streamOption.gaugeRange = value.stops
        .map((x: [string, number], i: number): HeatmapGaugeRange => {
          return { 
            color: x[0].slice(0, 7), 
            fromP: x[1] * 100, 
            from: this.buildGradientActualValue(x), 
            toP: i >= value.stops.length - 1 ? 100 : value.stops[i + 1][1] * 100, 
            to: i >= value.stops.length - 1 ? (this.streamOption && this.streamOption.minMaxRange ? this.streamOption.minMaxRange[1] : 100) : this.buildGradientActualValue(value.stops[i + 1])
          };
        });
    }
  }

  private buildGradientActualValue(x: [string, number]): number {
    return this.streamOption && this.streamOption.minMaxRange ? ((this.streamOption.minMaxRange[1] - this.streamOption.minMaxRange[0]) * x[1] + this.streamOption.minMaxRange[0]) : 0;
  }
  // #endregion freeform heatmap

  
  // #region state name converter
  addStateName(): void {
    const newValue: StreamOptionStateNameConverter = { value: "", convertTo: "" };
    if (!this.streamOption.stateNameConverter) {
      this.streamOption.stateNameConverter = [];
    }
    this.stateNameConverters.push(newValue);
  }

  removeStateName(index: number): void {
    this.stateNameConverters.splice(index, 1);
  }

  get stateNameConverters(): StreamOptionStateNameConverter[] {
    const result = this.streamOption.stateNameConverter ? this.streamOption.stateNameConverter : [];
    return result;
  }

  set stateNameConverters(value: StreamOptionStateNameConverter[]) {
    this.streamOption.stateNameConverter = value;
  }
  // #endregion state name converter

  // #region link
  dashboardSelected(node: TreeNodeForUI): void {
    if (this.aws) {
      this.streamOption.link = node.key ?? "";
      this.streamOption.linkDashboardType = node.additionalData.isOrganisation ? DashboardType.Organisation : DashboardType.Personal;
    }
  }

  get linkTypes() {
    const result = [
      { name: 'Stream Data', key: 0 },
      { name: 'Dashboard', key: 1 }
    ];
    return result;
  }
  // #endregion link
}

export default WidgetSettingsStreamView;
</script>
