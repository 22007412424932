import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { MongoEntityPage } from "@/models/MongoEntityPage";
import Reports3State from "./states/Reports3State";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import { HtmlToPdfReportParameters } from "@/models/reports/v3/HtmlToPdfReportParameters";
import NavigationHelper from "@/helpers/NavigationHelper";
import moment from "moment";
import { useOrganisationStore } from "./organisation";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { Report3ElementFeatures, Report3ElementFeaturesToString } from "@/models/reports/v3/Report3ElementFeatures";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { Reports3EntityLight } from "@/models/reports/v3/Reports3EntityLight";
import { Reports3ScheduleEntityLight } from "@/models/reports/v3/Reports3ScheduleEntityLight";

export const useReports3Store = defineStore('reports3', {
  state: (): Reports3State => ({ 
    isLoaded: false,
    guid: "",
    data: null,
    take: 20,
    skip: 0,
    search: "",
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false,
    
    isLoadedLight: false,
    guidLight: "",
    dataLight: null,

    inProgressTest: false,
    testResult: null,
    testError: "",

    isLoadedOne: false,
    guidOne: "",
    dataOne: null,
    dataOneSchedules: null,
    dataOneElements: null,
    dataOneSelectedElementConfiguration: null,
    editMode: false,
    cssHtml: null,
    cssPdf: null,
    cssCommon: null,
    cssGrid: null,
    isOneDirty: false,
    isOneSchedulesDirty: false,

    displayConfigurationDialog: false,
    displaySettingsDialog: false,
    isCompactMode: false,
    copiedConfiguration: null,
    cutOrCopy: false
  }),
  getters: {
    getSelectedElementTitle: (state): string => {
      if (!state.dataOneSelectedElementConfiguration && state.dataOne) {
        return "Body";
      } else if (state.dataOneSelectedElementConfiguration?.Role === Reports3ItemRole.Grid) {
        return "Grid";
      } else if (state.dataOneSelectedElementConfiguration?.Role === Reports3ItemRole.Element) {
        let element = undefined;
        if (state.dataOneElements?.length) {
          const id = state.dataOneSelectedElementConfiguration?.ElementId;
          element = state.dataOneElements.find((x) => x.Id === id);
        }
        return element?.Name ?? 'Element';
      } else {
        return "";
      }
    },
    getSelectedElementDescription: (state): string => {
      if (state.dataOneSelectedElementConfiguration?.Role === Reports3ItemRole.Element) {
        let element = undefined;
        if (state.dataOneElements?.length) {
          const id = state.dataOneSelectedElementConfiguration?.ElementId;
          element = state.dataOneElements.find((x) => x.Id === id);
        }
        return element?.Description ?? '';
      } else {
        return "";
      }
    },
    getSelectedElementEntity: (state): Reports3ElementEntity | undefined => {
      if (state.dataOneSelectedElementConfiguration?.ElementId) {
        const elementId = state.dataOneSelectedElementConfiguration.ElementId;
        const result = state.dataOneElements?.find(x => x.Id === elementId);
        return result;
      }
      return undefined;
    }
  },
  actions: {
    async load(skip: number, take: number, search: string) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.data = null;
        this.skip = skip;
        this.take = take;
        this.search = search;
        const url = `rest/Reports_V3/Reports?skip=${skip}&limit=${take}&search=${search}`;
        const response = await axios.get<MongoEntityPage<Reports3Entity>>(url);
        if (this.guid === guid) {
          this.data = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load reports",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = null;
        this.isLoaded = true;
      }
    },
    async loadLight() {
      try {
        const guid = uuidv4();
        this.guidLight = guid;
        this.isLoadedLight = false;
        this.dataLight = null;
        const url = `rest/Reports_V3/Reports/light`;
        const response = await axios.get<Reports3EntityLight[]>(url);
        if (this.guidLight === guid) {
          this.dataLight = response.data;
          this.isLoadedLight = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load reports",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.dataLight = null;
        this.isLoadedLight = true;
      }
    },
    async loadCss() {
      if (this.cssHtml === null) {
        const promises = [
          axios.get<string>(`${window.location.protocol}//${window.location.host}/assets/report-constructor-html.css`),
          axios.get<string>(`${window.location.protocol}//${window.location.host}/assets/report-constructor-pdf.css`),
          axios.get<string>(`${window.location.protocol}//${window.location.host}/assets/report-constructor-common.css`),
          axios.get<string>(`${window.location.protocol}//${window.location.host}/assets/report-constructor-grid.css`)
        ];
        const results = await Promise.all(promises);
        this.cssHtml = results[0].data ?? "";
        this.cssPdf = results[1].data ?? "";
        this.cssCommon = results[2].data ?? "";
        this.cssGrid = results[3].data ?? "";
      }
    },
    async loadOne(id: string) {
      try {
        const guid = uuidv4();
        this.guidOne = guid;
        this.isLoadedOne = false;
        this.dataOne = null;
        this.dataOneSelectedElementConfiguration = null;
        this.dataOneSchedules = null;
        this.isOneDirty = false;
        await this.loadCss();
        if (id === "new") {
          const organisationStore = useOrganisationStore();
          const nowUtc = moment.utc().toDate();
          const newRecord: Reports3Entity = {
            Id: "",
            Name: `New report ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
            Header: null,
            Items: [],
            Footer: null,
            DateRange: {
              active: true,
              rangePreset: 2,
              rangePresetHolder: 2,
              startDate: "",
              startTime: "",
              endDate: "",
              endTime: "",
              aggPeriod: AggregationPeriod.Hourly,
              autoAggPeriod: true
            },
            Datasources: [],
            OrganisationId: organisationStore.currentOrganisation?.Id ?? -1,
            MarginTop: 1,
            MarginBottom: 1,
            MarginLeft: 1,
            MarginRight: 1,
            Created: nowUtc,
            Updated: nowUtc,
            CreatedBy: "", // api will fill it
            UpdatedBy: "" // api will fill it
          };
          this.dataOne = newRecord;
          this.dataOneElements = [];
          this.dataOneSchedules = [];
          this.isLoadedOne = true;
        } else {
          const url = `rest/Reports_V3/Reports/${id}`;
          const response = await axios.get<Reports3Entity | null>(url);
          const urlSchedules = `rest/Reports_V3/Schedules/report/${id}/light`;
          const responseSchedules = await axios.get<Reports3ScheduleEntityLight[] | null>(urlSchedules);
          if (this.guidOne === guid) {
            function getIds(items: Reports3ElementConfiguration[]): string[] {
              const result: string[] = [];
              for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.Items?.length) {
                  result.push(...getIds(item.Items));
                }
                if (item.ElementId) {
                  result.push(item.ElementId);
                }
              }
              return result;
            }
            let ids: string[] = [];
            if (response.data) {
              ids.push(...getIds(response.data.Items));
              if (response.data.Header?.ElementId) {
                ids.push(response.data.Header.ElementId);
              }
              if (response.data.Footer?.ElementId) {
                ids.push(response.data.Footer.ElementId);
              }
            }
            // remove duplicates
            ids = [...new Set(ids)];
            // load elements
            let elements: Reports3ElementEntity[] = [];
            if (ids.length) {
              const url2 = `rest/Reports_V3/Elements/many`;
              const response2 = await axios.post<Reports3ElementEntity[] | null>(url2, ids);
              elements = response2.data ?? [];
            }
            if (this.guidOne === guid) {
              this.dataOne = response.data;
              this.dataOneSchedules = responseSchedules.data?.map(x => x.Id) ?? [];
              this.dataOneElements = elements;
              this.isLoadedOne = true;
            }
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load report",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.dataOne = null;
        this.dataOneElements = null;
        this.isLoadedOne = true;
      }
    },
    async createUpdate(entity: Reports3Entity, scheduleIds: string[] | null = null): Promise<Reports3Entity | null> {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/Reports_V3/Reports`;
        const response = await axios.post<Reports3Entity>(url, entity);
        if (scheduleIds && response.data?.Id) {
          const urlSchedules = `rest/Reports_V3/Schedules/report/${response.data.Id}`;
          await axios.post(urlSchedules, scheduleIds);
        }
        if (this.isLoaded && this.data) {
          if (entity.Id) {
            const index = this.data.Items.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.data.Items[index] = response.data;
            } else {
              await this.load(0, this.take, this.search);
            }
          } else {
            await this.load(0, this.take, this.search);
          }
        }
        if (this.isLoadedOne && this.dataOne) {
          if (!entity.Id) {
            // new
            NavigationHelper.goTo(`/report-manager/report/${response.data.Id}`);
          }
        }
        ToastService.showToast("success", "Reports", "Changes saved", 5000);
        this.updateInProgress = false;
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save report",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return null;
      }
    },
    async generate(body: HtmlToPdfReportParameters): Promise<boolean> {
      try {
        this.inProgressTest = true;
        this.testError = "";
        this.testResult = null;
        const url = `rest/Reports_V3/Reports/Generate`;
        const response = await axios.post<string>(url, body);
        this.inProgressTest = false;
        this.testResult = response.data;
        return true;
      } catch (error) {
        this.inProgressTest = false;
        this.testError = ErrorHelper.handleAxiosError(error).message;
        return false;
      }
    },
    async delete(entity: Reports3Entity): Promise<boolean> {
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/Reports_V3/Reports/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Reports", `${entity.Name} is deleted`, 5000);
        if (this.isLoaded && this.data) {
          const index = this.data.Items.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.data.Items.splice(index, 1);
            this.data.Total--;
          }
        }
        this.deleteInProgress = false;
        this.deleteError = false;
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
        return false;
      }
    },
    createGridConfiguration(): Reports3ElementConfiguration {
      const item: Reports3ElementConfiguration = {
        Uid: uuidv4(),
        Size: 12,
        Role: Reports3ItemRole.Grid,
        ElementId: "",
        Items: [],
        DatasourceId: null,
        FeaturesConfiguration: null,
        AdditionalParameters: null,
        MarginTop: 0,
        MarginBottom: 0,
        MarginLeft: 0,
        MarginRight: 0
      };
      return item;
    },
    createElementConfiguration(element: Reports3ElementEntity): Reports3ElementConfiguration {
      const item: Reports3ElementConfiguration = {
        Uid: uuidv4(),
        Size: element.DefaultSize,
        Role: Reports3ItemRole.Element,
        ElementId: element.Id,
        Items: null,
        DatasourceId: null,
        FeaturesConfiguration: {},
        AdditionalParameters: {},
        MarginTop: 0,
        MarginBottom: 0,
        MarginLeft: 0,
        MarginRight: 0
      };
      if (!this.dataOneElements) {
        this.dataOneElements = [];
      }
      if (!this.dataOneElements.find((x) => x.Id === element.Id)) {
        this.dataOneElements.push(element);
      }
      this.prepareAdditionalParameters(item);
      this.prepareFeaturesConfiguration(item);
      return item;
    },
    selectElement(elementConfiguration: Reports3ElementConfiguration): void {
      if (this.dataOneSelectedElementConfiguration?.Uid !== elementConfiguration.Uid) {
        this.prepareAdditionalParameters(elementConfiguration);
        this.prepareFeaturesConfiguration(elementConfiguration);
        this.dataOneSelectedElementConfiguration = elementConfiguration;
      }
    },
    prepareAdditionalParameters(elementConfigurations: Reports3ElementConfiguration): void {
      if (elementConfigurations.ElementId) {
        const element = this.dataOneElements?.find(x => x.Id === elementConfigurations.ElementId);
        if (element) {
          if (!elementConfigurations.AdditionalParameters) {
            elementConfigurations.AdditionalParameters = {};
          }
          // romeve filds
          for (const key in elementConfigurations.AdditionalParameters) {
            if (!element.AdditionalParameters.some(x => x.Name === key)) {
              delete elementConfigurations.AdditionalParameters[key];
            }
          }
          // add fields
          for (const x of element.AdditionalParameters) {
            if (typeof elementConfigurations.AdditionalParameters[x.Name] === "undefined") {
              elementConfigurations.AdditionalParameters[x.Name] = x.DefaultValue;
            }
          }
        }
      }
    },
    prepareFeaturesConfiguration(elementConfigurations: Reports3ElementConfiguration): void {
      if (elementConfigurations.ElementId) {
        const element = this.dataOneElements?.find(x => x.Id === elementConfigurations.ElementId);
        if (element) {
          if (!elementConfigurations.FeaturesConfiguration) {
            elementConfigurations.FeaturesConfiguration = {} as Record<string, any>;
          }
          const featuresStrings = element.Features.map(x => Report3ElementFeaturesToString[x]);
          if (element.EnableData) {
            featuresStrings.push("DataStreams");
          }
          // romeve filds
          for (const key in elementConfigurations.FeaturesConfiguration) {
            if (!featuresStrings.some(x => x === key)) {
              delete elementConfigurations.FeaturesConfiguration[key];
            }
          }
          // add fields
          for (const x of featuresStrings) {
            if (typeof elementConfigurations.FeaturesConfiguration[x] === "undefined") {
              switch (x) {
                case "DataStreams":
                  elementConfigurations.FeaturesConfiguration[x] = {};
                  break;
                case Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesType]:
                  elementConfigurations.FeaturesConfiguration[x] = {};
                  break;
                case Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesTypeStacked]:
                    elementConfigurations.FeaturesConfiguration[x] = {};
                    break;
                case Report3ElementFeaturesToString[Report3ElementFeatures.YAxis]:
                  elementConfigurations.FeaturesConfiguration[x] = {};
                  break;
                case Report3ElementFeaturesToString[Report3ElementFeatures.BitpoolAI]:
                  elementConfigurations.FeaturesConfiguration[x] = {
                    PersonaId: "",
                    QuestionId: "custom",
                    CustomQuestion: "Please provide a brief overview of the data."
                  };
                  break;
              }
            }
          }
        }
      }
    },
    findAllElementConfigurations(elementConfigurations: Reports3ElementConfiguration[]): Reports3ElementConfiguration[] {
      const result: Reports3ElementConfiguration[] = [];
      for (const elementConfiguration of elementConfigurations) {
        if (elementConfiguration.Role === Reports3ItemRole.Element && elementConfiguration.DatasourceId) {
          result.push(elementConfiguration);
        }
        if (elementConfiguration.Items?.length) {
          result.push(...this.findAllElementConfigurations(elementConfiguration.Items));
        }
      }
      return result;
    }
  },
})
