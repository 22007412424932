<template>
  <div class="dialog-content openai-widget-dialog-content">
    <div class="flex-auto">
      <div 
        v-for="(message, index) in visibleMessages" 
        :key="message.timestamp" 
        :id="`message-${message.timestamp}`"
        class="openai-comments-item"
      >
        <div class="openai-comments-item-inner" :class="message.author === 'You' ? 'user' : 'assistant'">
          <div class="openai-comments-item-head">
            <Avatar v-if="message.author === 'You' && avatarUrl" :image="avatarUrl" />
            <Avatar v-else-if="message.author === 'You'" :label="shortName(fullUsername)" />
            <Avatar v-else class="openai-logo" image="/assets/openai.svg" />

            <div class="flex-auto" style="min-width: 0;">
              <div class="openai-comments-item-role"><b>{{ message.author === 'You' ? fullUsername : message.author }}</b></div>
              <div class="openai-comments-item-time">
                <DateTimezoneView :date="message.timestamp" timezone="local" :hideSeconds="true" />
              </div>
            </div>

            <div class="openai-copy-button-wrapper flex-none align-self-start">
              <Button 
                v-if="message.author !== 'You'"
                v-tippy="'Download Pdf'" 
                class="p-button-rounded p-button-icon-only p-button-text p-button-secondary w-3rem h-3rem"
                style="color: var(--copy-ai-text-button-color);"
                @click="printMessage(message)"
                :icon="pdfGenerationInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-download'" 
                :disabled="pdfGenerationInProgress"
              />
              <Button v-tippy="'Copy text'" class="p-button-rounded p-button-icon-only p-button-text p-button-secondary w-3rem h-3rem"
                @click="copyText(message)">
                <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.1 6.1V3.72C6.1 2.76791 6.1 2.29187 6.28529 1.92822C6.44827 1.60834 6.70834 1.34827 7.02822 1.18529C7.39187 1 7.86791 1 8.82 1H15.28C16.2321 1 16.7081 1 17.0718 1.18529C17.3917 1.34827 17.6517 1.60834 17.8147 1.92822C18 2.29187 18 2.76791 18 3.72V10.18C18 11.1321 18 11.6081 17.8147 11.9718C17.6517 12.2917 17.3917 12.5517 17.0718 12.7147C16.7081 12.9 16.2321 12.9 15.28 12.9H12.9M3.72 18H10.18C11.1321 18 11.6081 18 11.9718 17.8147C12.2917 17.6517 12.5517 17.3917 12.7147 17.0718C12.9 16.7081 12.9 16.2321 12.9 15.28V8.82C12.9 7.86791 12.9 7.39187 12.7147 7.02822C12.5517 6.70834 12.2917 6.44827 11.9718 6.28529C11.6081 6.1 11.1321 6.1 10.18 6.1H3.72C2.76791 6.1 2.29187 6.1 1.92822 6.28529C1.60834 6.44827 1.34827 6.70834 1.18529 7.02822C1 7.39187 1 7.86791 1 8.82V15.28C1 16.2321 1 16.7081 1.18529 17.0718C1.34827 17.3917 1.60834 17.6517 1.92822 17.8147C2.29187 18 2.76791 18 3.72 18Z" stroke="var(--copy-ai-text-button-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </Button>
            </div>
          </div>

          <BitpoolAIMessageView :message="message" :index="index"/>
        </div>
      </div>

      <div v-if="bitpoolAIChatState.inProgress" id="chat-gpt-typing" class="openai-comments-item is-typing">
        <div class="openai-comments-item-inner assistant">
          <div class="openai-comments-item-head">
            <Avatar class="openai-logo" image="/assets/openai.gif" />

            <div class="flex-auto" style="min-width: 0;">
              <div class="openai-comments-item-role"><b>Bitpool AI</b></div>
              <div class="openai-comments-item-time">
                <DateTimezoneView date="now" timezone="local" :hideSeconds="true" />
              </div>
            </div>
          </div>

          <div class="openai-comments-item-body-wrap">
            <div class="openai-comments-item-body break-word comment-bot">
              <div class="flex align-items-center">Bitpool AI is thinking <div id="chat-gpt-typing-dots" class="dot-typing"></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="openai-examples" v-if="selectedPersona && selectedPersona.Questions.length">
      <div>
        <Button 
          v-for="question in selectedPersona.Questions"
          :key="question"
          :disabled="bitpoolAIChatState.inProgress" 
          @click="sendMessage(question)"
          :label='`"${question}"`' 
          icon="pi pi-arrow-right p-button-icon-right" 
          class="openai-comments-examle-btn" 
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Avatar from 'primevue/avatar';
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import DashboardState from "@/store/states/DashboardState";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import WidgetDataState from "@/store/states/WidgetDataState";
import { AdvancedWidgetSettings } from "@/models/dashboard/AdvancedWidgetSettings";
import { WidgetDataSettings } from "@/models/dashboard/WidgetDataSettings";
import { json2csv, Json2CsvOptions } from "json-2-csv";
import moment from "moment";
import BitpoolAIChatState from "@/stores/states/BitpoolAIChatState";
import { useBitpoolAIChatStore } from '@/stores/bitpoolAIChat';
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import LightbulbSvg from "@/components/svg/LightbulbSvg.vue";
import { BasicWidgetSettings } from "@/models/dashboard/BasicWidgetSettings";
import BitpoolAIMessageView from "@/components/views/dashboards/BitpoolAIMessageView.vue";
import ToastService from "@/services/ToastService";
import { useAISpeechStore } from "@/stores/aiSpeech";
import { useTagManagerStore } from '@/stores/tagManager';
import { AIPersonaEntity } from "@/models/bitpool-ai/AIPersonaEntity";
import { useAIPersonaStore } from "@/stores/aiPersona";
import { HtmlToPdfAIParameters } from "@/models/bitpool-ai/HtmlToPdfAIParameters";
import  secureRandomPassword from 'secure-random-password';
import { ChatGPTHistory } from "@/models/bitpool-ai/ChatGPTHistory";
import PrintHelper from "@/helpers/PrintHelper";
import { NavTreeNodeMinimalWithParents } from "@/models/nav-tree/NavTreeNodeMinimalWithParents";
import { useAIInsightLibraryStore } from "@/stores/aiInsightLibrary";

@Component({
  components: {
    Button,
    ProgressSpinner,
    Avatar,
    DateTimezoneView,
    LightbulbSvg,
    BitpoolAIMessageView
  },
})
class BitpoolAIView extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig | null;

  get widgetId(): string {
    return (this.widget ? this.widgetConfig?._id : undefined) ?? "";
  }

  get widgetConfig(): WidgetConfig | undefined {
    const widgetConfig = this.widget ? this.dashboardState.widgets?.find(x => x.guid === this.widget?.guid) : undefined;
    return widgetConfig;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  bitpoolAIChatStore = useBitpoolAIChatStore();
  aiSpeechStore = useAISpeechStore();
  tagManagerStore = useTagManagerStore();
  aiPersonaStore = useAIPersonaStore();
  aiInsightLibraryStore = useAIInsightLibraryStore();

  get bitpoolAIChatState(): BitpoolAIChatState {
    return this.bitpoolAIChatStore;
  }

  get bws(): BasicWidgetSettings | undefined {
    return this.widgetConfig?.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig?.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig?.widgetOptions.widgetDataSettings;
  }

  get avatarUrl(): string {
    return this.$store.getters["auth/getAvatarUrl"];
  }

  get fullUsername(): string {
    return this.$store.getters["auth/getFullUsername"];
  }

  shortName(fullname: string | null): string {
    return fullname ? fullname.split(" ").map(x => x[0]).join("") : "";
  }

  get selectedPersona(): AIPersonaEntity {
    let result: AIPersonaEntity | undefined = undefined;
    if (this.availablePersonas.length === 0) {
      result = {
        Id: "", 
        Name: "",
        Model: "",
        Personality: "",
        Enabled: true,
        Default: false,
        Created: new Date(),
        Updated: new Date(),
        CreatedBy: "",
        UpdatedBy: "",
        Questions: [],
        Voice: "",
        Endpoint: "openai-chat"
      }
    } else {
      const id = this.bitpoolAIChatState.selectedAI;
      if (id) {
        result = this.availablePersonas.find(x => x.Id === id);
      }
      if (!result) {
        result = this.availablePersonas.find(x => x.Default);
      }
      if (!result) {
        result = this.availablePersonas[0];
      }
    }
    return result;
  }
  
  get availablePersonas(): AIPersonaEntity[] {
    const result: AIPersonaEntity[] = this.aiPersonaStore.entities ? this.aiPersonaStore.entities.filter(x => x.Enabled) : [];
    result.push({ 
      Id: "personal", 
      Name: "Personal",
      Model: "",
      Personality: "",
      Enabled: true,
      Default: false,
      Created: new Date(),
      Updated: new Date(),
      CreatedBy: "",
      UpdatedBy: "",
      Questions: [],
      Voice: "",
      Endpoint: "openai-chat"
    });
    return result;
  }

  created() {
    this.init();
  }

  async init(): Promise<void> {
    this.bitpoolAIChatStore.newConversation();
    if (this.aws?.aiPersonaId) {
      this.bitpoolAIChatState.selectedAI = this.aws.aiPersonaId;
    }
    let question = "";
    if (!this.aws?.aiQuestionId || this.aws.aiQuestionId === "custom") {
      question = this.aws?.aiCustomQuestion ?? "";
    } else {
      const insight = this.aiInsightLibraryStore.entities?.find(x => x.Id === this.aws?.aiQuestionId);
      if (insight) {
        question = insight.Question;
        if (insight.PersonaId) {
          this.bitpoolAIChatState.selectedAI = insight.PersonaId
        }
      }
    }
    const streamKeys: string[] = [];
    if (this.wds && this.widgetConfig && this.wds.streamOptions?.length) {
      const names: string[] = [];
      this.wds.streamOptions.forEach(stream => {
        if (stream.StreamKey) {
          streamKeys.push(stream.StreamKey);
          if (this.aws?.fields) {
            this.aws.fields.forEach(field => {
              switch (field) {
                case "min":
                case "max":
                case "avg":
                case "diff":
                case "sum":
                  names.push(`${stream.Label ? stream.Label : stream.Name ? stream.Name : stream.StreamKey} [${field}]`);
                  break;
              }
            });
          } else {
            names.push(stream.Label ? stream.Label : stream.Name ? stream.Name : stream.StreamKey);
          }
        }
      });
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        const datesSet = new Set<number>();
        data.forEach((streamData) => {
          if (streamData.Data && streamData.Data.length) {
            streamData.Data.forEach(element => {
              if (typeof element.x !== "undefined" && element.x !== null) {
                datesSet.add(element.x);
              }
            });
          }
        });
        const dates = Array.from(datesSet);
        dates.sort();
        const rows: Record<string, any> = {};
        rows["header"] = { date: "Timestamp" };
        const widgetWithAdditionalPreviousRangeData = this.widgetConfig?.widgetType === 'powerusage' || this.widgetConfig?.widgetType === 'baselinechart';
        dates.forEach(date => {
          const row: any = {
            date: moment(date).utc().format("YYYY-MM-DD HH:mm:ss")
          };
          data.forEach((stream, index) => {
            if (widgetWithAdditionalPreviousRangeData && index === 0 || !widgetWithAdditionalPreviousRangeData) {
              row[`field_${index}`] = null;
              rows["header"][`field_${index}`] = names[index] ?? stream.StreamKey;
            }
          });
          rows[`${date}`] = row;
        });
        data.forEach((streamData, index) => {
          if (!streamData.Error && streamData.Data && streamData.Data.length) {
            if (this.widgetConfig?.widgetType === 'powerusage' && index === 2) {
              // ignore last time occurrence
              return;
            }
            streamData.Data.forEach(element => {
              const key = `${element.x}`;
              if (rows[key]) {
                const field = widgetWithAdditionalPreviousRangeData ? "field_0" : `field_${index}`;
                rows[key][field] = typeof element.y === "number" ? parseFloat(element.y.toFixed(2)) : element.y;
              }
            });
          }
        });
        const tableValues: any[] = [];
        for (const key in rows) {
          tableValues.push(rows[key]);
        }

        const options: Json2CsvOptions = {
          delimiter: {
            wrap: '"',
            field: ',',
            eol: '\n'
          },
          emptyFieldValue: "",
          prependHeader: false,
          sortHeader: false,
          excelBOM: false,
          trimHeaderFields: true,
          trimFieldValues: true
        };
        const csvData = await json2csv(tableValues, options);
        let units = this.aws?.widgetUnit;
        if (units) {
          units = `Units: ${units}\n`;
        } else if (this.aws?.yaxis) {
          const axis = this.aws?.yaxis;
          let index = 0;
          units = "";
          this.wds.streamOptions.forEach(stream => {
            if (stream.StreamKey) {
              if (stream.Params) {
                const name = names[index];
                const streamAxis = axis[stream.Params.yaxis];
                if (streamAxis.name) {
                  units += `'${name}' units: ${streamAxis.name}\n`;
                }
              }
              index++;
            }
          });
        } else {
          units = "";
        }
        let tagsPrepend = "";
        const includeParents = true;
        if (includeParents) {
          const tagsWithParents = await this.tagManagerStore.getStreamsTagsWithParents(streamKeys);
          let index = 0;
          this.wds.streamOptions.forEach(stream => {
            if (stream.StreamKey) {
              if (stream.Params) {
                let name = names[index];
                let item: NavTreeNodeMinimalWithParents | null | undefined = tagsWithParents[stream.StreamKey];
                while (item) {
                  const streamTags = (item ? item.Tags : []).filter(x => !x.includes("Ref=") && !x.endsWith("Ref"));
                  if (streamTags.length) {
                    tagsPrepend += `Project haystack tags applied to '${name}': ${streamTags.join(", ")}\n`;
                  }
                  if (item.Parent) {
                    tagsPrepend += `'${name}' is contained by '${item.Parent.Name}'\n`;
                    name = item.Parent.Name;
                  }
                  item = item.Parent;
                }
              }
              index++;
            }
          });
        } else {
          const tags = await this.tagManagerStore.getStreamsTags(streamKeys);
          let index = 0;
          this.wds.streamOptions.forEach(stream => {
            if (stream.StreamKey) {
              if (stream.Params) {
                const streamTags = (tags[stream.StreamKey] ? tags[stream.StreamKey] : []).filter(x => !x.includes("Ref=") && !x.endsWith("Ref"));
                if (streamTags.length) {
                  const name = names[index];
                  tagsPrepend += `Project haystack tags applied to '${name}': ${streamTags.join(", ")}\n`;
                }
              }
              index++;
            }
          });
        }
        this.bitpoolAIChatState.prependData =
          `Purpose of the data: ${this.bws?.widgetName}\nData in csv format.\nFirst column is Timestamp in format: YYYY-MM-DD HH:mm:ss.\nOther columns represent data points values.\n${units}${tagsPrepend}Data for analyze:\n${csvData}`;
        const initialMessage = question ? question : this.bitpoolAIChatState.initialMessage;
        // don't auto-send first message for localhost and local network
        if (location.hostname !== "localhost" && !location.hostname.startsWith("192.")) {
          await this.sendMessage(initialMessage);
        } else {
          this.bitpoolAIChatState.newMessage = initialMessage;
        }
      }
    }
  }

  get newMessage(): string {
    return this.bitpoolAIChatState.newMessage;
  }

  set newMessage(value: string) {
    this.bitpoolAIChatState.newMessage = value;
  }

  async sendMessage(message: string): Promise<void> {
    this.bitpoolAIChatState.newMessage = message;
    await this.bitpoolAIChatStore.sendMessage();
  }

  get visibleMessages(): ChatGPTHistory[] {
    return this.bitpoolAIChatState.data.filter(x => !x.hidden);
  }

  private async buildHtmlForCopy(message: ChatGPTHistory) {
    if (message.enableTyping) {
      message.enableTyping = false;
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    const messageElement = document.getElementById(`message-${message.timestamp}`);
    const messageBody = messageElement?.querySelector(".openai-comments-item-body");
    let result = "";
    if (messageBody && messageBody.childNodes.length) {
      for (let i = 0; i < messageBody.childNodes.length; i++) {
        const nodeTop = messageBody.childNodes[i] as HTMLElement;
        if (nodeTop.childNodes.length) {
          for (let j = 0; j < nodeTop.childNodes.length; j++) {
            const node = nodeTop.childNodes[j] as HTMLElement;

            // render highcharts as base64
            let base64 = "";
            if (node.classList?.contains("chart-element")) {
              const svg = node.querySelector("svg");
              if (svg) {
                const width = 2 * node.clientWidth;
                const height = 2 * node.clientHeight;
                base64 = await PrintHelper.svgToPng(width, height, svg.outerHTML, false, "#printCanvas");
              }
            }

            // add base64 or html to result
            if (base64) {
              // add image to result
              result += `<img src="${base64}" />`;
            } else {
              // add text to result
              const oh = node.outerHTML
              if (oh) {
                result += oh;
              }
            }
          }
        }
      }
    }
    if (result) {
      // remove all iframes
      result = this.removeIframes(result);
    }
    return result ?? message.message
  }

  removeIframes(html: string) {
    // Create a new regular expression to match iframe tags
    var iframeRegex = /<iframe(.*?)<\/iframe>/gi;

    // Replace all iframe tags with empty string
    return html.replace(iframeRegex, '');
  }

  async copyText(message: ChatGPTHistory): Promise<void> {
    try {
      const text = new DOMParser().parseFromString(message.message, 'text/html').body.textContent || "";
      const html = await this.buildHtmlForCopy(message);

      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([html], { type: 'text/html' }),
          'text/plain': new Blob([text], { type: 'text/plain' })
        })
      ]);
      ToastService.showToast("success", "", "Copied!", 5000);
    } catch (err) {
      ToastService.showToast("error", "", "Failed to copy HTML content", 5000);
    }
  }

  pdfGenerationInProgress = false;

  async printMessage(message: ChatGPTHistory): Promise<void> {
    try {
      const html = await this.buildHtmlForCopy(message);
      this.pdfGenerationInProgress = true;
      const rnd = secureRandomPassword.randomPassword(
        { 
          length: 5, 
          characters: [secureRandomPassword.upper, secureRandomPassword.digits] 
        }
      );
      const body: HtmlToPdfAIParameters = {
        Purpose: this.bws?.widgetName ?? "not specified",
        HtmlContent: html,
        Filename: `ai-report-${moment().format("YYYY-MM-DDTHH-mm-ss")}-${rnd}.pdf`,
        Date: moment().format("DD/MM/YYYY HH:mm")
      };
      const response = await this.bitpoolAIChatStore.printMessage(body);
      window.open(response, '_blank');
      ToastService.showToast("success", "", "Generated!", 5000);
    } catch (err) {
      ToastService.showToast("error", "", "Failed to generate Pdf", 5000);
    }
    finally {
      this.pdfGenerationInProgress = false;
    }
  }
}

export default BitpoolAIView;
</script>