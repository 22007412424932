<template>
  <div class="default-view-container">
    <div class="default-view-head-section">
      <h1 class="mb-0 mr-5">Color Themes</h1>
    </div>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Color Themes List</h2>
      </header>
      <div class="default-view-body">
        <DataTable
          :value="colorThemeStore.entities" 
          dataKey="Id"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="850px" 
          class="p-datatable-sm default-visual-table responsive-breakpoint color-themes-table">
          <template #header>
            <div class="table-header">
              <Button :disabled="!colorThemeStore.isLoaded" label="Add Theme" icon="pi pi-plus-circle" class="my-1" @click="openCreateUpdateDialog(null)" />
            </div>
          </template>
          <template #empty>
            <div v-if="colorThemeStore.isLoaded" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column field="Name" header="Name" headerStyle="min-width: min-content;" headerClass="no-break-word">
            <template #body="slotProps">
              <span class="block with-inline-btn">
                {{ slotProps.data.Name }}
                <span v-if="slotProps.data.OrganisationId === 0" v-tippy="'Public'" class="table-cell-icon">
                  <i class="pi pi-globe"></i>
                </span>
              </span>
            </template>
          </Column>
          <Column field="Description" header="Description"></Column>
          <Column field="Colors" header="Colors">
            <template #body="slotProps">
              <div class="select-bg-color-theme-colors mt-0">
                <div 
                  v-for="(themeColor, index) in slotProps.data.Colors" 
                  :key="index" 
                  :style="{backgroundColor: themeColor}"
                  @click="copyKey(themeColor)"
                  v-tippy="themeColor"
                  style="width: 3rem; height: 3rem; border-radius: .2857rem;"
                ></div>
              </div>
            </template>
          </Column>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div>
                <div class="inline-flex">
                  <Button 
                    icon="pi pi-clone" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openCloneDialog(slotProps.data)"
                    v-tippy="'Copy'"
                  />
                  <Button 
                    icon="pi pi-pencil" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openCreateUpdateDialog(slotProps.data)"
                    v-tippy="'Edit'"
                  />
                  <Button 
                    icon="pi pi-trash" 
                    class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                    @click="openConfirmation(slotProps.data)" 
                    :disabled="slotProps.data.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin"
                    v-tippy="'Delete'"
                  />
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
    <OrganisationColorsDialogView ref="colorThemeDialog"/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Message from 'primevue/message';
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import ToastService from "@/services/ToastService";
import copy from 'copy-to-clipboard';
import ConfirmationService from "@/services/ConfirmationService";
import { ColorThemeEntity } from "@/models/organisation/ColorThemeEntity";
import { useColorThemeStore } from "@/stores/colorTheme";
import ColorSelectView from "@/components/widgets-next/settings/ColorSelectView.vue";
import OrganisationColorsDialogView from "./OrganisationColorsDialogView.vue";

@Component({
  components: {
    Message,
    Button,
    DataTable,
    Column,
    ProgressSpinner,
    ColorSelectView,
    OrganisationColorsDialogView
  },
})
class OrganisationColorsView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  colorThemeStore = useColorThemeStore();

  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }

  // #region new/edit
  openCloneDialog(record: ColorThemeEntity): void {
    if (this.$refs.colorThemeDialog) {
      (this.$refs.colorThemeDialog as OrganisationColorsDialogView).openCloneDialog(record);
    }
  }

  openCreateUpdateDialog(record: ColorThemeEntity | null): void {
    if (this.$refs.colorThemeDialog) {
      (this.$refs.colorThemeDialog as OrganisationColorsDialogView).openCreateUpdateDialog(record);
    }
  }

  closeCreateUpdateDialog(): void {
    if (this.$refs.colorThemeDialog) {
      (this.$refs.colorThemeDialog as OrganisationColorsDialogView).closeCreateUpdateDialog();
    }
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: ColorThemeEntity | null = null;

  openConfirmation(record: ColorThemeEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete theme?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Theme',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.colorThemeStore.delete(this.selectedRecord);
    }
  }
  // #endregion delete
}

export default OrganisationColorsView;
</script>