import { defineStore } from 'pinia'
import DateHelper from "@/helpers/DateHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
import { CollectedDataInfo } from "@/models/CollectedDataInfo";
import ToastService from "@/services/ToastService";
import axios from "axios";
import localeManager from "@/localeManager";

interface LoginState {
  username: string;
  password: string;
  displaySection: string;
  loginSuccess: boolean;
  signupSuccess: boolean;
  collectedDataInfo?: CollectedDataInfo;
}

export const useLoginStore = defineStore('login', {
  state: (): LoginState => ({
    username: '',
    password: '',
    displaySection: 'login',
    loginSuccess: false,
    signupSuccess: false,
    collectedDataInfo: undefined
  }),
  
  actions: {
    setUsername(value: string) {
      this.username = value;
    },
    setPassword(value: string) {
      this.password = value;
    },
    setDisplaySection(value: string) {
      this.displaySection = value;
    },
    setLoginSuccess(value: boolean) {
      this.loginSuccess = value;
    },
    setSignupSuccess(value: boolean) {
      this.signupSuccess = value;
    },
    
    async loadCollectedDataInfo() {
      try {
        if (!this.collectedDataInfo) {
          const response = await axios.get<CollectedDataInfo>(
            "/rest/BitPool_V1/CollectedDataInfo"
          );
          if (response.data?.Date) {
            response.data.Date = DateHelper.parseFromMicrosoftString(
              response.data.Date as string
            );
          }
          this.collectedDataInfo = response.data;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          localeManager.t("login.loadCollectedDataInfoError"),
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    }
  }
});