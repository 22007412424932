<template>
  <div ng-non-bindable>
    <ninja-keys
      @selected="selected"
      @change="change"
      :placeholder="placeholder"
      :data="hotkeys"
    >
      <div slot="footer"></div>
    </ninja-keys>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import "ninja-keys";
import AuthState from "@/store/states/AuthState";
import ToastService from "@/services/ToastService";
import CustomWindow from '../CustomWindow';
import { useAISpeechStore } from "@/stores/aiSpeech";
import { useBitpoolAIChatStore } from "@/stores/bitpoolAIChat";
import localeManager from "@/localeManager";
import { useSystemStore } from "@/stores/system";

declare const window: CustomWindow;

@Component({
  components: {
  },
})
class HotKeyRoot extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  systemStore = useSystemStore();
  
  aiSpeechStore = useAISpeechStore();
  bitpoolAIChatStore = useBitpoolAIChatStore();
  
  placeholder = "Type a command or search";

  get hotkeys(): any[] {
    const result: any[] = [];
    if (this.authState.authKey && !this.authState.jailMode) {
      if (this.authState.permissions?.BitpoolAdmin) {
        // result.push({
        //   id: "Admin",
        //   title: "Admin",
        //   handler: () => {
        //     NavigationHelper.goTo("/data/admin");
        //   },
        //   keywords: "Admin"
        // });
        result.push({
          id: "Enable: Save Dashboard",
          title: "Enable: Save Dashboard",
          handler: () => {
            this.$store.commit("auth/setEnableSaveDashboard", true);
            ToastService.showToast("success", "Dev Options", "Enable: Save Dashboard", 5000);
          },
          keywords: "Enable: Save Dashboard"
        });
        result.push({
          id: "Disable: Save Dashboard",
          title: "Disable: Save Dashboard",
          handler: () => {
            this.$store.commit("auth/setEnableSaveDashboard", false);
            ToastService.showToast("success", "Dev Options", "Disable: Save Dashboard", 5000);
          },
          keywords: "Disable: Save Dashboard"
        });
        result.push({
          id: "Enable: Debug App Height",
          title: "Enable: Debug App Height",
          handler: () => {
            this.systemStore.enableDevOption("appHeight");
            ToastService.showToast("success", "Dev Options", "Enable: Debug App Height", 5000);
          },
          keywords: "Enable: Debug App Height"
        });
        result.push({
          id: "Disable: Debug App Height",
          title: "Disable: Debug App Height",
          handler: () => {
            this.systemStore.disableDevOption("appHeight");
            ToastService.showToast("success", "Dev Options", "Disable: Debug App Height", 5000);
          },
          keywords: "Disable: Debug App Height"
        });
        result.push({
          id: "Enable: Christmas",
          title: "Enable: Christmas",
          handler: () => {
            this.systemStore.enableDevOption("isChristmas");
            ToastService.showToast("success", "Dev Options", "Enable: Christmas", 5000);
          },
          keywords: "Enable: Christmas"
        });
        result.push({
          id: "Disable: Christmas",
          title: "Disable: Christmas",
          handler: () => {
            this.systemStore.disableDevOption("isChristmas");
            ToastService.showToast("success", "Dev Options", "Disable: Christmas", 5000);
          },
          keywords: "Disable: Christmas"
        });
        result.push({
          id: "Enable: Speech",
          title: "Enable: Speech",
          handler: () => {
            this.aiSpeechStore.isGloballyDisabled = false;
            ToastService.showToast("success", "Dev Options", "Enable: Speech", 5000);
          },
          keywords: "Enable: Speech"
        });
        result.push({
          id: "Disable: Speech",
          title: "Disable: Speech",
          handler: () => {
            this.aiSpeechStore.isGloballyDisabled = true;
            ToastService.showToast("success", "Dev Options", "Disable: Speech", 5000);
          },
          keywords: "Disable: Speech"
        });
        result.push({
          id: "Enable: AI dev stub",
          title: "Enable: AI dev stub",
          handler: () => {
            this.bitpoolAIChatStore.devStub = true;
            ToastService.showToast("success", "Dev Options", "Enable: AI dev stub", 5000);
          },
          keywords: "Enable: AI dev stub"
        });
        result.push({
          id: "Disable: AI dev stub",
          title: "Disable: AI dev stub",
          handler: () => {
            this.bitpoolAIChatStore.devStub = false;
            ToastService.showToast("success", "Dev Options", "Disable: AI dev stub", 5000);
          },
          keywords: "Disable: AI dev stub"
        });
        result.push({
          id: "Enable: Bitpool AI Realtime",
          title: "Enable: Bitpool AI Realtime",
          handler: () => {
            this.systemStore.enableDevOption("bitpoolAIReatime");
            ToastService.showToast("success", "Dev Options", "Enable: Bitpool AI Realtime", 5000);
          },
          keywords: "Enable: Bitpool AI Realtime"
        });
        result.push({
          id: "Disable: Bitpool AI Realtime",
          title: "Disable: Bitpool AI Realtime",
          handler: () => {
            this.systemStore.disableDevOption("bitpoolAIReatime");
            ToastService.showToast("success", "Dev Options", "Disable: Bitpool AI Realtime", 5000);
          },
          keywords: "Disable: Bitpool AI Realtime"
        });
      }
    }
    result.push({
      id: "English",
      title: "Language: English",
      handler: () => {
        localeManager.changeLocale("en");
        ToastService.showToast("success", "Dev Options", "English", 5000);
      }
    });
    result.push({
      id: "German",
      title: "Language: German",
      handler: () => {
        localeManager.changeLocale("de");
        ToastService.showToast("success", "Dev Options", "German", 5000);
      }
    });
    result.push({
      id: "Light",
      title: "Theme: Light",
      handler: () => {
        if (this.authState.userSettings) {
          this.authState.userSettings.darkTheme = false;
        }
        window.switchTheme(false);
        ToastService.showToast("success", "Dev Options", "Light", 5000);
      }
    });
    result.push({
      id: "Dark",
      title: "Theme: Dark",
      handler: () => {
        if (this.authState.userSettings) {
          this.authState.userSettings.darkTheme = true;
        }
        window.switchTheme(true);
        ToastService.showToast("success", "Dev Options", "Dark", 5000);
      }
    });
    return result;
  }

  mounted(): void {
    window.showQuickActions = this.showQuickActions;
  }

  showQuickActions(): void {
    const ninja = document.querySelector('ninja-keys') as any;
    ninja.open();
  }

  selected(event: any) {
    //console.log("Quick menu - selected", event.detail);
  }

  change(event: any) {
    //console.log("Quick menu - change", event.detail);
  }
}

export default HotKeyRoot;
</script>

<style lang="css" scoped>
  ninja-keys {
    --ninja-z-index: 100;
  }
</style>