class FontHelper {
  public readonly fontFamilies = ['Poppins', 'Exo 2'];
  public readonly fontSizes = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60];
  public readonly fontSizesWithLabel = this.fontSizes.map(size => ({ value: size, label: `${size}px` }));
  public readonly fontWeights = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];
  public readonly fontStyles = ['normal', 'italic'];
  public readonly textAligns = ['left', 'center', 'right', 'justify'];
  public readonly textAlignsWithIcons = this.textAligns.map(x => ({ value: x, icon: `pi pi-align-${x}` }));
  public readonly textAlignsVertical = ['flex-start', 'center', 'flex-end'];
  public readonly textAlignsVerticalWithIcons = [
    { value: 'flex-start', icon: `report-constructor-settings-align-element report-constructor-settings-align-element-top`, label: 'top' },
    { value: 'center', icon: `report-constructor-settings-align-element report-constructor-settings-align-element-center`, label: 'center' },
    { value: 'flex-end', icon: `report-constructor-settings-align-element report-constructor-settings-align-element-bottom`, label: 'bottom' }
  ];
}

export default new FontHelper();