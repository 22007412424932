<template>
  <div>
    <DataTable
      :value="webhookServerStore.entities" 
      v-model:expandedRows="expandedRows" 
      dataKey="Id"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint webhook-server-table">
      <template #header>
        <div class="table-header">
          <Button label="Add Configuration" icon="pi pi-plus-circle" class="my-1" @click="openCreateUpdateDialog(null)" />
        </div>
      </template>
      <template #empty>
        <div v-if="webhookServerStore.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 48px;" bodyClass="no-label-in-stack">
        <template #body="slotProps">
          <div class="inline-flex expand-toggler-btn">
            <Button :icon="(preCloseState.findIndex(x => x === slotProps.data.Id) >= 0 || !expandedRows[slotProps.data.Id]) ? 'pi pi-chevron-right' : 'pi pi-chevron-down'" class="p-button-icon-only p-button-rounded p-button-text p-button-secondary" @click="toggleRow(slotProps.data)" />
          </div>
        </template>
      </Column>
      <Column field="Enabled" header="Enabled" headerStyle="width: 1%; min-width: 65px;">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.Enabled ? 'Yes' : 'No' }}
          </div>
        </template>
      </Column>
      <Column field="Name" header="Name" headerStyle="width: 20%; min-width: min-content;" headerClass="no-break-word"></Column>
      <Column field="Description" header="Description" headerStyle="width: 30%; min-width: min-content;" headerClass="no-break-word"></Column>
      <Column field="Organisation" header="Organisation" headerStyle="width: 20%; min-width: min-content;" headerClass="no-break-word"></Column>
      <Column field="Pool" header="Pool" headerStyle="width: 20%; min-width: min-content;" headerClass="no-break-word"></Column>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 169px;" bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <div>
            <div class="inline-flex" v-tippy="{ content: (currentOrganisation?.Name === slotProps.data.Organisation && (userId === slotProps.data.UserId || authState.permissions && authState.permissions.FullAccess)) ? '' : (userId === slotProps.data.UserId || authState.permissions && authState.permissions.FullAccess) ? `Please switch to '${slotProps.data.Organisation}' organisation` : 'Only Owner and CoOwner can clone this record' }">
              <Button 
                icon="pi pi-book" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openLogsDialog(slotProps.data)"
                :disabled="currentOrganisation?.Name !== slotProps.data.Organisation || (userId !== slotProps.data.UserId && !(authState.permissions && authState.permissions.FullAccess))"
              />
              <Button 
                icon="pi pi-clone" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCloneDialog(slotProps.data)" 
                :disabled="currentOrganisation?.Name !== slotProps.data.Organisation || (userId !== slotProps.data.UserId && !(authState.permissions && authState.permissions.FullAccess))"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCreateUpdateDialog(slotProps.data)" 
                :disabled="currentOrganisation?.Name !== slotProps.data.Organisation || (userId !== slotProps.data.UserId && !(authState.permissions && authState.permissions.FullAccess))"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                :disabled="currentOrganisation?.Name !== slotProps.data.Organisation || (userId !== slotProps.data.UserId && !(authState.permissions && authState.permissions.FullAccess))"
              />
            </div>
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <transition name="p-toggleable-content" appear>
          <ul class="webhook-table-other-fields" v-if="preCloseState.findIndex(x => x === slotProps.data.Id) < 0">
            <li class="pt-3">
              <span><b>Webhook</b></span>
              <span class="flex align-items-center">
                <span class="inline-block with-inline-btn">
                  <span class="break-word">{{getLink(slotProps.data)}}</span>
                  <Button
                    icon="pi pi-copy text-lg" 
                    class="p-button-icon-only p-button-rounded p-button-text" 
                    @click="copyKey(getLink(slotProps.data))"
                  />
                </span>
              </span>
            </li>
            <li class="my-3 sm:my-0" v-if="slotProps.data.Secret">
              <span><b>Secret</b></span>
              <span class="flex align-items-center"><HiddenTextView :text="slotProps.data.Secret" :copy="true"/></span>
            </li>
            <li>
              <span><b>Script</b></span>
              <span class="flex align-items-center">
                <span class="inline-block with-inline-btn">
                  <span class="break-word">{{getScriptName(slotProps.data.ScriptId)}}</span>
                </span>
              </span>
            </li>                                
          </ul>
        </transition>
      </template>
    </DataTable>

    <Dialog header="Webhook Configuration" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="webhook-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <div class="field col-fixed mr-5 pt-1">
              <div class="flex align-items-center">
                <InputSwitch 
                  inputId="editRecordClean"
                  v-model="editRecord.Enabled"
                  class="vertical-align-top"
                />
                <label for="editRecordClean" class="mb-0 ml-2">Enabled</label>
              </div>
            </div>
            <div class="field col-fixed mr-5 sm:mr-3 pt-1">
              <div class="flex align-items-center">
                <InputSwitch 
                  inputId="editRecordNewPool"
                  v-model="isNewPool"
                  @change="onIsNewPoolChange"
                  class="vertical-align-top"
                />
                <label for="editRecordNewPool" class="mb-0 ml-2">New Pool</label>
              </div>
            </div>
            <div class="field col-fixed sm:ml-auto pt-1">
              <div class="flex align-items-center">
                <InputSwitch 
                  inputId="editRecordDebug"
                  v-model="editRecord.Debug"
                  class="vertical-align-top"
                  @change="onDebugChange"
                />
                <label for="editRecordDebug" class="mb-0 ml-2">Debug</label>
              </div>
            </div>
            <div v-if="isNewPool" class="field col-12">
              <label for="editRecordPool">New Pool Name</label>
              <div>
                <InputText 
                  id="editRecordPool" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Pool"
                />
              </div>
            </div>
            <div v-else class="field col-12">
              <label for="editRecordEndpointPool">Select Pool</label>
              <div>
                <Dropdown 
                  id="editRecordEndpointPool" 
                  v-model="selectedPool" 
                  @change="onSelectPool" 
                  :options="pools" 
                  optionLabel="Name"
                  placeholder="Select pool" 
                  :filter="true" 
                  filterPlaceholder="Find pool" 
                  class="w-full dropdown-lg"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="editRecordName">Name</label>
              <div>
                <InputText 
                  id="editRecordName" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Name"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="editRecordDescription">Description</label>
              <div>
                <InputText 
                  id="editRecordDescription" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Description"
                />
              </div>
            </div>
            <div class="field col-12">
              <label for="editRecordWebhook">Webhook</label>
              <div class="p-inputgroup flex-1">
                <InputText
                  id="editRecordWebhook"
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="link"
                  :disabled="true"
                />
                <Button icon="pi pi-copy text-2xl" class="w-4rem" @click="copyKey(link)" />
              </div>
            </div>
            <div class="field col-12 md:col-6 md:mb-0">
              <label for="editRecordSecret">Secret</label>
              <div class="p-inputgroup flex-1">
                <InputText
                  id="editRecordSecret"
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Secret"
                  :disabled="true"
                />
                <Button icon="pi pi-refresh text-2xl" class="w-4rem" @click="editRecord.Secret = generatePassword()" />
              </div>
            </div>
            <div class="field col-12 md:col-6 mb-0">
              <label for="editRecordScript">Script</label>
              <div>
                <Dropdown 
                  id="editRecordScript" 
                  v-model="editRecord.ScriptId"
                  @change="onSelectScript" 
                  v-if="webhookScriptStore.scripts"
                  :options="webhookScriptStore.scripts" 
                  optionLabel="Name"
                  optionValue="Id"
                  placeholder="Select script" 
                  :filter="true" 
                  filterPlaceholder="Find script" 
                  class="w-full dropdown-lg"
                />
              </div>
            </div>
          </div>
          <div v-if="editRecordScript?.AdditionalParameters.length" class="formgrid grid">
            <div 
              class="field col-12 md:col-6 mb-0 field-margin-top flex flex-column" 
              v-for="(additionalParameter, index) in editRecordScript.AdditionalParameters" 
              :key="index"
            >
              <label :for="`editRecordParam-${additionalParameter.Name}`" class="flex-shrink-0">
                {{ additionalParameter.Label }}
              </label>
              <div class="flex-auto flex align-items-center">
                <InputText 
                  v-if="additionalParameter.Type === ScriptAdditionalParameterType.String"
                  :id="`editRecordParam-${additionalParameter.Name}`"
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.AdditionalParameters[additionalParameter.Name]"
                />
                <InputNumber
                  v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Number"
                  :id="`editRecordParam-${additionalParameter.Name}`"
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.AdditionalParameters[additionalParameter.Name]"
                  :minFractionDigits="2"
                  :maxFractionDigits="20"
                />
                <InputSwitch
                  v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Boolean"
                  :id="`editRecordParam-${additionalParameter.Name}`"
                  v-model="editRecord.AdditionalParameters[additionalParameter.Name]"
                  class="vertical-align-middle"
                />
              </div>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button v-if="editRecord" :label="editRecord.Id ? 'Update' : 'Create'" :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveRecord" :disabled='!editRecord.Name || !editRecord.Secret || !editRecord.Pool || !editRecord.ScriptId || updateInProgress' />
          </span>
        </div>
      </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="displayConfirmation" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="flex align-items-center">
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete record?</span>
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary"/>
        <Button label="Yes" icon="pi pi-check" @click="deleteRecord" />
      </template>
    </Dialog>

    <Dialog header="Logs" v-model:visible="displayLogsDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="connection-history-config-dialog">
      <div class="dialog-content" v-if="selectedRecord">
        <ConnectionHistoryView v-model="selectedRecord.Id" :type="logsType"/>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import InlineMessage from 'primevue/inlinemessage';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import SelectButton from 'primevue/selectbutton';
import { PoolModelLite } from "@/models/PoolModelLite";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import HiddenTextView from "@/components/views/HiddenTextView.vue";
import CustomWindow from '@/CustomWindow';
import AuthState from "@/store/states/AuthState";
import ToastService from "@/services/ToastService";
import copy from 'copy-to-clipboard';
import  secureRandomPassword from 'secure-random-password';
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { useOrganisationStore } from "@/stores/organisation";
import { ConnectionHistoryType } from "@/models/connection/ConnectionHistoryType";
import ConnectionHistoryView from "@/components/views/connections/ConnectionHistoryView.vue";
import { useWebhookServerStore } from "@/stores/webhookServer";
import { WebhookServerConfig } from "@/models/webhooks/WebhookServerConfig";
import { useWebhookScriptStore } from "@/stores/webhookScript";
import { WebhookScriptEntity } from "@/models/webhooks/WebhookScriptEntity";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

declare const window: CustomWindow;

@Component({
  
  components: {
    Button,
    InputText,
    InputNumber,
    Dropdown,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    InlineMessage,
    Message,
    ProgressSpinner,
    SelectButton,
    HiddenTextView,
    ConnectionHistoryView
  },
  directives: {
  }
})
class WebhooksServerView extends Vue { 
  webhookServerStore = useWebhookServerStore();
  webhookScriptStore = useWebhookScriptStore();

  get userId(): string {
    const userData = this.allUserData;
    const result = userData && userData.userData ? userData.userData.UserId : "";
    return result;
  }

  expandedRows: Record<string, boolean> = {};
  preCloseState: string[] = [];

  toggleRow(row: WebhookServerConfig): void {
    if (this.expandedRows[row.Id]) {
      // close
      this.preCloseState.push(row.Id);
      window.setTimeout(() => {
        const idIndex = this.preCloseState.findIndex(x => x === row.Id);
        if (idIndex >= 0 && this.expandedRows[row.Id]) {
          const newExpandedRows = { ...this.expandedRows };
          delete newExpandedRows[row.Id];
          this.expandedRows = newExpandedRows;
          this.preCloseState.splice(idIndex, 1);
        }
      }, 450);
    } else {
      // open
      const idIndex = this.preCloseState.findIndex(x => x === row.Id);
      if (idIndex >= 0) {
        this.preCloseState.splice(idIndex, 1);
      }
      this.expandedRows = { ...this.expandedRows, [row.Id]: true };
    }
  }

  created(): void {
    if (this.authState.permissions?.FullAccess) {
      this.webhookServerStore.load();
      this.$store.dispatch("pools/loadPoolsList");
    }
  }

  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }

  getScriptName(scriptId: string): string {
    const script = this.webhookScriptStore.scripts?.find(x => x.Id === scriptId);
    return script ? script.Name : "-";
  }

  // #region new/edit
  displayCreateUpdateDialog = false;
  editRecord: WebhookServerConfig | null = null;
  selectedPool: PoolModelLite | null | undefined = null;
  isNewPool = false;

  get editRecordScript(): WebhookScriptEntity | null | undefined {
    return this.webhookScriptStore.scripts?.find(x => x.Id === this.editRecord?.ScriptId);
  }

  get pools(): PoolModelLite[] {
    const pools = this.$store.state.pools.poolsList && this.$store.state.pools.poolsList.Pools ?
      this.$store.state.pools.poolsList.Pools as PoolModelLite[] :
      [];
    return pools;
  }

  types = [
    {name: 'Interval', key: 'Interval'},
    {name: 'COV', key: 'COV'}
  ];
  
  onSelectPool(event: any): void {
    // event.originalEvent: Original event
    // event.value: Selected option value
    if (this.editRecord) {
      this.editRecord.PoolKey = event.value.Id;
      this.editRecord.Pool = event.value.Name;
    } 
  }

  onIsNewPoolChange(): void {
    if (this.editRecord) {
      this.editRecord.PoolKey = "";
      this.editRecord.Pool = "";
    }
    this.selectedPool = null;
  }

  onDebugChange(): void {
    if (this.editRecord) {
      if (this.editRecord.Debug) {
        this.editRecord.DebugEnd = `${moment().utc().add(1, "hours").format("YYYY-MM-DDTHH:mm:ss")}Z`;
      } else {
        this.editRecord.DebugEnd = null;
      }
    }
  }

  onSelectScript(event: any): void {
    // event.originalEvent: Original event
    // event.value: Selected option value
    if (this.editRecord) {
      const script = this.webhookScriptStore.scripts?.find(x => x.Id === event.value);
      if (script) {
        this.prepareAdditionalParameters(this.editRecord, script);
      }
    } 
  }

  ScriptAdditionalParameterType = ScriptAdditionalParameterType;

  prepareAdditionalParameters(config: WebhookServerConfig, script: WebhookScriptEntity): void {
    // romeve filds
    for (const key in config.AdditionalParameters) {
      if (!script.AdditionalParameters.some(x => x.Name === key)) {
        delete config.AdditionalParameters[key];
      }
    }
    // add fields
    script.AdditionalParameters.forEach(x => {
      if (typeof config.AdditionalParameters[x.Name] === "undefined") {
        config.AdditionalParameters[x.Name] = x.DefaultValue;
      }
    });
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get link(): string {
    return this.editRecord?.Webhook ? this.getLink(this.editRecord) : "";
  }

  getLink(record: WebhookServerConfig): string {
    const host = location.hostname.includes("localhost") ?
      "https://localhost:7006" : 
      (location.hostname.includes("beta") || location.hostname.includes("test")) ? 
        "https://dev.api.bitpool.com" :
        "https://api.bitpool.com";
    return `${host}/rest/webhook/${record.Webhook}`;
  }

  generatePassword(): string {
    return secureRandomPassword.randomPassword();
  }

  openCloneDialog(record: WebhookServerConfig): void {
    const newRecord: WebhookServerConfig = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    newRecord.Secret = this.generatePassword(),
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: WebhookServerConfig | null): void {
    this.selectedPool = record ? this.pools.find(x => x.Id === record.PoolKey) : null;
    this.editRecord = record ? Object.assign({}, record) : {
      Id: "",
      Name: "",
      Description: "",
      Secret: this.generatePassword(),
      Organisation: this.currentOrganisation ? this.currentOrganisation.Name : "",
      OrganisationId: this.currentOrganisation ? this.currentOrganisation.Id : -1,
      UserId: this.allUserData.userData.UserId,
      Pool: "",
      PoolKey: "",
      Enabled: true,
      ScriptId: "",
      AdditionalParameters: {},
      Webhook: uuidv4().replaceAll("-", ""),
      Debug: false,
      DebugEnd: null
    };
    if (this.editRecord && this.editRecordScript) {
      this.prepareAdditionalParameters(this.editRecord, this.editRecordScript);
    }
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.webhookServerStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.webhookServerStore.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.webhookServerStore.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }
  // #endregion new/edit

  // #region delete
  displayConfirmation = false;
  selectedRecord: WebhookServerConfig | null = null;

  openConfirmation(record: WebhookServerConfig | null): void {
    this.selectedRecord = record;
    this.displayConfirmation = true;
  }

  closeConfirmation(): void {
    this.displayConfirmation = false;
  }

  deleteRecord(): void {
    this.closeConfirmation();
    if (this.selectedRecord) {
      this.webhookServerStore.delete(this.selectedRecord);
    }
  }
  // #endregion delete

  // #region logs
  displayLogsDialog = false;
  logsType = ConnectionHistoryType.WebhookInternal;

  openLogsDialog(record: WebhookServerConfig | null): void {
    this.selectedRecord = record;
    this.displayLogsDialog = true;
  }
  // #endregion logs
}

export default WebhooksServerView;
</script>