<template>
  <section :class="notification.IsRead ? '' : 'notification-unread'">
    <div class="notification-content flex-auto">
      <div class="notification-head flex align-items-start">
        <div class="flex align-items-center">
          <i class="notification-read-mark" :class="notification.IsRead ? '' : 'bg-primary'"></i>
          <Checkbox v-if="selectable" v-model="isSelected" :binary="true" />
        </div>
        <div class="flex flex-wrap align-items-start flex-auto" style="gap: .7142rem;">
          <h4 class="mb-0 flex-shrink-0 w-full">{{ notification.Title }}</h4>
          <div class="notification-groups-list flex flex-wrap align-items-center flex-auto" v-if="notification.NotificationGroupsIds?.length">
            <span 
              v-for="notificationGroupId in notification.NotificationGroupsIds" 
              :key="notificationGroupId"
              class="notification-badge group-badge"
              :style="getChipStylesByGroupId(notificationGroupId)"
            >
              {{ getNotificationGroupName(notificationGroupId) }}
            </span>
          </div>
        </div>
            
        <Button
          icon="pi pi-ellipsis-h text-2xl" 
          @click="toggleActionsMenu"
          class="p-button-text p-button-text-neutral p-button-icon-only p-button-rounded flex-shrink-0 ml-auto"
        />
        <Menu 
          id="notification_actions" 
          ref="notification_actions" 
          :model="actionsMenuItems" 
          :popup="true" 
        />
      </div>
      <div class="notification-body">
        <div 
          v-for="(message, index) in notification.Messages"
          :key="index"
        >
          <span class="notification-badge" :class="{ 'notification-badge-alert': isRed(message) }">
            {{ message.Subtitle }}
          </span>
          <div class="notification-show-text">
            <Button 
              link
              :label="showDetails[`field_${index}`] ? 'Hide details' : 'Show details'" 
              :icon="showDetails[`field_${index}`] ? 'pi pi-chevron-up ml-auto' : 'pi pi-chevron-down ml-auto'"
              iconPos="right"
              @click="showDetails[`field_${index}`] = !showDetails[`field_${index}`]" 
              class="notification-show-text-link"
            />
          
            <Transition name="p-toggleable-content">
              <div class="notification-text" v-if="showDetails[`field_${index}`]" v-html="markdownToHtml(message.Body)">
              </div>
            </Transition>
          </div>
        </div>
        <span class="notification-date flex-shrink-0 text-sm mr-1 sm:mr-3"><DateTimezoneView :date="notification.Date" timezone="local"/></span>
        <div v-if="notification.Files?.length > 0" class="notification-files">
          <div
            v-for="(file, index) in notification.Files"
            :key="index"
            class="mt-2"
          >
            <a :href="file" target="_blank"><i class="pi pi-paperclip text-base"></i>{{ getFilename(file) }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import NavigationHelper from "@/helpers/NavigationHelper";
import { NotificationEntity } from "@/models/notification/NotificationEntity";
import { NotificationSourceType } from "@/models/notification/NotificationSourceType";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import ToastService from "@/services/ToastService";
import { useOrganisationStore } from "@/stores/organisation";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { Component, Prop, Vue } from "vue-facing-decorator";
import CustomWindow from '@/CustomWindow';
import { useNotificationStore } from "@/stores/notification";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Menu from "primevue/menu";
import { MenuItem } from "primevue/menuitem";
import ConfirmationService from "@/services/ConfirmationService";
import { reactive } from "vue";
import { NotificationMessage } from "@/models/notification/NotificationMessage";
import { useNotificationGroupStore } from "@/stores/notificationGroup";
import chroma from "chroma-js";
import ColorHelper from "@/helpers/ColorHelper";
import { NotificationGroupEntity } from "@/models/notification/NotificationGroupEntity";

declare const window: CustomWindow;

@Component({
  components: {
    Button,
    Checkbox,
    Menu,
    DateTimezoneView
  },
})
class NotificationsItemView extends Vue { 
  @Prop({ required: true }) notification!: NotificationEntity;
  @Prop({ required: false, default: true }) selectable!: boolean;
  @Prop({ required: false, default: undefined }) afterNavigateTo!: undefined | (() => void);
  
  notificationStore = useNotificationStore();
  organisationStore = useOrganisationStore();
  notificationGroupStore = useNotificationGroupStore();

  showDetails: Record<string, boolean> = {};

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get selectedNotifications(): Record<string, NotificationEntity> {
    return this.notificationStore.selectedNotifications;
  }

  set selectedNotifications(value: Record<string, NotificationEntity>) {
    this.notificationStore.selectedNotifications = value;
  }

  get isSelected(): boolean {
    return !!this.selectedNotifications[this.notification.Id];
  }

  set isSelected(value: boolean) {
    if (value) {
      this.selectedNotifications[this.notification.Id] = this.notification;
    } else {
      delete this.selectedNotifications[this.notification.Id];
    }
  }

  created(): void {
    this.notification.Messages.forEach((message, index) => {
      this.showDetails[`field_${index}`] = false;
    })
  }
  
  markdownToHtml(markdown: string): string {
    const result = DOMPurify.sanitize(marked.parse(markdown));
    return result;
  }

  getFilename(url: string): string {
    let filename = url.substring(url.lastIndexOf("/") + 1);
    // remove guid from filename
    if (filename.length > 37 && filename[36] === "_") {
      return filename.substring(37, filename.length);
    } else {
      return filename;
    }
  }

  getNotificationButtonLabel(notification: NotificationEntity): string {
    if (notification.Source.Type === NotificationSourceType.Flow) {}
    switch (notification.Source.Type) {
      case NotificationSourceType.Flow:
        return "Open Flow";
      case NotificationSourceType.DashboardComment:
        return "Open Dashboard";
      case NotificationSourceType.ReportSchedule:
        return "Open Report Manager";
      default:
        return "";
    }
  }

  async openNotification(notification: NotificationEntity): Promise<void> {
    let url: string;
    switch (notification.Source.Type) {
      case NotificationSourceType.Flow:
        url = `/data/flow/${notification.Source.Id}`;
        break;
      case NotificationSourceType.DashboardComment:
        url = `/dashboards?pane=organisation&id=${notification.Source.Id}`;
        break;
      case NotificationSourceType.ReportSchedule:
        url = `/report-manager`;
        break;
      default:
        url = "";
        break;
    }
    if (url && this.currentOrganisation) {
      if (notification.OrganisationId !== this.currentOrganisation.Id) {
        const organisation = this.organisationStore.entities?.find(x => x.Id === notification.OrganisationId);
        if (organisation) {
          await window.angularInitService.switchOrganisation(organisation);
        } else {
          ToastService.showToast(
            "error",
            "Onisation not found",
            "",
            5000
          );
          url = "";
        }
      }
      if (url) {
        NavigationHelper.goTo(url);
        if (this.afterNavigateTo) {
          this.afterNavigateTo();
        }
      }
    }
  }
  
  markAs(notification: NotificationEntity, value: "read" | "unread"): void {
    this.notificationStore.markAs([notification], value);
  }

  delete(): void {
    const message = `Are you sure you want to delete notification?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Notification',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        this.notificationStore.delete([this.notification]);
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  toggleActionsMenu(event: Event): void {
    if (this.$refs.notification_actions) {
      (this.$refs.notification_actions as Menu).toggle(event);
    }
  }

  get actionsMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    result.push({ 
      label: this.notification.IsRead ? 'Mark as Unread' : 'Mark as Read',
      command: () => this.markAs(this.notification, this.notification.IsRead ? 'unread' : 'read')
    });
    result.push({ 
      label: this.getNotificationButtonLabel(this.notification),
      command: () => this.openNotification(this.notification), 
      visible: !!this.getNotificationButtonLabel(this.notification)
    });
    result.push({ 
      label: "Delete notification",
      class: "delete-item",
      command: () => this.delete(), 
    });
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  isRed(message: NotificationMessage): boolean {
    const redSubtitles: string[] = [
      "Out of Limits",
      "Data Anomaly",
      "Data Quality",
      "Tag Value"
    ];
    return redSubtitles.includes(message.Subtitle);
  }

  getNotificationGroupName(notificationGroupId: string): string {
    const item = this.notificationGroupStore.entitiesUser?.find(x => x.Id === notificationGroupId);
    if (item) {
      return item.Name;
    } else {
      return notificationGroupId;
    }
  }

  getChipStylesByGroupId(notificationGroupId: string): string {
    const item = this.notificationGroupStore.entitiesUser?.find(x => x.Id === notificationGroupId);
    if (item) {
      return this.getChipStyles(item);
    } else {
      return "";
    }
  }

  getChipStyles(item: NotificationGroupEntity): string {
    let result: string = "";
    if (item.Color) {
      const colorRGB = chroma.hex(item.Color).rgb();
      result = `--colored-chip-selected-bg-color: ${colorRGB[0]}, ${colorRGB[1]}, ${colorRGB[2]}; --colored-chip-selected-bg-color-with-opacity: rgba(var(--colored-chip-selected-bg-color), var(--colored-chip-selected-bg-color-opacity));`;
      const contrastColor = ColorHelper.getContrastColor(item.Color);
      result = `${result}color: ${contrastColor};`;
    }
    return result;
  }
}

export default NotificationsItemView;
</script>