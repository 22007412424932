<template>
  <div 
    v-if="isLoaded && errorMessage" 
    class="report-data-error"
  >
    <i class="pi pi-exclamation-triangle text-2xl" v-tippy="errorMessage"></i>
  </div>
  <div 
    v-show="isLoaded" 
    ref="htmlPreview" 
    class="html-preview-shadow-root"
    :style="`padding-top: ${configuration?.MarginTop ?? 0}cm; padding-bottom: ${configuration?.MarginBottom ?? 0}cm; padding-left: ${configuration?.MarginLeft ?? 0}cm; padding-right: ${configuration?.MarginRight ?? 0}cm;`"
  >
  </div>
  <div v-if="!isLoaded" class="report-loading">
    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import ReportHtmlHelper from "@/helpers/ReportHtmlHelper";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { debounce } from "throttle-debounce";
import { nextTick } from "vue";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import { useReports3Store } from "@/stores/reports3";
import Highcharts from 'highcharts';
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import { useOrganisationStore } from "@/stores/organisation";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { useReports3DataStore } from "@/stores/reports3Data";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import ProgressSpinner from 'primevue/progressspinner';
import { Report3ElementFeatures, Report3ElementFeaturesToString } from "@/models/reports/v3/Report3ElementFeatures";
import TextHelper from "@/helpers/TextHelper";
import StreamOption from "@/models/dashboard/StreamOption";
import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import DOMPurify from "dompurify";
import { marked } from "marked";
import DateHelper from "@/helpers/DateHelper";
import DataHelper from "@/helpers/DataHelper";

@Component({
  components: {
    ProgressSpinner
  },
  directives: {
  }
})
class ReportsHtmlElementView extends Vue { 
  @Prop({ required: true }) element!: Reports3ElementEntity;
  @Prop({ required: false, default: null }) configuration!: Reports3ElementConfiguration | null;
  @Prop({ required: false, default: null }) report!: Reports3Entity | null;

  reports3Store = useReports3Store();
  reports3DataStore = useReports3DataStore();
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  @Watch('element', { immediate: false, deep: true })
  @Watch('configuration', { immediate: false, deep: true })
  async onElementChanged(val: string, oldVal: string): Promise<void> {
    this.debouncePreview();
  }

  @Watch('isLoaded', { immediate: false, deep: true })
  async onIsLoaded(val: boolean, oldVal: boolean): Promise<void> {
    if (val) {
      this.generatePreview();
    } else {
      this.cleanupPreview();
    }
  }

  debouncePreview = debounce(1250, this.generatePreview);

  mounted() {
    this.generatePreview();
  }

  shadowRoot: ShadowRoot | null = null;
  errorMessage = "";

  get isLoaded(): boolean {
    const dataIdentifier = this.reports3DataStore.buildDataIdentifier(this.element, this.configuration, this.datasource, this.report);
    const aiDataIdentifier = this.reports3DataStore.buildAIDataIdentifier(this.element, this.configuration, this.datasource, this.report);
    const result = !!this.reports3DataStore.isLoaded[dataIdentifier] && !!this.reports3DataStore.isLoadedAiInsights[aiDataIdentifier];
    return result || !this.element.EnableData || !!this.configuration && !this.configuration.DatasourceId;
  }

  get datasource(): Reports3Datasource | null {
    let result = null;
    if (this.report && this.configuration && this.element.EnableData) {
      const ds = this.report.Datasources.find(x => x.Uid === this.configuration?.DatasourceId);
      if (ds) {
        result = ds;
      }
    } else if (!this.report && !this.configuration && this.element.EnableData) {
      result = this.reports3DataStore.buildStubDataConfiguration();
    }
    return result;
  }

  cleanupPreview(): void {
    const htmlPreview = this.$refs.htmlPreview as HTMLElement;
    if (htmlPreview) {
      if (!this.shadowRoot) {
        const shadowRoot = htmlPreview.attachShadow({ mode: 'open' }); // open, closed
        this.shadowRoot = shadowRoot;
      }
      this.shadowRoot.innerHTML = '';
    }
  }

  async generatePreview(): Promise<void> {
    if (this.element) {
      if (!this.isLoaded) {
        return;
      }
      const dataIdentifier = this.reports3DataStore.buildDataIdentifier(this.element, this.configuration, this.datasource, this.report);
      const data: AggregatedDataHighchartsResponse[] | null = this.reports3DataStore.data[dataIdentifier] ?
        JSON.parse(JSON.stringify(this.reports3DataStore.data[dataIdentifier])) :
        null;

      const htmlPreview = this.$refs.htmlPreview as HTMLElement;
      if (htmlPreview) {
        if (!this.shadowRoot) {
          const shadowRoot = htmlPreview.attachShadow({ mode: 'open' }); // open, closed
          this.shadowRoot = shadowRoot;
        }
        this.shadowRoot.innerHTML = '';
        await nextTick();
        let aiInsight: [string, string] | null = null;
        if (this.element.Features.includes(Report3ElementFeatures.BitpoolAI)) {
          const aiDataIdentifier = this.reports3DataStore.buildAIDataIdentifier(this.element, this.configuration, this.datasource, this.report);
          aiInsight = this.reports3DataStore.aiInsights[aiDataIdentifier];
        }
        const html = ReportHtmlHelper.generateShadowPreview(
          this.element, 
          this.configuration, 
          `${this.reports3Store.cssHtml ?? ""}\n${this.reports3Store.cssCommon ?? ""}`,
          this.currentOrganisation?.Name ?? 'Organisation',
          this.reports3Store.dataOne?.Name ?? 'Report',
          aiInsight
        );
        const htmlElement = this.createElementFromHTML(html[0])
        this.shadowRoot.appendChild(htmlElement);
        if (html[1]) {
          await nextTick();
          try {
            const elemenDataConfiguration: Reports3Datasource | null = JSON.parse(JSON.stringify(this.datasource));
            if (elemenDataConfiguration && this.configuration?.FeaturesConfiguration) {
              const featuresConfiguration = this.configuration.FeaturesConfiguration;

              const keyDataStreams = "DataStreams";
              if (featuresConfiguration[keyDataStreams]) {
                let streamOptions: StreamOption[] = [];
                const streamOptionsSource = elemenDataConfiguration.Configuration.streamOptions.filter(x => x.StreamKey);
                const removeDataIndexes: number[] = [];
                const uids = Object.getOwnPropertyNames(featuresConfiguration[keyDataStreams]);
                if (uids.length > 0) {
                  for (let i = 0; i < streamOptionsSource.length; i++) {
                    const dataStream = streamOptionsSource[i];
                    if (dataStream.Uid && featuresConfiguration[keyDataStreams][dataStream.Uid]) {
                      streamOptions.push(dataStream);
                    } else {
                      removeDataIndexes.push(i);
                    }
                  }
                } else {
                  for (let i = 0; i < streamOptionsSource.length; i++) {
                    removeDataIndexes.push(i);
                  }
                }
                
                if (data) {
                  for (let i = removeDataIndexes.length - 1; i >= 0; i--) {
                    data.splice(removeDataIndexes[i], 1);
                  }
                }
                elemenDataConfiguration.Configuration.streamOptions = streamOptions;
              }

              const keyDataSeriesType = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesType];
              if (featuresConfiguration[keyDataSeriesType]) {
                for (const streamOption of elemenDataConfiguration.Configuration.streamOptions) {
                  if (streamOption.Uid && featuresConfiguration[keyDataSeriesType][streamOption.Uid]) {
                    const value = featuresConfiguration[keyDataSeriesType][streamOption.Uid];
                    streamOption.type = value;
                  }
                }
              }

              const keyDataSeriesTypeStacked = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesTypeStacked];
              if (featuresConfiguration[keyDataSeriesTypeStacked]) {
                for (const streamOption of elemenDataConfiguration.Configuration.streamOptions) {
                  if (streamOption.Uid && featuresConfiguration[keyDataSeriesTypeStacked][streamOption.Uid]) {
                    const value = featuresConfiguration[keyDataSeriesTypeStacked][streamOption.Uid];
                    streamOption.stackedType = value;
                  }
                }
              }

              const keyDataYAxis = Report3ElementFeaturesToString[Report3ElementFeatures.YAxis];
              if (featuresConfiguration[keyDataYAxis]) {
                for (const streamOption of elemenDataConfiguration.Configuration.streamOptions) {
                  if (streamOption.Uid && featuresConfiguration[keyDataYAxis][streamOption.Uid]) {
                    const value = featuresConfiguration[keyDataYAxis][streamOption.Uid];
                    streamOption.Params.yaxis = value;
                  }
                }
              }
            }

            // Extract Date Range
            const isTotal = this.element.Features.includes(Report3ElementFeatures.DataOnlyTotalAggregation);
            const requestBody = elemenDataConfiguration ?
              DataHelper.wdsToApiRequest(
                elemenDataConfiguration.Configuration, 
                this.report && elemenDataConfiguration.UseReportDRS ? this.report.DateRange : null,
                isTotal ? "reports3_total" : "reports3"
              ) : null;
            let dateRange: [string, string] | null = null;
            if (requestBody) {
              const dates = DateHelper.extractDateFromRequestBody(requestBody);
              dateRange = [DateHelper.dateToString(dates[0]), DateHelper.dateToString(dates[1])];
            }

            // Error visualization for data
            const errors: string[] = [];
            if (data && data.length > 0) {
              for (const item of data) {
                if (item.Error) {
                  errors.push(`Stream [${item.StreamKey}]: ${item.Error}`);
                }
              }
            }
            if (errors.length) {
              this.errorMessage = errors.join("; ");
            } else {
              this.errorMessage = "";
            }

            // Execute script
            const script = html[1];
            const func = new Function("shadowRoot", "libraries", "data", "dataConfiguration", "dateRange", script);
            const libraries = {
              Highcharts: Highcharts,
              marked: marked,
              DOMPurify: DOMPurify
            };
            func(this.shadowRoot, libraries, TextHelper.objectKeysToCamelCase(data), TextHelper.objectKeysToCamelCase(elemenDataConfiguration), dateRange);
          } catch (error) {
            const errorMessage = ErrorHelper.handleAxiosError(error).message;
            ToastService.showToast(
              "error",
              "Report Element Error",
              errorMessage,
              5000
            );
          }
        }
        if (this.element.Role !== Reports3ElementRole.Body) {
          await nextTick();
          const pageNumber = this.shadowRoot?.querySelector(".pageNumber") as HTMLElement;
          if (pageNumber) {
            pageNumber.innerHTML = "1";
          }
          const totalPages = this.shadowRoot?.querySelector(".totalPages") as HTMLElement;
          if (totalPages) {
            totalPages.innerHTML = "1";
          }
        }
      }      
    }
  }

  createElementFromHTML(htmlString: string): DocumentFragment {
    var template = document.createElement('template');
    template.innerHTML = htmlString;
    return template.content;
  }
}

export default ReportsHtmlElementView;
</script>