import { defineStore } from "pinia";
import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import BitpoolAIRealtimeState from "./states/BitpoolAIRealtimeState";
import { ChatAIRequest } from "@/models/bitpool-ai/ChatAIRequest";
import { ChatAIResponse } from "@/models/bitpool-ai/ChatAIResponse";
import moment from "moment";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { nextTick } from "vue";
import { ChatAIMessage } from "@/models/bitpool-ai/ChatAIMessage";
import { HtmlToPdfAIParameters } from "@/models/bitpool-ai/HtmlToPdfAIParameters";
import { useAISpeechStore } from "./aiSpeech";
import JSON5 from "json5";
import { ReadPagePreviewResult } from "@/models/bitpool-ai/ReadPagePreviewResult";

function youtubeLinkParse(url: string){
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}

function processEmbeds(htmlString: string): string {
  const parser = new DOMParser();
  const document = parser.parseFromString(htmlString, "text/html");

  // images
  const imagesNodes = document.getElementsByTagName('img');
  for (let i = 0; i < imagesNodes.length; i++) {
    const img = imagesNodes[i];
    const srcLowerCase = img.src ? img.src.toLowerCase() : "";
    if (srcLowerCase.includes("base64,")) {
      img.classList.add("base64-image");
    } else {
      img.classList.add("external-image");
    }
  }

  // links
  const linksNodes = document.getElementsByTagName('a');
  for (let i = 0; i < linksNodes.length; i++) {
    const a = linksNodes[i];
    const hrefLowerCase = a.href ? a.href.toLocaleLowerCase() : "";
    let previewLink = true;
    if (hrefLowerCase.endsWith(".jpg") || hrefLowerCase.endsWith(".png") || hrefLowerCase.endsWith(".svg")) {
      a.classList.add("external-image-link");
      a.classList.add("no-embed");
      const embed = document.createElement('div');
      embed.style.maxWidth = "100%";
      embed.style.textAlign = "center";
      embed.style.margin = "1rem 0";
      embed.innerHTML = `<img class="external-image" style="max-width: 100%; max-height: 100%; width: auto; height: auto; object-fit: contain;" src="${a.href}"/>`;
      a.parentNode?.insertBefore(embed, a.nextSibling);
      previewLink = false;
    } else {
      const youtubeId = youtubeLinkParse(a.href);
      if (youtubeId) {
        a.classList.add("youtube-video-link");
        a.classList.add("no-embed");
        const embed = document.createElement('div');
        embed.classList.add("ratio");
        embed.classList.add("iframe-container");
        embed.style.maxWidth = "100%";
        embed.style.margin = "1rem 0";
        embed.innerHTML = `<iframe class="youtube-video" width="100%" src="https://www.youtube.com/embed/${youtubeId}" frameborder="0" allowfullscreen></iframe>`;
        a.parentNode?.insertBefore(embed, a.nextSibling);
        previewLink = false;
      }
    } 
    if (previewLink) {
      const embedId = uuidv4();
      a.classList.add("preview-link-embed");
      a.id = `preview-link-embed-${embedId}`;
      const embed = document.createElement('div');
      embed.id = `preview-link-embed-content-${embedId}`;
      embed.className = "preview-link-embed-content";
      embed.innerHTML = a.href;
      a.parentNode?.insertBefore(embed, a.nextSibling);
    }
  }

  // tables
  const tasbleNodes = document.getElementsByTagName('table');
  for (let i = 0; i < tasbleNodes.length; i++) {
    const table = tasbleNodes[i];
    const wrapper = document.createElement('div');
    wrapper.className = "table-responsive"
    table.parentNode?.insertBefore(wrapper, table);
    wrapper.appendChild(table);
  }

  // charts
  const codeNodes = document.getElementsByTagName('code');
  for (let i = 0; i < codeNodes.length; i++) {
    const code = codeNodes[i];
    const inner = code.innerHTML.trim();
    try {
      const json = JSON5.parse(inner);
      if (json.chart) {
        // highcharts
        code.classList.add("ai-embed-highcharts");
        code.id = `ai-embed-highcharts-${uuidv4()}`;
      }
    } catch {
      // not a valid json
    }
  }

  return document.body.innerHTML;
}

export const useBitpoolAIRealtimeStore = defineStore('bitpoolAIRealtime', {
  state: (): BitpoolAIRealtimeState => ({ 
    guid: "",
    data: [],
    inProgress: false,
    newMessage: "",
    prependData: "",
    selectedAI: "",
    initialMessage: "What can you tell me about this data?",
    devStub: false,

    isSessionActive: false,
    events: [],
    dataChannel: null,
    peerConnection: null,
    audioElement: null
  }),
  getters: {
  },
  actions: {
    newConversation(): void {
      this.data = [];
      this.inProgress = false;
      this.guid = "";
      this.newMessage = "";
      this.prependData = "";
    },
    async printMessage(body: HtmlToPdfAIParameters): Promise<string> {
      const url = `rest/AI_V1/messageToPdf`;
      const response = await axios.post<string>(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    },
    async getLinkPreview(link: string): Promise<ReadPagePreviewResult | null> {
      try {
        const url = `rest/AI_V1/link-preview`;
        const response = await axios.post<ReadPagePreviewResult>(url, link, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        return response.data;
      } catch {
        return null;
      }
    },
    cleanHistory(): void {
      this.data = [];
      this.inProgress = false;
      this.guid = "";
    },
    async sendMessage(): Promise<string> {
      let result = "";
      const guid = uuidv4();
      const currentMessage = this.newMessage;
      const currentMessageHtml = DOMPurify.sanitize(marked.parse(currentMessage));
      const userTimestamp = moment().valueOf();
      try {
        this.guid = guid;
        this.newMessage = "";
        this.inProgress = true;

        const aiSpeechStore = useAISpeechStore();
        if (this.data.length) {
          await aiSpeechStore.speakPreRecordedWait(this.selectedAI);
        } else {
          await aiSpeechStore.speakPreRecordedHello(this.selectedAI);
        }

        const history: ChatAIMessage[] = [];
        history.push({ role: "system", message: this.prependData, messageId: "" });
        this.data.forEach(record => {
          history.push({ 
            role: record.author === "You" ? "user" : "assistant", 
            message: record.messageMD,
            messageId: ""
          });
        });
        const body: ChatAIRequest = {
          message: currentMessage,
          persona: this.selectedAI,
          history: history,
          threadId: this.data.length ? (this.data[this.data.length - 1].response?.threadId ?? "") : "",
        };
  
        this.data.push({
          response: undefined,
          message: currentMessageHtml,
          messageMD: currentMessage,
          timestamp: userTimestamp,
          author: "You",
          hidden: !this.data.length && this.initialMessage === currentMessage,
          enableTyping: false
        });
        
        await nextTick();
        document.querySelector(`#chat-gpt-typing`)?.scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"});
  
        const url = `rest/AI_V1/chat`;
        const response = await axios.post<ChatAIResponse>(url, body);
        result = processEmbeds(DOMPurify.sanitize(marked.parse(response.data.response)));
        let seconds = 0;
        while (aiSpeechStore.isPlaying && seconds < 30) {
          seconds++; // Wait for the audio to finish or 30 seconds
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        if (this.guid === guid) {
          const sanitizedHtml = result;
          const timestamp = moment().valueOf();

          await aiSpeechStore.speakHtml(result, this.selectedAI);
          this.data.push({
            response: response.data,
            message: sanitizedHtml,
            messageMD: response.data.response,
            timestamp: timestamp,
            author: "Bitpool AI",
            hidden: false,
            enableTyping: true
          });
          this.inProgress = false;
        }
      } catch (error) {
        const message = ErrorHelper.handleAxiosError(error).message;
        if (message.includes("context_length_exceeded")) {
          ToastService.showToast(
            "warn",
            "Bitpool AI Warning",
            "The maximum context length for this model has been reached. Please change rollup for this time period.",
            5000
          );
        } else {
          ToastService.showToast(
            "error",
            "Bitpool AI Error",
            ErrorHelper.handleAxiosError(error).message,
            5000
          );
        }
        this.inProgress = false;
        if (this.guid === guid) {
          this.newMessage = currentMessage;
        }
        const messageIndex = this.data.findIndex(x => x.timestamp === userTimestamp);
        this.data.splice(messageIndex, 1);
      }
      return result;
    },

    async startSession(): Promise<void> {
      // Get an ephemeral key from the API server
      const url = `rest/AI_V1/realtime/token`;
      const response = await axios.get<string>(url);
      const EPHEMERAL_KEY = JSON.parse(response.data).client_secret.value;
  
      // Create a peer connection
      const pc = new RTCPeerConnection();
  
      // Set up to play remote audio from the model
      this.audioElement = document.createElement("audio");
      this.audioElement.autoplay = true;
      pc.ontrack = (e) => { if (this.audioElement) this.audioElement.srcObject = e.streams[0] };
  
      // Add local audio track for microphone input in the browser
      const ms = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      pc.addTrack(ms.getTracks()[0]);
  
      // Set up data channel for sending and receiving events
      const dc = pc.createDataChannel("oai-events");
      this.dataChannel = dc;

      // Append new server events to the list
      dc.addEventListener("message", (e) => {
        const data = JSON.parse(e.data);
        this.events.push(data);
        // if (data.type === "response.audio_transcript.done" && data.transcript) {
        //   this.data.push({
        //     response: {
        //       response: data.transcript,
        //       threadId: "realtime",
        //       messageId: data.response_id ?? "-"
        //     },
        //     message: data.transcript,
        //     messageMD: data.transcript,
        //     timestamp: moment().valueOf(),
        //     author: "You",
        //     hidden: false,
        //     enableTyping: false
        //   });
        // }
        if (data.type === "response.output_item.done" && data.item?.role === "assistant" && data.item.content?.length && data.item.content?.[0]?.transcript) {
          const lastMessage = this.data.length ? this.data[this.data.length - 1] : null;
          const currentTranscript = data.item.content[0].transcript;
          const html = processEmbeds(DOMPurify.sanitize(marked.parse(currentTranscript)))
          if (lastMessage && lastMessage.response && lastMessage.response.messageId === data.response_id) {
            lastMessage.response.response += currentTranscript;
            lastMessage.message += currentTranscript;
            lastMessage.messageMD += currentTranscript;
          } else {
            this.data.push({
              response: {
                response: currentTranscript,
                threadId: "realtime",
                messageId: data.response_id ?? "-"
              },
              message: html,
              messageMD: currentTranscript,
              timestamp: moment().valueOf(),
              author: "Bitpool AI",
              hidden: false,
              enableTyping: false
            });
          }
        }
      });

      // Set session active when the data channel is opened
      dc.addEventListener("open", () => {
        this.isSessionActive = true;
        //this.events = [];
      });
  
      // Start the session using the Session Description Protocol (SDP)
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);
  
      const baseUrl = "https://api.openai.com/v1/realtime";
      const model = "gpt-4o-realtime-preview-2024-12-17";
      const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
        method: "POST",
        body: offer.sdp,
        headers: {
          Authorization: `Bearer ${EPHEMERAL_KEY}`,
          "Content-Type": "application/sdp",
        },
      });
  
      const answer: RTCSessionDescriptionInit = {
        type: "answer",
        sdp: await sdpResponse.text(),
      };
      await pc.setRemoteDescription(answer);
  
      this.peerConnection = pc;
    },
  
    // Stop current session, clean up peer connection and data channel
    stopSession() {
      this.isSessionActive = false;

      if (this.dataChannel) {
        this.dataChannel.close();
        this.dataChannel = null;
      }
      if (this.peerConnection) {
        this.peerConnection.close();
        this.peerConnection = null;
      }
      if (this.audioElement) {
        this.audioElement.srcObject = null;
        this.audioElement.pause();
        this.audioElement = null;
      }
    },

    // Send a message to the model
    async sendClientEvent(message: any): Promise<void> {
      if (this.dataChannel) {
        let maxSeconds = 10;
        while (this.dataChannel?.readyState !== "open" && maxSeconds > 0) {
          console.log("Waiting for data channel to open...");
          await new Promise(resolve => setTimeout(resolve, 1000));
          maxSeconds--;
        }
        message.event_id = message.event_id || crypto.randomUUID();
        this.dataChannel.send(JSON.stringify(message));
        this.events.push(message);
      } else {
        console.error(
          "Failed to send message - no data channel available",
          message,
        );
      }
    },

    // Send a text message to the model
    sendTextMessage(message: string, hidden = false) {
      const event = {
        type: "conversation.item.create",
        item: {
          type: "message",
          role: "user",
          content: [
            {
              type: "input_text",
              text: message,
            },
          ],
        },
      };

      this.sendClientEvent(event);
      this.sendClientEvent({ type: "response.create" });

      if (!hidden) {
        this.data.push({
          response: {
            response: "",
            threadId: "realtime",
            messageId: "user-message"
          },
          message: message,
          messageMD: message,
          timestamp: moment().valueOf(),
          author: "You",
          hidden: false,
          enableTyping: false
        });
      }
    }
  },
});
