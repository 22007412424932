<template>
  <Dialog header="Create dashboard" v-model:visible="displayDialog" :modal="true" :style="{width: '97rem'}" class="create-dashboard-dialog bp-stepper-dialog">
    <template #header>
      <span class="p-dialog-title" data-pc-section="title">
        <span class="flex-shrink-0">Create dashboard</span>
        <span v-if="activeStep > 0" class="bp-stepper-dialog-header-subtitle">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 18"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 17 8-8-8-8"/></svg>
          <span>{{ wizardTypes.find(wt => wt.value === wizardType)?.label }}</span>
        </span>
      </span>
    </template>
    <div class="dialog-content">
      <Stepper 
        :linear="true" 
        v-model:activeStep="activeStep" 
        class="bp-stepper bp-stepper-dashboard compact-mode" 
        :class="`bp-stepper-step-${activeStep} bp-stepper-mode-${wizardType} ${isBack ? 'is-back' : ''}`"
        @stepChange="onStepChange"
      >
        <StepperPanel>
          <template #header="{ index, clickCallback }">
            <button class="bp-stepper-step-header" @click="clickCallback">
              <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                Welcome
              </span>
              <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
            </button>
          </template>

          <template #content="{ nextCallback }">
            <div class="bp-stepper-body">
              <div class="bp-stepper-body-inner">
                <div class="flex flex-column default-width-xl">
                  <h3>How would you like to create your dashboard?</h3>
                  <div>
                    <Button 
                      v-for="wt in wizardTypes"
                      :key="wt.value"
                      :label="wt.label"
                      :disabled="wt.value !== 'blank' && !authState.permissions?.BitpoolAdmin"
                      @click="isBack = false; wizardType = wt.value; nextCallback($event);"
                      class="bp-stepper-body-inner-button"
                      outlined
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </StepperPanel>

        <StepperPanel>
          <template #header="{ index, clickCallback }">
            <button class="bp-stepper-step-header" @click="clickCallback">
              <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                Name & type
              </span>
              <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
            </button>
          </template>

          <template #content="{ prevCallback, nextCallback }">
            <div class="bp-stepper-body">
              <div class="bp-stepper-body-inner">
                <div class="flex flex-column">
                  <h3>Give this dashboard a name</h3>
                  <InputText v-model="dashboardName" :placeholder="'Enter dashboard name'" class="size-large" :disabled="updateDashboardInProgress" />

                  <div v-if="wizardType !== 'blank'" class="bp-stepper-body-next-option">
                    <h3>What is this dashboard for?</h3>
                    <div>
                      <ul class="bp-stepper-tile-list">
                        <li
                          v-for="item in aiTypes"
                          :key="item.name"
                          :class="item.name === aiType ? 'is-selected' : ''"
                          @click="onAITypeClick(item)"
                        >
                          <div>
                            <i v-html="item.icon"></i>
                            <span>{{ item.name }}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bp-stepper-footer">
              <Button label="Back" outlined class="text-lg" @click="prevCallback" />
              <Button 
                v-if="wizardType !== 'blank'"
                label="Next"
                class="text-lg"
                :disabled="!dashboardName"
                @click="nextCallback($event); fixPromptTextAreaAutoResize();" 
              />
              <Button
                v-else
                label="Create"
                :icon="updateDashboardInProgress ? 'pi pi-spin pi-spinner' : ''"
                class="text-lg"
                @click="saveDashboard"
                :disabled="updateDashboardInProgress"
              />
            </div>
          </template>
        </StepperPanel>

        <StepperPanel v-if="wizardType !== 'blank'">
          <template #header="{ index, clickCallback }">
            <button class="bp-stepper-step-header" @click="clickCallback">
              <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                Bitpool AI
              </span>
              <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
            </button>
          </template>

          <template #content="{ index, prevCallback, nextCallback }">
            <div class="bp-stepper-body">
              <div class="bp-stepper-body-inner">
                <div class="flex flex-column">
                  <h3>Do you have specific instructions for BitpoolAI?</h3>
                  <TextAreaSpeech v-if="index === activeStep" v-model="prompt" :rows="3" :autoResize="true" />

                  <div class="mt-5 md:mt-6">
                    <ul class="bp-stepper-box-list">
                      <li
                        v-for="item in prompts"
                        :key="item"
                        :class="item === prompt ? 'is-selected' : ''"
                        @click="prompt = item"
                      >
                        <i></i>
                        <span>{{ item }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="bp-stepper-footer">
              <Button label="Back" outlined class="text-lg" @click="prevCallback" />
              <Button label="Next" class="text-lg" :disabled="!prompt" @click="openWidgetGroups(); nextCallback($event);" />
            </div>
          </template>
        </StepperPanel>

        <StepperPanel v-if="wizardType !== 'blank'">
          <template #header="{ index, clickCallback }">
            <button class="bp-stepper-step-header" @click="clickCallback">
              <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                Select widgets
              </span>
              <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
            </button>
          </template>

          <template #content="{ index, prevCallback, nextCallback }">
            <div class="bp-stepper-body">
              <div class="bp-stepper-body-inner" v-if="index === activeStep">
                <div class="flex flex-column">
                  <h3>Select widgets that Bitpool AI can use to generate your dashboard</h3>
                  <div>
                    <IconField iconPosition="right">
                      <InputIcon class="pi pi-search text-xl pr-2"></InputIcon>
                      <InputText
                        v-model="searchWidgets"
                        placeholder="Search widgets"
                        @input="debounceSearchWidgets()"
                        class="w-full size-large pr-7"
                      />
                    </IconField>
                    <span class="bp-stepper-body-inner-action-text bp-secondary-text mt-2">
                      <span v-if="searchFinalWidgets && visibleWidgets.length > 0"><b>{{ visibleWidgets.length }}</b> result<span v-if="visibleWidgets.length > 1">s</span> found</span>
                      <span v-else-if="searchFinalWidgets">There are no matching results, try a different search!</span>
                      &nbsp;
                    </span>
                    <div class="flex align-items-center gap-2 mt-2 md:pt-1 pb-1">
                      <span class="bp-stepper-body-inner-action-text mr-auto"><b>{{ selectedWidgets.length }}</b> selected widget<span v-if="selectedWidgets.length > 1">s</span></span>
                      <Button
                        label="Select none"
                        :disabled="selectedWidgets.length === 0"
                        @click="selectNoneWidgets"
                        link
                        class="justify-content-center label-flex-none flex-shrink-0 bp-stepper-body-inner-text-button default-link-button text-base p-1"
                      />
                      <Button
                        label="Select all"
                        :disabled="selectedWidgets.length === availableWidgets.length"
                        @click="selectAllWidgets"
                        link
                        class="justify-content-center label-flex-none flex-shrink-0 bp-stepper-body-inner-text-button text-base p-1"
                      />
                    </div>

                    <Accordion :multiple="true" :activeIndex="widgetGroupsActiveIndex" class="w-full ai-suggested-widgets mt-2">
                      <AccordionTab v-for="item in widgetGroups" :key="item[0].name">
                        <template #header>
                          <span class="p-accordion-header-text" data-pc-section="headertitle">{{ item[0].name }} ({{ getSelectedWidgetCount(item[1]) }})</span>
                        </template>
                        <div class="ai-suggested-widgets-list">
                          <div
                            v-for="widget in item[1]"
                            :key="widget.name"
                            :class="selectedWidgets.includes(widget.name) ? `ai-suggested-widget-selected` : ``"
                            @click="toggleWidget(widget)"
                            :style="isWidgetVisible(widget) ? `` : `opacity: 0.3;`"
                          >
                            <span>
                              <InlineSvg :src="widget.icon"/>
                            </span>
                            <h5>{{ widget.displayName }}</h5>
                          </div>
                        </div>
                      </AccordionTab> 
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
            <div class="bp-stepper-footer">
              <Button label="Back" outlined class="text-lg" @click="prevCallback" />
              <Button label="Next" class="text-lg" @click="nextCallback" :disabled="selectedWidgets.length === 0" />
            </div>
          </template>
        </StepperPanel>

        <StepperPanel v-if="wizardType !== 'blank'">
          <template #header="{ index, clickCallback }">
            <button class="bp-stepper-step-header" @click="clickCallback">
              <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                Add data
              </span>
              <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
            </button>
          </template>

          <template #content="{ index, prevCallback, nextCallback }">
            <div class="bp-stepper-body">
              <div class="bp-stepper-body-inner" v-if="activeStep === index">
                <div>
                  <h3>Add and configure data streams in your dashboard</h3>

                  <div class="bp-stepper-body-date-range">
                    <h4 class="mb-0">Configure date range</h4>
                    <p class="bp-stepper-body-title-help-text">These settings will apply across all widgets within the dashboard.</p>
                    <DashboardWidgetGlobalDateRangeView :gdrs="dateRange" :rdrsMode="true" :aiDashboardMode="true"/>
                  </div>

                  <div class="mt-5 md:mt-6">
                    <h4>Add your data</h4>
                    <div>
                      <Accordion @tabOpen="onAIStreamsOpen" class="w-full ai-suggested-streams">
                        <AccordionTab header="AI suggested streams" :disabled="!navTreeStore.structuredDataForUI?.length">
                          <div v-if="aiSuggestedStreamsLoadingInProgress" class="w-full h-full flex align-items-center justify-content-center">
                            <ProgressSpinner class="spinner-primary" style="width: 50px; height: 50px" strokeWidth="3" animationDuration="1s" />
                          </div>
                          <div v-else>
                            <div v-for="node in aiSuggestedStreams" :key="node.key" class="bp-stepper-stream-item">
                              <span v-if="node.icon" :class="node.icon" class="tree-node-icon flex-shrink-0"></span>
                              <span class="break-word">{{ node.label }}</span>
                              <div class="ml-auto flex align-items-center flex-shrink-0 gap-1">
                                <Button
                                  @click="viewStream(node.key)"
                                  icon="pi pi-external-link text-base m-0" 
                                  link
                                  class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button justify-content-center"
                                />
                                <Button
                                  v-if="isNodeAdded(node)"
                                  icon="pi pi-times text-base m-0"
                                  @click="removeStreamFromDashboard(node)"
                                  link
                                  class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button tree-node-delete-button justify-content-center"
                                />
                                <Button
                                  v-else
                                  icon="pi pi-plus text-base m-0"
                                  @click="addStreamToDashboard(node)"
                                  link
                                  class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button justify-content-center"
                                />
                              </div>
                            </div>
                          </div>
                        </AccordionTab>
                      </Accordion>
                      <InlineMessage severity="info" v-if="!navTreeStore.structuredDataForUI?.length" class="light-inline-message my-4">
                        No structured data is currently available. Please add structured data to utilize this feature.
                      </InlineMessage>
                    </div>
                    <div class="selected-streams mt-4 md:mt-5">
                      <div class="selected-streams-head">
                          <span class="bp-stepper-body-inner-action-text"><b>{{ selectedPoints.length }}</b> selected data stream<span v-if="selectedPoints.length > 1">s</span></span>
                        <Button
                          label="Add"
                          @click="openAddStreamDialog"
                          icon="pi pi-plus text-xl"
                          outlined
                          class="bp-stepper-body-inner-add-button"
                        />
                      </div>
                      <div class="selected-streams-body" v-if="selectedPoints.length">
                        <div class="bp-stepper-stream-items-container">
                          <div>
                            <DashboardNewNodesTreeView :nodes="selectedNodes" :nodeDeleted="onNodeDeleted"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Dialog header="Add data streams" v-model:visible="addStreamDialogVisible" :modal="true" :style="{width: '62rem'}"  class="bp-stepper-add-stream-dialog">
                      <div class="dialog-content">
                        <div v-if="addStreamDialogVisible">
                          <!-- Structured -->
                          <div v-if="structuredView">
                            <label for="structuredData-aiDashboard" class="block mb-3 font-semibold">
                              <span>Structured Data</span>
                            </label>
                            <div>
                              <TreeWithCheckboxesView 
                                ref="treeWithCheckboxesStructured"
                                id="structuredData-aiDashboard"
                                v-if="navTreeStore.isLoaded && navTreeStore.structuredDataForUI"
                                :nodes="navTreeStore.structuredDataForUI" 
                                :changeSelected="structuredChangeSelected"
                                placeholder="Find Entities"
                                :selectableWithTags="['point|equip>point']"
                              />
                              <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6" animationDuration="1s" />
                            </div>
                          </div>

                          <!-- Not-structured -->
                          <div v-if="!structuredView">
                            <label for="unstructuredData-aiDashboard" class="block mb-3 font-semibold">
                              <span>Unstructured Data</span>
                            </label>
                            <div>
                              <TreeWithCheckboxesView 
                                ref="treeWithCheckboxesUnstructured"
                                id="unstructuredData-aiDashboard"
                                v-if="navTreeStore.unstructuredIsLoaded && navTreeStore.unstructuredDataForUI"
                                :nodes="navTreeStore.unstructuredDataForUI" 
                                :changeSelected="unstructuredChangeSelected"
                                placeholder="Find Entities"
                              />
                              <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6" animationDuration="1s" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <template #header>
                        <div class="flex-auto flex flex-wrap sm:flex-nowrap justify-content-between align-items-center gap-4">
                          <span class="p-dialog-title w-full sm:w-auto">Add data streams</span>
                          <div class="flex align-items-center pr-3">
                            <InputSwitch v-model="structuredView" inputId="structuredView-aiDashboard" class="vertical-align-top" />
                            <label for="structuredView-aiDashboard" class="mb-0 ml-2">Structured Data</label>
                          </div>
                        </div>
                      </template>
                      <template #footer>
                        <Button label="Save" icon="pi pi-check" @click="addStreamDialogVisible = false" />
                      </template>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
            <div class="bp-stepper-footer">
              <Button label="Back" outlined class="text-lg" @click="prevCallback" />
              <Button label="Next" class="text-lg" @click="setDates(); nextCallback($event);" :disabled="!structuredSelectedNodes.length && !unstructuredSelectedNodes.length" />
            </div>
          </template>
        </StepperPanel>

        <StepperPanel v-if="wizardType !== 'blank'">
          <template #header="{ index, clickCallback }">
            <button class="bp-stepper-step-header" @click="clickCallback">
              <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                Color theme
              </span>
              <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
            </button>
          </template>
          <template #content="{ prevCallback, nextCallback }">
            <div class="bp-stepper-body">
              <div class="bp-stepper-body-inner">
                <div class="flex flex-column">
                  <InlineMessage v-if="theme && theme.Colors.length < selectedPoints.length" severity="info" class="light-inline-message mb-5 md:mb-6">
                    <p class="light-inline-message-highlighted">
                      Not enough colours for selected data streams
                    </p>
                    <p class="mb-0">
                      <b>{{ selectedPoints.length }}</b> colours are needed! Please select <b>{{ selectedPoints.length - theme.Colors.length }}</b> more, or continue and allow colours to be automatically generated.
                    </p>
                  </InlineMessage>
                  <h3>Select a color theme</h3>
                  <Dropdown
                    class="w-full size-large min-w-0"
                    v-model="theme"
                    :options="themes"
                    dataKey="Id"
                    optionLabel="Name"
                    placeholder="Select Theme"
                    panelClass="theme-colors-dropdown"
                    filter
                    :disabled="colorGenerationInProgress"
                  >
                    <template #option="slotProps">
                      <div class="theme-colors-dropdown-item">
                        <span>{{ slotProps.option.Name }}</span>
                        <ul>
                          <li 
                            v-for="(themeColor, index) in slotProps.option.Colors" 
                            :key="index"
                            :style="{backgroundColor: themeColor} "
                          ></li>
                        </ul>
                      </div>
                    </template>
                  </Dropdown>
                  <div class="theme-colors-list-container mt-5 md:mt-6">
                    <span v-if="theme" class="bp-stepper-body-inner-action-text"><b>{{ theme.Colors.length }}</b> color<span v-if="theme.Colors.length > 1">s</span></span>
                    <ul class="theme-colors-list" v-if="theme">
                      <li 
                        v-for="(themeColor, index) in theme.Colors" 
                        :key="`color-${index}-${themeColor.replaceAll('#', '')}`" 
                      >
                        <ColorSelectView
                          :color="themeColor" 
                          :updateColor="(color: string) => updateColorTheme(theme, index, color)"
                          :enableThemes="false"
                          :allowDelete="true"
                        />
                      </li>
                      <li>
                        <span @click="generateColorsForTheme(theme)" class="theme-colors-list-add-color" :class="colorGenerationInProgress ? 'theme-colors-list-add-color-disabled' : ''">
                          <span v-if="colorGenerationInProgress" class="pi pi-spin pi-spinner"></span>
                          <svg v-else xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.333 11H1.666m9.333-9.333v18.667"/></svg>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="bp-stepper-footer">
              <Button label="Back" outlined class="text-lg" @click="prevCallback" />
              <Button label="Next" class="text-lg" @click="nextCallback" :disabled="colorGenerationInProgress" />
            </div>
          </template>
        </StepperPanel>

        <StepperPanel v-if="wizardType !== 'blank'">
          <template #header="{ index, clickCallback }">
            <button class="bp-stepper-step-header" @click="clickCallback">
              <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                Review
              </span>
              <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
            </button>
          </template>

          <template #content="{ index, prevCallback, nextCallback }">
            <div class="bp-stepper-body">
              <div class="bp-stepper-body-inner">
                <div v-if="index === activeStep">
                  <BlockUI :blocked="updateDashboardInProgress || aiGenerationInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="updateDashboardInProgress ? 'blockui-blocked' : ''">
                    <h3>Are these details correct?</h3>
                    <div class="bp-stepper-body-review mt-5 md:mt-6">
                      <div class="bp-stepper-body-review-item">
                        <div class="bp-stepper-body-review-item-head">
                          <h4>Dashboard Name</h4>
                          <Button label="Edit" icon="pi pi-pen-to-square" link @click="isBack = true; activeStep = 1; activeStepPrevious = 0;" class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button text-base"/>
                        </div>
                        <div class="bp-stepper-body-review-item-body">
                          {{ dashboardName }}
                        </div>
                      </div>
                      <div class="bp-stepper-body-review-item">
                        <div class="bp-stepper-body-review-item-head">
                          <h4>Dashboard Type</h4>
                          <Button label="Edit" icon="pi pi-pen-to-square" link @click="isBack = true; activeStep = 1; activeStepPrevious = 0;" class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button text-base"/>
                        </div>
                        <div class="bp-stepper-body-review-item-body">
                          {{ aiType }}
                        </div>
                      </div>
                      <div class="bp-stepper-body-review-item">
                        <div class="bp-stepper-body-review-item-head">
                          <h4>Prompt</h4>
                          <Button label="Edit" icon="pi pi-pen-to-square" link @click="isBack = true; activeStep = 2; activeStepPrevious = 1;" class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button text-base"/>
                        </div>
                        <div class="bp-stepper-body-review-item-body">
                          {{ prompt }}
                        </div>
                      </div>
                      <div class="bp-stepper-body-review-item">
                        <div class="bp-stepper-body-review-item-head">
                          <h4>Widgets ({{ selectedWidgets.length }})</h4>
                          <Button label="Edit" icon="pi pi-pen-to-square" link @click="isBack = true; activeStep = 3; activeStepPrevious = 2;" class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button text-base"/>
                        </div>
                        <div class="bp-stepper-body-review-item-body">
                          <div class="bp-stepper-widgets-chips-list">
                            <Chip v-for="widget in selectedWidgets" :key="widget" :label="aiWidgets.find(x => x.name === widget)?.displayName ?? ''"/>
                          </div>
                        </div>
                      </div>
                      <div class="bp-stepper-body-review-item">
                        <div class="bp-stepper-body-review-item-head">
                          <h4>Date range</h4>
                          <Button label="Edit" icon="pi pi-pen-to-square" link @click="isBack = true; activeStep = 4; activeStepPrevious = 3;" class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button text-base"/>
                        </div>
                        <div class="bp-stepper-body-review-item-body">
                          <dl class="bp-stepper-body-review-date-range">
                            <dt>Period</dt>
                            <dd>{{ dateRanges.find(x => x.key === dateRange.rangePreset)?.name ?? '' }}</dd>
                            <dt>Start Date</dt>
                            <dd><DateTimezoneView :date="dateFrom" timezone="local"/></dd>
                            <dt>End Date</dt>
                            <dd><DateTimezoneView :date="dateTo" timezone="local"/></dd>
                          </dl>
                        </div>
                      </div>
                      <div class="bp-stepper-body-review-item">
                        <div class="bp-stepper-body-review-item-head">
                          <h4>Data streams ({{ selectedPoints.length }})</h4>
                          <Button label="Edit" icon="pi pi-pen-to-square" link @click="isBack = true; activeStep = 4; activeStepPrevious = 3;" class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button text-base"/>
                        </div>
                        <div class="bp-stepper-body-review-item-body">
                          <div class="bp-stepper-stream-items-container">
                            <div>
                              <DashboardNewNodesTreeView :nodes="selectedNodes" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="theme" class="bp-stepper-body-review-item">
                        <div class="bp-stepper-body-review-item-head">
                          <h4>Colors</h4>
                          <Button label="Edit" icon="pi pi-pen-to-square" link @click="isBack = true; activeStep = 5; activeStepPrevious = 4;" class="flex-shrink-0 bp-stepper-body-inner-text-button default-link-button text-base"/>
                        </div>
                        <div class="bp-stepper-body-review-item-body">
                          <span class="bp-stepper-body-inner-action-text bp-secondary-text"><b>{{ theme.Colors.length }}</b> color<span v-if="theme.Colors.length > 1">s</span></span>
                          <ul class="theme-colors-list" v-if="theme">
                            <li 
                              v-for="(themeColor, index) in theme.Colors" 
                              :key="`color-${index}-${themeColor.replaceAll('#', '')}`" 
                            >
                              <div class="select-bg-color-field" :style="{ backgroundColor: themeColor }"></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <ProgressSpinner class="spinner-primary mt-0" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
                  </BlockUI>
                </div>
              </div>
            </div>
            <div class="bp-stepper-footer">
              <Button label="Back" outlined class="text-lg" @click="prevCallback" :disabled='updateDashboardInProgress' />
              <Button
                label="Generate"
                :icon="updateDashboardInProgress || aiGenerationInProgress ? 'pi pi-spin pi-spinner' : ''"
                iconPos="right"
                class="text-lg"
                @click="saveDashboard"
                :disabled="updateDashboardInProgress || aiGenerationInProgress"
              />
            </div>
          </template>
        </StepperPanel>
      </Stepper>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import Stepper, { StepperChangeEvent } from "primevue/stepper";
import StepperPanel from "primevue/stepperpanel";
import RadioButton from "primevue/radiobutton";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Checkbox from 'primevue/checkbox';
import Chip from "primevue/chip";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import InlineMessage from "primevue/inlinemessage";
import InputSwitch from "primevue/inputswitch";
import { Space } from "@/models/dashboard/Space";
import DashboardState from "@/store/states/DashboardState";
import SpaceHelper from "@/helpers/SpaceHelper";
import NavigationHelper from "@/helpers/NavigationHelper";
import { useOrganisationStore } from "@/stores/organisation";
import { DashboardType } from "@/models/dashboard/DashboardType";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import WidgetHelper from "@/helpers/WidgetHelper";
import { useColorThemeStore } from "@/stores/colorTheme";
import { ColorThemeEntity } from "@/models/organisation/ColorThemeEntity";
import ColorSelectView from "@/components/widgets-next/settings/ColorSelectView.vue";
import OrganisationColorsDialogView from "../organisation/OrganisationColorsDialogView.vue";
import ColorHelper from "@/helpers/ColorHelper";
import TreeWithCheckboxesView from "@/components/views/TreeWithCheckboxesView.vue";
import { useNavTreeStore } from "@/stores/navTree";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import moment from "moment";
import { useBitpoolAIChatStore } from "@/stores/bitpoolAIChat";
import { StreamDataTypeStr } from "@/models/enums/StreamDataTypeStr";
import StreamOption from "@/models/dashboard/StreamOption";
import { generateColorRGB } from "@marko19907/string-to-color";
import chroma from "chroma-js";
import { AggregationType } from "@/models/enums/AggregationType";
import { AggregationTypeString } from "@/models/enums/AggregationTypeString";
import { WidgetFeature } from "@/models/enums/WidgetFeature";
import ToastService from "@/services/ToastService";
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import AuthState from "@/store/states/AuthState";
import { RDRSModel } from "@/models/reports/v3/RDRSModel";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import DashboardWidgetGlobalDateRangeView from "@/components/views/dashboards/DashboardWidgetGlobalDateRangeView.vue";
import { nextTick } from "vue";
import { WidgetGroupDescription } from "@/models/dashboard/WidgetGroupDescription";
import InlineSvg from 'vue-inline-svg';
import DateHelper from "@/helpers/DateHelper";
import { TimeRange } from "@/models/enums/TimeRange";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { debounce } from "throttle-debounce";
import DashboardNewNodesTreeView from "@/components/views/dashboards/DashboardNewNodesTreeView.vue";
import TextAreaSpeech from "@/components/views/TextAreaSpeech.vue";

interface AIType {
  name: string;
  icon: string;
  prompts: string[];
  widgets: string[];
}

@Component({
  components: {
    InputText,
    IconField,
    InputIcon,
    Button,
    Dialog,
    BlockUI,
    ProgressSpinner,
    Stepper,
    StepperPanel,
    RadioButton,
    Textarea,
    Dropdown,
    Checkbox,
    Chip,
    Accordion,
    AccordionTab,
    InlineMessage,
    InputSwitch,
    ColorSelectView,
    OrganisationColorsDialogView,
    TreeWithCheckboxesView,
    DashboardWidgetGlobalDateRangeView,
    InlineSvg,
    DateTimezoneView,
    DashboardNewNodesTreeView,
    TextAreaSpeech
  },
})
class DashboardNewDialogView extends Vue {
  displayDialog = false;
  activeStep = 0;
  activeStepPrevious = 0;
  isBack = false;
  onStepChange(event: StepperChangeEvent): void {
    if (event.index > this.activeStepPrevious) {
      this.isBack = false;
    } else {
      this.isBack = true;
    }
    this.activeStepPrevious = event.index;
  }

  wizardType = "";
  wizardTypes = [{label: "From blank", value: "blank"}, {label: "Generate with AI", value: "ai"}];
  
  aiType = "Energy & Power Use";
  aiTypes: AIType[] = [
    {
      name: "Energy & Power Use",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.333 2.667 5.458 16.917c-.465.559-.698.838-.701 1.073-.003.205.088.4.247.529.184.148.547.148 1.273.148H16l-1.333 10.667 11.875-14.25c.465-.559.698-.838.701-1.074a.667.667 0 0 0-.247-.528c-.184-.148-.547-.148-1.273-.148H16l1.333-10.667Z"/></svg>',
      prompts: [
        `Create a dashboard that provides actionable insights into energy and power consumption, including peak consumption periods, trends in total energy consumption over time, etc.

Data Organization:
Avoid mixing different types of data or units of measurement within the same widget. Group data only if they share the same type and unit of measurement to prevent confusion.
Use separate grids for different types of data or to compare distinct aspects of energy and power usage. 

Widget Grouping and Design:
Group related widgets within grids to enhance readability and data interpretation.
Assign clear, concise, and descriptive names to grids.
Select the most appropriate widget types for the data.
You can visualize the same data in multiple ways, but avoid using multiple widgets that are redundant or contradictory.

Aggregation Methods:
Use the "diff" aggregation for energy consumption data (e.g., kWh) to calculate net usage over time.
Use the "avg" aggregation for power usage data (e.g., kW) to provide insights into typical consumption levels during specific periods.
`,
        `Please build a dashboard that provides insights into energy and power usage, including peak consumption periods, total energy consumption over time, and potential areas for energy savings.
Don't mix different types of data or units of measurement in the same widget, as this can lead to confusion and misinterpretation of data. But if data types and units of measurement are same, you can group them inside the same widget.
Please use separte grid to display different types of data or to compare different aspects of energy and power usage.
Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.
Please use best widget types for the data types.
Use diff aggregation for energy and avg aggregation for power.`,
        "todo: prompt 3"
      ],
      widgets: [
        "heatmap",
        "piechartwidget",

        "dashboardwidget",
        "stackedchartwidget",
        "peakdemand",
        "baselinechart",
        "conditiondurationwidget",

        "pointwidget",
        "datagridwidget",
        "leaderboardwidget",

        "fweather",
        "reporting",

        "powerusage",
        "solargenerated",
        "powerusedgenerated"
      ]
    },
    {
      name: "HVAC & Air Quality",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M2.668 15.997h13.333m-4-10.666v21.333m-8-14.667 4 4-4 4m12-12-4 4-4-4m0 16 4-4 2 2m12.667-2.62V5.332a2.667 2.667 0 0 0-5.333 0v14.047a5.333 5.333 0 1 0 5.333 0Z"/></svg>',
      prompts: [
        "Please build a dashboard that provides insights into HVAC and air quality, including temperature, humidity, and air quality. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "heatmap",
        "radialgaugehc",
        "piechartwidget",

        "dashboardwidget",
        "stackedchartwidget",
        "baselinechart",
        "conditiondurationwidget",

        "pointwidget",
        
        "alarm",
        "reporting",

        "powerusage"
      ]
    },
    {
      name: "Chiller Monitoring",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.001 10.664v10.667m0-10.667v-8m0 8L9.335 3.997M16 10.664l6.667-6.667m-6.667 17.334v8m0-8-6.666 6.666M16 21.331l6.667 6.666m-1.333-12H10.668m10.667 0h8m-8 0L28 9.331m-6.666 6.666L28 22.664m-17.333-6.667h-8m8 0L4.001 9.331m6.667 6.666-6.667 6.667"/></svg>',
      prompts: [
        "Please build a dashboard that provides insights into chiller monitoring, including temperature, humidity, and chiller efficiency. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "radialgaugehc",
        "piechartwidget",

        "dashboardwidget",
        "stringchart",
        "stackedchartwidget",
        "baselinechart",
        "conditiondurationwidget",
        "columnrangewidget",

        "pointwidget",
        "leaderboardwidget",

        "alarm",
        "powerusage"
      ]
    },
    {
      name: "Water Consumption",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M29.335 21.33a8 8 0 1 1-16 0c0-5.75 8-18.666 8-18.666s8 12.915 8 18.667ZM10.668 11.997a4 4 0 0 1-8 0c0-2.875 4-9.333 4-9.333s4 6.458 4 9.333Z"/></svg>',
      prompts: [
        "Please build a dashboard that provides insights into water consumption, including water usage, water quality, and water efficiency. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "heatmap",
        "radialgaugehc",
        "piechartwidget",

        "dashboardwidget",
        "stackedchartwidget",
        "peakdemand",
        "baselinechart",
        "conditiondurationwidget",
        
        "pointwidget",
        "datagridwidget",
        "leaderboardwidget",

        "alarm",
        "powerusage"
      ]
    },
    {
      name: "Tenant Comfort",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10.667 18.667s2 2.667 5.333 2.667 5.333-2.667 5.333-2.667m1.334-6.347c-.527.647-1.247 1.014-2 1.014-.754 0-1.454-.367-2-1.014m-5.334 0c-.526.647-1.246 1.014-2 1.014-.753 0-1.453-.367-2-1.014m20 3.68c0 7.364-5.97 13.334-13.333 13.334-7.364 0-13.333-5.97-13.333-13.334C2.667 8.637 8.637 2.667 16 2.667c7.364 0 13.333 5.97 13.333 13.333Z"/></svg>',
      prompts: [
        "Please build a dashboard that provides insights into tenant comfort, including temperature, humidity, and occupancy. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "heatmap",
        "radialgaugehc",
        "piechartwidget",

        "dashboardwidget",
        "stackedchartwidget",
        "baselinechart",
        
        "pointwidget",
        "leaderboardwidget",
        "limittrackerwidget",

        "alarm",
        "powerusage"
      ]
    },
    {
      name: "Lighting",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.335 23.542v3.122a2.667 2.667 0 0 0 5.333 0v-3.122M16.001 2.664v1.333m-12 12H2.668m4.667-8.666-.8-.8m18.133.8.8-.8m3.867 9.466H28m-4 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"/></svg>',
      prompts: [
        "Please build a dashboard that provides insights into lighting, including lighting usage, lighting efficiency, and lighting control. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "heatmap",
        "piechartwidget",

        "dashboardwidget",
        "stringchart",
        "stackedchartwidget",
        "baselinechart",
        "conditiondurationwidget",

        "pointwidget",
        
        "alarm",

        "powerusage",
        "powerusedgenerated"
      ]
    },
    {
      name: "Emissions Tracking",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M28 24s-1.587-.626-2.667-.93c-6.827-1.927-11.84 3.787-18.666 1.86C5.587 24.627 4 24 4 24m24-8s-1.587-.626-2.667-.93c-6.827-1.927-11.84 3.787-18.666 1.86C5.587 16.627 4 16 4 16m24-8s-1.587-.626-2.667-.93c-6.827-1.926-11.84 3.787-18.666 1.86C5.587 8.627 4 8 4 8"/></svg>',
      prompts: [
        "Please build a dashboard that provides insights into emissions tracking, including CO2 emissions, NOx emissions, and PM2.5 emissions. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "heatmap",
        "piechartwidget",

        "dashboardwidget",
        "stackedchartwidget",
        "baselinechart",
        
        "pointwidget",
        "leaderboardwidget",
        
        "powerusage"
      ]
    },
    {
      name: "Waste Management",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21.333 8V6.934c0-1.494 0-2.24-.29-2.811a2.667 2.667 0 0 0-1.166-1.165c-.57-.291-1.317-.291-2.81-.291h-2.134c-1.493 0-2.24 0-2.81.29-.502.256-.91.664-1.166 1.166-.29.57-.29 1.317-.29 2.81V8m2.666 7.334V22m5.334-6.666V22M4 8h24m-2.667 0v14.934c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748c-.855.436-1.976.436-4.216.436h-5.866c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748c-.436-.856-.436-1.976-.436-4.216V8"/></svg>',
      prompts: [
        "Please build a dashboard that provides insights into waste management, including waste generation, waste disposal, and waste recycling. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "heatmap",
        "piechartwidget",
        "radialgaugehc",

        "dashboardwidget",
        "stackedchartwidget",
        "baselinechart",
        
        "pointwidget",
        "leaderboardwidget",
        
        "powerusage"
      ]
    },
    {
      name: "Vertical Transport",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4" d="M22.667 5.333v21.334m0 0-5.334-5.334m5.334 5.334L28 21.333M9.333 26.668V5.333m0 0L4 10.668m5.333-5.333 5.334 5.333"/></svg>',
      prompts: [
        "Please build a dashboard that provides insights into vertical transport, including traffic flow, traffic congestion, and traffic safety. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "heatmap",
        "piechartwidget",

        "dashboardwidget",
        "stringchart",
        "stackedchartwidget",
        "baselinechart",
        
        "pointwidget",
        "leaderboardwidget",
        
        "powerusage"
      ]
    },
    {
      name: "NABERS Monitoring",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><g stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" clip-path="url(#a)"><path d="M14.55 19.059c.387-2.568 1.4-4.915 3.1-6.846 2.82-3.205 7.02-4.692 11.635-4.547.714 4.567-.232 8.912-3.052 12.117-2.819 3.196-7.019 4.664-11.634 4.528M14.55 19.055a17.075 17.075 0 0 0-.038 4.605"/><path d="M14.512 23.672c.029.203.048.405.087.608M14.602 24.3l9.027-10.206"/><path d="M14.552 19.06c-.522-1.584-1.371-3.003-2.569-4.181-2.413-2.356-5.812-3.274-9.442-2.887-.29 3.64.714 7.01 3.128 9.365 2.298 2.24 5.494 3.186 8.931 2.935M7.387 16.719l7.125 6.951"/></g><defs><clipPath id="a"><path fill="#fff" d="M2 7.172h28v17.65H2z"/></clipPath></defs></svg>',
      prompts: [
        "Please build a dashboard that provides insights into NABERS monitoring, including air quality, water quality, and noise levels. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "heatmap",
        "piechartwidget",

        "dashboardwidget",
        "stackedchartwidget",
        "baselinechart",

        "pointwidget",
        "leaderboardwidget",
        
        "powerusage",
        "solargenerated",
        "powerusedgenerated"
      ]
    },
    {
      name: "Other",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path class="has-fill-color" d="M16 17.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM25.333 17.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM6.667 17.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 17.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM25.333 17.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM6.667 17.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"/></svg>',
      prompts: [
        "Please build a dashboard that provides insights into other areas, including industry, energy, and transportation. Don't mix different types of data in the same widget, as this can lead to confusion and misinterpretation of data. But if data types are same, you can group them inside same widget. Please use separte grid to display different types of data. Group widgets inside grid to make them easier to read and understand. Grid name should be descriptive and concise.",
        "todo: prompt 2",
        "todo: prompt 3"
      ],
      widgets: [
        "piechartwidget",

        "dashboardwidget",
        "stackedchartwidget",
        "baselinechart",
        "conditiondurationwidget",

        "pointwidget",
        "datagridwidget",
        "leaderboardwidget"
      ]
    }
  ];
  onAITypeClick(item: AIType): void {
    this.aiType = item.name;
    this.selectedWidgets = [...item.widgets];
  }

  prompt = "";
  get prompts(): string[] {
    return this.aiTypes.find(t => t.name === this.aiType)?.prompts || [];
  }
  async fixPromptTextAreaAutoResize(): Promise<void> {
    // https://github.com/primefaces/primevue/issues/4510#issuecomment-1974914887
    await nextTick();
    this.prompt += ' ';

    await nextTick();
    this.prompt = this.prompt.slice(0, -1);
  }

  selectedWidgets: string[] = [];
  get aiWidgets(): WidgetDescription[] {
    return WidgetHelper.getWidgetsListForAI();
  }

  themeHolder: ColorThemeEntity | null | undefined = undefined;
  customTheme: ColorThemeEntity = {
    Id: "custom",
    Name: "Custom",
    Description: "Custom color theme",
    Colors: [],
    OrganisationId: -1,
    Created: "",
    Updated: "",
    CreatedBy: "",
    UpdatedBy: ""
  };
  get theme(): ColorThemeEntity | null {
    let result = null;
    if (!this.themeHolder) {
      const colorThemeId = localStorage.getItem("colorThemeId");
      if (colorThemeId) {
        const theme = this.themes.find(x => x.Id === colorThemeId);
        if (theme) {
          result = theme;
        }
      }
      if (!result) {
        result = this.themes.length > 0 ? this.themes[0] : null;
      }
      this.themeHolder = result;
    }
    return this.themeHolder;
  }
  set theme(value: ColorThemeEntity | null) {
    if (value) {
      if (value.Id !== "custom") {
        localStorage.setItem("colorThemeId", value.Id);
      }
    } else {
      localStorage.removeItem("colorThemeId");
    }
    this.themeHolder = value;
  }
  get themes(): ColorThemeEntity[] {
    const result = this.colorThemeStore.entities ? [...this.colorThemeStore.entities] : [];
    result.push(this.customTheme)
    return result;
  }

  dateRange: RDRSModel = {
    active: true,
    rangePreset: 2,
    rangePresetHolder: 2,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    aggPeriod: AggregationPeriod.Hourly,
    autoAggPeriod: true
  };

  structuredSelectedNodes: TreeNodeForUI[] = [];
  structuredChangeSelected(nodes: TreeNodeForUI[]): void {
    this.structuredSelectedNodes = nodes;
  }
  unstructuredSelectedNodes: TreeNodeForUI[] = [];
  unstructuredChangeSelected(nodes: TreeNodeForUI[]): void {
    this.unstructuredSelectedNodes = nodes;
  }
  findPoints(nodes: TreeNodeForUI[]): TreeNodeForUI[] {
    const result: TreeNodeForUI[] = [];
    for (const node of nodes) {
      if (node.tags?.includes("point")) {
        result.push(node);
      }
      if (node.children) {
        result.push(...this.findPoints(node.children));
      }
    }
    return result;
  }
  get selectedPoints(): TreeNodeForUI[] {
    const result = this.findPoints(this.structuredSelectedNodes);
    result.push(...this.unstructuredSelectedNodes);
    return result;
  }
  get selectedNodes(): TreeNodeForUI[] {
    const result: TreeNodeForUI[] = [...this.structuredSelectedNodes];
    result.push(...this.unstructuredSelectedNodes);
    return result;
  }
  onNodeDeleted(node: TreeNodeForUI): void {
    const indexStructured = this.structuredSelectedNodes.indexOf(node);
    if (indexStructured >= 0) {
      this.structuredSelectedNodes.splice(indexStructured, 1);
    } else {
      const indexUnstructured = this.unstructuredSelectedNodes.indexOf(node);
      if (indexUnstructured >= 0) {
        this.unstructuredSelectedNodes.splice(indexUnstructured, 1);
      }
    }
  }

  organisationStore = useOrganisationStore();
  colorThemeStore = useColorThemeStore();
  navTreeStore = useNavTreeStore();
  bitpoolAIChatStore = useBitpoolAIChatStore();

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get dashboardType(): DashboardType {
    return this.dashboardState.dashboardType;
  }

  colorHelper = ColorHelper;
  
  updateDashboardInProgress = false;
  dashboardName = "";
  selectedDashboard: Space | null = null;

  openDialog(parentDashboard: Space): void {
    this.activeStep = 0;
    this.activeStepPrevious = 0;
    this.isBack = false;
    this.wizardType = "blank";
    this.onAITypeClick(this.aiTypes[0])
    this.prompt = "";
    this.initWidgetGroups();
    this.themeHolder = undefined;
    this.dateRange = {
      active: true,
      rangePreset: 2,
      rangePresetHolder: 2,
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      aggPeriod: AggregationPeriod.Hourly,
      autoAggPeriod: true
    };
    this.structuredView = true;
    this.structuredSelectedNodes = [];
    this.unstructuredSelectedNodes = [];
    const dashboard = SpaceHelper.createDashboard(parentDashboard, "New dashboard");
    this.selectedDashboard = dashboard;
    this.dashboardName = dashboard.spaceName;
    this.displayDialog = true;

    if (!this.navTreeStore.isLoaded) {
      this.navTreeStore.load();
    }
    if (!this.navTreeStore.unstructuredIsLoaded) {
      this.navTreeStore.loadUnstructured();
    }
  }

  closeDialog(): void {
    this.displayDialog = false;
  }

  async saveDashboard(): Promise<void> {
    if (!this.updateDashboardInProgress && this.selectedDashboard && this.dashboardName && this.organisationStore.currentOrganisation) {
      this.updateDashboardInProgress = true;
      this.selectedDashboard.spaceName = this.dashboardName;
      const dashboard = this.selectedDashboard;
      const id = dashboard._id ? dashboard._id : "new_dashboard";
      await this.$store.dispatch(
        "dashboard/saveDashboard", 
        { 
          dashboard: dashboard,
          organisationId: this.dashboardType === DashboardType.Organisation ? this.organisationStore.currentOrganisation.Id : undefined
        }
      );
      const state = this.dashboardState.dashboardState[id];
      if (state && state[0] && !state[2]) {
        if (id === "new_dashboard") {
          NavigationHelper.goToDashboard(this.dashboardType, dashboard._id);
        }
        if (this.wizardType === "blank") {
          this.closeDialog();
        } else {
          await this.createDashboardWithAI(dashboard);
        }
      }
      this.updateDashboardInProgress = false;
    }
  }

  // #region date range
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  dateRanges = DateHelper.getDateRanges();

  setDates(): void {   
    if (this.dateRange.rangePreset === TimeRange.Custom) { 
      const startDateM = moment(`${this.dateRange.startDate}T${this.dateRange.startTime}`);
      const endDateM = moment(`${this.dateRange.endDate}T${this.dateRange.endTime}`);
      this.dateFrom = startDateM.toDate();
      this.dateTo = endDateM.toDate();
    } else {
      const dates = DateHelper.timeRangeToMoments(this.dateRange.rangePreset);
      const start = dates[0];
      const end = dates[1];
      if (start) {
        this.dateFrom = start.toDate();
      }
      if (end) {
        this.dateTo = end.toDate();
      }
    }
  }
  // #endregion date range

  // #region ai
  aiSuggestedStreamsLoadingInProgress = false;
  aiSuggestedStreamsRequestKey = "";
  aiSuggestedStreams: TreeNodeForUI[] = [];

  findStreamsByKeys(nodes: TreeNodeForUI[], keys: string[]): TreeNodeForUI[] {
    const result: TreeNodeForUI[] = [];
    for (const node of nodes) {
      if (node.key && keys.includes(node.key) && node.tags?.includes("point")) {
        result.push(node);
      }
      if (node.children) {
        result.push(...this.findStreamsByKeys(node.children, keys));
      }
    }
    return result;
  }

  async onAIStreamsOpen(): Promise<void> {
    const currentRequestKey = `${this.organisationStore.currentOrganisation?.Id}-${this.aiType}`;
    if (currentRequestKey !== this.aiSuggestedStreamsRequestKey) {
      this.aiSuggestedStreamsLoadingInProgress = true;
      this.aiSuggestedStreamsRequestKey = currentRequestKey;
      try {
        const keys = await this.bitpoolAIChatStore.suggestStreamsForAIDashboard(this.aiType);
        if (currentRequestKey === this.aiSuggestedStreamsRequestKey) {
          if (keys.length > 0 && this.navTreeStore.structuredDataForUI) {
            const streams = this.findStreamsByKeys(this.navTreeStore.structuredDataForUI, keys);
            this.aiSuggestedStreams = streams;
          } else {
            this.aiSuggestedStreams = [];
          }
        }
      } finally {
        this.aiSuggestedStreamsLoadingInProgress = false;
      }
    }
  }

  viewStream(streamKey: string | null | undefined): void {
    if (streamKey) {
      const newUrl = `/data/streams/${streamKey}`;
      window.open(newUrl, '_blank');
    }
  }

  isNodeAdded(node: TreeNodeForUI): boolean {
    return this.structuredSelectedNodes.includes(node);
  }

  addStreamDialogVisible = false;
  structuredView = true;

  async openAddStreamDialog(): Promise<void> {
    this.addStreamDialogVisible = true;
    await this.setCheckedNodes();
  }

  async setCheckedNodes(): Promise<void> {
    await nextTick();
    if (this.structuredView) {
      if (this.structuredSelectedNodes.length) {
        (this.$refs.treeWithCheckboxesStructured as TreeWithCheckboxesView).changeCheckedExternally(this.structuredSelectedNodes, false);
      }
    } else {
      if (this.unstructuredSelectedNodes.length) {
        (this.$refs.treeWithCheckboxesUnstructured as TreeWithCheckboxesView).changeCheckedExternally(this.unstructuredSelectedNodes, false);
      }
    }
  }

  @Watch('structuredView', { immediate: false, deep: false })
  onStructuredViewChanged(val: boolean, oldVal: boolean): void {
    this.setCheckedNodes();
  }

  addStreamToDashboard(node: TreeNodeForUI): void {
    if (!this.structuredSelectedNodes.includes(node)) {
      this.structuredSelectedNodes.push(node);
    }
  }

  removeStreamFromDashboard(node: TreeNodeForUI): void {
    const index = this.structuredSelectedNodes.indexOf(node);
    if (index !== -1) {
      this.structuredSelectedNodes.splice(index, 1);
    }
  }

  buildAIMessage(nodes: TreeNodeForUI[], isRootLevel = true): string {
    let result = "";
    for (const node of nodes) {
      const tags = node.tags?.filter(x => !x.startsWith("ref=") && !x.includes("Ref=")) ?? [];
      if (tags.includes("equip")) {
        if (node.children?.length) {
          const children = this.buildAIMessage(node.children, false);
          if (children) {
            result += `\n(Equip) Name: ${node.label} with tags: [${tags.join(", ")}] contains:${children}${isRootLevel ? "\n---\n" : ""}`;
          }
        }
      } else {
        result += `\n(Stream) StreamKey: ${node.key}, Name: ${node.label}, Stream data type: ${node.dataType === StreamDataTypeStr.Double ? "numeric" : "string"}, Tags: [${tags.join(", ")}]${isRootLevel ? "\n---\n" : ""}`;
      }
    }
    return result;
  }

  findSelectedStream(nodes: TreeNodeForUI[], streamKey: string): TreeNodeForUI | undefined {
    for (const node of nodes) {
      if (node.key === streamKey) {
        return node;
      }
      if (node.children) {
        const result = this.findSelectedStream(node.children, streamKey);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  }

  aiGenerationInProgress = false;
  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  async createDashboardWithAI(dashboard: Space): Promise<void> {
    this.aiGenerationInProgress = true;
    let message = `${this.prompt}\n\nIMPORTANT: please make sure your using all the selected streams.`;
    const allSelectedNodes = [...this.structuredSelectedNodes,...this.unstructuredSelectedNodes];
    if (allSelectedNodes.length > 0) {
      const dataMessage = this.buildAIMessage(allSelectedNodes);
      if (!dataMessage) {
        ToastService.showToast("error", "Error", "No stream selected", 5000);
        this.aiGenerationInProgress = false;
        return;
      }
      message = `${message}\n\nData:${dataMessage}`;
    }
    const widgetsTypes = this.selectedWidgets;
    const widgetsString = WidgetHelper.getWidgetsStringForAI(widgetsTypes);
    const response = await this.bitpoolAIChatStore.generateDashboard({
      Message: message,
      WidgetTypes: widgetsTypes,
      Widgets: widgetsString
    });
    if (!response) {
      this.aiGenerationInProgress = false;
      return;
    }
    const colorsHex = [...this.theme?.Colors ?? []];
    if (colorsHex.length === 0) {
      colorsHex.push(this.colorHelper.random());
    }
    let missingColorsCount = this.selectedPoints.length - colorsHex.length;
    if (missingColorsCount > 0) {
      const colors: string[] = [];
      // Convert rgb to hsl using chroma.js
      colorsHex.forEach(x => {
        const color = chroma(x);
        const hslArray = color.hsl();
        const hslString = `hsl(${Math.round(hslArray[0])}, ${Math.round(hslArray[1] * 100)}%, ${Math.round(hslArray[2] * 100)}%)`;
        colors.push(hslString);
      });
      const newColors = await this.bitpoolAIChatStore.generateColors(colors, missingColorsCount);
      newColors.forEach(x => {
        // Convert hsl to rgb using chroma.js
        const color = chroma(x);
        const rgb = color.rgb();
        const colorHex = chroma.rgb(rgb[0], rgb[1], rgb[2]).hex();
        colorsHex.push(colorHex);
      });
    }
    // the default values of saturation: 75, lightness: 50, and alpha: 100
    const colorOptions = { saturation: 75, lightness: 48, alpha: 100 };
    const streams = this.selectedPoints;
    const streamColors = streams.map((x, index) => {
      if (colorsHex.length > index) {
        return { streamKey: x.key ?? "", color: colorsHex[index] };
      } else {
        const colorRGBA = generateColorRGB(x.key ?? "", colorOptions);
        const values = colorRGBA.replace("rgba(", "").replace(")", "").split(",");
        const colorHex = chroma.rgb(parseFloat(values[0]), parseFloat(values[1]), parseFloat(values[2]), parseFloat(values[3])).hex();
        return { streamKey: x.key ?? "", color: colorHex };
      }
    });
    const grids: SpaceWidgetConfig[] = [];
    const widgets: WidgetConfig[] = [];
    response.Dashboard.Childrens?.forEach(x => {
      const newGrid = SpaceHelper.createGrid();
      // disabled to prevent visualization issues
      // newGrid.size.size = x.Size.Size;
      // newGrid.size.sizeSM = x.Size.SizeSM;
      // newGrid.size.sizeMD = x.Size.SizeMD;
      // newGrid.size.sizeLG = x.Size.SizeLG;
      // newGrid.size.sizeXL = x.Size.SizeXL;
      
      // x.Name - title widget
      const titleWidgetDescription = WidgetHelper.getWidget("titlewidget");
      if (titleWidgetDescription) {
        const titleWidget = SpaceHelper.createWidget(titleWidgetDescription);
        titleWidget[1].widgetOptions.basicWidgetSettings.widgetName = x.Name;
        titleWidget[0].size.size = 12;
        titleWidget[0].size.sizeSM = 12;
        titleWidget[0].size.sizeMD = 12;
        titleWidget[0].size.sizeLG = 12;
        titleWidget[0].size.sizeXL = 12;
        newGrid.widgets?.push(titleWidget[0]);
        widgets.push(titleWidget[1]);
      }
      x.Childrens?.forEach(y => {
        const widgetDescription = WidgetHelper.getWidget(y.WidgetType);
        if (widgetDescription) {
          const newWidget = SpaceHelper.createWidget(widgetDescription);
          newWidget[0].size.size = y.Size.Size;
          newWidget[0].size.sizeSM = y.Size.SizeSM;
          newWidget[0].size.sizeMD = y.Size.SizeMD;
          newWidget[0].size.sizeLG = y.Size.SizeLG;
          newWidget[0].size.sizeXL = y.Size.SizeXL;
          newWidget[1].widgetOptions.basicWidgetSettings.widgetName = y.Name;
          if (newWidget[1].widgetOptions.widgetDataSettings) {
            if (widgetDescription.features.includes(WidgetFeature.dateRange)) {
              newWidget[1].widgetOptions.widgetDataSettings.rangePreset = this.dateRange.rangePreset;
              newWidget[1].widgetOptions.widgetDataSettings.rangePresetHolder = this.dateRange.rangePresetHolder;
              newWidget[1].widgetOptions.widgetDataSettings.startDate = this.dateRange.startDate;
              newWidget[1].widgetOptions.widgetDataSettings.startTime = this.dateRange.startTime;
              newWidget[1].widgetOptions.widgetDataSettings.endDate = this.dateRange.endDate;
              newWidget[1].widgetOptions.widgetDataSettings.endTime = this.dateRange.endTime;
            }
            if (widgetDescription.features.includes(WidgetFeature.dataAggregation)) {
              newWidget[1].widgetOptions.widgetDataSettings.autoAggPeriod = this.dateRange.autoAggPeriod;
              newWidget[1].widgetOptions.widgetDataSettings.aggPeriod = this.dateRange.aggPeriod;
            }
          }
          if (newWidget[1].widgetOptions.widgetDataSettings?.streamOptions) {
            if (newWidget[1].widgetOptions.widgetDataSettings.streamOptions.length) {
              // for stream slots
              newWidget[1].widgetOptions.widgetDataSettings.streamOptions = [];
            }
            let maxStreamsCount = widgetDescription.streamSlots?.length ?? 0;
            if (widgetDescription.name === "heatmap") {
              maxStreamsCount = 1; // ai can't fill second stream correctly
            }
            if (widgetDescription.name === "datagridwidget") {
              if (newWidget[1].widgetOptions.advancedWidgetSettings?.fields) {
                const containsDiff = y.Streams.some(z => z.AggregationType === "diff");
                const containsOther = y.Streams.some(z => z.AggregationType !== "diff");
                if (containsDiff) {
                  newWidget[1].widgetOptions.advancedWidgetSettings?.fields.push("diff");
                }
                if (!containsOther) {
                  const avgIndex = newWidget[1].widgetOptions.advancedWidgetSettings.fields.indexOf("avg");
                  if (avgIndex !== -1) {
                    newWidget[1].widgetOptions.advancedWidgetSettings.fields.splice(avgIndex, 1);
                  }
                }
              }
            }
            y.Streams?.forEach((z, indexStream) => {
              if (!z.StreamKey) {
                return;
              }
              if (maxStreamsCount > 0 && indexStream >= maxStreamsCount) {
                return;
              }
              const streamOption: StreamOption = JSON.parse(JSON.stringify(widgetDescription.defaultStreamOptions));
              streamOption.StreamKey = z.StreamKey;
              var stream = this.findSelectedStream(this.selectedPoints, z.StreamKey);
              if (!stream) {
                return;
              }
              // fill name
              streamOption.Name = stream.label ?? z.StreamKey;
              streamOption.Label = stream.label ?? z.StreamKey;
              streamOption.structured = true;
              // stream units
              if (typeof streamOption.units !== "undefined" || 
                typeof newWidget[1].widgetOptions.advancedWidgetSettings?.widgetUnit !== "undefined"
              ) {
                const unitTag = stream.tags?.find(t => t.startsWith("unit="));
                if (unitTag) {
                  if (typeof streamOption.units !== "undefined") {
                    streamOption.units = unitTag.substring(5);
                  }
                  if (typeof newWidget[1].widgetOptions.advancedWidgetSettings?.widgetUnit !== "undefined") {
                    newWidget[1].widgetOptions.advancedWidgetSettings.widgetUnit = unitTag.substring(5);
                  }
                }
              }
              // color
              if (streamOption.hexStreamColor) {
                const colorHex = streamColors.find(c => c.streamKey === z.StreamKey)?.color;
                if (colorHex) {
                  streamOption.hexStreamColor = colorHex;
                } else {
                  streamOption.hexStreamColor = ColorHelper.random();
                }
              }
              if (z.AggregationType) {
                // "sum", "avg", "min", "max", "diff", "first", "last"
                switch (z.AggregationType) {
                  case "sum":
                    streamOption.Params.aggType = AggregationType.Sum;
                    break;
                  case "avg":
                    streamOption.Params.aggType = AggregationType.Avg;
                    break;
                  case "min":
                    streamOption.Params.aggType = AggregationType.Min;
                    break;
                  case "max":
                    streamOption.Params.aggType = AggregationType.Max;
                    break;
                  case "diff":
                    streamOption.Params.aggType = AggregationType.Diff;
                    break;
                  case "first":
                    streamOption.Params.aggType = AggregationType.First;
                    break;
                  case "last":
                    streamOption.Params.aggType = AggregationType.Last;
                    break;
                }
              }
              if (z.AggregationTypeString) {
                // "most", "least", "first", "last"
                switch (z.AggregationTypeString) {
                  case "most":
                    streamOption.Params.aggTypeString = AggregationTypeString.Most;
                    break;
                  case "least":
                    streamOption.Params.aggTypeString = AggregationTypeString.Least;
                    break;
                  case "first":
                    streamOption.Params.aggTypeString = AggregationTypeString.First;
                    break;
                  case "last":
                    streamOption.Params.aggTypeString = AggregationTypeString.Last;
                    break;
                }
              }
              if (z.SeriesType) {
                if (widgetDescription.features.includes(WidgetFeature.dataStreamsSeriesType)) {
                  streamOption.type = z.SeriesType;
                } else if (widgetDescription.features.includes(WidgetFeature.dataStreamsSeriesTypeStacked)) {
                  streamOption.stackedType = z.SeriesType;
                }
              }
              newWidget[1].widgetOptions.widgetDataSettings?.streamOptions.push(streamOption);
            });
          }

          newGrid.widgets?.push(newWidget[0]);
          widgets.push(newWidget[1]);
        }
      });
      grids.push(newGrid);
    });
    // save widgets
    this.dashboardState.widgets?.push(...widgets);
    await this.$store.dispatch("dashboard/saveWidgets", widgets);
    widgets.forEach(x => {
      const state1 = this.dashboardState.widgetState[x.guid];
      if (state1 && state1[0] && !state1[2]) {
        // ok
      } else {
        const index = this.dashboardState.widgets?.findIndex(y => y.guid === x.guid);
        if (typeof index === "number" && index >= 0) {
          this.dashboardState.widgets?.splice(index, 1);
        }
      }
    })
    // save grids
    dashboard.widgets.push(...grids);
    await this.$store.dispatch(
      "dashboard/saveDashboard", 
      { 
        dashboard: dashboard,
        organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
      }
    );
    this.closeDialog();
    grids.forEach(x => {
      this.emitter.emit("grid_created", x.guid);
    });
    ToastService.showToast("success", "Bitpool AI", response.Comment, 10000);
    this.aiGenerationInProgress = false;
  }
  // #endregion ai

  // #region themes
  updateColorTheme(theme: ColorThemeEntity | null, index: number, color: string): void { 
    if (theme) {
      const custom = this.themes.find(x => x.Id === "custom");
      if (custom) {
        if (theme.Id !== "custom") {
          custom.Colors = [...theme.Colors];
        }
        if (color === "delete") {
          custom.Colors.splice(index, 1);
        } else {
          custom.Colors[index] = color;
        }
        if (theme.Id !== "custom") {
          this.themeHolder = custom;
        }
      }
    }
  }

  addColorToTheme(theme: ColorThemeEntity | null): void {
    if (theme) {
      const custom = this.themes.find(x => x.Id === "custom");
      if (custom) {
        if (theme.Id !== "custom") {
          custom.Colors = [...theme.Colors];
          this.themeHolder = custom;
        }
        this.themeHolder?.Colors.push(this.colorHelper.random());
      }
    }
  }

  colorGenerationInProgress = false;

  async generateColorsForTheme(theme: ColorThemeEntity | null): Promise<void> {
    if (this.colorGenerationInProgress) {
      return;
    }
    if (theme) {
      const custom = this.themes.find(x => x.Id === "custom");
      if (custom) {
        this.colorGenerationInProgress = true;
        try {
          if (theme.Id !== "custom") {
            custom.Colors = [...theme.Colors];
            this.themeHolder = custom;
          }

          let count = this.selectedPoints.length - custom.Colors.length;
          if (count <= 0) {
            count = 1;
          }

          const colorsHex = [...custom.Colors];
          if (colorsHex.length === 0) {
            colorsHex.push(this.colorHelper.random());
          }
          const colors: string[] = [];
          // Convert rgb to hsl using chroma.js
          colorsHex.forEach(x => {
            const color = chroma(x);
            const hslArray = color.hsl();
            const hslString = `hsl(${Math.round(hslArray[0])}, ${Math.round(hslArray[1] * 100)}%, ${Math.round(hslArray[2] * 100)}%)`;
            colors.push(hslString);
          });
          const newColors = await this.bitpoolAIChatStore.generateColors(colors, count);
          newColors.forEach(x => {
            // Convert hsl to rgb using chroma.js
            const color = chroma(x);
            const rgb = color.rgb();
            const colorHex = chroma.rgb(rgb[0], rgb[1], rgb[2]).hex();
            custom.Colors.push(colorHex);
          });
        }
        finally {
          this.colorGenerationInProgress = false;
        }
      }
    }
  }
  // #endregion themes

  // #region widgets
  widgetGroupsActiveIndex: number[] = [];
  widgetGroups: [WidgetGroupDescription, WidgetDescription[]][] = [];
  availableWidgets: WidgetDescription[] = [];

  initWidgetGroups(): void {
    const groups = WidgetHelper.getGroups();
    const allWidgets = WidgetHelper.getWidgetsListForAI();
    this.availableWidgets = allWidgets;
    let widgetGroups: [WidgetGroupDescription, WidgetDescription[]][] = groups.map(group => [group, allWidgets.filter(x => x.group === group.name)]);
    widgetGroups = widgetGroups.filter(x => x[1].length > 0);
    this.widgetGroups = widgetGroups;
  }

  openWidgetGroups(): void {
    const indexes = this.widgetGroups.map((x, index) => index);
    this.widgetGroupsActiveIndex = indexes;
  }

  toggleWidget(widget: WidgetDescription): void {
    const index = this.selectedWidgets.findIndex(x => x === widget.name);
    if (index !== -1) {
      this.selectedWidgets.splice(index, 1);
    } else {
      this.selectedWidgets.push(widget.name);
    }
  }

  getSelectedWidgetCount(widgets: WidgetDescription[]): number {
    return widgets.filter(x => this.selectedWidgets.includes(x.name)).length;
  }

  selectAllWidgets(): void {
    this.selectedWidgets = this.widgetGroups.flatMap(x => x[1].map(y => y.name));
  }

  selectNoneWidgets(): void {
    this.selectedWidgets = [];
  }

  searchWidgets = "";
  searchFinalWidgets = "";
  debounceSearchWidgets = debounce(500, this.updateFinalSearchWidgets);

  async updateFinalSearchWidgets(): Promise<void> {
    this.searchFinalWidgets = this.searchWidgets;
    // open accordion with widgets
    await nextTick();
    const visibleWidgets = this.visibleWidgets;
    const visibleGroupsNames = this.widgetGroups
      .filter(x => visibleWidgets.some(y => y.group === x[0].name))
      .map(x => x[0].name);
    const visibleGroupsIndexes: number[] = this.widgetGroups
      .map((x, index): [number, string] => [index, x[0].name])
      .filter(x => visibleGroupsNames.some(y => y === x[1]))
      .map(x => x[0]);
    this.widgetGroupsActiveIndex = visibleGroupsIndexes;
  }

  get visibleWidgets(): WidgetDescription[] {
    return this.widgetGroups.flatMap(x => x[1]).filter(x => this.isWidgetVisible(x));
  }

  isWidgetVisible(widget: WidgetDescription): boolean {
    if (this.searchFinalWidgets) {
      const regex = new RegExp(this.searchFinalWidgets, "i");
      const result = widget.displayName.match(regex);
      return !!result;
    } else {
      return true;
    }
  }
  // #endregion widgets
}

export default DashboardNewDialogView;
</script>