<template>
  <div class="default-view-body">
    <DataTable
      :value="aiPersonaStore.entities" 
      dataKey="Id"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width">
      <template #header>
        <div class="table-header">
          <Button :disabled="!aiPersonaStore.isLoaded" label="Add Persona" icon="pi pi-plus-circle" class="my-1" @click="openCreateUpdateDialog(null)" />
        </div>
      </template>
      <template #empty>
        <div v-if="aiPersonaStore.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Enabled" header="Enabled" headerStyle="width: 1%; min-width: 65px;">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.Enabled ? 'Yes' : 'No' }}
          </div>
        </template>
      </Column>
      <Column field="Name" header="Name" headerStyle="min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word"></Column>
      <Column field="Model" header="Model" headerStyle="width: 15%; min-width: min-content;" headerClass="no-break-word">
        <template #body="slotProps">
          <div>{{aiModelStore.endpointModels && aiModelStore.endpointModels[slotProps.data.Endpoint] && aiModelStore.endpointModels[slotProps.data.Endpoint][slotProps.data.Model] ? aiModelStore.endpointModels[slotProps.data.Endpoint][slotProps.data.Model] : slotProps.data.Model}}</div>
        </template>
      </Column>
      <Column field="Personality" header="Personality" headerStyle="width: 50%; min-width: min-content;"></Column>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <div>
            <div class="inline-flex">
              <Button 
                icon="pi pi-clone" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCloneDialog(slotProps.data)"
                v-tippy="'Copy'"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCreateUpdateDialog(slotProps.data)"
                v-tippy="'Edit'"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                :disabled="slotProps.data.Default"
                v-tippy="'Delete'"
              />
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
    <Dialog header="Persona" v-model:visible="displayCreateUpdateDialog" :modal="true" :style="{width: '56rem'}" class="bitpool-ai-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
          <div class="admin-bitpool-ai-settings-container">
            <div class="formgrid grid">
              <div class="field col-12">
                <div class="flex align-items-center">
                  <InputSwitch 
                    inputId="editRecordEnabled"
                    v-model="editRecord.Enabled"
                    class="vertical-align-top"
                  />
                  <label for="editRecordEnabled" class="mb-0 ml-2">Enabled</label>
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <label for="editRecordName">Name</label>
                <div>
                  <InputText 
                    id="editRecordName" 
                    class="inputfield w-full"
                    type="text" 
                    v-model="editRecord.Name"
                  />
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <label for="editRecordEndpoint">Endpoint</label>
                <div>
                  <Dropdown 
                    inputId="editRecordEndpoint"
                    v-model="editRecord.Endpoint"
                    :options="availableEndpoints"
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Select Endpoint"
                    class="w-full"
                    @change="onEndpointChanged"
                  />
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <span class="inline-flex align-items-center gap-2 mb-2" :class="(aiModelStore.endpoints?.find(x => x.Id === editRecord?.Endpoint)?.ConfigurationUrl) ? 'with-inline-btn' : ''">
                  <label for="editRecordModel" class="mb-0">Model</label>
                  <Button
                    v-if="aiModelStore.endpoints?.find(x => x.Id === editRecord?.Endpoint)?.ConfigurationUrl"
                    icon="pi pi-external-link" 
                    class="p-button-icon-only p-button-rounded p-button-text action-button-size"
                    @click="openUrl(aiModelStore.endpoints?.find(x => x.Id === editRecord?.Endpoint)?.ConfigurationUrl ?? '')"
                  />
                </span>
                <div>
                  <Dropdown 
                    inputId="editRecordModel"
                    v-model="editRecord.Model"
                    :options="availableModels"
                    filter
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Select Model"
                    class="w-full"
                  />
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <label for="editRecordVoice">Voice</label>
                <div>
                  <Dropdown 
                    inputId="editRecordVoice"
                    v-model="editRecord.Voice"
                    :options="availableVoices"
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Select Voice"
                    class="w-full"
                  />
                </div>
              </div>
              <div class="field col-12 mb-0">
                <span class="inline-flex align-items-center gap-2 mb-2">
                  <label for="editRecordPersonality" class="mb-0">Personality</label>
                  <i 
                    v-if="aiModelStore.endpoints?.find(x => x.Id === editRecord?.Endpoint)?.ConfigurationUrl"
                    v-tippy="'Overwrite AI Instructions'"
                    class="pi pi-info-circle"
                  >
                  </i>
                </span>
                <div>
                  <Textarea 
                    id="editRecordPersonality" 
                    class="inputfield w-full"
                    style="white-space: pre-wrap;" 
                    :autoResize="true" 
                    :rows="3"
                    placeholder="You can describe bot personality. Optional."
                    v-model="editRecord.Personality"
                  />
                </div>
              </div>
              <div class="col-12 admin-bitpool-ai-settings-conversations-container">
                <div class="formgrid grid">
                  <div class="col-12">
                    <div class="inline-flex align-items-center">
                      <h4>Questions</h4>
                      <Button 
                        @click="editRecord.Questions.push('')"
                        class="p-button-outlined p-button-rounded admin-bitpool-ai-settings-control-btn ml-3 flex-none" 
                        icon="pi pi-plus"
                        label="Add" />
                    </div>
                  </div>
                  <div class="col-12">
                    <!-- Questions List -->
                    <div 
                      class="admin-bitpool-ai-settings-colored-box admin-bitpool-ai-settings-conversation-item admin-bitpool-ai-settings-question" 
                      v-for="(question, i) in editRecord.Questions"
                      :key="i" 
                      :id="`questionContainer${i}`"
                    >
                      <div class="admin-bitpool-ai-settings-conversation-item-header">
                        <div>
                          <h5>
                            <div class="flex-auto">
                              <Textarea 
                                :id="`editRecordQuestion${i}`" 
                                class="inputfield w-full"
                                style="white-space: pre-wrap;" 
                                :autoResize="true" 
                                :rows="1"
                                v-model="editRecord.Questions[i]"
                              />
                            </div>
                          </h5>
                        </div>
                        <div>
                          <div class="flex-none">
                            <Button
                              class="p-button-outlined p-button-danger p-button-icon-only p-button-rounded admin-bitpool-ai-settings-control-btn"
                              icon="pi pi-trash" 
                              @click="editRecord.Questions.splice(i, 1)" 
                              v-tippy="'Delete'">
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button 
              v-if="editRecord" 
              :label="editRecord.Id ? 'Update' : 'Create'" 
              :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
              @click="saveRecord" 
              :disabled='!editRecord.Name || !editRecord.Endpoint|| !editRecord.Model || !editRecord.Voice || updateInProgress || !authState.permissions?.BitpoolAdmin' 
            />
          </span>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Message from 'primevue/message';
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import moment from "moment";
import ConfirmationService from "@/services/ConfirmationService";
import { useAIPersonaStore } from "@/stores/aiPersona";
import { AIPersonaEntity } from "@/models/bitpool-ai/AIPersonaEntity";
import { useAIModelStore } from "@/stores/aiModel";
import { useOrganisationStore } from "@/stores/organisation";
import { useAIVoiceStore } from "@/stores/aiVoice";

@Component({
  components: {
    Message,
    Button,
    InputText,
    InputNumber,
    Textarea,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown
  },
})
class BitpoolAIPersonaView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  aiPersonaStore = useAIPersonaStore();
  aiModelStore = useAIModelStore();
  aiVoiceStore = useAIVoiceStore();

  get availableEndpoints(): any[] {
    return this.aiModelStore.endpoints ? this.aiModelStore.endpoints
      .map(x => { return { label: x.Name, value: x.Id }; }) : [];
  }

  onEndpointChanged(): void {
    if (this.editRecord) {
      this.editRecord.Model = "";
    }
  }

  get availableModels(): any[] {
    if (this.editRecord && this.editRecord.Endpoint && this.aiModelStore.endpointModels) {
      const models = this.aiModelStore.endpointModels[this.editRecord.Endpoint];
      return models ? Object.keys(models)
        .map(x => { return { label: models ? models[x] : x, value: x }; }) : [];
    } else {
      return [];
    }
  }

  get availableVoices(): any[] {
    return this.aiVoiceStore.entities ? this.aiVoiceStore.entities
      .map(x => { return { label: (x.charAt(0).toUpperCase() + x.slice(1)), value: x }; }) : [];
  }

  created(): void {
    if (!this.aiModelStore.isLoaded || this.aiModelStore.entities == null) {
      this.aiModelStore.load();
    }
    if (!this.aiVoiceStore.isLoaded || this.aiVoiceStore.entities == null) {
      this.aiVoiceStore.load();
    }
  }

  openUrl(url: string): void {
    window.open(url, '_blank')
  }

  // #region new/edit
  displayCreateUpdateDialog = false;
  editRecord: AIPersonaEntity | null = null;

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  openCloneDialog(record: AIPersonaEntity): void {
    const newRecord: AIPersonaEntity = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    newRecord.Default = false;
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: AIPersonaEntity | null): void {
    const nowUtc = moment.utc().toDate();
    this.editRecord = record ? JSON.parse(JSON.stringify(record)) : {
      Id: "",
      Name: "",
      Model: "",
      Personality: "",
      Enabled: true,
      Default: false,
      Created: nowUtc,
      Updated: nowUtc,
      CreatedBy: this.allUserData.userName,
      UpdatedBy: this.allUserData.userName,
      Questions: [],
      Voice: "",
      Endpoint: "openai-chat"
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.aiPersonaStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.aiPersonaStore.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.aiPersonaStore.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: AIPersonaEntity | null = null;

  openConfirmation(record: AIPersonaEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete persona?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Persona',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.aiPersonaStore.delete(this.selectedRecord);
    }
  }
  // #endregion delete
}

export default BitpoolAIPersonaView;
</script>