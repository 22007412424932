import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { MongoEntityPage } from "@/models/MongoEntityPage";
import Reports3ElementsState from "./states/Reports3ElementsState";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { HtmlToPdfReportParameters } from "@/models/reports/v3/HtmlToPdfReportParameters";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import moment from "moment";
import { useOrganisationStore } from "./organisation";
import NavigationHelper from "@/helpers/NavigationHelper";
import DateHelper from "@/helpers/DateHelper";

export const useReports3ElementsStore = defineStore('reports3Elements', {
  state: (): Reports3ElementsState => ({ 
    isLoaded: false,
    guid: "",
    data: null,
    take: 20,
    skip: 0,
    search: "",
    elementRole: null,
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false,

    inProgressTest: false,
    testResult: null,
    testError: "",

    isLoadedOne: false,
    guidOne: "",
    dataOne: null,
    isOneDirty: false
  }),
  getters: {
  },
  actions: {
    async load(skip: number, take: number, search: string, elementRole: Reports3ElementRole | null = null) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.data = null;
        this.skip = skip;
        this.take = take;
        this.search = search;
        this.elementRole = elementRole;
        let url = `rest/Reports_V3/Elements?skip=${skip}&limit=${take}&search=${search}`;
        if (elementRole !== null) {
          url +=`&elementRole=${elementRole}`;
        }
        const response = await axios.get<MongoEntityPage<Reports3ElementEntity>>(url);
        if (this.guid === guid) {
          this.data = response.data;
          response.data.Items.forEach(x => {
            x.Created = DateHelper.parseFromMicrosoftString(x.Created as string);
            x.Updated = DateHelper.parseFromMicrosoftString(x.Updated as string);
          });
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load reports elements",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = null;
        this.isLoaded = true;
      }
    },
    async loadOne(id: string) {
      try {
        const guid = uuidv4();
        this.guidOne = guid;
        this.isLoadedOne = false;
        this.dataOne = null;
        this.isOneDirty = false;
        if (id === "new") {
          const organisationStore = useOrganisationStore();
          const nowUtc = moment.utc().toDate();
          const newRecord: Reports3ElementEntity = {
            Id: "",
            Name: "",
            Description: "",
            Image: "",
            HTML: `<!-- This HTML template is used to display the data -->
<div>todo: write HTML here</div>`,
      CSS: `/* This CSS template is used to style the data */
/* todo: write CSS here */`,
      JavaScript: `/**
* This function is used to display the data
*
* @param parent - The parent HTML element
* @param parameters - The parameters to be used
* @param data - The data to be displayed
* @param dataConfiguration - The configuration of the data
* @param libraries - The libraries to be used
* @param onReadyEvent - The event to be called when the data is ready
*/
function displayData(parent, parameters, data, dataConfiguration, libraries, onReadyEvent) {
  try {
    // todo: write JavaScript here
  } catch (error) {
    // todo: handle error
  } finally {
    onReadyEvent();
  }
}`,
            Role: Reports3ElementRole.Body,
            Height: 0,
            DefaultSize: 12,
            EnableData: false,
            DataStreamsMaxCount: 0, // 0 - unlimited
            Features: [],
            AdditionalParameters: [],
            OrganisationId: organisationStore.currentOrganisation?.Id ?? -1,
            Created: nowUtc,
            Updated: nowUtc,
            CreatedBy: "", // api will fill it
            UpdatedBy: "" // api will fill it
          };
          this.dataOne = newRecord;
          this.isLoadedOne = true;
        } else {
          const url = `rest/Reports_V3/Elements/${id}`;
          const response = await axios.get<Reports3ElementEntity | null>(url);
          if (this.guidOne === guid) {
            this.dataOne = response.data;
            this.isLoadedOne = true;
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load reports element",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.dataOne = null;
        this.isLoadedOne = true;
      }
    },
    async getDefaultHeaderAndFooter(): Promise<Reports3ElementEntity[]> {
      try {
        let url = `rest/Reports_V3/Elements/default`;
        const response = await axios.get<Reports3ElementEntity[]>(url);
        response.data.forEach(x => {
          x.Created = DateHelper.parseFromMicrosoftString(x.Created as string);
          x.Updated = DateHelper.parseFromMicrosoftString(x.Updated as string);
        });
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load reports elements",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return [];
      }
    },
    async createUpdate(entity: Reports3ElementEntity): Promise<Reports3ElementEntity | null> {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/Reports_V3/Elements`;
        const response = await axios.post<Reports3ElementEntity>(url, entity);
        if (this.isLoaded && this.data) {
          if (entity.Id) {
            const index = this.data.Items.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.data.Items[index] = response.data;
            } else {
              await this.load(0, this.take, this.search, this.elementRole);
            }
          } else {
            await this.load(0, this.take, this.search, this.elementRole);
          }
        }
        if (this.isLoadedOne && this.dataOne) {
          if (!entity.Id) {
            // new
            NavigationHelper.goTo(`/report-manager/element/${response.data.Id}`);
          }
        }
        ToastService.showToast("success", "Reports Elements", "Changes saved", 5000);
        this.updateInProgress = false;
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save reports element",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return null;
      }
    },
    async generate(body: HtmlToPdfReportParameters): Promise<boolean> {
      try {
        this.inProgressTest = true;
        this.testError = "";
        this.testResult = null;
        const url = `rest/Reports_V3/Reports/Generate`;
        const response = await axios.post<string>(url, body);
        this.inProgressTest = false;
        this.testResult = response.data;
        return true;
      } catch (error) {
        this.inProgressTest = false;
        this.testError = ErrorHelper.handleAxiosError(error).message;
        return false;
      }
    },
    async delete(entity: Reports3ElementEntity): Promise<boolean> {
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/Reports_V3/Elements/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Reports Elements", `${entity.Name} is deleted`, 5000);
        if (this.isLoaded && this.data) {
          const index = this.data.Items.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.data.Items.splice(index, 1);
            this.data.Total--;
          }
        }
        this.deleteInProgress = false;
        this.deleteError = false;
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
        return false;
      }
    }
  },
})
