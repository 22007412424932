<template>
  <span class="p-button-container">
    <Button 
      @click="showAddMenu($event)"
      :class="buttonClasses"
      v-if="!noButton"
    >
      <span class="text-lg lg:text-2xl p-button-icon p-button-icon-left svg-icon">
        <AddWidgetSvg/>
      </span>
      <span class="p-button-label">Add</span>
    </Button>
    <Menu ref="addMenu" :model="addMenuItems" :popup="true" />

    <Dialog header="Add Widget" v-model:visible="displayDialog" :modal="true" :breakpoints="{'992px': '90vw'}" :style="{width: '60.5rem'}" class="add-widget-dialog">
      <template #header>
        <div class="flex flex-wrap sm:flex-nowrap sm:flex-auto sm:justify-content-between sm:align-items-center">
          <span class="p-dialog-title mb-3 sm:mb-0">Add Widget</span>
          <IconField iconPosition="left" class="search-input-box">
            <InputIcon class="pi pi-search lg:text-xl"></InputIcon>
            <InputText
              class="inputfield text-base lg:text-lg"
              placeholder="Search"
              type="text"
              v-model="search"
              @input="debounceSearch()"
            />
          </IconField>
        </div>
      </template>
      <div class="dialog-content">
        <BlockUI :blocked="addWidgetInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner blockui-with-overlay-z-index" :class="addWidgetInProgress ? 'blockui-blocked' : ''"> 

          <div class="add-widget-content">
            <div class="add-widget-container add-widget-group">
              <div class="add-widget-container-header">
                <h3>Widget Group</h3>
                <span @click="toggleHideGroupSelect" class="link" :class="{ 'is-open': !hideGroupSelect }">
                  {{hideGroupSelect ? "Show" : "Hide"}} <i class="pi" :class="{ 'pi-chevron-up': !hideGroupSelect, 'pi-chevron-down': hideGroupSelect }"></i>
                </span>
              </div>
              <transition name="p-toggleable-content">
                <div class="add-widget-container-body" v-if="!hideGroupSelect">
                  <div class="add-widget-group-list">
                    <div v-for="group in groups" :key="group.name" @click="selectGroup(group.name)" :class="{'is-active': group.name === selectedGroup}">
                      <span>
                        <InlineSvg :src="group.icon"/>
                      </span>
                      <h4>{{group.name}}</h4>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="add-widget-types">
            <div v-for="group in groups" :key="group.name">
              <div v-if="(!selectedGroup || group.name === selectedGroup) && getWidgets(group.name).length" class="add-widget-container add-widget-type">
                <div class="add-widget-container-header">
                  <h3>{{group.name}}</h3>
                  <span @click="toggleHideGroups(group.name)" class="link" :class="{ 'is-open': !hideGroups[group.name] }">
                    {{hideGroups[group.name] ? "Show" : "Hide"}} <i class="pi" :class="{ 'pi-chevron-up': !hideGroups[group.name], 'pi-chevron-down': hideGroups[group.name] }"></i>
                  </span>
                </div>
                <transition name="p-toggleable-content">
                  <div class="add-widget-container-body" v-if="!hideGroups[group.name]">
                    <div class="add-widget-type-list">
                      <div v-for="widget in getWidgets(group.name)" :key="widget.name" @click="addWidget(widget)" :class="widget.iconClass">
                        <span>
                          <InlineSvg :src="widget.icon"/>
                        </span>
                        <div>
                          <h5>{{widget.displayName}}</h5>
                          <p>{{widget.description}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
          
        </BlockUI>
      </div>
      <template #footer></template>
    </Dialog>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Tag from 'primevue/tag';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { MenuItem } from "primevue/menuitem";
import InputText from 'primevue/inputtext';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { Ref } from "vue-facing-decorator";
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import SpaceHelper from "@/helpers/SpaceHelper";
import WidgetHelper from "@/helpers/WidgetHelper";
import { WidgetGroupDescription } from "@/models/dashboard/WidgetGroupDescription";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import { debounce } from 'throttle-debounce';
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import AddWidgetSvg from "@/components/svg/AddWidgetSvg.vue";
import DashboardState from "@/store/states/DashboardState";
import { Space } from "@/models/dashboard/Space";
import InlineSvg from 'vue-inline-svg';
import AuthState from "@/store/states/AuthState";
import { DashboardType } from "@/models/dashboard/DashboardType";
import { useOrganisationStore } from "@/stores/organisation";

@Component({
  components: {
    Tag,
    Button,
    Dialog,
    Menu,
    InputText,
    BlockUI,
    ProgressSpinner,
    IconField,
    InputIcon,
    AddWidgetSvg,
    InlineSvg
  },
})
class DashboardAddWidgetView extends Vue {
  @Prop({ required: true }) dashboardId!: string;
  @Prop({ required: true }) widgets!: SpaceWidgetConfig[];
  @Prop({ required: true }) buttonClasses!: string;
  @Prop({ required: true }) noButton!: boolean;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  @Ref() readonly addMenu!: Menu;
  get addMenuItems(): MenuItem[] {
    const result: MenuItem[] = [
      {
        label: 'Add Grid',
        icon: undefined,
        command: () => {
          this.addGrid();
        },
      },
      {
        label: 'Add Widget',
        icon: undefined,
        command: () => {
          this.openDialog();
        },
      }
    ];
    return result;
  }

  search = '';
  searchFinal = '';
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
    if (this.searchFinal) {
      this.hideGroupSelect = true;
    } else {
      this.hideGroupSelect = false;
    }
  }

  selectedGroup = "";
  selectGroup(group: string): void {
    if (this.selectedGroup === group) {
      this.selectedGroup = "";
    } else {
      this.selectedGroup = group;
    }
  }

  hideGroupSelect = false;
  toggleHideGroupSelect(): void {
    this.hideGroupSelect = !this.hideGroupSelect;
  }
  
  hideGroups: Record<string, boolean> = {};
  toggleHideGroups(name: string): void {
    this.hideGroups[name] = !this.hideGroups[name];
  }

  showAddMenu(event: Event): void {
    this.addMenu.show(event);
  }

  displayDialog = false;

  openDialog(): void {
    this.displayDialog = true;
  }

  closeDialog(): void {
    this.displayDialog = false;
  }

  get groups(): WidgetGroupDescription[] {
    return WidgetHelper.getGroups();
  }

  getWidgets(group: string): WidgetDescription[] {
    let result = WidgetHelper.getWidgets(group);
    if (this.searchFinal) {
      const regex = new RegExp(this.searchFinal, "i");
      result = result.filter(x => x.displayName.match(regex));
    }
    return result;
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  get dashboards(): Space[] | null | undefined {
    return this.$store.getters["dashboard/getDashboards"];
  }
  
  addWidgetInProgress = false;

  async addGrid(): Promise<void> {
    const dashboard = this.dashboards?.find(x => x._id === this.dashboardId);
    if (this.widgets && dashboard) {
      const newGrid = SpaceHelper.createGrid();
      this.widgets.push(newGrid);
      let isOk = false;
      await this.$store.dispatch(
        "dashboard/saveDashboard", 
        { 
          dashboard: dashboard,
          organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
        }
      );
      const state = this.dashboardState.dashboardState[this.dashboardId];
      if (state && state[0] && !state[2]) {
        isOk = true;
      }
      if (isOk) {
        this.emitter.emit("grid_created", newGrid.guid);
      } else {
        const index = this.widgets.findIndex(x => x.guid === newGrid.guid);
        if (index >= 0) {
          this.widgets.splice(index, 1);
        }
      }
    }
  }

  async addWidget(widget: WidgetDescription): Promise<void> {
    const dashboard = this.dashboards?.find(x => x._id === this.dashboardId);
    if (widget.defaultOptions && dashboard) {
      const newWidget = SpaceHelper.createWidget(widget);
      this.addWidgetInProgress = true;
      this.widgets.push(newWidget[0]);
      this.dashboardState.widgets?.push(newWidget[1]);
      await this.$store.dispatch("dashboard/saveWidget", newWidget[1]);
      const state1 = this.dashboardState.widgetState[newWidget[1].guid];
      let isOk = false;
      if (state1 && state1[0] && !state1[2]) {
        await this.$store.dispatch(
          "dashboard/saveDashboard", 
          { 
            dashboard: dashboard,
            organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
          }
        );
        const state2 = this.dashboardState.dashboardState[this.dashboardId];
        if (state2 && state2[0] && !state2[2]) {
          isOk = true;
        }
      }
      this.addWidgetInProgress = false;
      if (isOk) {
        this.closeDialog();
        this.emitter.emit("widget_created", newWidget[0].guid);
      } else {
        const index1 = this.widgets.findIndex(x => x.guid === newWidget[0].guid);
        if (index1 >= 0) {
          this.widgets.splice(index1, 1);
        }
        if (this.dashboardState.widgets) {
          const index2 = this.dashboardState.widgets.findIndex(x => x.guid === newWidget[1].guid);
          if (index2 >= 0) {
            this.dashboardState.widgets.splice(index2, 1);
          }
        }
      }
    } else {
      alert("Not yet implemented")
    }
  }
}

export default DashboardAddWidgetView;
</script>