import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { MongoEntityPage } from "@/models/MongoEntityPage";
import NavigationHelper from "@/helpers/NavigationHelper";
import Reports3SchedulesState from "./states/Reports3SchedulesState";
import { Reports3ScheduleEntity } from "@/models/reports/v3/Reports3ScheduleEntity";
import { Reports3ScheduleEntityLight } from "@/models/reports/v3/Reports3ScheduleEntityLight";

export const useReports3SchedulesStore = defineStore('reports3Schedules', {
  state: (): Reports3SchedulesState => ({ 
    isLoaded: false,
    guid: "",
    data: null,
    take: 20,
    skip: 0,
    search: "",
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false,
    
    isLoadedLight: false,
    guidLight: "",
    dataLight: null,

    isLoadedOne: false,
    guidOne: "",
    dataOne: null,
    isOneDirty: false
  }),
  getters: {
  },
  actions: {
    async load(skip: number, take: number, search: string) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.data = null;
        this.skip = skip;
        this.take = take;
        this.search = search;
        const url = `rest/Reports_V3/Schedules?skip=${skip}&limit=${take}&search=${search}`;
        const response = await axios.get<MongoEntityPage<Reports3ScheduleEntity>>(url);
        if (this.guid === guid) {
          this.data = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load reports schedules",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = null;
        this.isLoaded = true;
      }
    },
    async loadLight() {
      try {
        const guid = uuidv4();
        this.guidLight = guid;
        this.isLoadedLight = false;
        this.dataLight = null;
        const url = `rest/Reports_V3/Schedules/light`;
        const response = await axios.get<Reports3ScheduleEntityLight[]>(url);
        if (this.guidLight === guid) {
          this.dataLight = response.data;
          this.isLoadedLight = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load reports schedules",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.dataLight = null;
        this.isLoadedLight = true;
      }
    },
    async loadOne(id: string) {
      try {
        const guid = uuidv4();
        this.guidOne = guid;
        this.isLoadedOne = false;
        this.dataOne = null;
        this.isOneDirty = false;
        const url = `rest/Reports_V3/Schedules/${id}`;
        const response = await axios.get<Reports3ScheduleEntity | null>(url);
        if (this.guidOne === guid) {
          this.dataOne = response.data;
          this.isLoadedOne = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load reports schedule",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.dataOne = null;
        this.isLoadedOne = true;
      }
    },
    async createUpdate(entity: Reports3ScheduleEntity): Promise<Reports3ScheduleEntity | null> {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/Reports_V3/Schedules`;
        const response = await axios.post<Reports3ScheduleEntity>(url, entity);
        if (this.isLoaded && this.data) {
          if (entity.Id) {
            const index = this.data.Items.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.data.Items[index] = response.data;
            } else {
              await this.load(0, this.take, this.search);
            }
          } else {
            await this.load(0, this.take, this.search);
          }
        }
        if (this.isLoadedOne && this.dataOne) {
          if (!entity.Id) {
            // new
            NavigationHelper.goTo(`/report-manager/schedule/${response.data.Id}`);
          }
        }
        ToastService.showToast("success", "Reports Schedules", "Changes saved", 5000);
        this.updateInProgress = false;
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save reports schedule",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return null;
      }
    },
    async delete(entity: Reports3ScheduleEntity): Promise<boolean> {
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/Reports_V3/Schedules/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Reports Schedules", `${entity.Name} is deleted`, 5000);
        if (this.isLoaded && this.data) {
          const index = this.data.Items.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.data.Items.splice(index, 1);
            this.data.Total--;
          }
        }
        this.deleteInProgress = false;
        this.deleteError = false;
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
        return false;
      }
    }
  },
})
