import { defineStore } from 'pinia';
import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { OrganisationExportModel } from "@/models/OrganisationExportModel";
import AdminOrganisationStatsState from './states/AdminOrganisationStatsState';

export const useAdminOrganisationStatsStore = defineStore('adminOrganisationStats', {
  state: (): AdminOrganisationStatsState => ({
    data: [],
    isLoaded: false
  }),

  actions: {
    async loadData() {
      try {
        this.isLoaded = false;
        const url = `rest/BitPool_V2/organisations/stats`;
        const response = await axios.get<OrganisationExportModel[]>(url);
        this.data = response.data;
        this.isLoaded = true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load organisation stats",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = [];
        this.isLoaded = true;
      }
    }
  }
});