export default class CronConfig {
  private _modes: string[] = ["Yearly", "Monthly", "Weekly", "Daily"];
  private _daysOfWeek: [string, string][] = [
    ["2", "Monday"],
    ["3", "Tuesday"],
    ["4", "Wednesday"],
    ["5", "Thursday"],
    ["6", "Friday"],
    ["7", "Saturday"],
    ["1", "Sunday"]
  ];
  private _months: [string, string][] = [
    ["1", "January"],
    ["2", "February"],
    ["3", "March"],
    ["4", "April"],
    ["5", "May"],
    ["6", "June"],
    ["7", "July"],
    ["8", "August"],
    ["9", "September"],
    ["10", "October"],
    ["11", "November"],
    ["12", "December"]
  ];
  private _days: string[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
  private _mode: string = "Yearly";
  private _dayOfWeek: string = "?";
  private _month: string = "1";
  private _day: string = "1";
  private _hour: string = "0";
  private _minute: string = "0";
  private _second: string = "0";
  private _cronExpression: string = "";

  constructor(cronExpression?: string) {
    this._cronExpression = cronExpression || "";
    if (cronExpression) {
      this.parse();
    }
  }

  get modes(): string[] {
    return this._modes;
  }

  get daysOfWeek(): [string, string][] {
    return this._daysOfWeek;
  }

  get months(): [string, string][] {
    return this._months;
  }
  get days(): string[] {
    return this._days;
  }

  get mode(): string {
    return this._mode;
  }

  set mode(value: string) {
    if (value === "Yearly") {
      this._month = "1";
      this._day = "1";
      this._dayOfWeek = "?";
    } else if (value === "Monthly") {
      this._month = "*";
      this._day = "1";
      this._dayOfWeek = "?";
    } else if (value === "Weekly") {
      this._month = "*";
      this._day = "?";
      this._dayOfWeek = "2";
    } else if (value === "Daily") {
      this.month = "*";
      this._day = "*";
      this._dayOfWeek = "?";
    }
    this._mode = value;
  }

  get dayOfWeek(): string {
    return this._dayOfWeek;
  }

  set dayOfWeek(value: string) {
    if (this._day !== "?" && value !== "?") {
      this._day = "?";
    }
    if (value === "?") {
      this._day = "*";
    }
    this._dayOfWeek = value;
  }

  get month(): string {
    return this._month;
  }

  set month(value: string) {
    this._month = value;
  }

  get day(): string {
    return this._day;
  }

  set day(value: string) {
    if (this._dayOfWeek !== "?" && value !== "?") {
      this._dayOfWeek = "?";
    }
    if (value === "?") {
      this._dayOfWeek = "*";
    }
    this._day = value;
  }

  get hour(): string {
    return this._hour;
  }

  set hour(value: string) {
    this._hour = value;
  }

  get minute(): string {
    return this._minute;
  }

  set minute(value: string) {
    this._minute = value;
  }

  get second(): string {
    return this._second;
  }

  set second(value: string) {
    this._second = value;
  }

  getMode(): string {
    const isAny = (value: string) => value === "*" || value === "?";

    // Cron format: second minute hour day month dayOfWeek
    // https://www.quartz-scheduler.net/documentation/quartz-3.x/tutorial/crontriggers.html#cron-expressions
    if (this._dayOfWeek !== "?") {
      // Example: 0 0 0 ? * 1
      return "Weekly"; // Runs every week
    } else if (isAny(this._day) && isAny(this._month)) {
      // Example: 0 0 0 * * ?
      return "Daily"; // Runs every day
    } else if (isAny(this._month)) {
      // Example: 0 0 0 1 * ?
      return "Monthly"; // Runs every month
    } else {
      // Example: 0 0 0 1 1 ?
      return "Yearly"; // Runs every year
    }
  }

  toCronExpression(): string {
    return `${this._second} ${this._minute} ${this._hour} ${this._day} ${this._month} ${this._dayOfWeek}`;
  }

  parse(): void {
    if (!this._cronExpression) {
      throw new Error("Cron expression is empty");
    }

    const parts = this._cronExpression.split(" ");
    if (parts.length !== 6) {
      throw new Error("Invalid cron expression");
    }

    const [second, minute, hour, day, month, dayOfWeek] = parts;

    this._second = second;
    this._minute = minute;
    this._hour = hour;
    this._day = day;
    this._month = month;
    this._dayOfWeek = day === "?" ? dayOfWeek : "?";
    this._mode = this.getMode();
  }
}