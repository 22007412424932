<template>
  <div ng-non-bindable :class="getRootElementClasses()">
    <DebugView/>

    <div class="page" :class="pageClasses">
      <!-- Page header -->
      <header v-if="withHeader" class="page-header is-fixed no-print">
        <div>
          <Button v-if="(!authState.sharedDashboardInfo?.single && displaySection === 'dashboard') || displaySection === 'presentation'" icon="pi pi-bars" class="p-button-rounded p-button-primary p-button-icon-only p-button-text p-button-lg mr-2 sm:mr-3 md:mr-4" @click="visibleSidebar = !visibleSidebar"/>
          <img
            v-if="isDarkTheme"
            :src="'/assets/bitpool-logo-white.svg'"
            width="134"
            alt="Bitpool"
          />
          <img
            v-else-if="!isDarkTheme"
            :src="'/assets/bitpool-logo-dark.svg'"
            width="134"
            alt="Bitpool"
          />
          <img
            v-if="displaySection === 'presentation'"
            :src="'/assets/bitpool-icon-white.svg'"
            width="42"
            alt="Bitpool"
            class="presentation-logo-small-screen"
          />
          <img
            :src="'/assets/bitpool-logo-white.svg'"
            width="134"
            alt="Bitpool"
            class="logo-small-screen"
          />
        </div>
        <div v-if="displaySection === 'presentation'" class="ml-auto">
          <SharedPresentationControlsView
            :pane="dashboardTypes.Slides"
            :slide="getUrlParameter('slide', true)"
          ></SharedPresentationControlsView>
        </div>
      </header>

      <!--  Menu in left aside or sidebar-->
      <Sidebar v-model:visible="visibleSidebar" v-if="(!authState.sharedDashboardInfo?.single && displaySection === 'dashboard') || displaySection === 'presentation'" class="sidebar-menu sidebar-dark has-mini-layout no-print">
        <NavMenuView 
          :displaySection="displaySection"
          :afterNavigateTo="afterNavigateTo"
          :openNewDashboardDialog="(parentDashboard: Space | null | undefined) => {}"
          :openRenameDashboardDialog="(dashboard: Space | null | undefined) => {}"
        />
      </Sidebar>
      
      <div class="page-container" ref="pageContainer">
        <main>
          <div v-if="displaySection === 'dashboard'">
            <DashboardView
              :pane="dashboardTypes.Shared"
              :dashboardId="getUrlParameter('id', true)"
            ></DashboardView>
          </div>
          <div v-if="displaySection === 'widget'">
            <SharedWidgetView/>
          </div>
          <div v-if="displaySection === 'presentation'">
            <SharedPresentationView
              :pane="dashboardTypes.Slides"
              :slide="getUrlParameter('slide', true)"
            ></SharedPresentationView>
          </div>
          <div v-else-if="displaySection === 'not_found'">Not found</div>
        </main>
      </div>

      <footer class="page-footer no-print"></footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AngularOptions from "@/models/AngularOptions";
import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';
import NavMenuView from '@/components/views/NavMenuView.vue';
import Menu from 'primevue/menu';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import DashboardView from '@/components/views/dashboards/DashboardView.vue';
import SharedWidgetView from '@/components/views/dashboards/SharedWidgetView.vue';
import SharedPresentationView from '@/components/views/dashboards/SharedPresentationView.vue';
import DebugView from "@/components/views/DebugView.vue";
import CustomWindow from '@/CustomWindow';
import RootState from "@/store/states/RootState";
import AuthState from "@/store/states/AuthState";
import Ripple from 'primevue/ripple';
import { DashboardType } from "@/models/dashboard/DashboardType";
import SharedPresentationControlsView from "@/components/views/dashboards/SharedPresentationControlsView.vue";
import EventBusHelper from "@/helpers/EventBusHelper";
import { Emitter } from "mitt";
import { usePageStore } from "@/stores/page";
import { Space } from "@/models/dashboard/Space";

declare const window: CustomWindow;

@Component({
  components: {
    Button,
    Sidebar,
    NavMenuView,
    Menu,
    ProgressSpinner,
    Dialog,
    DashboardView,
    SharedWidgetView,
    SharedPresentationView,
    DebugView,
    SharedPresentationControlsView
  },
  directives: {
    'ripple': Ripple
  }
})
class ViewPage extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;
  displaySection = "";
  parts: string[] = [];
  visibleSidebar = false;
  sidebarDisabledFrom = 1200;
  visibleSidebarWidgets = false;
  windowWidth = window.innerWidth;
  dashboardTypes = DashboardType;

  get rootState(): RootState {
    return this.$store.state;
  }
  
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }

  pageStore = usePageStore();
  
  get withHeader(): boolean {
    return this.displaySection !== 'widget' || !!this.authState.sharedWidgetInfo?.withHeader;
  }

  afterNavigateTo(): void {
    this.visibleSidebar = false;
    this.visibleSidebarWidgets = false;
  }

  oldPath = "";
  oldSearch = "";

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  updateState(): void {
    this.urlSearchParams = new URLSearchParams(window.location.search);
    const path = window.location.pathname;
    const search = window.location.search;
    if (this.oldPath !== path || this.oldSearch !== search) {
      this.oldPath = path;
      this.oldSearch = search;
      this.parts = path.split("/");
      let newDisplaySection = "";
      // routes here, becase vue native routes conflicting with angularjs routes
      if (this.parts.length > 2 && this.parts[2].toLowerCase() === "dashboard") {
        newDisplaySection = "dashboard";
      } else if (this.parts.length > 2 && this.parts[2].toLowerCase() === "widget") {
        newDisplaySection = "widget";
      } else if (this.parts.length > 2 && this.parts[2].toLowerCase() === "presentation") {
        newDisplaySection = "presentation";
      }
      if (newDisplaySection) {
        this.displaySection = newDisplaySection;
      } else {
        this.displaySection = "not_found";
      }
    }
  }

  unsubscribeLocationChange: any = null;

  created(): void {
    this.pageStore.$reset();
    this.emitter.on("window_size_changed_debounce", this.onResize);
    this.updateState();
    // subscribe to angularjs route change event
    this.unsubscribeLocationChange = this.angularOptions.angularScope.$on('$locationChangeSuccess', ($event: any, next: any, current: any) => { 
      this.updateState();
      if (this.$refs.pageContainer) {
        (this.$refs.pageContainer as HTMLElement).scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.onResize);
    if (this.unsubscribeLocationChange) {
      this.unsubscribeLocationChange();
    }
  }

  onResize(): void {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth >= this.sidebarDisabledFrom) {
      this.visibleSidebar = false;
    }
  }

  urlSearchParams: URLSearchParams | null = null;

  getUrlParameter(name: string, toLower: boolean): string | null | undefined {
    if (!this.urlSearchParams) {
      this.urlSearchParams = new URLSearchParams(window.location.search);
    }
    let value = this.urlSearchParams?.get(name);
    if (value && toLower) {
      value = value.toLowerCase();
    }
    return value;
  }

  getRootElementClasses(): string[] {
    const result: string[] = [
      "mini-layout",
      `share-${this.displaySection}`
    ];
    if (this.rootState.isFullscreen) {
      result.push("fullscreen-mode");
    }
    if (this.authState.sharedDashboardInfo?.single) {
      result.push("hide-nav-menu");
    }
    return result;
  }
  
  get pageClasses(): string[] {
    const result: string[] = [];
    if (this.withHeader) {
      result.push("with-header");
    } else {
      result.push("without-header");
    }
    if (this.displaySection === 'presentation') {
      result.push("presentation-page");
    }
    return result;
  }
}

export default ViewPage;
</script>
